import Select, { createFilter } from 'react-select';

import style from "./SelectInput.module.css"

function SelectInput(props) {
  return (
    <div className={style.InputRow} style={{width: "100%", marginLeft: "0px", display: "flex", alignItems: "center"}}>
        <div className={style.selectContainer} style={{flex: "8", textAlign: "left"}} >
            <Select    
                value={props.value ? props.options.filter((f) => f.value == props.value.value)  : ""}
                onChange={event => props.handleSelectChange(event)}
                placeholder={props.placeholder}
                noOptionsMessage={() => props.noOptionsMessage}
                options={props.options}
                filterOption={createFilter({ignoreAccents: false})}
            />
        </div>
    </div>
  );
}

export default SelectInput