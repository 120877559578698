import React, { useContext, useEffect, useState } from 'react'
import Header from './Header/Header'

import { useNavigate } from 'react-router-dom';

import CardSub from './Card/CardSub';
import CardDoppelt from "./Card/CardDoppelt"
import style from "./Card/Card.module.css"

import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';

import SelectInput from './Bausteine/SelectInput/SelectInput';

import axios from 'axios';
import Swal from 'sweetalert2';

import PartnerConnectModal from './Bausteine/PartnerConnectModal';
import PartnerConnectCard from './Bausteine/PartnerConnect';
import { AuthContext } from '../store/auth-context';
import { UserContext } from '../store/user-context';

function DokumenteWeiterleitung() {
  
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);
  const { user, partnerListe, setPartnerListe } = useContext(UserContext);

  //const [showPartnerSelect, setShowPartnerSelect] = useState(false);

  //const [kategorieInput, setKategorieInput] = useState("Alle Partner");
  const [partnerInput, setPartnerInput] = useState("");

  const [selectedPartnerName, setSelectedPartnerName] = useState(false);

  // Connectete Partner
  //const [partnerListeModal, setPartnerListeModal] = useState([]);


  /*
  const [kategorieListe, setKategorieListe] = useState([
    {
      label: "Alle Partner",
      value: "Alle Partner"
    },{
      label: "Architekten",
      value: "Architekten"
    },{
      label: "Auto",
      value: "Auto"
    }, {
      label: "Multimedia",
      value: "Multimedia"
    }
  ]);
  */

  useEffect(() => {

    if(currentUser?.uid) {

      axios({
          method: 'get',
          headers: {
              'Authorization': 'Bearer ' + currentUser.accessToken
          },
          url: process.env.REACT_APP_BACKEND+"/partner/connected-partner",
      })
      .then(resData => {

        let liste = [];
        resData.data.forEach(elem => {
          if(elem.aboNeedsAccept && elem.aboStatus == "open") {
            liste.push({value: elem.slug, label: elem.partner_name+ " | warte auf Bestätigung", isDisabled: true})
          }
          else if(elem.aboNeedsAccept && elem.aboStatus == "declined" ) {
            liste.push({value: elem.slug, label: elem.partner_name+ " | Abo abgelehnt", isDisabled: true})
          }
          else if(elem.aboNeedsAccept && elem.aboStatus == "canceled" ) {
            liste.push({value: elem.slug, label: elem.partner_name+ " | Abo beendet", isDisabled: true})
          }
          else {
            //accepted
            liste.push({value: elem.slug, label: elem.partner_name})
          }
        })
        setPartnerListe(liste);
      })
      .catch(error => {
        Swal.fire({
          "icon": "error",
          "title": "Fehler",
          "html": "Fehler beim Abrufen deiner Partner."
      });
      })
    }
  },[currentUser])

  

  const handlePartnerChangeModal = (data) => {
    //console.log(data.value);
    setPartnerInput(data.value);

    // VIN1 CRM Backend => PartnerCollection => Partner Object in subscribers hinterlegen

    // In Firebase User Doc Daten subscriptions Array Feld den "unique" partner_name pushen

    // Partner in aktuelle Liste direkt aufnehmen!
  }

  const handlePartnerChange = (e) => {
    setSelectedPartnerName(e.value);
  }


  //const handleKategorieChange = (data) => {
    //console.log(data.value);
  //  setKategorieInput(data.value);

    // Axios set Partner in DB

    // Partner in aktuelle Liste direkt aufnehmen!
 // }





  return (
    <>
      <Header url={"/news"}/>


      {/*
          <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
            <Modal.Header closeButton>
                <Modal.Title>Connecte dich mit einem Partner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                <p>Wähle im folgenden Dropdown einen Partner aus. Du kannst durch Eingaben in dem Feld nach Partnern suchen.</p>
                <SelectInput placeholder={"Suche nach einem Partner..."} noOptionsMessage={"Kein Partner gefunden."} options={partnerListeModal} handleSelectChange={handlePartnerChangeModal} />

            </Modal.Body>
            <Modal.Footer>
                <p
                  style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#656565", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                  onClick={() => setShowModal(!showModal)}
                >
                  Schließen
                </p>
                <p
                  style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                  onClick={() => setShowModal(!showModal)}
                >
                  Mit Partner connecten
                </p>
            </Modal.Footer>
          </Modal>
      */}

      <Container style={{maxWidth: "800px"}}>

        <Row className="justify-content-center">
          <Col style={{ textAlign: "center" }}>
            <img src={require("../img/Dokumente.png")} alt="" style={{maxWidth: "160px", marginTop: "40px"}} />
            <h1 style={{color: "#520da7"}}>Deine Dokumente sicher im Griff</h1><br />
            <p className="text-center">
              Hier kannst Du Deine Dokumente und Verträge einsehen oder Deine eigenen Dateien hochladen.
            </p>
          </Col>
        </Row>

        
        <Row className="justify-content-center">
          <Col xs={12} md={12} style={{marginTop: "20px"}}> 
            <CardDoppelt
              url={"privat"}
              img={"PrivateDokumente.png"}
              title={"Private Dokumente"}
              text={"Deine Dokumente bleiben deine Privatsphäre. Lade jetzt Deine wichtigsten Unterlagen hoch und schütze sie vor Verlust."}
            />
          </Col>
          {/*
              <Col xs={12} md={4} style={{marginTop: "20px"}}>
                <Card url={"/neue-dokumente"} img={"briefkasten.png"} title={"Letzte neue Dokumente"} />
              </Col>
            */}
        </Row>


        <Row className="justify-content-center">
          <Col xs={12} md={12} style={{marginTop: "20px"}}
            onClick={() => navigate("/partner/vin1", {state: { category: "Dokumente" }})}
          > 
            <CardDoppelt
              //url={{"/partner/vin1", state: { category: "Dokumente" } }}
              img={"VIN1Dokumente.png"}
              title={"VIN1 Dokumente"}
              text={"Hier findest Du Dokumente, wie Angebote, Rechnungen und Verträge Deiner VIN1 Vorteilswelt."}
            />
          </Col>
        </Row>




        <PartnerConnectCard />




        <Row className="justify-content-center">
          <Col xs={12} md={12} style={{marginTop: "20px"}}>
            <div
              onClick={() => {}}
              className={style.cardContainerDoppelt}
              style={{cursor: "default"}}
            >

              <img className={style.cardImgDoppelt} src={require("../img/PartnerDokumente.png")} alt="" />
              <div className={style.cardTitleDoppeltContainer}>
                  <p className={style.cardTitle}>Partner Dokumente</p>
                  <p>Hier kannst Du Dokumente Deiner persönlichen Vertragspartner einsehen oder hochladen.</p>
                  
                  <div>
                    <SelectInput
                        value={{'label': selectedPartnerName, 'value': selectedPartnerName}}
                        options={partnerListe}
                        placeholder={"Wähle Partner aus"}
                        noOptionsMessage={() => "Du bist noch mit keinem Partner verbunden."}
                        handleSelectChange={handlePartnerChange}
                    />

                  </div>

                  <div className="mt-3">
                    {selectedPartnerName &&
                      <Button
                        style={{background: "var(--primary-color)", margin: "0px", border: "var(--primary-color)"}}
                        onClick={() => navigate("/partner/"+selectedPartnerName )}
                        //onClick={() => navigate("/partner/"+selectedPartnerName, { state: { category: "Dokumente" } } )}
                      >
                        Dokumente öffnen
                      </Button>
                    }
                    {!selectedPartnerName &&
                      <Button
                        style={{opacity: "50%", background: "var(--primary-color)", margin: "0px", border: "var(--primary-color)"}}
                        onClick={() => Swal.fire({icon: "info", title: "", text: "Wähle zum Fortfahren einen Partner aus."})}
                      >
                        Dokumente öffnen
                      </Button>
                    }
                  </div>
              </div>
          </div>
          </Col>
        </Row>

        <div style={{marginBottom: "160px"}} />
      
      </Container>

    </>
  );
}

export default DokumenteWeiterleitung