import React, { useState } from 'react'
import Header from '../../Header/Header'
import { useNavigate } from 'react-router-dom';

import WebViewModal from "../../Bausteine/WebViewModal/WebViewModal";

import { Container, Row, Col } from 'react-bootstrap/';

import style from './Kredite.module.css'
//import ImageWithText from '../ImageWithText/ImageWithText';

function Kredite() {

  const ColorTheme = "#0B3499";

  let navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState(false);

  const handleExternalURL = (url) => {

    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
  }

  return (

    <>
      <Header />
      <Container style={{maxWidth: "800px"}}>
      <Row style={{justifyContent: "center"}}>
          <Col style={{textAlign: "center"}}>
            <h1 style={{color: ColorTheme}}>Übersicht Kredite</h1>
          </Col>
        </Row>

        <br />
        <br />

 


        <Row className={style.Row}>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 2}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Baufinanzierung</h5>
            <p className="text-center">
              Wir sorgen für eine schnelle Finanzierung, sodass dein Bauvorhaben nicht verzögert wird.
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/finanzen/")}>Mehr Informationen &gt;</button>*/}   
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Kredit", subcategory: "Baufinanzierung" }})}>Jetzt beraten lassen &gt;</button>   
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Baufinanzierung.png")} alt="" className={style.ColImg} />
          </Col>
        </Row>

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/MobilKredit.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Kleinkredit</h5>
            <p className="text-center">
              Sofortentscheidung über die Bewilligung. Damit du besser planen kannst.
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/finanzen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Kredit", subcategory: "Kleinkredit" }})}>Jetzt beraten lassen &gt;</button>   
          </Col>
        </Row>



        <Row className={style.Row}>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 2}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Sonderfinanzierung</h5>
            <p className="text-center">
              Geschwindigkeit ist alles. Deine Sonderfinanzierung wird bei uns direkt bearbeitet.
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/finanzen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Kredit", subcategory: "Sonderfinanzierung" }})}>Jetzt beraten lassen &gt;</button>   
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Verbraucherkredit.png")} alt="" className={style.ColImg} />
          </Col>
        </Row>

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/DatenSicher.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Deine Daten sind sicher</h5>
            <p className="text-center">
              Wir machen uns für Datenschutz stark, damit Deine Daten Dir gehören.
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/finanzen/")}>Mehr Informationen &gt;</button>*/}
            {/*<button className={style.Btn} onClick={() => navigate("/beratung")}>Jetzt beraten lassen &gt;</button>*/} 
          </Col>
        </Row>

        
        <br />
        <br />



        <div className="row">
          <div className={style.BottomCard}>
            <h4 className="text-center">Jetzt mit Leichtigkeit Kredit beantragen</h4>
           {/* <button className={style.BtnNormal} onClick={() => handleExternalURL("https://vin1.eu/finanzen/")}>Hier mehr erfahren &gt;</button>*/}
            <button className={style.BtnNormal} style={{marginTop: "16px"}} onClick={() => navigate("/beratung", { state: { category: "Kredit" }})}>Jetzt Kontakt aufnehmen &gt;</button>
          </div>
        </div>

        <br />
        <br />
        <br />

        <Row style={{marginBottom:"80px"}}>
          <Col style={{textAlign: "center"}}>
            <button className={style.BtnNormal} onClick={() => navigate("/dokumente/finanzen")}>Zu Deiner Dokumentenablage &gt;</button>
          </Col>
        </Row>

        {showModal && (
          <WebViewModal
            url={modalUrl}
            hideModal={setShowModal}
          />
        )}

      </Container>
    </>
  )
}

export default Kredite


/*
    <Row style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <Col xs={12} md={6}>
            <ImageWithText img={"img/finanzen/baufinanzierungen.jpg"} title={"Baufinanzierungen"} subtitle={"Schneller Abschluss"} />
          </Col>
          <Col xs={12} md={6}>
            <ImageWithText img={"img/finanzen/kleinkredite.jpg"} title={"Kleinkredite"} subtitle={"Sofortentscheidung"} />
          </Col>
          <Col xs={12} md={6}>
            <ImageWithText img={"img/finanzen/sonderfinanzierungen.jpg"} title={"Sonderfinanzierungen"} subtitle={"Geschwindigkeit ist alle"} />
          </Col>
          <Col xs={12} md={6}>
            <ImageWithText img={"img/finanzen/datensindsicher.jpg"} title={"Deine Daten sind sicher"} subtitle={"Verantwortungsvoller Dateneinsatz"} />
          </Col>
        </Row>
        */