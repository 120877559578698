import React, { createContext, useState, useContext, useEffect } from 'react';

import { AuthContext } from './auth-context';
import { db } from "../firebase";
import { collection, doc, getDocs, getDoc, onSnapshot, query, where } from "firebase/firestore";

import axios from 'axios';

// Create the AuthContext
export const UserContext = createContext({
    user: {},
    unreadDocs: [],
});

// Create the AuthContextProvider component
export const UserProvider = ({ children }) => {

  const { currentUser } = useContext(AuthContext);

  const [user, setUser] = useState({});
  const [partnerListe, setPartnerListe] = useState([]);
  const [unreadDocs, setUnreadDocs] = useState([]);


  const updateUser = (newUserData) => {
    setUser(newUserData);
  };



  const updateUnreadDocs = (newArr) => {
    setUnreadDocs(newArr);
  };

  useEffect(() => {

    
    async function getUserData() {
       // Get UserData from CRM!
      try {
        let kundenDaten = await axios({
          method: "GET",
          headers: {
            'Authorization': 'Bearer ' + currentUser.accessToken
          },
          url: process.env.REACT_APP_BACKEND+"/my-vin1/meine-daten"
        });

        if(kundenDaten.status === 200) {
          setUser(kundenDaten.data);
        } else {
          setUser({});
        }
      } catch(err) {
        console.error("Error:", err);
        setUser({error: err});
      }

      // ALT.. Kundendaten von Firebase
      /*
        const docRef = doc(db, "Users", currentUser.uid);
        const userData = await getDoc(docRef);
  
        if (userData.data()) {
          setUser(userData.data());
        }
      */
    }


    // ungelesene Dokumente
    async function getUnreadDocs() {

      const colRef = collection(db, "Users", currentUser.uid, "Dokumente");
      
      let q = query(
        colRef,
        where("gelesen", "==", false)
      );

      // Listen for changes to the query
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        let count = querySnapshot.size;
        setUnreadDocs(count);
      });

      // Return a function to unsubscribe from the query
      // funktioniert nicht bei logout...
      // muss in Firebase/Auth Context
      return () =>  unsubscribe(); 
    
    } 
      
    let unsubscribe;
    if(unsubscribe) {
        unsubscribe();
    }

    if(currentUser?.uid) {
      getUnreadDocs();
      getUserData();
      //getPartner(); // abonnierte Parter laden + updaten!!
    }

  }, [currentUser]);


  // Value object to be provided by the context
  const userContextValue = {
    user,
    updateUser,
    unreadDocs,
    updateUnreadDocs,
    partnerListe,
    setPartnerListe
  };


  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};