import React, { useState, useEffect, useContext } from 'react'
import Header from '../../Header/Header'

import { useLocation } from 'react-router-dom'

import CardSmall from "../../Card/CardSmall"
import CardSub from "../../Card/CardSub"
import CardUpload from "../../Card/CardUpload"
import CardDokument from '../../Card/CardDokument'

import axios from 'axios';
import IMGViewModal from '../../Bausteine/IMGViewModal/IMGViewModal'

import { Container, Row, Col } from 'react-bootstrap';

import { ref, uploadBytesResumable, getDownloadURL, uploadTask, deleteObject } from "@firebase/storage";
import { db, storage } from "../../../firebase";
import { collection, doc, addDoc, getDocs, deleteDoc, query, where, onSnapshot, orderBy } from "firebase/firestore"; 
import Swal from 'sweetalert2'
import { makeID } from '../../../util/makeID'
import { AuthContext } from '../../../store/auth-context'


function DokuVersicherung() {


    const ColorTheme = "#87baf6";

    const cards = [
      {
        img: "Gesundheit.png",
        title: "Gesundheit",
        key: "Gesundheit"
      },
      {
        img: "RisikoVorsorge.png",
        title: "Risiko & Vorsorge",
        key: "Risiko"
      },
      {
        img: "AlterPflege.png",
        title: "Alter & Pflege",
        key: "Alter"
      },
      {
        img: "HaftRecht.png",
        title: "Haft & Recht",
        key: "Haft"
      },
      {
        img: "Mobil.png",
        title: "Fahrzeuge",
        key: "Fahrzeuge"
      },
      {
        img: "Immo.png",
        title: "Haus & Wohnung",
        key: "Immo"
      },
      {
        img: "Tier.png",
        title: "Tier",
        key: "Tier"
      },
      {
        img: "HandyReise.png",
        title: "Handy & Reise",
        key: "HandyReise"
      }
    ]

    const kategorien = [
      {
        Oberkategorie: "Gesundheit",
        Unterkategorien: ["Privat", "Gesetzlich", "Zahnzusatz", "Krankenzusatz", "Ausland"],
      },
      {
        Oberkategorie: "Risiko",
        Unterkategorien: ["Risiko", "Unfall", "Berufsunfähigkeit", "Grundfähigkeit", "Sterbegeld"],
      },
      {
        Oberkategorie: "Alter",
        Unterkategorien: ["Rente", "Riester", "Basis-Rente", "Sofort", "Pflege"]
      },
      {
        Oberkategorie: "Haft",
        Unterkategorien: ["Privathaftpflicht", "Berufshaftpflicht", "Rechtsschutz"]
      },
      {
        Oberkategorie: "Fahrzeuge",
        Unterkategorien: ["KFZ", "Zweirad", "Boot", "Flugzeug", "Sontige"]
      },
      {
        Oberkategorie: "Immo",
        Unterkategorien: ["Wohngebäude", "Elementar", "Hausrat", "Mietkaution", "Neubau"]
      },
      {
        Oberkategorie: "Tier",
        Unterkategorien: ["Haftpflicht", "Kranken"]
      },
      {
        Oberkategorie: "HandyReise",
        Unterkategorien: ["Handy", "Reise", "Auslandskranken"]
      }
    ];


    const { currentUser } = useContext(AuthContext);

    // Preview Modal JPG + PNG
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalImgSrc, setModalImgSrc] = useState("");

    const location = useLocation();
    const navigateBack = location?.state?.navigateBack ? location.state.navigateBack : "/partner/vin1";
    const url_state = location?.state?.navigateBack ? {category: "Dokumente"} : {};

    const [categoryTwo, setCategoryTwo] = useState(location.state?.category ?? "");
    const [categoryThree, setCategoryThree] = useState(location.state?.subcategory ??"");


    const [pickedFiles, setPickedFiles] = useState([]);

    const [progress, setProgress] = useState(0);
    const [privateDocs, setPrivateDocs] = useState([]);

    
     function handleCategory(categoryTwo) {
       setCategoryTwo(categoryTwo);
       setCategoryThree(null);
     }
    
     function handleSubCategory(categoryThree) {
       setCategoryThree(categoryThree);
     }



    // Scroll into View
    useEffect(() => {

      // If a category has been selected, scroll to the subcategories container
      if (categoryTwo && !categoryThree) {
        const element = document.getElementById('categoryThree');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
      
      if (categoryTwo && categoryThree) {
        const element = document.getElementById('documentContainer');
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, [categoryTwo, categoryThree]);

    
    

    useEffect(() => {

      // Get Dokumente
      async function getDokumente() {

        const colRef = collection(db, "Users", currentUser.uid, "Dokumente");
        const q = query(
          colRef,
          where("category", "==", "Versicherung"),
          where("categoryTwo", "==", categoryTwo),
          where("categoryThree", "==", categoryThree),
          orderBy("created", "desc")
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            let DocumentsArr = [];
            snapshot.forEach(doc => {
                if (doc.data()) {
                    DocumentsArr.push({
                        id: doc.id,
                        data: doc.data()
                    });
                }
            });
            setPrivateDocs(DocumentsArr);
        });

        // Make sure to unsubscribe from your listener when you no longer need it
        return unsubscribe;  
      }
      if(currentUser?.uid && categoryTwo && categoryThree) {
        const unsubscribe = getDokumente();
      }

    }, [currentUser, categoryTwo, categoryThree]);
    



    function handleFile(e) {
      //console.log(e.target.files[0].size);

        setPickedFiles([...pickedFiles, {
          id: makeID(8),
          file: e.target.files[0],
          name: e.target.files[0].name,
          size: e.target.files[0].size,
          type: e.target.files[0].type,
          path: e.target.value
        }
      ]);
      //console.log(pickedFiles);
    }

    function removeFileUploadHandler (id) {
      //console.log("remove: " + name);
      setPickedFiles(pickedFiles.filter(obj => obj.id !== id));
    }
     
    function handleFileUpload() {

      if(pickedFiles.length >=1) {
  
        for (let i = 0; i < pickedFiles.length; i++) {
  
         // Upload Resumable....
         let UUID = crypto.randomUUID();
         //const storageRef = ref(storage, "/"+auth.currentUser.uid+"/"+UUID+pickedFiles[i].name);
         const storageRef = ref(storage, "/"+currentUser.uid+"/"+UUID+pickedFiles[i].name);
         const uploadTask = uploadBytesResumable(storageRef, pickedFiles[i].file);
 
         uploadTask.on("state_changed", (snapshot) => {
             //const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
             //setProgress(progress); 
         },
         (error) => {
           console.log("Error: " +error);
           //https://firebase.google.com/docs/storage/web/upload-files
         },
         () => {
             // Hier kann tein Callback definiert werden, nach erfolgreichem Upload
             // Erstellen des "Meta-Dokuments zum Upload"
             getDownloadURL(uploadTask.snapshot.ref)
             .then((url) => {
 
        
               //addDoc(collection(db, "Users", auth.currentUser.uid, "Versicherungen"), {     
               addDoc(collection(db, "Users", currentUser.uid, "Dokumente"), {     
                 title: UUID+pickedFiles[i].name,
                 description: "",
                 uploader: "self",
                 category: "Versicherung",
                 categoryTwo: categoryTwo,
                 categoryThree: categoryThree,
                 created: new Date(),
                 mimeType: pickedFiles[i].type,
                 size: pickedFiles[i].size,
                 url: url
               })
               .then((docRef) => {
                   // Mit Snapshot Listener dürfen die Docs nicht manuell angepasst werden, weil dann doppelt.
                  /*setPrivateDocs([
                    {
                      id: docRef.id,
                      data: {
                        title: UUID+pickedFiles[i].name,
                        description: "",
                        uploader: "self",
                        category: "Immobilien",
                        categoryTwo: categoryTwo,
                        categoryThree: categoryThree,
                        created: new Date(),
                        mimeType: pickedFiles[i].type,
                        size: pickedFiles[i].size,
                        url: url
                      }
                    },
                    ...privateDocs
                  ])
                  */
               })
               .catch((error) => {
                 //console.log(error);
                 Swal.fire({
                   title: "Dokument Upload",
                   text: "Es gab einen Fehler beim Upload deiner Dokumente. Bitte versuche es später erneut.",
                   icon: "error"
                 });
 
               });
 
               });
         });
 
 
       } // for-loop multiple files
            
             
          Swal.fire({
            title: 'Erfolg',
            html: 'Alle Dateien wurden erfolgreich hochgeladen.',
            icon: 'success'
          });
          setPickedFiles([]);
      }
  
    }




    async function deleteFileAndDoc(id, filename) {

      const storageRef = ref(storage, "/"+currentUser.uid+"/"+filename);
      // Delete the file
      deleteObject(storageRef).then(() => {
  
        // if file deleted, delete metadata
        deleteDoc(doc(db, "Users", currentUser.uid, "Dokumente", id))
        .then (() => {
          //setPrivateDocs(privateDocs.filter(docs => docs.id !== id));
        })
        .catch(error =>  {
          console.log(error);
        })
      })
      .catch((error) => {
        console.log(error);
      });
      
    }
  


  return (
    <>
      <Header
        url={navigateBack}
        url_state={url_state}
      />
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col className="text-center">
            <img
              src={require("../../../img/Dokumente.png")}
              alt=""
              style={{ maxWidth: "120px", marginTop: "30px"}}
            />
            <h1 style={{color: ColorTheme}}>Dokumente Versicherungen</h1><br />
            <p className="text-center">
              Hier kannst du deine Unterlagen rund um das Thema Versicherungen verwalten.
            </p>
            <p className="text-center">
                Wähle eine Kategorie
            </p>
          </Col>
        </Row>


   

        <Row className="mb-5 justify-content-center">

          <Col key={"offen"} xs={12} md={5} style={{marginTop: "20px"}} onClick={() => handleCategory("Offene Bestätigungen")}>
              <CardSub title={"Offene Bestätigungen"} selected={categoryTwo == "Offene Bestätigungen" ? 1 : null} color={ColorTheme} />
          </Col>
          

          <Col key={"abgelehnt"} xs={12} md={5} style={{marginTop: "20px"}} onClick={() => handleCategory("Abgelehnt")}>
              <CardSub title={"Abgelehnte Vorgänge"} selected={categoryTwo == "Abgelehnt" ? 1 : null} color={ColorTheme} />
          </Col>
          
        </Row>


        
        <Row style={{justifyContent: "center"}}>

          {cards.map(card => 
            <Col
              key={card.title}
              xs={6} md={3} style={{marginTop: "20px"}} onClick={() => handleCategory(card.key)}
            >
              <CardSmall img={card.img} title={card.title} selected={categoryTwo == card.key ? 1 : null} color={ColorTheme} />
            </Col>  
          )}

        </Row>
        
        <br />

        <Row
          id="categoryThree"
          style={{justifyContent: "center", marginTop: "30px"}}
        >

          {categoryTwo && categoryTwo !== "Dokumente" && categoryTwo !== "Offene Bestätigungen" && categoryTwo !== "Abgelehnt" && 
            <Col xs={12} md={12} style={{marginBottom: "0"}}>
              <p className="text-center">Bitte wähle eine der folgenden Unterkategorien</p>
            </Col>
          }

          {categoryTwo &&
            (kategorien.find((key) => key.Oberkategorie === categoryTwo))?.Unterkategorien.map((u, index) => 
              <Col
                  key={index}
                  xs={6} md={3}
                  style={{marginTop: "20px"}}
                  onClick={() => handleSubCategory(u)}
              >      
                {categoryThree === u &&
                  <CardSub key={index} title={u} selected={1} color={ColorTheme} />
                } 
                {categoryThree !== u &&
                  <CardSub key={index} title={u} />
                }  
              </Col>
          )}  
        </Row>



        <Row style={{marginTop: "16px", justifyContent: "center", marginBottom: "50px"}}>
        {/* Dokument Upload */}
        {categoryTwo && categoryTwo !== "Dokumente" && categoryThree && categoryTwo !== "Offene Bestätigungen" && categoryTwo !== "Abgelehnt" &&
          <Col
            xs={12} md={12}
            className="uploadContainer"
          >
            <p className="text-center" style={{marginTop: "30px"}}>Deine gewählte Kategorie: <span className="fw-bold">{cards.find(c => c.key == categoryTwo).title} &gt; {categoryThree}</span></p>

            <h4 className="text-center">Dateiupload</h4>
            <p>1. Wähle deine Dateien aus (Fotos oder PDF)</p>

            <label htmlFor="docUpload" className="uploadBtn" style={{cursor: "pointer"}}>
              Datei auswählen
              <input
                id="docUpload"
                type="file"
                accept='application/pdf, image/png, image/jpeg'
                style={{display: "none"}}
                onChange={handleFile}
              />
            </label>
            

      
            {pickedFiles.length > 0 &&
              <div className="text-center" style={{marginTop: "20px", width: "100%"}}>
                     {/*<p>2. Gib der Datei eine kurze und knackige Beschreibung (z.B. "Perso" oder "Fahrzeugbrief")</p>*/}
                  <p>2. Lade das/die Dokument/-e hoch</p>
                  
                  {pickedFiles.map(file => 
                      <CardUpload key={file.id} data={file} removeFileUploadHandler={removeFileUploadHandler} />
                  )}

                <button className="uploadBtn" style={{cursor: "pointer"}} onClick={() => handleFileUpload()}>Dokumente hochladen</button>
              </div>
              
              
            }

            </Col>
          }
        </Row>



        <Row
          id="documentContainer"
          style={{justifyContent: "center", marginBottom: "80px", marginTop: pickedFiles.length > 0 ? "50px" : "16px"}}
        >
          
          {/* Dokumenten Liste */}
          {categoryTwo && categoryThree &&
            <Col
                xs={12} md={12}
            >
              <h4 className="text-center" style={{marginBottom: "20px"}}>Meine Dokumente</h4>

              {(privateDocs.filter((key) => key.data.categoryTwo == categoryTwo && key.data.categoryThree === categoryThree)).length === 0 &&
                <p style={{marginTop: "10px"}} className="text-center">
                  Noch keine Dokumente vorhanden.
                </p>
              }
              
              {(privateDocs.filter((key) => key.data.categoryTwo === categoryTwo && key.data.categoryThree === categoryThree)).map((doc) =>  
                  <CardDokument key={doc.id} data={doc} deleteFileAndDoc={deleteFileAndDoc} setModalTitle={setModalTitle} setModalImgSrc={setModalImgSrc} setShowModal={setShowModal} />    
              )}

            </Col>
            }
        </Row>

        <div style={{marginBottom: "160px"}}></div>
        
        {showModal && <IMGViewModal title={modalTitle} imgSrc={modalImgSrc} visible={setShowModal} />}     
        
      
      </Container>
    </>
  );
}

export default DokuVersicherung


