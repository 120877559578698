import React from 'react';
import PropTypes from 'prop-types';


//usage:
{/*
    <WebpImage 
        webpSrc="/path/to/image.webp"
        fallbackSrc="/path/to/image.jpg"
        alt="My Image"
    />
*/}


// WebpImage Component
function WebpImage ({ webpSrc, fallbackSrc, alt, className }) {

  return (
    <picture style={{display:"flex", justifyContent:"center"}}>
        <source type="image/webp" srcSet={webpSrc} />
        <img src={fallbackSrc} alt={alt} className={className}/>
    </picture>
  );
}

WebpImage.propTypes = {
  webpSrc: PropTypes.string.isRequired,
  fallbackSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default WebpImage;
