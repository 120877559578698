import React, { useState, useEffect, useRef } from "react";
import { HeaderWithoutAuthOhneNavigation } from "../Header/Header";
import Spinner from "../Bausteine/Spinner/Spinner";
import { useNavigate, useParams } from "react-router-dom";

import { Container, Row, Col, Form, Button } from "react-bootstrap";

import axios from "axios";
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import style from "./Formular.module.css";

import CustomModal from "../Bausteine/CustomModal"
import VINButton from "../../VINButton";

import SignatureCanvas from 'react-signature-canvas';
//'react-signature-pad-wrapper'

import { isValidIBAN } from "../../util/ibanValidator";
import { downloadPDF } from "../../util/downloadFiles";

import base64ToBlob from '../../util/base64ToBlob';

function VorteilspartnerOnboardingAktion() {

  let navigate = useNavigate();

  const sigCanvas = useRef();

  //const [searchParams] = useSearchParams();

  //Vertrieb MongoDB ID!
  const { id } = useParams();

  //Zuordnung zu Vorteilspartner
  const [mongoId, setMongoId] = useState("");

  //Dynamische Ausgabe von Produkten und Preisen
  const [produkte, setProdukte] = useState("");

  useEffect(() => {
    /*
    if (searchParams.get("id") !== null) {
      setMongoId(searchParams.get("id"));
      console.log(mongoId);
    }
    */
   setMongoId(id);
  },[id])



  const [showModalErstinformationen, setShowModalErstinformationen] = useState(false);
  const [spinner, setSpinner] = useState(false);
  

  
    // ===== Vorteile =====
    const [vorteil, setVorteil] = useState({
      kdnr: "",
      email: "",
      cbFlyer: true,
      cbLandingPage: true,
      branche: "",
      cbOnline: true,
      cbVorOrt: true,
      online: "",
      vorteilscode: "",
      webseite: "",
      vorOrt: "",
      anschrift: "",
      plz: "",
      stadt: "",
      land: ""
    });


    // ===== Prämien =====
    const [iban, setIBAN] = useState("");
    const [ibanValid, setIBANValid] = useState(true); // Ausgabe Fehlermeldungen
    const [ustid, setUstid] = useState("");

    // ===== Kundenumfrage =====
  /*
    const [useCRM, setUseCRM] = useState(null);
    const [umsatz, setUmsatz] = useState(null);
    const [anzMitarbeiter, setAnzMitarbeiter] = useState(null);
    const [anzKunden, setAnzKunden] = useState(null);
  */

  /*
    // Convert dataURL to Blob
    const dataURLtoBlob = (dataurl) => {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type: mime});
    }
  */
 
  // ---------------------------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------

  const handleResetSignature = (e) => {
    e.preventDefault();
    sigCanvas.current.clear();
  }




  const  handleBlankoDownload = async (e) => {
    e.preventDefault();
    try {
      //Fetch from Backend!
      let res = await axios.get(process.env.REACT_APP_BACKEND+'/sales-partner/blanko-vertrag');
      const base64Response = res.data;

      // Convert base64 to a Blob
      const pdfBlob = base64ToBlob(base64Response, 'application/pdf');

      // Create a URL and download the PDF
      const url = window.URL.createObjectURL(pdfBlob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'VIN1 Vorteilspartner Blankovertrag.pdf');
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch(err) {
      console.log("Fehler Downloading Vertrag: ", err);
    }
  }


  const handleSignContract = async (e) => {

    e.preventDefault();

    if(!vorteil.kdnr || !vorteil.email) {
      Swal.fire({
        icon: 'info',
        title: 'Pflichfelder',
        html: 'Bitte trage die Kundennummer und E-Mail des VIN1 Accounts für die Vorteilspartnerschaft ein.'
      })
      return;
    }

    if(!vorteil.name) {
      Swal.fire({
        icon: 'info',
        title: 'Pflichfelder',
        html: 'Bitte trage einen Namen für dein Unternehmen ein.'
      })
      return;
    }

    if(!vorteil.branche) {
      Swal.fire({
        icon: 'info',
        title: 'Pflichfelder',
        html: 'Bitte trage einen Branche für dein Unternehmen ein.'
      })
      return;
    }

    if(!vorteil.cbOnline && !vorteil.cbVorOrt) {
      Swal.fire({
        icon: 'info',
        title: 'Pflichfelder',
        html: 'Damit der Vertrag geschlossen werden kann, musst du einen Online oder vor Ort Vorteil angeben.'
      })
      return;
    }

    if(vorteil.cbOnline && (!vorteil.online || !vorteil.vorteilscode || !vorteil.webseite ))
    {
      Swal.fire({
        icon: 'info',
        title: 'Pflichfelder',
        html: 'Fülle alle Felder zu deinem Online-Vorteil aus.'
      })
      return;
    }

    if(vorteil.cbVorOrt && (!vorteil.vorOrt || !vorteil.anschrift || !vorteil.plz || !vorteil.stadt ))
    {
      Swal.fire({
        icon: 'info',
        title: 'Pflichfelder',
        html: 'Fülle alle Felder zu deinem vor Ort Vorteil aus.'
      })
      return;
    }

    if( sigCanvas.current.isEmpty() )
    {
      Swal.fire({
        icon: 'info',
        title: 'Pflichfelder',
        html: 'Unterschreibe den Vertrag im vorgesehenen Feld.'
      })
      return;
    }


  

    setSpinner(true);

        /*
        //Formular an uns übertragen
        let formData = new FormData();
        
        //---Zuweisung zu Sales---
        formData.append('vertrieb', mongoId );
        
        //---Kunde identifikation---
        formData.append('kdnr', vorteil.kdnr );
        formData.append('email', vorteil.email );
        
        //---Werbemittel---
        formData.append('flyer', vorteil.cbFlyer );
        formData.append('landingpage', vorteil.cbLandingPage );

        //--Vorteilspartner---
        formData.append('name', vorteil.name );

        //--Online-Vorteil--
        formData.append('vorteilOnline', vorteil.online );
        formData.append('vorteilscode', vorteil.vorteilscode );
        formData.append('url', vorteil.url );

        //--vor Ort Vorteil--
        formData.append('vorteilOffline', vorteil.vorOrt );
        formData.append('anschrift', vorteil.anschrift );
        formData.append('plz', vorteil.plz );
        formData.append('stadt', vorteil.stadt );

        //---Auszahlung Prämien---
        formData.append('iban', iban );
        formData.append('ibanValid', ibanValid );
        formData.append('ustid', ustid );

        //---Vertragsunterschrift---
        //Umwandeln in Blob
        const blob = dataURLtoBlob(sigCanvas.current.getCanvas().toDataURL())
        formData.append('unterschrift', blob, 'signature.png');
        */      

    //Create Vorteilspartner CRM
    axios({
      method: 'post',
      url: process.env.REACT_APP_BACKEND+"/sales-partner/vertrag-partner",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        //---Zuweisung zu Sales---
        vertriebId: mongoId,

        //---Kunde identifikation---
        kdnr: vorteil.kdnr,
        email: vorteil.email,
    
        //---Werbemittel---
        flyer: vorteil.cbFlyer,
        landingpage: vorteil.cbLandingPage,

        //--Vorteilspartner---
        name: vorteil.name,

        //--Branche--
        branche: vorteil.branche,

        //--Online-Vorteil--
        vorteilOnline: vorteil.online,
        vorteilscode: vorteil.vorteilscode,
        webseite: vorteil.webseite,

        //--vor Ort Vorteil--
        vorteilOffline: vorteil.vorOrt,
        anschrift: vorteil.anschrift,
        plz: vorteil.plz,
        stadt: vorteil.stadt,

        //---Auszahlung Prämien---
        iban: iban,
        ibanValid: ibanValid,
        ustid: ustid,

        //---Vertragsunterschrift---
        unterschrift: sigCanvas.current.getCanvas().toDataURL() //base64String 
      }  
    })
    .then(res => {
        setSpinner(false);
        Swal.fire({
            icon: 'success',
            title: 'Erfolg',
            html: 'Das Formular wurde erfolgreich abgesendet.<br />'
                  +'Unser Team kümmert sich um alles weiter und wird dich zeitnah kontaktieren.'
        })
    })
    .catch(err => {
        setSpinner(false);

        if(err.response && err.response.status == 422){
          Swal.fire({
            icon: 'error',
            title: 'Fehler',
            html: 'Kunde wurde nicht gefunden. Bitte Kundennummer und Login E-Mail überprüfen.'
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Fehler',
            html: 'Fehler beim Übermitteln des Formulars. Bitte erneut versuchen.'
          })
        }
       
    })
       
  }


  // ---------------------------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <HeaderWithoutAuthOhneNavigation />

      <Container style={{ maxWidth: "800px" }}>
        <Row className="d-flex justify-content-center">
          <Col>
            <h1 style={{ marginBottom: "30px" }}>Vorteilspartner Onboarding</h1>
            <p className="text-center" style={{ marginBottom: "30px", fontSize: "20px" }}>Aktionspreis bis 31.03.!</p>
          </Col>
        </Row>
      </Container>
      <Container style={{ maxWidth: "750px"}}>
        <Row style={{ justifyContent: "center" }}>
          <h2
            style={{ color: "#520da7", fontSize: "140%" }}
            className="fw-bold text-center"
          >
            Jetzt Vorteilspartner werden.
          </h2>
          <p className="text-center">
            Vintastisch, dass du dich interessierst VIN1-Vorteilspartner zu
            werden.
            <br />
            <br />
            <span style={{ fontSize: "110%", fontWeight: "bold" }}>
              Notwendige Schritte
            </span>
            <br />
            1. Fülle nachfolgendes Formular aus und unterzeichne den Vertrag.
            <br />
            2. Das VIN1 Team prüft Deinen Antrag und wird diesen schnellstmöglich bearbeiten. 
            <br />
            3. Wir bereiten alles für Dich vor und informieren dich per E-Mail
            über den Stand.
          </p>
        </Row>

        {/*<p>Standort: {standort}</p>*/}

        {/* Chrome Browser austricksen damit kein autoComplete... */}
        <input type="hidden" value="something" />

        <Row
          style={{
            justifyContent: "center",
            marginBottom: "80px",
            marginTop: "50px",
          }}
        >
          <Form autoComplete="off" type="post">
            <span>* Pflichtfeld</span>
            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="kdnr" className="form-label">
                Kundenummer Vorteilspartner* (Hinweis: App-Login im Bereich "Meine
                Daten")
              </label>
              <br />
              <input
                type="text"
                id="kdnr"
                name="kdnr"
                placeholder="Kundennummer"
                maxLength={6}
                value={vorteil.kdnr ?? ""}
                onChange={(e) => setVorteil(
                  {
                    ...vorteil,
                    kdnr: e.target.value.replace(/[^\d]/g, "")
                  })}
                />
            </div>
            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="email" className="form-label">
                Zugehörige E-Mail* (Hinweis: App-Login im Bereich
                "Meine Daten")
              </label>
              <br />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="E-Mail"
                value={vorteil.email ?? ""}
                onChange={(e) => setVorteil({
                    ...vorteil,
                    email: e.target.value.trim()
                  })}
              />
            </div>

            <h5 style={{ marginTop: "60px" }}>Onboarding/Werbemittel</h5>
            <label
              className="form-check-label"
              style={{ margin: "20px 0px 0px 20px" }}
            >
              <input
                id="cbOnboarding"
                className="form-check-input"
                type="checkbox"
                checked={true}
                disabled={true}
                onChange={() => {}}
              />
              <span style={{ paddingLeft: "10px" }}>
                  <b>Einrichtung Vorteilspartner Konto</b>
                  <div style={{paddingLeft: "28px"}}>Onboarding Vorteilspartner</div>     
              </span>
            </label>


            <label
              className="form-check-label"
              style={{ margin: "20px 0px 0px 20px" }}
            >
              <input
                id="cbFlyer"
                className="form-check-input"
                type="checkbox"
                checked={true}
                disabled={true}
                onChange={() => {}}
              />
              <span style={{ paddingLeft: "10px" }}>
                  <b>Print-Werbemittel (nur wenn du einen Vor-Ort-Vorteil bietest)</b>
                  <div style={{paddingLeft: "28px"}}>
                    personalisierte VIN1-Kundenfyler A5 (mit QR-Vorteilscode) inkl. Acryl-Aufsteller 
                    <br />
                    2 Poster Größe DIN A2
                  </div>        
              </span>
            </label>


            <label
              className="form-check-label"
              style={{ margin: "20px 0px 0px 20px" }}
            >
              <input
                id="cbLandingpage"
                className="form-check-input"
                type="checkbox"
                checked={true}
                disabled={true}
                onChange={() => {}}
              />
              <span style={{ paddingLeft: "10px" }}>
                  <b>Landingpage (nur wenn du einen Online-Vorteil bietest)</b>
                  <div style={{paddingLeft: "28px"}}>Landingpage zu Marketingzwecken mit personalisierten Vorteilspartner-Informationen</div>        
              </span>
            </label>

            <div
              style={{
                width: "30%",
                height: "2px",
                background: "black",
                marginTop: "10px",
              }}
            />

            <h6 className="mt-3 pl-3" style={{ fontSize: "110%" }}>
              Summe: 249€ <span style={{ fontSize: "80%" }}>inkl. 19% MwSt.</span>
            </h6>
            




            <div className="pb-5" />
                <hr />

                <h4 style={{ marginTop: "60px" }}>
                  Dein Vorteilsangebot
                </h4>

                <p>
                  Unter welchem genauen Namen willst du in der App auftauchen?<br />
                  Du kannst z.B. die Geschäftsform weglassen, wenn du möchtest.
                </p>

                <div
                  className={style.formInputCustom}
                  style={{ marginTop: "20px" }}
                >
                  <label htmlFor="online" className="form-label">
                    Anzeigename in der App * (z.B. Mustermann Gruppe GmbH =&gt; Mustermann Gruppe)
                  </label>
                  <br />
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Mustermann Gruppe"
                    value={vorteil.name ?? ""}
                    onChange={(e) => setVorteil({
                      ...vorteil,
                      name: e.target.value
                    })}
                  />
                </div>

                <p className="mt-5">
                  Entscheide, ob du Online, vor Ort oder an beiden Stellen einen Vorteil gewähren willst.
                </p>

                <label
                  className="form-check-label"
                  style={{ margin: "20px 0px 0px 20px" }}
                >
                  <input
                    id="cbOnline"
                    name="cbOnline"
                    className="form-check-input"
                    type="checkbox"
                    checked={vorteil.cbOnline ?? true}
                    onChange={(e) => setVorteil({
                      ...vorteil,
                      cbOnline: e.target.checked
                    })}
                  />
                  <span style={{ paddingLeft: "10px" }}>
                    Ich will einen Online-Vorteil bieten (eigener Online-Shop notwendig mit Rabattiermöglichkeit)
                  </span>
                </label>

                <br />

                <label
                  className="form-check-label"
                  style={{ margin: "20px 0px 0px 20px" }}
                >
                  <input
                    id="cbVorOrt"
                    name="cbVorOrt"
                    className="form-check-input"
                    type="checkbox"
                    checked={vorteil.cbVorOrt ?? true}
                    onChange={(e) => setVorteil({
                      ...vorteil,
                      cbVorOrt: e.target.checked
                    })}
                  />
                  <span style={{ paddingLeft: "10px" }}>
                    Ich will einen vor Ort Vorteil bieten.
                  </span>
                </label>



                <div
                  className={style.formInputCustom}
                  style={{ marginTop: "50px" }}
                >
                  <label htmlFor="branche" className="form-label">
                    In welche Branche bist du tätig bzw. bietest du deinen Vorteil an? *
                  </label>
                  <br />
                  <input
                    type="text"
                    id="branche"
                    name="branche"
                    placeholder="Gastronomie, Coaching, ..."
                    value={vorteil.branche ?? ""}
                    onChange={(e) => setVorteil({
                      ...vorteil,
                      branche: e.target.value
                    })}
                  />
                </div>




                {/* ==================== Vorteil vor Ort Details ==================== */}
                {vorteil.cbOnline && (
                  <>
                    <h5 style={{ marginTop: "60px" }}>
                      Online Vorteil
                    </h5>

                    <p style={{ marginTop: "30px" }}>
                      Du benötigst einen Webshop. Dort muss ein
                      allgemeingültiger Coupon/Gutschein hinterlegt werden, der dauerhaft bestehen bleibt. Der
                      Coupon wird den Kunden in der VIN1 App angezeigt.
                    </p>

                    <div
                      className={style.formInputCustom}
                      style={{ marginTop: "20px" }}
                    >
                      <label htmlFor="online" className="form-label">
                        Kurze Beschreibung des Online-Vorteils *
                      </label>
                      <br />
                      <input
                        type="text"
                        id="online"
                        name="online"
                        maxLength={90}
                        placeholder="10% Rabatt auf alles"
                        value={vorteil.online ?? ""}
                        onChange={(e) => setVorteil({
                          ...vorteil,
                          online: e.target.value
                        })}
                      />
                    </div>

                    <div
                      className={style.formInputCustom}
                      style={{ marginTop: "20px" }}
                    >
                      <label htmlFor="vorteilscode" className="form-label">
                        Vorteilscode im Shop *
                      </label>
                      <br />
                      <input
                        type="text"
                        id="vorteilscode"
                        name="vorteilscode"
                        placeholder="RABATT10"
                        value={vorteil.vorteilscode ?? ""}
                        onChange={(e) => setVorteil({
                          ...vorteil,
                          vorteilscode: e.target.value
                        })}
                      />
                    </div>

                    <div
                      className={style.formInputCustom}
                      style={{ marginTop: "20px" }}
                    >
                      <label htmlFor="webseite" className="form-label">
                        Web-URL zum Shop (inkl. https://, ...) *
                      </label>
                      <br />
                      <input
                        type="text"
                        id="webseite"
                        name="webseite"
                        placeholder="https://vin1.eu"
                        value={vorteil.webseite ?? ""}
                        onChange={(e) => setVorteil({
                          ...vorteil,
                          webseite: e.target.value.trim()
                        })}
                      />
                    </div>

                  </>
                )}

                {/* ==================== Vorteil vor Ort Details ==================== */}
                {vorteil.cbVorOrt && (
                  <>
                    <h5 style={{ marginTop: "60px" }}>
                      Vor Ort Vorteil
                    </h5>

                    <div
                      className={style.formInputCustom}
                      style={{ marginTop: "20px" }}
                    >
                      <label htmlFor="vorteilOffline" className="form-label">
                        Kurze Beschreibung des vor Ort Vorteils *
                      </label>
                      <br />
                      <input
                        type="text"
                        id="vorOrt"
                        name="vorOrt"
                        maxLength={90}
                        placeholder="10% Rabatt auf alles"
                        value={vorteil.vorOrt ?? ""}
                        onChange={(e) => setVorteil({
                          ...vorteil,
                          vorOrt: e.target.value
                        })}
                      />
                    </div>

                    <h5 style={{ marginTop: "30px" }}>
                      An welcher Anschrift findet der Kunde den vor Ort Vorteil? *
                    </h5>
                    <div
                      className={style.formInputCustom}
                      style={{ marginTop: "20px" }}
                    >
                      <label htmlFor="anschrift" className="form-label">
                        Anschrift (Straße + Hausnr) *
                      </label>
                      <br />
                      <input
                        type="text"
                        id="anschrift"
                        name="anschrift"
                        placeholder="Anschrift"
                        value={vorteil.anschrift ?? ""}
                        onChange={(e) => setVorteil({
                          ...vorteil,
                          anschrift: e.target.value
                        })}
                      />
                    </div>

                    <div
                      className={style.formInputCustom}
                      style={{ marginTop: "20px" }}
                    >
                      <label htmlFor="plz" className="form-label">
                        PLZ *
                      </label>
                      <br />
                      <input
                        type="text"
                        id="plz"
                        name="plz"
                        placeholder="PLZ"
                        autoComplete="on"
                        inputMode="numeric"
                        maxLength={5}
                        value={vorteil.plz ?? ""}
                        onChange={(e) => setVorteil({
                          ...vorteil,
                          plz: e.target.value.replace(/[^\d]/g, "")
                        })}
                      />
                    </div>

                    <div
                      className={style.formInputCustom}
                      style={{ marginTop: "20px" }}
                    >
                      <label htmlFor="stadt" className="form-label">
                        Stadt *
                      </label>
                      <br />
                      <input
                        type="text"
                        id="stadt"
                        name="stadt"
                        placeholder="Stadt"
                        value={vorteil.stadt ?? ""}
                        onChange={(e) => setVorteil({
                          ...vorteil,
                          stadt: e.target.value
                        })}
                      />
                    </div>
                  </>
                )}



                {/* ========= Prämien ======== */}
                
                <div className="pb-5" />
                <hr />

                <h5 style={{ marginTop: "30px" }}>
                  Prämienauszahlung
                </h5>

                <p>Kann nachgereicht werden, optional.</p>

                <div
                  className={style.formInputCustom}
                  style={{ marginTop: "20px" }}
                >
                  <label htmlFor="iban" className="form-label">
                    IBAN (optional)
                  </label>
                  <br />
                  <input
                    type="text"
                    id="iban"
                    name="iban"
                    placeholder="DEXX XXXX XXXX XXXX XX"
                    value={iban}
                    onChange={(e) => {
                      setIBAN(e.target.value.trim().replace(/ /g, ''));
                      setIBANValid(true); //Reset error, only check after input left again!
                    }}
                    onBlur={() => {
                      setIBANValid(isValidIBAN(iban.trim()));
                    }}
                  />
                  {iban.length >= 1 && !ibanValid &&
                    <p style={{marginLeft: "20px", color: "red"}}>IBAN inkorrekt, bitte prüfen.</p>
                  }
                </div>


                <div
                  className={style.formInputCustom}
                  style={{ marginTop: "20px" }}
                >
                  <label htmlFor="ustid" className="form-label">
                    USt.-ID (optional)
                  </label>
                  <br />
                  <input
                    type="text"
                    id="ustid"
                    name="ustid"
                    placeholder="DE123456789"
                    maxLength={11}
                    value={ustid}
                    onChange={(e) => setUstid(e.target.value.trim())}
                  />
                </div>





            {/* ==================== Vertrag ==================== */}
            <h5 style={{ marginTop: "60px" }}>Vorteilspartner Vertrag</h5>
            <div className="pointer text-center mt-5">
              <Button
                className="fw-bold"
                style={{
                  background: "#520ca7",
                  border: "none",
                  marginRight: "10px",
                }}
                onClick={handleBlankoDownload}
              >
                Download Blanko Vertrag PDF
              </Button>
            </div>
            <div className="mt-5">
              <SignatureCanvas
                canvasProps={{ className: "sigCanvas" }}
                penColor="black"
                ref={sigCanvas}
              />
            </div>
            <Row>
              <Col>
                <p>Unterschrift Vorteilspartner</p>
              </Col>
              <Col className="d-flex justify-content-end">
                <Button variant="secondary" onClick={handleResetSignature}>
                  Reset Unterschrift
                </Button>
              </Col>
            </Row>


            <div className="pointer text-center mt-5">
              <VINButton
                text={"Vertrag unterzeichnen"}
                onClick={handleSignContract}
              />
            </div>


                {/* ========= Kundeneinschätzung ======== */}
                {/*
                <div className="pb-5" />
                <hr />

                <h5 style={{ marginTop: "30px" }}>
                  Kundenumfrage
                </h5>


                <div
                  className={style.formInputCustom}
                  style={{ marginTop: "20px" }}
                >
                  <label htmlFor="crm" className="form-label">
                    Benutzt ihr ein Kundenverwaltungsprogramm vorhanden (CRM)?
                  </label>

                  <Form.Check
                    type="radio"
                    name="useCRM"
                    label="Ja"            
                    aria-label="radio 1"
                    onChange={(e) => setUseCRM(e.target.value)}
                    checked={useCRM === "Ja"}
                  />
                  
                  <Form.Check
                    type="radio"
                    name="useCRM"
                    label="Nein"            
                    aria-label="radio 1"
                    onChange={(e) => setUseCRM(e.target.value)}
                    checked={useCRM === "Nein"}
                  />
   
                </div>



                <div
                  className={style.formInputCustom}
                  style={{ marginTop: "20px" }}
                >
                  <label htmlFor="umsatz" className="form-label">
                    Jahresumsatz in € (geschätzt)
                  </label>
                  <br />
                  <input
                    type="text"
                    id="umsatz"
                    name="umsatz"
                    placeholder="100.000"
                    inputMode="numeric"
                    value={umsatz}
                    onChange={(e) => setUmsatz(e.target.value.replace(/[^\d]/g, ""))}
                  />
                </div>

                <div
                  className={style.formInputCustom}
                  style={{ marginTop: "20px" }}
                >
                  <label htmlFor="anzMitarbeiter" className="form-label">
                    Anzahl Mitarbeiter
                  </label>
                  <br />
                  <input
                    type="text"
                    id="anzMitarbeiter"
                    name="anzMitarbeiter"
                    placeholder="10"
                    inputMode="numeric"
                    value={anzMitarbeiter}
                    onChange={(e) => setAnzMitarbeiter(e.target.value.replace(/[^\d]/g, ""))}
                  />
                </div>

                <div
                  className={style.formInputCustom}
                  style={{ marginTop: "20px" }}
                >
                  <label htmlFor="anzMitarbeiter" className="form-label">
                    Anzahl Kunden
                  </label>
                  <br />
                  <input
                    type="text"
                    id="anzKunden"
                    name="anzKunden"
                    placeholder="750"
                    inputMode="numeric"
                    value={anzKunden}
                    onChange={(e) => setAnzKunden(e.target.value.replace(/[^\d]/g, ""))}
                  />
                </div>                   
                */}


           
            {/* Ende Formular nach Vertragsunterzeichnung */}

          </Form>




          {spinner && (
            <Spinner message={"Bitte warten. Daten werden verarbeitet."} />
          )}

          {showModalErstinformationen && (
            <CustomModal
              title={"Erstinformationen"}
              visible={showModalErstinformationen}
              setVisible={setShowModalErstinformationen}
            >
              <Row className="mb-3" style={{ justifyContent: "center" }}>
                <Col>
                  <p>
                    Hier findest alle wichtigen Informationsdokumente zum
                    Download
                  </p>

                  <p style={{ marginTop: "30px" }} className="fw-bold">
                    Erstinformationen
                  </p>
                  <div style={{}}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        downloadPDF(
                          "pdf/Erstinformationen_VIN1.pdf",
                          "Erstinformationen_VIN1.pdf"
                        )
                      }
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          textDecoration: "underline",
                        }}
                      >
                        Download Erstinformationen (PDF)
                      </span>
                      <FontAwesomeIcon
                        icon={faDownload}
                        size="lg"
                        style={{ marginLeft: "10px" }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </CustomModal>
          )}
        </Row>
      </Container>
    </>
  );
}

export default VorteilspartnerOnboardingAktion;
