import React from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import style from "./CardSmall.module.css"
import classLister from 'css-module-class-lister';

import { Container, Row, Col}  from 'react-bootstrap';


function CardUpload(props) {

  const classes = classLister(style);

  return (
   
      <div className={style.cardContainerUpload} style={{marginBottom: "20px"}}>

        <Row style={{width:"100%"}}>

            <Col style={{display: "flex", flex: "11", flexDirection: "column", alignItems: "flex-start", padding: "10px 0px 0px 15px"}}>
                <p className="fw-bold" style={{padding: "0", marginBottom: "5px"}}>{props.data.name}</p>
                
                {/*<p style={{color: "grey", padding: "0", marginBottom: "5px"}}>Beschreibung</p>
                <input
                    type="text"
                    placeholder="Hier Beschreibung eingeben..."
                    style={{border: "none", width: "100%"}}
                    onChange={(e) => props.setDesc(e.target.value.trim())}
                />
                */}
            </Col>

            <Col style={{display: "flex", flex: "1", justifyContent: "flex-end"}}>
                <FontAwesomeIcon style={{width:"auto", cursor: "pointer", marginTop: "5px", color: "#707070"}} icon={faXmark} size="xl" onClick={() => props.removeFileUploadHandler(props.data.id)} />
            </Col>

        </Row>
         

        <p className="" style={{}}>{props.name}</p>
      </div>
  )
}

export default CardUpload
