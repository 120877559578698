import React from 'react';
import ReactDOM from 'react-dom';
/*
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {Container, Row, Col} from "react-bootstrap";
*/

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";


const CustomModal = ({title, children, visible, setVisible, handleSave, saveText}) => {


  return ReactDOM.createPortal((
      <Modal show={visible} /*dialogClassName="pipelineModal"*/ size="lg" style={{marginTop:"30px"}} className="customModal">
        <Modal.Header>
          <Modal.Title><span style={{fontSize: "70%"}}>{title}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "75vh", overflowY: "auto"}}>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Button
             style={{background: "#dc3545", border: "none"}}
            onClick={() => setVisible(false)}
            >
            Schließen
          </Button>

          {handleSave &&
            <Button
              style={{border: "none"}}
              onClick={handleSave}
              >
              {saveText ?? "Absenden"}
            </Button>
          }

        </Modal.Footer>
      </Modal>
  ), document.body)
}

export default CustomModal