import React from 'react'
import { useNavigate } from 'react-router-dom'

import style from "./Card.module.css"

function CardDoppelt(props) {

    let navigate = useNavigate();

    const handleExternalURL = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

  return (
    <div
        onClick={ () => {
            if(props.url && !props.externalURL) {
                navigate(props.url);
            }
            else if(!props.url && props.externalURL) {
                handleExternalURL(props.externalURL);
            }
        }}
        className={style.cardContainerDoppelt}
    >
        {props.shake &&
             <img className={[style.cardImgDoppelt, style.shake].join(' ')} src={require("../../img/"+props.img)} alt="" />
        }
        {!props.shake &&
            <img className={style.cardImgDoppelt} src={require("../../img/"+props.img)} alt="" />
        }
        <div className={style.cardTitleDoppeltContainer}>
            <p className={style.cardTitle}>{props.title}</p>
            {props.text && props.text}
        </div>
    </div>
  )
}

export default CardDoppelt