import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import style from "./IMGViewModal.module.css"

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Backdrop = (props) => {
    return <div className={style.backdrop} onClick={props.onConfirm} />
}

const ModalOverlay = (props) => {

  return (
      <Modal show={props.visible} onHide={() => props.visible(false)}>
        <Modal.Header closeButton>
          <Modal.Title><span style={{fontSize: "70%"}}>{props.title}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/*<img id="myimg" src={props.img} style={{width: "800px", height: "800px"}} />*/}
            <img
              src={props.imgSrc}
              style={{
                width:"100%",
                height: "100%"
              }} />
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
            onClick={() => props.visible(false)}
            >
            Schließen
          </p>
        </Modal.Footer>
      </Modal>
  )
}

const IMGViewModal = (props) => {

    return (
      <Fragment>
        {ReactDOM.createPortal(<Backdrop hideModal={props.hideModal} />, document.getElementById('backdrop-root'))}
        {ReactDOM.createPortal(<ModalOverlay title={props.title} imgSrc={props.imgSrc} visible={props.visible} />, document.getElementById('overlay-root'))}
      </Fragment>
      
    )
}

export default IMGViewModal