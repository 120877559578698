import React, { useState, useEffect, useContext } from "react";
import Header from "../Header/Header";
import Spinner from "../Bausteine/Spinner/Spinner";

import CardSub from "../Card/CardSub";

import { Container, Row, Col, Button, Modal } from "react-bootstrap";

import style from "./Verbesserungen.module.css";

import axios from 'axios';

import Swal from "sweetalert2";
import { UserContext } from "../../store/user-context";
import VINButton from "../../VINButton";
import { AuthContext } from "../../store/auth-context";

function Verbesserungen() {

  const { user } = useContext(UserContext);
  const { currentUser } = useContext(AuthContext);

  //const [uhrzeiten] = useState(["08:00-13:00 Uhr", "13:00-17:00 Uhr", "17:00-19:00 Uhr"]);

  const [beschreibung, setBeschreibung] = useState("");
  //const [erreichbar, setErreichbar] = useState("");

  //const [morgens, setMorgens] = useState(false);
  //const [mittags, setMittags] = useState(false);
  //const [abends, setAbends] = useState(false);


  const [spinner, setSpinner] = useState(false);

  /*
  useEffect(() => {

    if(morgens && !mittags && !abends)
      setErreichbar("morgens ("+uhrzeiten[0]+")");
    else if(!morgens && mittags && !abends)
      setErreichbar("mittags ("+uhrzeiten[1]+")");
    else if(!morgens && !mittags && abends)
      setErreichbar("abends ("+uhrzeiten[2]+")");
    else if(morgens && mittags && !abends)
      setErreichbar("morgens ("+uhrzeiten[0]+"), mittags ("+uhrzeiten[1]+")");
    else if(morgens && !mittags && abends)
      setErreichbar("morgens ("+uhrzeiten[0]+"), abends ("+uhrzeiten[2]+")");
    else if(!morgens && mittags && abends)
      setErreichbar("mittags ("+uhrzeiten[1]+"), abends ("+uhrzeiten[2]+")");
    else if(morgens && mittags && abends)
      setErreichbar("ganztägig");

  }, [morgens, mittags, abends, uhrzeiten])
  */


  const handleSubmit = async () => {

    if (beschreibung === "") {
      Swal.fire({
        title: "Pflichtfelder",
        html: "Bitte fülle zunächst alle Felder aus und sende das Formular erneut ab.",
        icon: "info",
      });
      return;
    }


      setSpinner(true);

      //Create Task CRM FERTIG MACHEN IM BACKEND!!!
      await axios({
        method: 'post',
        headers: {
          'Authorization': 'Bearer ' + currentUser.accessToken
        },
        url: process.env.REACT_APP_BACKEND+"/my-vin1/feedback",
        data: {
          beschreibung: beschreibung
        }
      })
      .then(data => {
        Swal.fire({
          title: "Erfolg",
          html: "Deine Daten wurden erfolgreich an uns übermittelt.<br />Vielen Dank für deine Mitarbeit.",
          icon: "success",
        });

      })
      .catch(err => {
          console.log(err);

          Swal.fire({
            title: "Fehler",
            html: "Es ist ein Fehler bei der Übertragung aufgetreten. Versuche es später erneut.",
            icon: "error"
          });
      });



    //Clean Felder
    setSpinner(false);
  
    setBeschreibung("");

  }; //handleSubmit Ende


  return (
    <>
      <Header />
      <Container style={{ maxWidth: "800px" }}>
        <Row style={{ justifyContent: "center" }}>
          <Col style={{ textAlign: "center" }}>
            <img
              src={require("../../img/Verbesserungen.png")}
              alt=""
              style={{ maxWidth: "160px", margin: "40px 0px" }}
            />

            <h5>VIN1 Verbesserungsvorschläge</h5>
            <br />
            <p>Wir möchten die VIN1 WebApp stetig verbessern, damit Dein Nutzererlebnis noch größer wird. 
                Du hast jetzt die vinomenale Möglichkeit, an der Verbesserung mitzuwirken.
              <br />
              Dazu bitten wir Dich um konstruktive und sachliche Verbesserungsvorschläge, die sich ausschließlich auf die Benutzerfreundlichkeit und Bedienung der VIN1 WebApp beziehen. 
              <br />Das VIN1 Team wird sich Deinen Vorschlag genau anschauen und entscheiden, ob dieser umsetzbar ist. 
              <br />Umgesetzte Vorschläge belohnen wir mit einer vintastischen Überraschung.
              <br />Mach Dein VIN DING – mach jetzt mit!
            </p>
          </Col>
        </Row>
          

        <Row style={{ justifyContent: "center" }}>
          <Col style={{ textAlign: "center" }}>

          <div className={style.formInputCustom} style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", textAlign: "left"}}>
            <label htmlFor="beschreibung" className="form-label">Deine Anregung, Kritik, Verbesserungsvorschlag ({beschreibung.length}/1.000 Zeichen)</label>
            <textarea
              style={{border: "none", margin: "0px 12px"}}
              value={beschreibung}
              placeholder={"Beschreibe uns Deine Idee"}
              cols={40}
              rows={10}
              maxLength={1000}
              onChange={(e) => setBeschreibung(e.target.value)}
            />
            
            
          </div>

          </Col>
        </Row>
  

        <Row className="justify-content-center mt-5">
          <Col style={{ textAlign: "center" }}>

            <br />
            <br />
            <br />

            {/*<div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{
                  background: "#520da7",
                  padding: "15px",
                  width: "auto",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                disabled={spinner}
                onClick={!spinner ? handleSubmit : null}
              >
                AbsendenF
              </Button>
            </div>*/}


            <div className="pointer" onClick={!spinner ? handleSubmit : null}>
              <VINButton text={"Absenden"} />
            </div>


          </Col>
        </Row>

        <br />
        <br />
        <br />

      {spinner &&
        <Spinner
          message={"Bitte warten. Daten werden verarbeitet."}
        />
      }


        
      </Container>

    </>
  );
}

export default Verbesserungen;
