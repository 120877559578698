import React, { Fragment, useContext } from 'react'
import {Row, Col} from 'react-bootstrap'
import Card from '../../Card/Card'
import { UserContext } from '../../../store/user-context'



function Dokumente() {

    const { user } = useContext(UserContext);
      
    const cards = [
        {
            url: "/dokumente/versicherung",
            img: "Versicherungen.png",
            title: "Versicherungen"
        },
        {
            url: "/dokumente/kredite",
            img: "Finanzen.png",
            title: "Kredite"
        },
        {
            url: "/dokumente/invest",
            img: "Invest.png",
            title: "Finanzen & Invest"
        },
        {
            url: "/dokumente/energie",
            img: "Energie.png",
            title: "Energie"
        },
        {
            url: "/dokumente/immobilien",
            img: "Immo.png",
            title: "Immobilien"
        },
        {
            access: "steuertaxi",
            url: "/dokumente/steuertaxi",
            img: "Taxi.png",
            title: "Steuertaxi"
        },
    ]
    

  return (

    <Row className="mt-5 justify-content-center" style={{marginBottom: "160px"}} >

        <Col xs={12} md={12}>
            <p className="text-center">Hier findest du Dokumente wie z.B. Verträge oder Policen</p>
        </Col>

        {cards.map((card, index) =>
            <Fragment key={index}>
                {/* Rendern, wenn kein "access gesetzt", oder wenn dieser passend ist. */}
                {(!card.access || user?.appFreigaben?.hasOwnProperty(card.access)) &&
                    <Col
                        key={card.title}
                        xs={6} md={4} style={{marginTop: "20px"}}
                    >
                        <Card url={card.url} img={card.img} title={card.title} />
                    </Col>
                }
            </Fragment>
        )}
     

    </Row>
        
  )
}

export default Dokumente