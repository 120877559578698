import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"

import { Row, Col, Button }  from 'react-bootstrap';

import style from "./NewsletterVPCard.module.css"

function NewsletterCardVP(props) {

    const [weiterlesen, setWeiterlesen] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [modalUrl, setModalUrl] = useState(false);

    const handleExternalURL = (url) => {

        setShowModal(false);
        window.scrollTo({
          top: 0,
          behavior: "instant"
        });
        setModalUrl(url);
        setShowModal(true);
      }
    

  function downloadPDF(base64pdf) {
    const linkSource = base64pdf;
    const downloadLink = document.createElement("a");
    const fileName = "VIN1-Vertriebs-Newsletter.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (

    <Row className={style.cardContainerNews} style={{marginTop: "20px"}}>
        <Col xs={12} md={3} className={style.newsImageContainer}>
            <img className={style.newsImage} src={props.data.image} alt="" />
        </Col>
        <Col xs={12} md={9} style={{marginTop: "10px"}}>
       
            <div className={style.newsTextContainer}>
                <span style={{color: "#808080"}}>{props.data.datum}</span>
                <h5 className="fw-bold">{props.data.title}</h5>

                {props.data.newstext.length > 500 && !weiterlesen.includes(props.data.id) && 
                    <Fragment>
                        <p>{props.data.newstext.substring(0,400)+" ..."}</p>
                        <div className={style.textBtn} onClick={() => setWeiterlesen([...weiterlesen, props.data.id])}>
                            <FontAwesomeIcon className={style.icon} icon={faChevronDown} size="lg" /><span style={{paddingLeft: "10px"}}>Weiterlesen</span>
                        </div>
                    </Fragment>
                }
                {props.data.newstext.length > 400 && weiterlesen.includes(props.data.id) && 
                    <Fragment>
                        <p>{props.data.newstext}</p>
                        <div className={style.textBtn} onClick={() => setWeiterlesen([...weiterlesen.filter((item) => item !== props.data.id)])} >
                            <FontAwesomeIcon className={style.icon} icon={faChevronUp} size="lg" /><span style={{paddingLeft: "10px"}}>Einklappen</span>
                        </div>
                    </Fragment>
                }
                {props.data.newstext.length <= 400 && <p>{props.data.newstext}</p>}

            </div>
        </Col>
        <Col xs={12} md={12} className={style.btnContainer}>
                {props.data.pdf && <Button className="fw-bold" style={{background: "rgb(83, 178, 83)", border: "none", marginRight: "10px"}} onClick={() => downloadPDF(props.data.pdf)}>PDF Download</Button>}
                {props.data.url && <Button className="fw-bold" style={{background: "#520da7"}}onClick={()=>handleExternalURL(props.data.url)}>Mehr Infos</Button>}         
        </Col>

        {showModal && (
          <WebViewModal
            url={modalUrl}
            hideModal={setShowModal}
          />
        )}
        
    </Row>
  )
}

export default NewsletterCardVP