import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; 
import Header from "../Header/Header";
import { Container, Row, Col, Button } from "react-bootstrap";

import VINButton from '../../VINButton';

function RechnerBaufinanzierung() {

  const navigate = useNavigate();

  return (
    <>
      <Header url={"/"} />

      <Container style={{ maxWidth: "800px" }}>
        <Row style={{ justifyContent: "center" }}>
          <Col style={{ textAlign: "center" }}>
            <img
              src={require("../../img/Baufinanzierung.png")}
              alt=""
              style={{ maxWidth: "160px", marginTop: "30px" }}
            />

            <h1>Baufinanzierung</h1>
            <p className="text-center">
              Wir begleiten Dich auf dem Weg in die eigenen vier Wände mit der
              passendenden Finanzierungslösung und besten Konditionen!
            </p>
          </Col>
        </Row>

        <Row style={{justifyContent: "center"}}>

          <Col className="mt-3 d-flex justify-content-center">
            <VINButton text={"Finanzierung berechnen"} onClick={() => navigate("rechner")} />
          </Col>

          <Col className="mt-3 d-flex justify-content-center">
            <VINButton text={"Zinschart anzeigen"} onClick={() => navigate("zinsen")} />
          </Col>
        </Row>

        <div style={{ marginBottom: "80px" }} />
      </Container>
    </>
  );
}

export default RechnerBaufinanzierung;
