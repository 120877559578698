import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import style from "./CardSmall.module.css"
import classLister from 'css-module-class-lister';


function CardSmall(props) {

    let navigate = useNavigate();
    const classes = classLister(style);

  return (
   
      <div onClick={()=>navigate(props.url)} className={style.cardContainer} style={{outline: props.selected == 1 ? "4px solid "+props.color : ""}}>
        <img className={style.cardImg} src={require("../../img/"+props.img)} alt="" />
        <p className={style.cardTitle}>{props.title}</p>
      </div>
  )
}

export default CardSmall

 {/*<div onClick={()=>navigate(props.url)} className={classes(style.cardContainer, (props.selected == 1 && style.selected))}>*/}