
export const formatDateTime = (dateTimeString) => {

    return new Intl.DateTimeFormat('de-DE', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        //second: 'numeric',
        timeZone: 'Europe/Berlin',
    }).format(new Date(dateTimeString));
}


export const formatDateTimeLong = (dateTimeString) => {

    return new Intl.DateTimeFormat('de-DE', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'Europe/Berlin',
    }).format(new Date(dateTimeString));
}


export const formatDate = (dateString) => {

    return new Intl.DateTimeFormat('de-DE', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
    }).format(new Date(dateString));
}