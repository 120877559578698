import React from "react";
import QRCode from "qrcode.react";
import { Row, Col, Button } from "react-bootstrap";

class Component extends React.Component {
  constructor(props) {
  super(props);
  //this.downloadPng = this.downloadPng.bind(this);
  //this.downloadSvg = this.downloadSvg.bind(this);
  //this.renderSVGToCanvas = this.renderSVGToCanvas.bind(this);
};

// Wenn nicht als Arrow Function definiert, also z.B. nur
//downloadPng () {
//    ...
//}
// dann muss diese mit this.downloadPng = this.downloadPng.bind(this);
// im constructor bekannt gemacht werden.



  downloadPng = () => {

    const svg = document.getElementById('QRCode-svg');
    const canvas = document.querySelector('.HpQrcode > canvas');
    const ctx = canvas.getContext('2d');
  
    const svgBlob = new Blob([new XMLSerializer().serializeToString(svg)], { type: "image/svg+xml;charset=utf-8" });
    const domURL = window.URL || window.webkitURL;
    const url = domURL.createObjectURL(svgBlob);
    const img = new Image();
  
  
    img.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Draw the SVG onto the canvas
  
        const png = canvas.toDataURL("image/png");
  
        const link = document.createElement("a");
        link.href = png;
        link.download = "QRCode_" + this.props.partner + ".png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        domURL.revokeObjectURL(url); // Clean up
    };
  
    img.src = url;
  };

  /*
  downloadPng_OLD () {
    const canvas = document.querySelector('.HpQrcode > canvas');
    const link = document.createElement("a");
    link.href = canvas.toDataURL();
    link.download = "QRCode_VIN1.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }; 
  */ 

  downloadSvg = () => {
    const svg = document.querySelector('.HpQrcode > svg');
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svg);
    const dataUri = 'data:image/svg+xml,' + encodeURIComponent(svgString);
    const link = document.createElement("a");
    link.href = dataUri;
    link.download = "QRCode_" + this.props.partner + ".svg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    return (
         <div className="d-flex align-items-center justify-content-center flex-column">
	        <div className="HpQrcode my-2 position-relative">
                <QRCode 
                    value={"https://my.vin1.eu/register?partner="+this.props.partner}
                    id="QRCode-svg"
                    size={250} 
                    renderAs="svg" 
                />
                <canvas 
                    ref={this.canvasRef}
                    width={500} //Pixelmaße für Download
                    height={500}
                    style={{display: 'none'}} // Hide the canvas
                />
            </div>
            <Row
              style={{width: "70%", marginTop:"20px"}}
            >
                <Col xs={12} md={6}
                  className="d-flex justify-content-center"
                >
                    <Button 
                        className="btn-success border-0 rounded w-100 py-2 text-center my-2 d-flex align-items-center justify-content-center align-self-center"
                        onClick={this.downloadPng}
                        >
                        Download QR PNG 500px
                    </Button>
                </Col>
                <Col xs={12} md={6}
                  className="d-flex justify-content-center"
                >
                    <Button
                        className="btn-primary border-0 rounded w-100 py-2 text-center my-2 d-flex align-items-center justify-content-center align-self-center"
                        onClick={this.downloadSvg}
                        >
                        Download QR SVG Vektor
                    </Button> 
                </Col> 
            </Row>
         </div>
    );
};
};
export default Component;