import React, { useState, useContext, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom';
import Spinner from '../Bausteine/Spinner/Spinner';
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faCirclePlus, faDownload } from "@fortawesome/free-solid-svg-icons";

import Card from '../Card/Card'
import CardGesellschaften from '../Card/CardGesellschaften';
import CardUpload from '../Card/CardUpload';
import CardSub from '../Card/CardSub';

import { Container, Row, Col, Button, Form } from 'react-bootstrap';


//import Select, { createFilter } from 'react-select';
import Swal from 'sweetalert2';

import {
  ref,
  uploadBytesResumable,
  getDownloadURL
} from "@firebase/storage";
import { storage } from "../../firebase";
 

import axios from 'axios';
import { AuthContext } from "../../store/auth-context";

import { makeID } from '../../util/makeID';

import SelectInput from '../Bausteine/SelectInput/SelectInput';

import style from '../VIN1_Partnerseite/VIN_Bausteine/DateienSenden.module.css'
import VINButton from '../../VINButton';
import { UserContext } from '../../store/user-context';


function PartnerAnfrageSenden(props) {


    const ColorTheme = '#520da7'

    const { partner } = props;

    const { user } = useContext(UserContext);
    const { currentUser } = useContext(AuthContext);
  
    const [pickedFiles, setPickedFiles] = useState([]);
    const [anlass, setAnlass] = useState("");
    const [nachricht, setNachricht] = useState("");
  
    const [submittedOK, setSubmittedOK] = useState(false);

    const [spinner, setSpinner] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if(partner) {
        setLoading(false);
      }
    },[partner])



    function handleFile(e) {
  
          let count = pickedFiles.length;
          let data = [];
  
          Array.from(e.target.files).forEach(file => {

              
          
              if(count < 10) {

                if(file.size <= 10000000) {
                  data = [...data, {
                      id: makeID(8),
                      file: file,
                      name: file.name,
                      size: file.size,
                      type: file.type
                    }]
                  count = count + 1;
                }
                else
                {
                  Swal.fire({
                    title: "Dateigröße",
                    html: "Dateien sind bis zu einer Größe von 10MB erlaubt.",
                    icon: "info"
                  });
                }

              }
              else
              {
                  Swal.fire({
                      title: "Anzahl Dateien",
                      html: "Du kannst hier nur bis zu 10 Dateien pro Vorgang übermitteln. Die zu viel ausgewählten Dokumente wurden nicht hinzugefügt.",
                      icon: "info"
                  });
              }
          });
  
          setPickedFiles([...pickedFiles, ...data]);
      }
  
  
    function removeFileUploadHandler (id) {
      //console.log("remove: " + name);
      setPickedFiles(pickedFiles.filter(obj => obj.id !== id));
    }
  
  

  
  
  
  
  
    const handleSubmit = async (e) => {

      e.preventDefault();
      
  
      if(user.kdnr === undefined || user.kdnr === "") {
        Swal.fire({
          title: "Kundennummer",
          html: "Damit du dieses Formular absenden kannst, benötigst du eine Kundennummer. Wenn du dich nicht gerade registriert hast, wende dich an den Support.",
          icon: "info",
        });
        return;
      }
  
      //Spinner
      setSpinner(true);
  
      const uploadPromises = pickedFiles.map((file) => {
        return new Promise((resolve, reject) => {
          let UUID = crypto.randomUUID();
          const storageRef = ref(
            storage,
            "/Partner/" + partner.partner_name + "/BeraterConnect/"+ currentUser.uid + "/" + UUID + file.name
          );
          const uploadTask = uploadBytesResumable(storageRef, file.file);
      
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Track upload progress if needed
            },
            (error) => {
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then((url) => {
                  const fileDetails = {
                    uid: currentUser.uid,
                    title: UUID + file.name,
                    //category: category,
                    created: new Date(),
                    mimeType: file.type,
                    size: file.size,
                    url: url,
                  };
                  resolve(fileDetails);
                })
                .catch((error) => {
                  reject(error);
                });
            }
          );
        });
      });
      
  
      Promise.all(uploadPromises)
        .then((uploadedFiles) => {
          // All files have been uploaded successfully
  
            //Create Task with Public URLs
            axios({
              method: 'post',
              headers: {
                'Authorization': 'Bearer ' + currentUser.accessToken
              },
              url: process.env.REACT_APP_BACKEND+"/partner/anfrage",
              data: {
                partner_slug: partner.slug,
                betreff: anlass,
                nachricht: nachricht,
                dateien: [...uploadedFiles]
              }
            })
            .then(doc => {
              if(doc) {
                Swal.fire({
                  title: "Erfolg",
                  html: "Deine Daten wurden erfolgreich an "+partner.partner_name+" übermittelt.",
                  icon: "success",
                });
              }
            })
            .catch(err => {
              console.log("Error -1 uploading files: " + err);
        
              Swal.fire({
                title: "Fehler",
                html: "Es ist ein Fehler bei der Übertragung aufgetreten. Versuche es später erneut.",
                icon: "error"
              })  
            });
  
            //Clean Felder
            setSpinner(false);
  
            setPickedFiles([]);
        })
        .catch(err => {
          console.log("Error -2 uploading files: " + err);
          Swal.fire({
            title: "Fehler",
            html: "Es ist ein Fehler bei der Übertragung aufgetreten. Versuche es später erneut.",
            icon: "error"
          })  
  
          setSpinner(false);
  
        });
    }


    return (
      <>
        {loading && 
          <Spinner message={"Partner wird geladen."} />
        }
        {!loading && partner &&
          <Row className="mt-3 justify-content-center" style={{marginBottom: "160px"}} >
            <Col style={{textAlign: "center"}}>

              <form onSubmit={handleSubmit} type="POST" target="#">
                
                <Row style={{ justifyContent: "center" }}>      

                  <p>
                    Du hast Fragen an {partner.partner_name}?<br />
                    Schreibe jetzt eine Anfrage. Der Partner wird sich wenn nötig mit Dir in Verbindung setzen.
                  </p>

                  <Form.Label>Anlass</Form.Label>
                  <Form.Group className="mb-3">
                    <select
                      className="form-select"
                      value={anlass}
                      onChange={(e) => setAnlass(e.target.value)}
                      required
                    >
                      <option value="" disabled>Bitte wählen</option>

                      {partner.aufgabenarten.length >=1 &&
                        partner.aufgabenarten.map((art, index) =>
                        (
                         <option value={art} key={index}>{art}</option>
                        )
                      )}
                    
                      {!partner.aufgabenarten &&
                        <>
                          <option value={"Auftragsanfrage"} key={0}>Auftragsanfrage</option>
                          <option value={"Rückruf"} key={1}>Rückruf</option>
                        </>
                      }
                    </select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                      <Form.Label>Nachricht</Form.Label>
                      <Form.Control as="textarea" rows={5}
                          placeholder="Mitteilung"
                          name="nachricht"
                          value={nachricht}
                          onChange={(e) => setNachricht(e.target.value)}
                      />
                  </Form.Group>



                  <p className="text-center mt-3">Du willst deiner Anfrage Dateien hinzufügen? Kein Problem, erlaubt sind Bilder (.png, .jpg) und PDFs bis 10MB pro Datei.</p>
            
                </Row>


                  {pickedFiles.length === 0 && <p className="text-center">Du hast noch keine Dokumente ausgewählt.</p>}

                      {pickedFiles.map(file => 
                          <CardUpload key={file.id} data={file} removeFileUploadHandler={removeFileUploadHandler} />
                      )}
                      {pickedFiles.length !== 0 && 
                      <p className="text-center"> Füge weitere Dokumente durch erneutes klicken auf den Button hinzu.</p>
                      }


                    <label
                        htmlFor="fileUpload"
                        className="filePicker"
                    >
                      Dokumente auswählen
                      <input
                          id="fileUpload"
                          type="file"
                          multiple
                          accept="application/pdf, image/png, image/jpeg"
                          style={{ display: "none" }}
                          onChange={handleFile}
                      />
                    </label>

                  <br />
                  <br />


                  <br />
                  <br />
                  <br />

          
                  {/*<div style={{display: "flex", justifyContent: "center"}}>
                      <Button
                      style={{ background: ColorTheme, padding: "15px", width: "auto", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                      onClick={handleSubmit}
                      >Absenden</Button>
                  </div>*/}

                <div style={{all: "unset"}}>
                  <VINButton text={"Absenden"} type={"submit"} />
                </div>

     
              

              {submittedOK && <p className="text-center fw-bold" style={{marginTop: "10px", color: "#1f8139"}}>Du hast das Formular erfolgreich an uns übermittelt.</p>}          

              {spinner &&
                <Spinner message={"Bitte warten. Daten werden verarbeitet."} />
              }

            </form>

          </Col>
        </Row>
      }
    </>
  )
}

export default PartnerAnfrageSenden;


  
    /*
      //configure axios header
      const headerConfig = { headers: { 'Content-Type': 'multipart/form-data' } };

      //Formular an uns übertragen
      var FilesData = new FormData();
      FilesData.append('kundennummer', location.state.kundennummer);
      FilesData.append('bereich', bereich);

      let i = 0;
      pickedFiles.forEach(file => {
        FilesData.append("dateien["+i+"]", file.file);
        i = i+1;
      })

      let res = "";

      try {   
        // CSV File
        res = await axios({
            method: 'post',
            url: "https://api.mariusschulte.com/crm/beraterConnect.php",
            data: FilesData,
            headerConfig
        })

        //Create Task CRM
        await axios({
          method: 'post',
          headers: {
            'Authorization': 'Bearer ' + currentUser.accessToken
          },
          url: process.env.REACT_APP_BACKEND+"/task/berater-connect", // endpunkt nicht mehr vorhanden
          data: {
            kdnr: location.state.kundennummer,
            vorname: location.state.vorname,
            nachname: location.state.nachname,
            bereich: bereich
          }
        })
        
          Swal.fire({
            title: "Erfolg",
            html: "Deine Daten wurden erfolgreich an uns übermittelt.<br />Dein VIN1 Berater wird Kontakt zu dir aufnehmen.",
            icon: "success",
          });

        } 
        catch(err) {
          console.log(err);

            Swal.fire({
              title: "Fehler",
              html: "Es ist ein Fehler bei der Übertragung aufgetreten. Versuche es später erneut.",
              icon: "error"
            });
        }
      */

      /*
      try {
        // Send Mail
        var bodyFormData2 = new FormData();
        bodyFormData2.append('kundennummer', location.state.kundennummer);
        bodyFormData2.append('email', location.state.email);
        bodyFormData2.append('templateId', 4643);

        axios({
            method: 'post',
            url: "https://api.mariusschulte.com/crm/sendMail.php",
            data: bodyFormData2
        });

        //console.log("Mail versendet CRM")
      } 
      catch(err) {
      
      }
      */

      /*
      setMeineGesellschaften([
          {
              ID: makeid(15),
              Versicherung: versicherung.value,
              Übermittelt: getUploadDate()
          }, ...meineGesellschaften
      ]); 
      */


   //handleSubmit Ende





  /*
  const getUploadDate = () => {
    var date=new Date();
    let day=date.getDate();
    let month=date.getMonth();
    month=month+1;

    if((String(day)).length===1)
      day='0'+day;
    if((String(month)).length===1)
      month='0'+month;

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    if((String(hours)).length===1)
      hours='0'+hours;
    if((String(minutes)).length===1)
      minutes='0'+minutes;
    if((String(seconds)).length===1)
      seconds='0'+seconds;

    let dateT=day+ '.' + month + '.' + date.getFullYear() + ' '+ hours+':'+minutes+':'+seconds;
    //dateT=String(dateT);
    return dateT;
  }

  const makeid = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}




  async function downloadPDF(filepath, filename) {

    fetch(filepath).then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = filename;
          alink.click();
      })
    })
  }


  function handleFileUploadPDF(filepath, filename) {


    let UUID = crypto.randomUUID();
    if (filepath) {
      const storageRef = ref(
        storage,
        "/" + auth.currentUser.uid + "/" + UUID + filename
      );

      var url = "pdf/Maklervertrag+Vollmacht_VIN1.pdf";

      //You may not need this part if you have the PDF data locally already
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200) {
              //console.log(this.response, typeof this.response);
              //now convert your Blob from the response into a File and give it a name
              var fileOfBlob = new File([this.response], 'your_file.pdf');

        const uploadTask = uploadBytesResumable(storageRef, fileOfBlob);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            //console.log(snapshot);
          },
          (err) => console.log(err),
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {

                addDoc(collection(db, "Users", auth.currentUser.uid, "Privat"), {
                  title: UUID + filename,
                  description: "",
                  uploader: "app",
                  category: "Ich",
                  sub_category: "Vollmacht",
                  created: new Date(),
                  mimeType: "application/pdf",
                  size: fileOfBlob.size,
                  url: url,
                });          
            });
          }
        );
      }
    }
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();  

  } //filepath
}
*/
  
  

  