import React, { Fragment } from 'react'

import style from "./CardSub.module.css"


function CardSub(props) {

  // outline hat Probleme im Safari und wird als Rechteck dargestellt ohne Radius!
  // das selbe kann mit box-shadow erreicht werden.
  // box-shadow: 0 0 0 4px red;
  return (
    <>
      <div className={style.cardContainerSub}
        style={{boxShadow: props.selected == 1 ?
          '0 0 0 4px '+props.color+', rgba(0, 0, 0, 0.35) 0px 5px 15px'
        : 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}
      >
        <p className={style.cardTitleSub}>{props.title}</p>
      </div>
       
      {/* old
      <div className={style.cardContainerSub}
        style={{outline: props.selected == 1 ? "4px solid "+props.color : "",
                border: props.selected == 1 ? "3px solid red"+props.color : ""}}
        >
          <p className={style.cardTitleSub}>{props.title}</p>
      </div>
      */}
    </>
  )
}

export default CardSub