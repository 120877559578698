import React from 'react'

import style from "./VINButtonSecondary.module.css"

function VINButtonSecondary(props) {

  return (
    <div className={[style.container, style.containerOne]}>
        <button className={style.vinbutton} onClick={props.onClick}>
          <span className={style.buttonText}>{props.text}</span>
        <div className={style.fillOne}></div>
        </button>
    </div>
  )
}

export default VINButtonSecondary