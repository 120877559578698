import React, { useState, Fragment, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';

import Spinner from "../Bausteine/Spinner/Spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark  } from "@fortawesome/free-solid-svg-icons";

import { Container, Row, Col, Button } from "react-bootstrap";

import style from "./Login.module.css"
import axios from 'axios'
import Swal from 'sweetalert2'

import { getAuth, setPersistence, browserSessionPersistence, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

//import  secureLocalStorage  from  "react-secure-storage";
import { AuthContext } from "../../store/auth-context";
import VINButton from "../../VINButton";

import WebpImage from "../Bausteine/WebpImage";
import { UserContext } from "../../store/user-context";


function Login() {

    const { user, updateUser } = useContext(UserContext);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        //Speicher einmal aufräumen zur Sicherheit
        sessionStorage.clear();
    },[])

    // Vorteilspartner Landingpage leitet zum Login, aber Partner wird über LocalStorage getrackt,
    // falls sich dieser neu registriert, damit Sponsor nicht verloren geht.
    useEffect(() => {
        const partnerValue = searchParams.get("partner");
        if (partnerValue) {
            localStorage.setItem("partner", partnerValue);
        }
    }, [searchParams])
    


    const [processLogin, setProcessLogin] = useState(false);
    

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [resetPassword, setResetPassword] = useState(false);
    const [resetPasswordMail, setResetPasswordMail] = useState("");
    


    const handleLogin = async () => {

        if(email === "" || password === "")
        {
            Swal.fire({
                title: 'Fehler beim Login',
                text: 'Du musst eine Login E-Mail und dein Passwort eingeben, um dich einzuloggen.',
                icon: 'info',
                confirmButtonText: 'Ok'
              });
            return;
        }

        setProcessLogin(true);


        const auth = getAuth();
    
            signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                
                setProcessLogin(false);
                navigate("/");
            })
            .catch((error) => {

                setProcessLogin(false);
                
                if (error.code === "auth/invalid-email" || error.code === "auth/wrong-password")
                {
                    Swal.fire({
                        title: 'Fehler beim Login',
                        text: 'Benutzername und/oder Passwort falsch.',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    });
                }
                
                else if(error.code === "auth/user-not-found") {

                    Swal.fire({
                        title: 'Benutzerkonto',
                        text: 'Für die eingegebene E-Mail wurde kein Benutzerkonto gefunden.',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });

                }

                else if(error.code === "auth/too-many-requests") {

                    Swal.fire({
                        title: 'Benutzerkonto',
                        text: 'Du hast dich scheinbar zu oft falsch angemeldet. Klick Passwort vergessen und vergib ein neues Passwort um fortzufahren.',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });

                }

                else if(error.code === "auth/user-disabled")
                {
                    Swal.fire({
                        title: 'Benutzerkonto',
                        text: 'Dein Benutzerkonto ist momentan gesperrt.',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
            
                else
                {
                    Swal.fire({
                        title: 'Login',
                        text: 'Login momentan nicht möglich. Fehler: '+ error.message,
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    });
                }
            
            });
          
        
    }



    const handlePasswordVergessen = async() => {
        // Handling with Firebase Mail..
        /*
        const auth = getAuth();
        sendPasswordResetEmail(auth, resetPasswordMail)
        .then(() => {
            Swal.fire({
                title: 'Passwort vergessen',
                text: 'Wenn für die eingegebene E-Mail Adresse ein Benutzerkonto erstellt wurde, erhältst du eine E-Mail zum Zurücksetzen deines Passworts.',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        })
        .catch((error) => {
            //const errorCode = error.code;
            //const errorMessage = error.message;
            // ..
            Swal.fire({
                title: 'Passwort vergessen',
                text: 'Fehler beim Beantragen eines Passwort-Resets. Versuche es erneut oder setzte dich mit dem VIN1-Support in Verbindung.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        });
        */

        axios.post(process.env.REACT_APP_BACKEND + "/my-vin1/passwort-reset", {
            email: resetPasswordMail
        })
        .then((res) => {
            Swal.fire({
                title: 'Passwort vergessen',
                text: 'Wenn für die eingegebene E-Mail Adresse ein Benutzerkonto erstellt wurde, erhältst du eine E-Mail zum Zurücksetzen deines Passworts.',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        })
        .catch((error) => {
            //const errorCode = error.code;
            //const errorMessage = error.message;
            // ..
            Swal.fire({
                title: 'Passwort vergessen',
                text: 'Fehler beim Beantragen eines Passwort-Resets. Versuche es erneut oder setzte dich mit dem VIN1-Support in Verbindung.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        });
    }


// ---------------------------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------------------------


  let navigate = useNavigate();

  return (

    <Fragment>
        <Container className={style.loginContainer}>
            <Row style={{justifyContent: "center"}}>
                <Col className={style.cardContainer}>
      
                        {/*<img className={style.loginIMG} src={(require("../../img/VIN1_Logo_Lila.png"))} alt="" />*/}

                        <WebpImage
                            webpSrc={require("../../img_webp/VIN1_Logo_Lila.webp")}
                            fallbackSrc={require("../../img/VIN1_Logo_Lila.png")}
                            alt="VIN1 Logo"
                            className={style.loginIMG}
                        />
                     

                    <form className={style.formContainer}>

                        <div className={style.formInputCustom}>
                            <label htmlFor="email" className="form-label">E-Mail</label><br />
                            <input
                                type="text"
                                id="email"
                                name="email"
                                placeholder="user@example.com"
                                autoComplete="on"
                                autoCapitalize="none"
                                inputMode="email"
                                onChange={(e) => setEmail(e.target.value.trim())}
                            />
                       </div>
                        
                        <div className={style.formInputCustom}>
                            <label htmlFor="password" className="form-label">Passwort</label><br />
                            <input
                                type="password"
                                id="password"
                                name="password"
                                placeholder="**********"
                                autoComplete="on"
                                autoCapitalize="none"
                                onChange={(e) => setPassword(e.target.value.trim())}
                            />
                        </div>

                        <br />


                        {/*<div className="pointer" onClick={handleLogin}>
                            <VINButton text={"Einloggen"} />
                        </div>*/}
                        
                        <Button className={style.loginBtnCustom} onClick={handleLogin}>Einloggen</Button>
                    
                    </form>
                </Col>
            </Row>

            <br />

            <Row className={style.registerContainer}>
                <p className="fw-bold" style={{color: '#520da7', width: "auto", cursor: "pointer", margin: "10px 10px"}} onClick={()=>{
                    setResetPassword(!resetPassword);   
                    setTimeout((() => window.scrollTo(0, document.body.scrollHeight)), 100);
                }}>
                    Passwort vergessen?
                </p>
                <p className="fw-bold" style={{color: '#520da7', width: "auto", cursor: "pointer", margin: "10px 10px"}} onClick={()=>{
                    navigate("/register");
                }}>
                    noch kein Konto?
                </p>
            </Row>


        </Container>
        
        <br />

        {/*
        // ---------------------------------------------------------------------------------------------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------------------------------------
        */}
       
            {resetPassword &&
                <Container style={{maxWidth: "500px", marginBottom: "180px"}}>
                    <Row style={{justifyContent: "flex-end"}}>
                        <FontAwesomeIcon style={{width:"auto", cursor: "pointer", marginBottom: "10px"}} icon={faXmark} size="2x" onClick={() => { setResetPassword(!resetPassword) }} />
                    </Row>
                    <Row style={{justifyContent: "center"}}>
                        <h2 style={{color: '#520da7', fontSize: "140%"}} className="fw-bold text-center">
                            Passwort vergessen
                        </h2>
                        <p className="text-center">Trage hier deine Login E-Mail Adresse ein.<br />
                            Du bekommst eine E-Mail mit einem Link zum Zurücksetzen des Passworts zugesendet.
                        </p>
                        <div className={style.formInputCustom}>
                            <label htmlFor="email" className="form-label">E-Mail</label><br />
                            <input
                                type="text"
                                id="email"
                                name="email"
                                placeholder="user@example.com"
                                autoComplete="on"
                                value={resetPasswordMail ?? ""}
                                onChange={(e) => setResetPasswordMail(e.target.value.toLowerCase().trim())}
                            />
                        </div>
                        <Button className={style.BtnCustom} onClick={handlePasswordVergessen}>Passwort Reset anfordern</Button>
                    </Row>
                </Container>
                }

        {processLogin &&
            <Spinner
              message={"Deine Login Daten werden überprüft."}
            />
        }

    </Fragment>
  );
}

export default Login;