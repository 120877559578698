import React, { useState, useEffect } from 'react'
import Header from './Header/Header'
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, Button } from 'react-bootstrap';

import CardDoppelt from './Card/CardDoppelt';

import WeiterleitungPartnerSelection from './WeiterleitungPartnerSelection'

import PartnerConnectModal from './Bausteine/PartnerConnectModal';
import PartnerConnectCard from './Bausteine/PartnerConnect';

function ConnectWeiterleitung() {

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (val) => {
    setShowModal(val);
  }



  
  return (
    <>
      <Header url={"/"} />

      <Container style={{maxWidth: "800px"}}>

        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
            <Col
              xs={12}
              md={12}
              style={{ marginTop: "20px" }}
              onClick={() => navigate("dateien-senden")}
            >
              <CardDoppelt
                url={""}
                img={"BeraterConnect.png"}
                title={"Dateien senden"}
                text={
                  <p>
                    Jetzt Dokumente schnell und datenschutzkonform an
                    Deinen Berater senden.
                    <br />
                    <b>Jetzt Dokumente senden!</b>
                  </p>
                }
              />
            </Col>
          </Row>



          <Row style={{ marginTop: "16px", justifyContent: "center" }}>
            <Col
              xs={12}
              md={12}
              style={{ marginTop: "20px" }}
              onClick={() => navigate("/partner/vin1", { state: {category: "Verträge zusammenführen"} } )}
            >
              <CardDoppelt
                url={""}
                img={"Bestandsuebertragung.png"}
                title={"Verträge zusammenführen"}
                text={
                  <p>
                    Hier kannst Du Deine bestehenden Versicherungsverträge
                    an einer Stelle zusammenführen!
                    <br />
                    <b>Jetzt loslegen!</b>
                  </p>
                }
              />
            </Col>
          </Row>



          <Row style={{ marginTop: "16px", justifyContent: "center" }}>
            <Col
              xs={12}
              md={12}
              style={{ marginTop: "20px" }}
              onClick={() => navigate("/beratung")}
            >
              <CardDoppelt 
                url={""}
                img={"Berater.png"}
                title={"Berater kontaktieren"}
                text={
                  <p>
                    Du hast ein Anliegen oder möchtest beraten werden?
                    Dann kontaktiere uns hier!
                    <br />
                    <b>Jetzt Kontakt aufnehmen!</b>
                  </p>
                }
              />
            </Col>
          </Row>


          <Row style={{ marginTop: "16px", justifyContent: "center" }}>
            <Col
              xs={12}
              md={12}
              style={{ marginTop: "20px" }}
              onClick={() => navigate("unternehmens-connect")}
            >
              <CardDoppelt 
                url={""}
                img={"Gewerbe.png"}
                title={"Unternehmens-Connect"}
                text={
                  <p>
                    Trete in Interaktion mit allen verbundenen Unternehmen.
                    <br />
                    <b>Jetzt starten!</b>
                  </p>
                }
              />
            </Col>
          </Row>

          
        {/*<PartnerConnectCard />*/}
      
    
      {/*
        <Row className="mt-5"style={{marginTop: "16px", justifyContent: "center"}} >
          <h5 className="text-center">Bestände übertragen - Wähle zunächst aus</h5>
        </Row>
      
        <WeiterleitungPartnerSelection navigateTo={"Bestände übertragen"} />
      */}
         <div style={{marginBottom: "120px"}} />

      </Container>

    </>
  );
}

export default ConnectWeiterleitung