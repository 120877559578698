import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap'

import Header from '../Header/Header';
import CardSub from '../Card/CardSub';
import SelectInput from '../Bausteine/SelectInput/SelectInput';
import PartnerConnectCard from '../Bausteine/PartnerConnect';
import { AuthContext } from '../../store/auth-context';

import axios from 'axios';
import Swal from 'sweetalert2';
import { UserContext } from '../../store/user-context';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";


function UnternehmensConnect(props) {

    const ColorTheme = 'var(--primary-color)';

    const navigate = useNavigate();

    const location = useLocation();
    const navigateBack = location?.state?.navigateBack ? location.state.navigateBack : "/connect";
    const url_state = location?.state?.navigateBack ? {} : {};

    const { currentUser } = useContext(AuthContext);
    const { partnerListe, setPartnerListe } = useContext(UserContext);

  
    const [selectedPartnerName, setSelectedPartnerName] = useState("");
  
    const [showModal, setShowModal] = useState(false);
  
    const [bereich, setBereich] = useState("");
  
    const [selectedBereich, setSelectedBereich] = useState("");
   
    const [partnerInput, setPartnerInput] = useState("");
    const [kategorieInput, setKategorieInput] = useState("Alle Partner");
  
    const [kategorieListe, setKategorieListe] = useState([
      {
        label: "Alle Partner",
        value: "Alle Partner"
      },{
        label: "Architekten",
        value: "Architekten"
      },{
        label: "Auto",
        value: "Auto"
      }, {
        label: "Multimedia",
        value: "Multimedia"
      }
    ]);
  
    const [partnerListeModal, setPartnerListeModal] = useState([]);


    


    const handleKategorieChange = (data) => {
        //console.log(data.value);
        setKategorieInput(data.value);

        // Axios set Partner in DB

        // Partner in aktuelle Liste direkt aufnehmen!
    }

    const handlePartnerChange = (e) => {
        setSelectedPartnerName(e.value);
    }



    const handlePartnerChangeModal = (data) => {
        //console.log(data.value);
        setPartnerInput(data.value);

        // VIN1 CRM Backend => PartnerCollection => Partner Object in subscribers hinterlegen

        // In Firebase User Doc Daten subscriptions Array Feld den "unique" partner_name pushen

        // Partner in aktuelle Liste direkt aufnehmen!
    }


    const handlePartnerNavigate = (partnerName) => {
        //alert("navigiere: "+)
        //console.log(partnerName);
        navigate(partnerName);
    }





    useEffect(() => {

      if(currentUser?.uid) {
  
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + currentUser.accessToken
            },
            url: process.env.REACT_APP_BACKEND+"/partner/connected-partner",
        })
        .then(resData => {

          setPartnerListe(resData.data);
          /*
          let liste = [];
          resData.data.forEach(elem => {
            liste.push({value: elem.slug, label: elem.partner_name})
          })
          setPartnerListe(liste);
          */
        })
        .catch(error => {
          Swal.fire({
            "icon": "error",
            "title": "Fehler",
            "html": "Fehler beim Abrufen deiner Partner."
        });
        })
      }
    },[currentUser])


    const people = [
      { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
      { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
      { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
      { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
      // More people...
    ]




    return (
      <>
        <Header
            url={navigateBack}
            url_state={url_state}
        />
        <Container style={{ maxWidth: "800px", marginBottom: "160px" }}>
    
          <Row className="justify-content-center mt-5">

            <Col xs={12} md={12} className="text-center">
              {/*
                <p>
                  <b>In Kürze kannst du hier Partnern Dateien senden!</b><br />
                </p>
              */}
            
            {/*<img src={require("../../../img/hands.png")} alt="" style={{maxWidth: "160px", margin: "40px 0px"}} />*/}
      
              <img src={require("../../img/Gewerbe.png")} alt="" style={{maxWidth: "160px", margin: "0px 0px 20px 0px"}} />
                <h5>Unternehmens-Connect</h5>
                <p className="text-center">Verbinde dich mit einem Partner um zu interagieren.</p>
                <br />

                <div>
                  <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h5>Verbundene Partner</h5>
                      </div>
                      
                    </div>
                  </div>

                  {partnerListe.length == 0 ?
                    <i>Du bist mit keinem Partner verbunden.</i>
                    :
                    <div className="mt-8 flow-root overflow-hidden">
                      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <table className="w-full text-left">
                          <thead className="bg-white">
                            <tr>
                              <th scope="col" className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                Unternehmen
                                <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                                <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                              >
                                Standort
                              </th>
                              <th scope="col" className="relative py-3.5 pl-3">
                                <span className="sr-only">Aktion</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {partnerListe.map((partner, index) => (
                              <>
                                {/* Desktop View */}
                                <tr key={index} className="hidden md:table-row">
                                  <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                                    {partner.partner_name}
                                    <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                    <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                  </td>

                                  <td className="px-3 py-4 text-sm text-gray-500">{partner.stadt}</td>

                                  <td className="relative py-4 pl-3 text-right text-sm font-medium">
                                  
                                    {(!partner.aboNeedsAccept || (partner.aboNeedsAccept && partner.aboStatus == "accepted")) &&
                                      <div
                                        onClick={() => navigate('/partner/'+partner.slug)}
                                        className={`${ColorTheme} hover:text-indigo-900 cursor-pointer`}
                                      >
                                        Zum Partner
                                        <FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: "8px"}} />
                                        <span className="sr-only">, {partner.partner_name}</span>
                                      </div>
                                    }

                                    {partner.aboNeedsAccept && (partner.aboStatus == "open" || partner.aboStatus == undefined) &&
                                      <div
                                        className={`${ColorTheme} hover:text-indigo-900 cursor-pointer`}
                                      >
                                        Warte auf Bestätigung
                                        <span className="sr-only">, {partner.partner_name}</span>
                                      </div>
                                    }

                                    {partner.aboNeedsAccept && partner.aboStatus == "declined" &&
                                      <div
                                        className={`${ColorTheme} hover:text-indigo-900 cursor-pointer`}
                                      >
                                        abgelehnt
                                        <span className="sr-only">, {partner.partner_name}</span>
                                      </div>
                                    }
                                  </td>
                                </tr>


                                {/* Mobile View */}
                                <tr
                                  key={index}
                                  className="md:hidden"
                                  onClick={() => navigate('/partner/'+partner.slug)}
                                >
                                  <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                                    {partner.partner_name}
                                    <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                    <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                  </td>

                                  <td className="px-3 py-4 text-sm text-gray-500">{partner.stadt}</td>
                                  <td className="relative py-4 pl-3 text-right text-sm font-medium md:hidden">
                                    <FontAwesomeIcon icon={faChevronRight} />
                                  </td>
                                </tr>

                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }


                </div>
              


              
                
                {/*<p className="text-center">Wähle im folgenden Dropdown einen Partner aus mit dem du bereits verbunden bist.<br />
                  Du kannst durch Eingaben in dem Feld nach Partnern suchen.
                  Danach kannst du zum Partnerbereich wechseln.
                </p>

                <div>
                  <SelectInput
                      value={{'label': selectedPartnerName, 'value': selectedPartnerName}}
                      options={partnerListe}
                      placeholder={"Suche nach einem Partner..."}
                      noOptionsMessage={"Du bist noch mit keinem Partner verbunden."}
                      handleSelectChange={handlePartnerChange}
                  
                  />
                </div>

                {selectedPartnerName &&
                  <div
                      onClick={() => navigate('/partner/'+selectedPartnerName, { state: { category: props.navigateTo } } )}
                      className="btn btn-success w-auto mt-3"
                  >
                      Zum Partnerbereich wechseln
                  </div>
                */}         
                  
            </Col>

          </Row>

          <div className="mt-5">
            {/* MIT PARTNER VERBINDEN */}
            <PartnerConnectCard />
          </div>

        </Container>



            {/* Partner Kachel Liste
                <Row style={{marginTop: "16px", justifyContent: "center", marginBottom: "160px"}} >
                {partnerListe.map(p => 
                    <Col key={p.partner_name} xs={6} md={4} style={{marginTop: "20px"}} onClick={() => handlePartnerNavigate(p.partner_name)} >
                    <CardPartnerExternalImage url={""} img={p.partnerIMG} title={p.partner_name} />
                    </Col>
                )}
                </Row>
          

          <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
            <Modal.Header closeButton>
                <Modal.Title>Connecte dich mit einem Partner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Wähle eine Kategorie, um die Auswahl zu verkleinern oder suche im unteren Feld direkt nach einem Partner</p>
                <SelectInput placeholder={"Wähle eine Kategorie"} noOptionsMessage={"Keine Kategorie gefunden."} options={kategorieListe} handleSelectChange={handleKategorieChange} />

                <p>Wähle im folgenden Dropdown einen Partner aus. Du kannst durch Eingaben in dem Feld nach Partnern suchen.</p>
                <SelectInput placeholder={"Suche nach einem Partner..."} noOptionsMessage={"Kein Partner gefunden."} options={partnerListeModal} handleSelectChange={handlePartnerChangeModal} />


                <Form.Check
                  type={'checkbox'}
                  label={"Mit dem Abonnieren des Partners stimmst du zu, dass deine persönlichen Kundeninformationen (Anschrift, Geburtsdatum, Kontaktmöglichkeiten) für den abonnierten Partner sichtbar werden und dieser Dokumente in deine Dateiablage einspielen kann. Der Partner hat keine Einsicht auf deine anderen Dokumente."}
                  id="cbZustimmung"
                />
            </Modal.Body>
            <Modal.Footer>
                <p
                  style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#656565", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                  onClick={() => setShowModal(!showModal)}
                >
                  Schließen
                </p>
                <p
                  style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                  onClick={() => setShowModal(!showModal)}
                >
                  Mit Partner connecten
                </p>
            </Modal.Footer>
          </Modal>
          */}
      </>
    )
}

export default UnternehmensConnect

