import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom';

import { AuthContext } from '../../store/auth-context'

import Header from '../Header/Header';
import CardSub from '../Card/CardSub';
import CardDokumentPartner from '../Card/CardDokumentPartner';
import CardDokumentPartnerConfirm from '../Card/CardDokumentPartnerConfirm';

//import { ref, uploadBytesResumable, getDownloadURL, uploadTask, deleteObject } from "@firebase/storage";
import { db, storage } from "../../firebase";
import { collection, query, where, doc, getDoc, setDoc, getDocs, onSnapshot, orderBy } from "firebase/firestore"; 

import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import IMGViewModal from '../Bausteine/IMGViewModal/IMGViewModal';


import axios from 'axios';
import Swal from 'sweetalert2';


function PartnerDokumente(props) {

    const { currentUser } = useContext(AuthContext);

    //const { partnerName } = useParams();
    //const decodedPartnerName = decodeURIComponent(partnerName); // Leerzeichen => %20, ...

    const [selectedBereich, setSelectedBereich] = useState("");

    const [partnerDocs, setPartnerDocs] = useState([]);

    // Preview Modal JPG + PNG
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalImgSrc, setModalImgSrc] = useState("");



    useEffect(() => {
        let unsubscribe;
        if(unsubscribe) {
            unsubscribe();
        }

        // Get Dokumente
        async function getPartnerDocs() {

            const colRef = collection(db, "Partner", props.partner.slug, 'Dokumente');

            let q = query(
                colRef,
                where("category", "==", selectedBereich),
                where("googleUID", "==", currentUser.uid), //ACHTUNG!! Wichtig - sonst Einsicht auf andere Kundendaten. Zusätzlich mit Firebase Regeln geprüft.
                orderBy("created", "desc")
            );

            /*
            if(selectedBereich == "Bestätigungen") {
                q = query(colRef, where("bestaetigung", "==", true), where("bestaetigt", "==", false));
            } else {
                q = query(colRef, where("category", "==", selectedBereich));
            }
            */


          /*
            //First Time to get Link to create composite index!
            let DocumentsArr = [];
            let docs = await getDocs(q);
            docs.forEach((doc) => {
                DocumentsArr.push({
                    id: doc.id,
                    data: doc.data(),
                });
            });
            setPartnerDocs(DocumentsArr);
          */            

            // Listen for changes to the query
            let DocumentsArr = [];
            unsubscribe = onSnapshot(q, (querySnapshot) => {

                DocumentsArr = [];
                querySnapshot.forEach((doc) => {
                    DocumentsArr.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });
                setPartnerDocs(DocumentsArr);
            });
        
            
            // Return a function to unsubscribe from the query
            // funktioniert nicht bei logout...
            // muss in Firebase/Auth Context
            return () =>  unsubscribe(); 
            
            
        }


        async function getCRMDocs (art) {

            //customerAction (true, false)
            //customerActionType(bestaetigung, ...)
            //customerActionNote (string)

            //bestaetigt (true, false)
            //gelesen (true, false)
            //gelesenAm(false, timestamp)

            // Steuerung CRM
            //Bei erfolgreicher Aktion: Angenommen+Abgelehnt zunächst:
            // => bestaetigt false => true setzen
            // wenn Angenommen, dann passt schon alles, wenn:
            //Abgelehnt => neues Feld abgelehnt => true

            //setPartnerDocs([]);

            const colRef = collection(db, "Partner", props.partner.slug, 'Dokumente');
            
            let q = null;

            if(art == "Offene Bestätigungen") {
                q = query(
                    colRef,
                    where("customerAction", "==", true),
                    where("bestaetigt", "==", false),
                    where("abgelehnt", "==", false),
                    where("googleUID", "==", currentUser.uid), //ACHTUNG!! Wichtig - sonst Einsicht auf andere Kundendaten.  Zusätzlich mit Firebase Regeln geblockt.
                    orderBy("created", "desc")
                );
            } else {
                q = query(
                    colRef,
                    where("customerAction", "==", true),
                    where("bestaetigt", "==", false),
                    where("abgelehnt", "==", true),
                    where("googleUID", "==", currentUser.uid), //ACHTUNG!! Wichtig - sonst Einsicht auf andere Kundendaten. Zusätzlich mit Firebase Regeln geblockt.
                    orderBy("created", "desc")
                );
            }
            
            /*
            if(selectedBereich == "Bestätigungen") {
                q = query(colRef, where("bestaetigung", "==", true), where("bestaetigt", "==", false));
            } else {
                q = query(colRef, where("category", "==", selectedBereich));
            }
            */

            
            /*
            //First Time to get Link to create composite index!
            let DocumentsArr = [];
            let docs = await getDocs(q);
            docs.forEach((doc) => {
                DocumentsArr.push({
                    id: doc.id,
                    data: doc.data(),
                });
            });
            setPartnerDocs(DocumentsArr);
            */

            
            let DocumentsArr = [];
            // Listen for changes to the query
            unsubscribe = onSnapshot(q, (querySnapshot) => {

                DocumentsArr = [];
                querySnapshot.forEach((doc) => {
                    DocumentsArr.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });
                setPartnerDocs(DocumentsArr);
            });
            
        
            // Return a function to unsubscribe from the query
            // funktioniert nicht bei logout...
            // muss in Firebase/Auth Context
            return () =>  unsubscribe();  
        }



        if(currentUser && currentUser.uid && selectedBereich !== "Abgelehnt" && selectedBereich !== "Offene Bestätigungen") {
            getPartnerDocs();
        }
        else if (currentUser?.uid && selectedBereich == "Offene Bestätigungen") {
            getCRMDocs("Offene Bestätigungen");
        }
        else if (currentUser?.uid && selectedBereich == "Abgelehnt") {
            getCRMDocs("Abgelehnt");
        }

      }, [currentUser, selectedBereich]);
      




    return (
        <>
            <Container style={{maxWidth: "800px"}}>

                <Row style={{justifyContent: "center"}}>

                    <Col xs={12} className="mt-5 text-center">
                        <p>Manche Aufträge benötigen eine aktive Kundenbestätigung. Diese findest Du in den Offenen Bestätigungen. Falls etwas zu erledigen ist, wirst Du über Deinen <Link to="/news/dokumente-feed">Newsfeed</Link> darüber benachrichtigt.</p>
                    </Col>

                    <Col xs={12} className="mt-2 text-center">
                        <p>Klicke auf eine der Kacheln, um die Dokumente anzuzeigen.</p>
                    </Col>
                    
                    {selectedBereich == "Offene Bestätigungen" &&
                        <Col key={"offen"} xs={12} md={4} style={{marginTop: "10px"}} onClick={() => setSelectedBereich("Offene Bestätigungen")}>
                            <CardSub title={"Offene Bestätigungen"} selected={1} color={"#520da7"} />
                        </Col>
                    }

                    {selectedBereich != "Offene Bestätigungen" &&
                        <Col key={"offen"} xs={12} md={4} style={{marginTop: "10px"}} onClick={() => setSelectedBereich("Offene Bestätigungen")}>
                            <CardSub title={"Offene Bestätigungen"} color={"#520da7"} />
                        </Col>
                    }


                    {selectedBereich == "Abgelehnt" &&
                        <Col key={"abgelehnt"} xs={12} md={4} style={{marginTop: "10px"}} onClick={() => setSelectedBereich("Abgelehnt")}>
                            <CardSub title={"Abgelehnt"} selected={1} color={"#520da7"} />
                        </Col>
                    }

                    {selectedBereich != "Abgelehnt" &&
                        <Col key={"abgelehnt"} xs={12} md={4} style={{marginTop: "10px"}} onClick={() => setSelectedBereich("Abgelehnt")}>
                            <CardSub title={"Abgelehnt"} color={"#520da7"} />
                        </Col>
                    }

                </Row>


                <Row style={{justifyContent: "center"}} className="mt-4">

                    {(!props.partner.kategorien || props.partner.kategorien?.length == 0) && 
                        <h6 className="mt-3" style={{fontStyle: "italic"}}>
                            Keine Kategorien hinterlegt.
                        </h6>
                    }
                    {(props.partner.kategorien?.length > 1) && props.partner.kategorien.map(item => 

                        item === selectedBereich ? ( 
                            <Col key={item} xs={6} md={3} style={{marginTop: "20px"}} onClick={() => setSelectedBereich(item)}>
                                <CardSub title={item} selected={1} color={"#520da7"} />
                            </Col>
                        ) : (
                            <Col key={item} xs={6} md={3} style={{marginTop: "20px"}} onClick={() => setSelectedBereich(item)}>
                                <CardSub title={item} color={"#520da7"} />
                            </Col>
                        )
                    )}
                </Row>


                <Row style={{justifyContent: "center", marginBottom: "80px", marginTop: "40px"}} >
                
                    {/* Dokumenten Liste */}
                    {/*!selectedBereich &&
                        <Col xs={12} md={12}>
                            <p className="text-center">Klicke auf eine Kachel, um Dokumente anzuzeigen.</p>
                        </Col>
                    */}
                    
                    {selectedBereich && selectedBereich !== "Offene Bestätigungen" &&
                        <Col xs={12} md={12}>
                            <h4 className="text-center" style={{marginBottom: "20px"}}>Meine Dokumente</h4>

                            {partnerDocs.length === 0 && <p style={{marginTop: "10px"}} className="text-center">Noch keine Dokumente in {selectedBereich} vorhanden.</p>}
                            
                            {partnerDocs.length >= 0 && partnerDocs.map(doc =>  
                                <CardDokumentPartner key={doc.id} data={doc} partner={props.partner} setModalTitle={setModalTitle} setModalImgSrc={setModalImgSrc} setShowModal={setShowModal} />
                            )}
                        </Col>
                    }

                    {selectedBereich && selectedBereich == "Offene Bestätigungen" &&
                        <Col xs={12} md={12}>
                            <h4 className="text-center" style={{marginBottom: "20px"}}>Meine Dokumente</h4>

                            {partnerDocs.length === 0 && <p style={{marginTop: "10px"}} className="text-center">Noch keine Dokumente in {selectedBereich} vorhanden.</p>}
                            
                            {partnerDocs.length >= 0 && partnerDocs.map(doc => 
                                <CardDokumentPartnerConfirm key={doc.id} data={doc} partner={props.partner} partnerSlug={props.partner.slug} setModalTitle={setModalTitle} setModalImgSrc={setModalImgSrc} setShowModal={setShowModal} />   
                            )}
                        </Col>
                    }
                </Row>
        
                {showModal && <IMGViewModal title={modalTitle} imgSrc={modalImgSrc} visible={setShowModal} />}     


            </Container>    
        </>
    );
}

export default PartnerDokumente