import React, { forwardRef, useImperativeHandle, useState, useRef, useContext } from 'react';

import DownloadModalMaklervollmacht from './DownloadModalMaklervollmacht';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import SignatureCanvas from 'react-signature-canvas';
import { UserContext } from '../../store/user-context';
//'react-signature-pad-wrapper'




const MaklermandatUnterschrift = forwardRef((props, ref) => {

  const { user } = useContext(UserContext);

  const [showModal, setShowModal] = useState(false);

  const sigCanvasVollmacht = useRef();
  const sigCanvasVertrag = useRef();

  useImperativeHandle(ref, () => ({

    getSigVertragData: () => {
      if (!sigCanvasVertrag?.current.isEmpty()) {
        return sigCanvasVertrag.current.getCanvas().toDataURL();
      }
      return false;
    },

    getSigVollmachtData: () => {
      if ( !sigCanvasVollmacht?.current.isEmpty()) {
        return sigCanvasVollmacht.current.getCanvas().toDataURL();
      }
      return false;
    },
    
  }));


  const handleResetSignature = (name) => {

    if(name == "vertrag") {
      sigCanvasVertrag.current.clear();
    } 
    if(name == "vollmacht"){
      sigCanvasVollmacht.current.clear();
    }
  
  }

  return (
    <>
      {!user.maklervollmachtSignedCb &&
        <Container style={{ maxWidth: "800px", textAlign: "center"  }}>
          <Row>

          <h3 style={{ marginTop: "60px" }}>Maklermandat</h3>

          <p className="mt-5">
            Wir benötigen Dein Einverständnis, damit wir Deine Versicherungsdaten bei
            deinen Versicherern anfragen und digitalisieren können.
            Gib uns mit Deiner Unterschrift in den nachfolgenden Feldern Dein Einverständnis dafür.
          </p>

          <p className="mt-5">
            Blanko Maklervertrag/-vollmacht & Erstinformationen:
          </p> 
          <p>
            <span style={{color: "#0d6efd", textDecoration: "underline"}} onClick={() => setShowModal(true)}>Maklervertrag/-vollmacht & Erstinformationen</span>&nbsp;
          </p>


          {/* Signature Pad Maklervertrag */}

              <div className="mt-5">
                <SignatureCanvas
                  canvasProps={{ className: "sigCanvas" }}
                  penColor="black"
                  ref={sigCanvasVertrag}
                />
              </div>

            <Row>
              <Col>
                <p>Unterschrift Maklervertrag</p>
              </Col>
              <Col className="d-flex justify-content-end">
                <Button variant="secondary" onClick={() => handleResetSignature("vertrag")}>
                  Reset Unterschrift
                </Button>
              </Col>
            </Row>



          {/* Signature Pad Maklervollmacht */}

              <div className="mt-5">
                <SignatureCanvas
                  canvasProps={{ className: "sigCanvas" }}
                  penColor="black"
                  ref={sigCanvasVollmacht}
                />
              </div>
              
              <Row>
                <Col>
                <p>Unterschrift Maklervollmacht</p>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Button variant="secondary" onClick={() => handleResetSignature("vollmacht")}>
                    Reset Unterschrift
                  </Button>
                </Col>
              </Row>


              <DownloadModalMaklervollmacht
                showModal={showModal}
                setShowModal={setShowModal}
              />
          </Row>
        </Container>
      }

    </>
  )
});

export default MaklermandatUnterschrift