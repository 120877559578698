import React from 'react';
import ReactDOM from 'react-dom';

import Modal from "react-bootstrap/Modal";


const GewinnModal = ({title, children, visible, setVisible}) => {


  return ReactDOM.createPortal((
      <Modal show={visible} onHide={setVisible}/*dialogClassName="pipelineModal"*/ size="lg" style={{marginTop:"150px"}} className="customModal">
        <Modal.Header closeButton>
          <Modal.Title><span style={{fontSize: "80%"}}>{title}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "75vh", overflowY: "auto"}}>
          {children}
        </Modal.Body>
      </Modal>
  ), document.body)
}

export default GewinnModal