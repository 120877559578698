import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../store/auth-context";

import { Row, Col } from 'react-bootstrap'

import axios from 'axios';
import Swal from "sweetalert2";

import GewinnCard from "./GewinnCard";


function GewinnspielGewinne() {

  const { currentUser } = useContext(AuthContext);

  const [gewinne, setGewinne] = useState([]);

  useEffect(() => {

    async function getGewinne() {
      axios({
        method: "get",
        headers: {
          Authorization: "Bearer " + currentUser.accessToken,
        },
        url: process.env.REACT_APP_BACKEND + "/my-vin1/gewinne",
      })
        .then((resData) => {
          setGewinne(resData.data);
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Fehler beim Abrufen der Gewinne.",
          });
        });
      
    };
 
    

    if (currentUser && currentUser.accessToken) {
      getGewinne();
    }
  }, [currentUser]);




  const handleGewinnErhalten = (gewinnId) => {

    if(!currentUser.accessToken) {
        Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Du bist nicht Authentifiziert."
        })
        return;
    }

    axios({
      method: "post",
      headers: {
        Authorization: "Bearer " + currentUser.accessToken,
      },
      url: process.env.REACT_APP_BACKEND + "/my-vin1/gewinn-bestaetigen",
      data: {
        gewinnspielId: gewinnId,
        gewinnErhalten: true 
      }
    })
      .then(res => {
  
        // Update Gewinne damit Button versteckt werden kann.
        const updatedGewinnspiele = gewinne.map(g => {
          if (g.id === gewinnId) {
            return { ...gewinne,
              gewinnErhalten: true,
              gewinnErhaltenAm: new Date()
            };
          } else {
            return g;
          }
        });

      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Fehler beim Bestätigen des Gewinns.",
        });
      });
  };



  return (
    <>
        {gewinne.length >= 1 &&
            <Row className="justify-content-center">
                <h1 style={{fontSize: "90%"}}>
                    Meine Gewinne
                </h1>

        
                <Col sm={12} >
                    <ul
                        style={{paddingLeft: "0px"}}
                    >
                        {gewinne.map(gewinn => 
                            <li
                                key={gewinn.id}
                                style={{listStyle: "none"}}
                            >
                                <GewinnCard data={gewinn} handleGewinnErhalten={handleGewinnErhalten} />
                            </li>
                        )}
                    </ul>
                </Col>
            </Row>
        }
    </>
  );
}

export default GewinnspielGewinne;
