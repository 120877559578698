// Your web app's Firebase configuration - VIN1 App Live Zugangsdaten!!
import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

//Added because of ContextAPI
//import { createContext, useContext, useState, useEffect } from "react";
//import { onAuthStateChanged } from "firebase/auth";

// Init Cloud Messaging (Push Notifications)
import { getMessaging, getToken } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBrsX-NDH_biS8tMHyrf2D2qSNjJEONzEg",
  authDomain: "vin1-app.firebaseapp.com",
  projectId: "vin1-app",
  storageBucket: "vin1-app.appspot.com",
  messagingSenderId: "434911354294",
  appId: "1:434911354294:web:a9ecb926cb97986b51b517",
};

// Initialisierung Firebase V9
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

// Setzt Daten in SessionStorage und verhintert erneutes "Laden des Tokens", und somit missbrauch.
// Außerdem ist hierüber auch ohne den Token gesichert, dass bei Refresh der Login erhalten bleibt.
// Login wird verworfen bei: Logout oder Tab schließen
await setPersistence(auth, browserSessionPersistence);

//export const auth = initializeAuth(app, { persistence: getReactNativePersistence(AsyncStorage) });
export const db = getFirestore(app);
export const storage = getStorage(app);

// Init Cloud Messaging (Push Notifications)
//https://medium.com/@sumanthegde123/web-push-notifications-with-react-and-firebase-with-safari-error-handling-d2979d10c9ac
// Welche Browser unterstützt: https://firebase.google.com/docs/web/environments-js-sdk
/*
const messaging = getMessaging(app);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: process.env.REACT_APP_VAPID_KEY})
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        //setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        //setTokenFound(false);
        // shows on the UI that permission is required 
      }
    })
    .catch((err) => {
      console.log('Push API Token error:');
      console.log(err);
      // catch error while creating client token
    });
}
*/
