import React, { useEffect, useState } from 'react';
import style from "./WelcomeSpinner.module.css";

const messages = ["Willkommen bei VIN1.", "Dein Konto wird gerade eingerichtet."];

function WelcomeSpinner(props) {

  const [currentMessage, setCurrentMessage] = useState(null);
  const [opacity, setOpacity] = useState(0);
  const [transitionIn, setTransitionIn] = useState('1.5s');
  const [transitionOut, setTransitionOut] = useState('1s');

  useEffect(() => {
    setTimeout(() => setCurrentMessage(messages[0]), 0);  // Display first message
    setTimeout(() => setOpacity(1), 0);  // Fade in
    setTimeout(() => setOpacity(0), 1000);  // Fade out after 1.5s fade in + 1s stay
    setTimeout(() => setCurrentMessage(null), 2000);  // Remove first message after 1.5s fade in + 1s stay + 1s fade out
    
    setTransitionIn('1.5s');
    setTransitionOut('1s');

    // Start cycle: Cross fade, stay
    const cycleTimer = setInterval(() => {

      setTimeout(() => {
        // Switch message
        setCurrentMessage(prevMessage => prevMessage === messages[1] ? messages[0] : messages[1]);
      }, 0);  // Display new message
      setTimeout(() => setOpacity(1), 0);  // Cross fade in
      setTimeout(() => setOpacity(0), 1500);  // Cross fade out after 1.5s fade in + 0.5s stay
    }, 2500);  // Length of each cycle: 1.5s fade in + 0.5s stay + 1.5s fade out + 0.5s stay

    return () => clearInterval(cycleTimer);
  }, []);

  return (
    <div className={style.overlay}>
      <h1 style={{ opacity: opacity, transition: `opacity ${opacity === 1 ? transitionIn : transitionOut}` }}>
        {currentMessage}
      </h1>
    </div>
  );
}

export default WelcomeSpinner;
