import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignJustify, faChevronDown, faChevronUp, faCircleCheck } from "@fortawesome/free-solid-svg-icons"

import { Row, Col, Button }  from 'react-bootstrap';

import moment from 'moment';

import style from "./GewinnspielCard.module.css"

function GewinnspielCardPreview(props) {

    const [weiterlesen, setWeiterlesen] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [modalUrl, setModalUrl] = useState(false);

    const handlePDF = (url) => {
        window.open(url, 'noopener nofollow');
    }

    const handleExternalURL = (url) => {

        setShowModal(false);
        window.scrollTo({
            top: 0,
            behavior: "instant"
          });
        setModalUrl(url);
        setShowModal(true);
      }
    

  function downloadPDF(base64pdf) {
    const linkSource = base64pdf;
    const downloadLink = document.createElement("a");
    const fileName = "VIN1-Gewinnspiel.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (

    <Row className={style.cardContainerNews} style={{marginTop: "20px"}}>
        <Col xs={12} md={2} className={style.newsImageContainer}>
            <img className={style.newsImage} src={props.data.image} alt="" />
        </Col>
        <Col xs={12} md={10} style={{marginTop: "10px", paddingLeft: "16px", paddingRight: "16px"}}>
       
       
            <div className={style.textContainer}>

                {props.data.startdatum && props.data.startdatum != "1970-01-01 00:00:00" &&
                    <span style={{color: "#808080", marginBottom: "8px"}}>
                        Aktionszeitraum: &nbsp;
                        {moment(new Date(props.data.startdatum)).format('DD.MM.YY, HH:mm')}
                        &nbsp;-&nbsp;
                        {moment(new Date(props.data.enddatum)).format('DD.MM.YY, HH:mm')}
                    </span>
                }

                                
                {props.data.startdatum == "1970-01-01 00:00:00" &&
                    <span style={{color: "#808080", marginBottom: "8px"}}>
                        Aktionszeitraum: &nbsp;
                        {moment(new Date(props.data.publishDate)).format('DD.MM.YY, HH:mm')}
                        &nbsp;-&nbsp;
                        {moment(new Date(props.data.enddatum)).format('DD.MM.YY, HH:mm')}
                    </span>
                }

                <h5>Vorschau - <b>{props.data.title}</b></h5>


                {props.data.text.length <= 400 && !weiterlesen.includes(props.id) && 
                    <p dangerouslySetInnerHTML={{__html: props.data.text}}></p>
                }

                {props.data.text.length > 400 && !weiterlesen.includes(props.id) &&
                    <Fragment>
                        <p dangerouslySetInnerHTML={{__html: props.data.text.substring(0,400)+" ..."}}></p>
                        <div className={style.textBtn} onClick={() => setWeiterlesen([...weiterlesen, props.id])}>
                            <FontAwesomeIcon className={style.icon} icon={faChevronDown} size="lg" /><span style={{paddingLeft: "10px"}}>Weiterlesen</span>
                        </div>
                    </Fragment>
                }

                {props.data.text.length > 400 && weiterlesen.includes(props.id) && 
                    <Fragment>
                        <p dangerouslySetInnerHTML={{__html: props.data.text}}></p>
                        <div className={style.textBtn} onClick={() => setWeiterlesen([...weiterlesen.filter((item) => item !== props.id)])}>
                            <FontAwesomeIcon className={style.icon} icon={faChevronUp} size="lg" /><span style={{paddingLeft: "10px"}}>Einklappen</span>
                        </div>
                    </Fragment>
                }
                
            </div>
        </Col>
        <Col xs={12} md={12} className={props.data.teilgenommen ? style.btnContainerCenter : style.btnContainer}>
            {props.data.pdf && <Button className="fw-bold" style={{background: "rgb(83, 178, 83)", border: "none", marginRight: "10px"}} onClick={() => downloadPDF(props.data.pdf)}>Info PDF</Button>}
            {props.data.url && <Button className="fw-bold" style={{background: "", border: "none", marginRight: "10px"}} onClick={() => handlePDF(props.data.url) }>Teilnahmebedingungen</Button>}              
        </Col>

        {showModal && (
          <WebViewModal
            url={modalUrl}
            hideModal={setShowModal}
          />
        )}
        
    </Row>
  )
}

export default GewinnspielCardPreview