import React from 'react'

import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut, faCircleInfo, faBookOpen, faUser, faSearch  } from "@fortawesome/free-solid-svg-icons"

import style from "./Header.module.css"
import { useNavigate } from "react-router-dom";

//import  secureLocalStorage  from  "react-secure-storage";

function HeaderMobileHome(props) {

    let navigate = useNavigate();

    const handleNavigateBack = () => {
        navigate(-1);
    }

   const handleBackWithSelection = (url, url_state) => {
        navigate(url, { state: url_state })
   }

    const handleNavigateBackDokumente = (url) => {
        navigate(url);
    }




    const handleNavigateHome = () => {
        navigate("/");
    }

    const handleLogout = () => {

        //unsubscribe && unsubscribe();
    
        signOut(auth)
        .then(() => {
            sessionStorage.clear();
            navigate("/login");
        })
        .catch((error) => alert(error.message));  
    

    }


  return (
    <header className={style.headerContainer}>
        <div className={style.headerInner}>
            
            <div className="d-flex justify-content-left">
                <FontAwesomeIcon size={"lg"} className={style.icon} icon={faUser} onClick={() => navigate("/meine-daten")}  />
                <FontAwesomeIcon size={"lg"} style={{width: "80px", paddingLeft: "16px"}} className={style.icon} icon={faBookOpen} onClick={() => navigate("/informationen")}  />
            </div>

            <div>
                <img src={require("../../img/VIN1_Logo_Lila.png")} alt="" width="100px" /*100px*/ className={style.icon} />
            </div>

            <div className="d-flex justify-content-right">          
                <FontAwesomeIcon size={"lg"} style={{width: "80px"}} className={style.icon} icon={faSignOut} onClick={handleLogout}  />
            </div>

        </div>
    </header>
  )
}

export default HeaderMobileHome




