import React, { useState, useEffect, useContext } from 'react'
import Header from '../Header/Header'

import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';

import axios from "axios"
import { AuthContext } from '../../store/auth-context';
import CustomModal from '../Bausteine/CustomModal';
import Swal from 'sweetalert2';

//import NewsletterCard from './NewsletterCard';

function Neuigkeiten() {

  let navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);

  const { id } = useParams();


  const [newsletter, setNewsletter] = useState({});
  const [showBeratungsModal, setShowBeratungsModal] = useState(false);

  /*
  useEffect(() => {

    if(showBeratungsModal) {

      Swal.fire({
        icon: "info",
        title: "Angebot",
        html:
            "<b>Willst du das Angebot in Anspruch nehmen?</b><br />" +
            "Du wirst unabhängig von deiner Auswahl zur richtigen Stelle geleitet.<br />" +
            "Gelesene Dokumente findest du, indem du auf Archiv klickst.",
        confirmButtonColor: "--primary-color",
        confirmButtonText: "Annehmen",
        showDenyButton: true,
        denyButtonColor: "#888",
        denyButtonText: "Nein, schließen.",
        showCloseButton: true,
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          alert("bestätigt")
            //"gelesen" => true, damit Doc in Archiv sichtbar.
            //props.handleUpdateGelesen(doc.id);
        }
        
        if (!result.isDismissed) {

        }

        setShowBeratungsModal(false);
      });

    }
    
  },[showBeratungsModal])
  */

  useEffect(() => {
    if(currentUser?.uid) {
      loadNewsletter();
    }
  }, [currentUser])

  async function loadNewsletter() {

    let response = [];
    let newsObj = {};
    
    // VIN1 Newsletter Alt
    if(!isNaN(id)) {
      response = await axios.get("https://api.vin1.eu/newsletterById.php?AUTH=bv8FnNYM6JU6Uk7QE8ujvM6S6WMVpg&ID="+id);
      newsObj = {
        id: response.data.ID,
        title: response.data.TITEL,
        datum: response.data.DATUM,
        enddatum: response.data.ENDDATUM,
        image: response.data.IMG_B64 ? response.data.IMG_B64 : null,
        newstext: response.data.NACHRICHT,
        url: response.data.URL,
        pdf: response.data.PDF_B64,
      };
    }
    // VIN1 Newsletter NEU + Partner_Newsletter
    else
    {

      // MongoDB Newsletter
      response = await axios({
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + currentUser.accessToken
        },
        url: process.env.REACT_APP_BACKEND+"/my-vin1/newsletter/"+id
      });
      
      newsObj = {
        id: response.data._id,
        title: response.data.title,
        datum: response.data.publishDate,
        image: response.data.imageUrl ? response.data.imageUrl : null,
        newstext: response.data.message,
        url: response.data.externalUrl,
        pdf: response.data.pdfFileUrl,
      };
    }
    setNewsletter(newsObj);
  }




  const handleExternalURL = (url) => {

    // iFrames machen haftbar in eigener Seite, daher als neuer Tab.
    window.open(url, '_blank', 'noopener,noreferrer');
    /*
    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
    */
  } 

  function downloadPDF(base64pdf) {
    const linkSource = base64pdf;
    const downloadLink = document.createElement("a");
    const fileName = "VIN1-Newsletter.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  } 
  



  return (
    <>
      <Header />
      <Container style={{maxWidth: "800px"}}>

        <Row style={{justifyContent: "center"}}>

          <Col
            xs={12}
            className="text-center"
          >
            <img
              src={newsletter.image}
              alt="image-newsletter"
              style={{ height: "160px", maxWidth: "800px", marginTop: "40px"}}
            />
            {/*
            MySQL VIN Newsletter momentan nur Date, daher Fehler!
            <p className="mt-3 mb-0">{new Intl.DateTimeFormat('de-DE', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              }).format(new Date(newsletter.datum))}
            </p>*/}

            {!newsletter.enddatum ?

              <p className="mt-3 mb-0">
                {newsletter.datum && !isNaN(new Date(newsletter.datum).getTime()) ?
                  <span>
                    {new Intl.DateTimeFormat('de-DE', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                      }).format(new Date(newsletter.datum))}
                  </span>
                  :
                  <span>
                    {newsletter.datum}
                  </span>
                }
              </p>
              :
              <div className="mt-3" />
            }


            <h1 className="mt-0">{newsletter.title}</h1>
          </Col>

          <Col
            xs={12}
            className="text-center mt-3"
          >
            {newsletter.pdf &&
              <Button
                className="fw-bold"
                style={{background: "rgb(83, 178, 83)", border: "none", marginRight: "10px"}}
                onClick={() => downloadPDF(newsletter.pdf)}
              >
                PDF Download
              </Button>
            }

            {newsletter.url &&
              <Button
                className="fw-bold"
                style={{background: "#520da7"}}
                onClick={()=>handleExternalURL(newsletter.url)}
              >
                Mehr Infos
              </Button>
            }         
          </Col>


          {/*
            <Col xs={12} md={12} className="text-center mt-3">
              <Button className="fw-bold" style={{background: "#520da7"}} onClick={()=>setShowBeratungsModal(true)}>Beraten lassen</Button>
            </Col>
          */}

          <Col
            xs={12}
            className="mt-5"
          >
            <p>{newsletter.newstext}</p>
          </Col>

        </Row>


       
        {/*showBeratungsModal && 
              <CustomModal 
                  title={"Newsletter"}
                  visible={showBeratungsModal}
                  setVisible={setShowBeratungsModal}
                  handleSave={handleSaveModal}
              > 
                <p>Jetzt Partner bewerten. Du kannst halbe und ganze Sterne vergeben.</p>
    
              </CustomModal>
        */}



        {/*newsletter.map((news) =>   
          <NewsletterCard key={news.id} data={news} />
        )*/}

      <Row style={{marginBottom:"80px"}}></Row>
      
      </Container>
    </>
  )
}

export default Neuigkeiten