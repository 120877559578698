import React from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faDownload, faTrash, faEye, faPenNib, faClipboardCheck , faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import style from "./CardSmall.module.css"
import classLister from 'css-module-class-lister';

import { Row, Col } from "react-bootstrap";

//import DownloadLink from 'react-download-link'
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { auth, db, storage  } from "../../firebase";
//import { doc } from "firebase/firestore"; 

//import axios from 'axios';
//import Swal from 'sweetalert2'
//import Dokumente from '../DokumenteWeiterleitung';

function CardDokumentPartner(props) {


  const { slug: partner_slug } = props.partner;

  //const classes = classLister(style);

  //const [url, setUrl] = useState("");

/*
  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;   
        resolve(base64data);
      }
    });
  }

  function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
*/

/*
  function downloadFile(fileURL, fileName) {
        var save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
        save.download = fileName || filename;
           if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                document.location = save.href; 
    // window event not working here
            }else{
                var evt = new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }   
    }
    */


  async function previewPDFFile(url, filename) {


    //UUID+filename = filename!
    const storageRef = ref(storage, "/"+auth.currentUser.uid+"/"+filename);

    getDownloadURL(ref(storage, url))
      .then((url) => {

        //console.log("get Download");

        // `url` is the download URL for 'images/stars.jpg'
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;

          //url = window.URL.createObjectURL(blob);

          var file = new Blob([blob], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(file);
          

          // set object tag fullscreen...
          //setUrl(fileURL);

          //alt
          //window.open(fileURL);

          // Hiermit geht es auch in iOS
          const a = document.createElement("a");
          a.setAttribute("download", fileURL);
          a.setAttribute("href", fileURL);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

        };
        
        xhr.open('GET', url);
        xhr.send();
          
      })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  }


  async function previewIMGFile(url, filename, title) {

    //UUID+filename = filename!
    const storageRef = ref(storage, "/"+auth.currentUser.uid+"/"+filename);

    getDownloadURL(ref(storage, url))
      .then((url) => {

        //const img = document.getElementById('myimg');
        //img.setAttribute('src', url);
        props.setModalTitle(title);
        props.setModalImgSrc(url);
        props.setShowModal(true);
        
      })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  }




  async function downloadFile(url, filename) {

    //UUID+filename = filename!
    const storageRef = ref(storage, "/Partner/"+auth.currentUser.uid+"/"+partner_slug+"/"+filename);

    getDownloadURL(ref(storage, url))
      .then((url) => {

        // `url` is the download URL for 'images/stars.jpg'
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;

          url = window.URL.createObjectURL(blob);

          var a = document.createElement("a");
          a.href = url;
          a.download = filename.substr(36);
          a.click();
          window.URL.revokeObjectURL(url);
        };
        
        xhr.open('GET', url);
        xhr.send();
        
        // Or inserted into an <img> element
        //const img = document.getElementById('myimg');
        //img.setAttribute('src', url);
      })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  }



  return (
   
      <div className={style.cardContainerUpload} style={{marginBottom: "20px", background: "#ffffff" }}>
        <Row style={{width:"100%", padding: "0px 0px 0px 8px", fontSize: "90%"}}>
            <Col style={{display: "flex", flex: "11", flexDirection: "column", alignItems: "flex-start", padding: "10px 0px 0px 15px"}}>
                <p className="fw-bold" style={{marginBottom: "5px", textAlign: "left", color: "#000000" }}>{props.data.data.title.substr(36)}</p>
                <p style={{padding: "0", marginBottom: "5px", textAlign: "left"}}>{new Intl.DateTimeFormat('de-DE', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                  }).format(new Date(props.data.data.created.seconds *1000))}
                </p>
            </Col>

            <Col style={{display: "flex", flex: "1", justifyContent: "flex-end"}}>

                {props.data.data.mimeType === "application/pdf" && 
                  <FontAwesomeIcon style={{width:"auto", cursor: "pointer", marginTop: "5px", marginRight: "30px", color: "#707070"}} icon={faEye} size="lg" onClick={() => previewPDFFile(props.data.data.url, props.data.data.title, props.data.data.title.substr(36))} /> 
                }
                {(props.data.data.mimeType === "image/jpeg" || props.data.data.mimeType === "image/png")  && 
                  <FontAwesomeIcon style={{width:"auto", cursor: "pointer", marginTop: "5px", marginRight: "30px", color: "#707070"}} icon={faEye} size="lg" onClick={() => previewIMGFile(props.data.data.url, props.data.data.title, props.data.data.title.substr(36))} /> 
                } 

                <FontAwesomeIcon style={{width:"auto", cursor: "pointer", marginTop: "5px", color: "#707070"}} icon={faDownload} size="lg" onClick={() => downloadFile(props.data.data.url, props.data.data.title)} />    
                
                <p className="" style={{}}>{props.data.data.name}</p>
            </Col>
        </Row>

      </div>
  )
}

export default CardDokumentPartner
