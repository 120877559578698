import React from 'react';

import './App.css';

//Tailwind
import "./tailwind.css";



//import { useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

// Secure Storage for Saving Auth
//import  secureLocalStorage  from  "react-secure-storage";

// --------------------------------------------------------------------------
// -------------------- Protected Routes with Firebase ----------------------
import { useContext, useMemo, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
//import { useLocalStorage } from "./useLocalStorage";


// --------------------------------------------------------------------------
// -------------------------------- PAGES -----------------------------------

import Login from './components/Login/Login';
import Register from './components/Login/Register';
import PasswortReset from './components/Login/PasswortReset';
import PasswortSetPreregister from './components/Login/PasswortSetPreregister';

import Home from "./components/Home/Home"

import Neuigkeiten from "./components/Neuigkeiten/Neuigkeiten"

import Versicherungen from "./components/Informationen/Versicherungen/Versicherungen"
import Kredite from "./components/Informationen/Kredite/Kredite"
import FinanzenInvest from "./components/Informationen/FinanzenInvest/FinanzenInvest"
import Energie from "./components/Informationen/Energie/Energie"
import Immobilien from "./components/Informationen/Immobilien/Immobilien"


//import Connect from "./components/Connect/Connect"

import Partnerprogramm from "./components/Informationen/Partnerprogramm/Partnerprogramm"

import UnreadDocs from "./components/News/UnreadDocs"
import News from "./components/News/News"
import MeineDaten from "./components/MeineDaten/MeineDaten"

import Cashback from "./components/Cashback/Cashback"
import NeuigkeitenVP from './components/NeuigkeitenVP/NeuigkeitenVP';

//import Schaden from "./components/Schaden/Schaden"

//import Berater from "./components/Berater/Berater"
import Beratung from "./components/Beratung/Beratung"
//import Tuning from "./components/Tuning/Tuning"

import Gewinnspiel from "./components/Gewinnspiel/Gewinnspiel"

import Informationen from "./components/Informationen/Informationen"

import PartnerDokumente from "./components/Partner/PartnerDokumente"
import DokuPrivat from "./components/Dokumente/Privat/Privat"

import DokuVersicherung from "./components/Dokumente/Versicherung/Versicherung"
import DokuKredite from "./components/Dokumente/Kredite/Kredite"
import DokuInvest from "./components/Dokumente/Invest/Invest"
import DokuEnergie from "./components/Dokumente/Energie/Energie"
import DokuImmobilien from "./components/Dokumente/Immobilien/Immobilien"
import DokuSteuertaxi from "./components/Dokumente/Steuertaxi/Steuertaxi"

import Impressum from "./components/Impressum/Impressum"
import Datenschutz from "./components/Datenschutz/Datenschutz"
import Nutzungsbedingungen from "./components/Nutzungsbedingungen/Nutzungsbedingungen"
import Erstinformation from "./components/Erstinformation/Erstinformation"

import Vorteilspartner from "./components/Vorteilspartner/Vorteilspartner"
import VorteilspartnerLandingpage from "./components/VorteilspartnerLandingpage/VorteilspartnerLandingpage"

import VIN1_Partnerseite from './components/VIN1_Partnerseite/VIN1_Partnerseite';
import Partnerseite from './components/Partner/Partnerseite';

//import Gewerbepartner from './components/Gewerbepartner/Gewerbepartner';

import Verbesserungen from './components/Verbesserungen/Verbesserungen';

import InformationenDokumente from "./components/InformationenDokumente/InformationenDokumente"


// Rechner
import RechnerBaufinanzierung from './components/RechnerBaufinanzierung/RechnerBaufinanzierung';
import RechnerBaufinanzierungRechner from './components/RechnerBaufinanzierung/BaufinanzierungFrame';
import RechnerBaufinanzierungZinsChart from './components/RechnerBaufinanzierung/ZinschartFrame';

//Momentan iframe nicht einbindbar!
//import Autoversicherung from './components/Autoversicherung/Autoversicherung';

// Lead-Formulare
import Mietkaution from './components/Lead-Formulare/Mietkaution';
import VorteilspartnerOnboarding from './components/Lead-Formulare/VorteilspartnerOnboarding';


//Rechner
//import EnergieRechner from './components/EnergieRechner/EnergieRechner';

//import { getAuth, onAuthStateChanged } from "firebase/auth";

import 'bootstrap/dist/css/bootstrap.min.css';


//import { AuthProvider } from './Auth'
import { AuthContext, AuthProvider } from './store/auth-context';
import { UserContext, UserProvider } from './store/user-context';
//import GewerbepartnerDaten from './components/Gewerbepartner/GewerbepartnerDaten';


import DokumenteWeiterleitung from "./components/DokumenteWeiterleitung"
import ConnectWeiterleitung from './components/ConnectWeiterleitung';
import DateienSendenWeiterleitung from './components/DateienSendenWeiterleitung';
//import SchadenWeiterleitung from './components/SchadenWeiterleitung'
//import NewsletterCard from './components/Neuigkeiten/NewsletterCard';
import TarifTaxi from './components/TarifTaxi/TarifTaxi';

import UnternehmensConnect from './components/UnternehmensConnect/UnternehmensConnect';



import MobileAppMenu from './components/Bausteine/MobileAppMenu/MobileAppMenu';
import Footer from './components/Footer/Footer';

// --------------------------------------------------------------------------
// --------------- Implement Protected Routes with Firebase -----------------
/*
const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);
    navigate("/");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate("/login", { replace: true });
  };
  
  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),x
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};


export const useAuth = () => {
  return useContext(AuthContext);
};
*/


// Notifications
import { getToken } from "firebase/messaging";
import { fetchToken, onMessageListener } from './firebase';


import moment from 'moment';
import 'moment/locale/de';
import VorteilspartnerOnboardingAktion from './components/Lead-Formulare/VorteilspartnerOnboardingAktion';


import { hotjar } from 'react-hotjar';



// alt mit useState in App selbst
//export const ProtectedRoute = ({ children, user }) => {

// Neu mit Storage
export const ProtectedRoute = ({ children }) => {

  const { contextValue } = useContext(AuthContext);

  if (!contextValue.isLoggedIn) {
     // user is not authenticated - redirect to Login
    return <Navigate to="/login" />;
  }
  return children;
};


export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
  }, [pathname])  
}







function App() {

  moment.locale('de');


  // ========== HOTJAR INTEGRATION only load/include, if cookie banner is accepted! ============
  
      const hjid = 3897601;
      const hjsv = 6;


        hotjar.initialize(hjid, hjsv);

        // Identify the user
        hotjar.identify('USER_ID', { userProperty: 'value' });

        // Add an event
        hotjar.event('button-click');

        // Update SPA state
        hotjar.stateChange('/my/page');

        // Check if Hotjar has been initialized before calling its methods
        if (hotjar.initialized()) {
          hotjar.identify('USER_ID', { userProperty: 'value' });
        }

    // ========== HOTJAR INTEGRATION only load/include, if cookie banner is accepted! ============






  const { user } = useContext(UserContext);

  // Vorbereitung Push Notifications
  /*
  const [isTokenFound, setTokenFound] = useState(false);
  
  function requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission()
      .then((permission) => {
        if (permission === 'granted') {
          fetchToken();
          setTokenFound(true);
          console.log('Notification permission granted.');
          return true;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  useEffect(() => {
    requestPermission(); 
  }, []);
 */

  /*
  onMessageListener()
  .then(payload => {
    //setShow(true);
    //setNotification({title: payload.notification.title, body: payload.notification.body})
    console.log(payload);
  })
  .catch(err => console.log('failed: ', err));
  

    {isTokenFound && <h1> Notification permission enabled 👍🏻 </h1>}
    {!isTokenFound && <h1> Need notification permission ❗️ </h1>}
  */

  return (

    <AuthProvider>
      <UserProvider>

        <Router basename="/">

          <MobileAppMenu />
          
          <ScrollToTop />

          <div className="page-container">
            <div className="content-wrap">

              <Routes>
                
                <Route path="/login" element={<Login />} />

                <Route path="/neues-passwort/:googleuid/:token" element={<PasswortReset />} />
                <Route path="/initiales-passwort/:googleuid/:token" element={<PasswortSetPreregister />} />

                <Route path="/register" element={<Register />} />
            

                {/* Alle Pfade nach Login */}
                <Route path="/" element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute> }
                />

                    <Route path="meine-daten" element={
                      <ProtectedRoute>
                        <MeineDaten />
                      </ProtectedRoute> }
                    />

                    <Route path="beratung" element={
                      <ProtectedRoute>
                        <Beratung />
                      </ProtectedRoute> }
                    />

                    <Route path="partner/vin1" element={
                      <ProtectedRoute>
                        <VIN1_Partnerseite />
                      </ProtectedRoute> }
                    />

                    <Route path="partner/:slug" element={
                      <ProtectedRoute>
                        <Partnerseite />
                      </ProtectedRoute> }
                    />

                    {/* Auf der Gewerbepartnerseite müssen die Partneraktionen DOkumente, Dateien senden etc. aufgeführt werden! */}
                    {/*
                    <Route path="partner/:partnerName" element={
                        <ProtectedRoute user={user}>
                          <Gewerbepartner />
                        </ProtectedRoute> }
                    />
                    */}

                    {/* Achtung - geändert von dokumente/:partnerName! */}
                    <Route path="partnerdokumente/:partnerName" element={
                      <ProtectedRoute>
                        <PartnerDokumente />
                      </ProtectedRoute> }
                    />
                

              


                    <Route path="informationen/energie" element={
                      <ProtectedRoute>
                        <Energie />
                      </ProtectedRoute> }
                    />

                    <Route path="informationen/versicherungen" element={
                      <ProtectedRoute>
                        <Versicherungen />
                      </ProtectedRoute> }
                    />

                    <Route path="informationen/immobilien" element={
                      <ProtectedRoute>
                        <Immobilien />
                      </ProtectedRoute> }
                    />


                    <Route path="informationen/finanzen" element={
                      <ProtectedRoute>
                        <Kredite />
                      </ProtectedRoute> }
                    />


                    <Route path="informationen/invest" element={
                      <ProtectedRoute>
                        <FinanzenInvest />
                      </ProtectedRoute> }
                    />

                    <Route path="informationen/partnerprogramm" element={
                      <ProtectedRoute>
                        <Partnerprogramm />
                      </ProtectedRoute> }
                    />


                
                    <Route path="vorteile" element={
                      <ProtectedRoute>
                        <Vorteilspartner />
                      </ProtectedRoute> }
                    />
                    

                    {/* Friday Autoversicherung */}
                    {/*<Route path="autoversicherung" element={
                      <ProtectedRoute>
                        <Autoversicherung />
                      </ProtectedRoute> }
                    />
                    */}
                    


                    {/* Baufinazierungsrechner Interhyp */}
                    <Route path="baufinanzierung" element={
                      <ProtectedRoute>
                        <RechnerBaufinanzierung />
                      </ProtectedRoute> }
                    />

                    <Route path="baufinanzierung/rechner" element={
                      <ProtectedRoute>
                        <RechnerBaufinanzierungRechner />
                      </ProtectedRoute> }
                    />

                    <Route path="baufinanzierung/zinsen" element={
                      <ProtectedRoute>
                        <RechnerBaufinanzierungZinsChart />
                      </ProtectedRoute> }
                    />




                    {/* ========= Zwischenpage: Newsfeed + Dokumente ========== */}
                    <Route path="news" element={
                        <ProtectedRoute>
                          <News />
                        </ProtectedRoute> }
                    />
                    
                    
                    {/* ========= MIT EINEM KLICK ALLES IM BLICK ========== */}

                    <Route path="news/dokumente-feed" element={
                      <ProtectedRoute>
                        <UnreadDocs />
                      </ProtectedRoute> }
                    />

                    <Route path="dokumente" element={
                      <ProtectedRoute>
                        <DokumenteWeiterleitung />
                      </ProtectedRoute> }
                    />
                          

                        <Route path="dokumente/privat" element={
                          <ProtectedRoute>
                            <DokuPrivat />
                          </ProtectedRoute> }
                        />

                        <Route path="dokumente/energie" element={
                          <ProtectedRoute>
                            <DokuEnergie />
                          </ProtectedRoute> }
                        />

                        <Route path="dokumente/versicherung" element={
                          <ProtectedRoute>
                            <DokuVersicherung />
                          </ProtectedRoute> }
                        />

                        <Route path="dokumente/immobilien" element={
                          <ProtectedRoute>
                            <DokuImmobilien />
                          </ProtectedRoute> }
                        />

                        <Route path="dokumente/kredite" element={
                          <ProtectedRoute>
                            <DokuKredite />
                          </ProtectedRoute> }
                        />

                        <Route path="dokumente/invest" element={
                          <ProtectedRoute>
                            <DokuInvest />
                          </ProtectedRoute> }
                        />

                        {user?.appFreigaben?.hasOwnProperty('steuertaxi') &&
                          <Route path="dokumente/steuertaxi" element={
                            <ProtectedRoute>
                              <DokuSteuertaxi />
                            </ProtectedRoute> }
                          />
                        }


                  
                    <Route path="news/:id" element={
                      <ProtectedRoute>
                        <Neuigkeiten />
                      </ProtectedRoute> }
                    />


                    {/* ================= CONNECT ================== */}

                    <Route path="connect" element={
                        <ProtectedRoute>
                          <ConnectWeiterleitung />
                        </ProtectedRoute> }
                    />

                    <Route path="connect/dateien-senden" element={
                        <ProtectedRoute>
                          <DateienSendenWeiterleitung />
                        </ProtectedRoute> }
                    />

                    <Route path="connect/unternehmens-connect" element={
                        <ProtectedRoute>
                          <UnternehmensConnect />
                        </ProtectedRoute> }
                    />

              


                    {/* ================= Weitere ================== */}

                    <Route path="informationen" element={
                      <ProtectedRoute>
                        <Informationen />
                      </ProtectedRoute> }
                    />





                    {/*<Route path="tarif-taxi" element={
                        <ProtectedRoute user={user}>
                          <TarifTaxi />
                        </ProtectedRoute> }
                    />*/}


                    <Route path="cashback" element={
                        <ProtectedRoute>
                          <Cashback />
                        </ProtectedRoute> }
                    />

                    <Route path="vertriebsnews" element={
                        <ProtectedRoute>
                          <NeuigkeitenVP />
                        </ProtectedRoute> }
                    />



                    {/*
                      <Route path="schaden" element={
                          <ProtectedRoute>
                            <Schaden />
                          </ProtectedRoute> }
                      />
                    */}
                    

                    <Route path="gewinnspiel" element={
                      <ProtectedRoute>
                        <Gewinnspiel />
                      </ProtectedRoute> }
                    />

                    <Route path="verbesserungen" element={
                      <ProtectedRoute>
                        <Verbesserungen />
                      </ProtectedRoute> }
                    />



                    {/*<Route path="energie/rechner" element={<EnergieRechner />} />
                    */}




                    {/* 1. Footer (nur mit Login!) */}

                    <Route path="erstinformation" element={
                      <ProtectedRoute>
                        <Erstinformation />
                      </ProtectedRoute> }
                    />


                    <Route path="informationen-dokumente" element={
                      <ProtectedRoute>
                        <InformationenDokumente />
                      </ProtectedRoute> }
                    />


                {/* Lead-Formulare (ohne Login) */}
                <Route path="mietkaution" element={<Mietkaution />} />
                <Route path="vp-onboarding/:id" element={<VorteilspartnerOnboardingAktion />} />


                {/* Vorteilspartner Landingpage - redirect https://vorteilspartner.vin1.eu/:id NEXT.JS Deployment unter /vorteilspartner */}
                <Route path="vorteilspartner/:id" element={<VorteilspartnerLandingpage />} />


                <Route path="impressum" element={<Impressum />} />
                <Route path="datenschutz" element={<Datenschutz />} />
                <Route path="nutzungsbedingungen" element={<Nutzungsbedingungen />} />

                <Route path="*"
                  element={<Navigate to="/" replace />}
                />
                {/*<Route path="*" element={<p>There's nothing here: 404!</p>} />*/}
        
            </Routes>


          </div>
        <Footer />
      </div>

      </Router>

    </UserProvider>
  </AuthProvider>

  );
}

export default App;

