import React from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileText,
  faChevronRight,
  faNewspaper,
  faChevronDown,
  faChevronUp,
  faGift
} from "@fortawesome/free-solid-svg-icons";

import { Container, Row, Col, Button } from "react-bootstrap";

import style from "./UnreadDocs.module.css";
import Swal from "sweetalert2";

import { formatDate } from '../../util/date/dates'

function CardFeed(props) {

  let navigate = useNavigate();


  const getDateFirebase = (timestamp) => {

    return new Intl.DateTimeFormat('de-DE', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit', 
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }).format(new Date(timestamp.seconds*1000)).substring(0,8);

  };

  const getDate = (timestamp) => {

    return new Intl.DateTimeFormat('de-DE', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit', 
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format(new Date(timestamp*1000)).substring(0,8);
  }



  return (
    <Row style={{ padding: "0px 10px" }}>
      <br />
      <br />
      {props.data.length === 0 && (
        <p className="text-center" style={{ marginBottom: "80px" }}>
          Keine ungelesenen Informationen vorhanden.
        </p>
      )}

      {props.data.length > 0 &&
        props.data
          .sort((a, b) => (b.date > a.date ? 1 : -1))
          .map((doc) => (
            <li key={doc._id ? doc._id : doc.id} className={style.card}>

              {/* Dokumentenfeed */}
              {doc.id && (isNaN(doc.id) && doc.kategorie !== "Partner-Angebote") && 
                <div
                  className={style.cardInner}
                  onClick={() => {
                    
                    // Nur Gelesen Aktion, wenn in Feed Ungelesen
                    {doc.showInNewsfeed &&
                        Swal.fire({
                          icon: "info",
                          title: "Dokument",
                          html:
                              "<b>Willst du das Dokument als gelesen markieren?</b><br />" +
                              "Du wirst unabhängig von deiner Auswahl zur richtigen Stelle geleitet.<br />" +
                              "Gelesene Dokumente findest du, indem du auf Archiv klickst.",
                          confirmButtonColor: "--primary-color",
                          confirmButtonText: "Als gelesen markieren",
                          showDenyButton: true,
                          denyButtonColor: "#888",
                          denyButtonText: "Nur zum Dokument",
                          showCloseButton: true,
                          reverseButtons: true,
                        })
                        .then(async (result) => {

                          if (result.isConfirmed) {
                              //"gelesen" => true, damit Doc in Archiv sichtbar.
                              props.handleUpdateGelesen(doc.id);
                          }
                          
                          if (!result.isDismissed) {
                            // Nur zum Dokument leiten, wenn nicht abgebrochen
                            navigate("/dokumente/" + doc.category.toLowerCase(), {
                              state: {
                                  navigateBack: '/news/dokumente-feed',
                                  category: doc.categoryTwo,
                                  subcategory: doc.categoryThree,
                              },
                            });
                          }
                          
                          //if(result.isDismissed) {
                          //  alert("Abbrechen...")
                          //}
                        })
                    }

                    {/* Wenn in Archiv */}
                    {!doc.showInNewsfeed &&
                      // Nur zum Dokument leiten, wenn nicht abgebrochen
                      navigate("/dokumente/" + doc.category.toLowerCase(), {
                        state: {
                            navigateBack: '/news/dokumente-feed',
                            category: doc.categoryTwo,
                            subcategory: doc.categoryThree,
                        },
                      })
                    }

                    {/* Ende onClick Abfrage */}
                  }}
                 
                >

                  <div className={style.cardContent}>
                    <FontAwesomeIcon className={style.icon} icon={faFileText} />
                    <div className={style.title}>
                      <div className={style.subTitle}>
                        <span className={style.date}>{getDateFirebase(doc.created)}</span>
                        <span className={style.date}>VIN1</span>
                      </div>
                      <p>{doc.description} - {doc.category}</p>
                    </div>
                      {/*
                        <p className={style.titleWeb}>
                          <span className={style.date}>{getDate(doc.date)}</span>
                          {doc.name}
                        </p>
                        <p className={style.titleMobil}>
                          <span className={style.date}>{getDate(doc.date)}</span>
                          {doc.name}
                        </p>
                      */}
                  </div>
                  <div className={style.NavDocument}>
                    <span>Zum Dokument</span>
                    <FontAwesomeIcon
                      className={style.icon}
                      icon={faChevronRight}
                    />
                  </div>
                </div>
              }




              {/* NewsletterFeed PHP */}
              {!isNaN(doc.id) && 
                <div
                  className={style.cardInner}
                  onClick={() => navigate("/news/"+doc.id)}
                >
                  <div className={style.cardContent}>
                    <FontAwesomeIcon
                      className={style.icon}
                      icon={faNewspaper}
                    />

                    <div className={style.title}>
                      <div className={style.subTitle}>
                        {!doc.enddatum && <span className={style.date}>{getDate(doc.created)}</span>}
                        <span className={style.date}>VIN1</span>
                      </div>
                      <p>{doc.name}</p>
                    </div>

                  </div>
                  <div className={style.NavDocument}>
                    <span>Zum Newsblog</span>
                    <FontAwesomeIcon
                      className={style.icon}
                      icon={faChevronRight}
                    />
                  </div>
                </div>
              }


  

              {/* NewsletterFeed CRM */}
              {doc._id &&
                <div
                  className={style.cardInner}
                  onClick={() => navigate("/news/"+doc._id)}
                >
                  <div className={style.cardContent}>
                    <FontAwesomeIcon
                      className={style.icon}
                      icon={faNewspaper}
                    />
                    <div className={style.title}>
                      <div className={style.subTitle}>
                        <span className={style.date}>{formatDate(doc.publishDate)}</span>
                        <span className={style.date}>{doc.partner_name}</span>
                      </div>
                      <p>{doc.title}</p>
                    </div>
                  </div>
                  <div className={style.NavDocument}>
                    <span>Zum Newsblog</span>
                    <FontAwesomeIcon
                      className={style.icon}
                      icon={faChevronRight}
                    />
                  </div>
                </div>
              }

              



              {/* B2B Angebote */}
              {/*(doc._id && isNaN(doc.id) && doc.kategorie == "Partner-Angebote") &&                
                <div
                  className={style.cardInner}
                  onClick={() => navigate("/news/"+doc.id)}
                >
                
                  <div className={style.cardContent}>
                      <FontAwesomeIcon
                        className={style.icon}
                        icon={faGift}
                      />

                      <div className={style.title}>
                        <div className={style.subTitle}>
                          <span className={style.date}>{getDate(doc.date)}</span>
                          <span className={style.date}>{doc.partner_name}</span>
                        </div>
                        <p>{doc.name}</p>
                      </div>
                      
                  </div>

                  <div className={style.NavDocument}>
                    <span>Zum Angebot</span>
                    <FontAwesomeIcon
                      className={style.icon}
                      icon={faChevronRight}
                    />
                  </div>

                </div>
            */}



            </li>
          ))}
    </Row>
  );
}

export default CardFeed;
