import React, { useEffect, useState, useRef, useContext } from 'react'
import Header from '../Header/Header'
import { useNavigate, useLocation } from 'react-router-dom';

import style from "./Vorteilspartner.module.css";

//import VorteilspartnerKachel from "../Bausteine/VorteilspartnerKachel/VorteilspartnerKachel"

//import { QrReader } from "react-qr-reader";
import QR from "./QR";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode, faQrcode, faMapLocation } from "@fortawesome/free-solid-svg-icons";

import { Container, Row, Col, Modal, Button }  from "react-bootstrap";

import axios from "axios";
import Swal from "sweetalert2";

import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";


//Google Maps
//import { Wrapper, Status, Map, Marker } from "@googlemaps/react-wrapper";
import GoogleMapsKarte from './GoogleMapsKarte';
import VorteilspartnerSuchliste from './VorteilspartnerSuchliste';
import { AuthContext } from '../../store/auth-context';
import { UserContext } from '../../store/user-context';




function Vorteilspartner() {

  const { user } = useContext(UserContext); 
  const { currentUser } = useContext(AuthContext); 
  
  const [zoom] = useState(14); // initial zoom
  const [mapCenter, setMapCenter] = useState({
    lat: 51.7302443,
    lng: 7.4823169
  }); // Standort Noki, überschreiben mit aktueller Position.

  // Get Current Position of User with JS (User gets an alert to allow position)
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function(position) {
      setMapCenter({lat: position.coords.latitude, lng: position.coords.longitude});
      //console.log("Position: lat: "+position.coords.latitude + " | lng: " + position.coords.longitude)
    })
  }, [])

  const [showVorteilsseite, setShowVorteilsseite] = useState(false);

  const [onlinePartnerData, setOnlinePartnerData] = useState([]);
  const [offlinePartnerData, setOfflinePartnerData] = useState([]);

  const [showQrReader, setShowQrReader] = useState(false);

  // Wahl zwischen Online und vor Ort Partner
  const [OnlinePartner, setOnlinePartner] = useState(true);

  const [mapMarkers, setMapMarkers] = useState([]);
  const [radiusFilter, setRadiusFilter] = useState(1000);


  // Alle Online Vorteilspartner abrufen
  useEffect(() => {

    async function fetchData () {
        let result = await axios({
          method: "GET",
          headers: {
            'Authorization': 'Bearer ' + currentUser.accessToken
          },
          url: process.env.REACT_APP_BACKEND+"/vorteilspartner/app"
        })

        setOnlinePartnerData(result.data);
    };

    // Alle Partner abrufen, wenn Online Bereich angewäht
    if(OnlinePartner && currentUser?.accessToken) {
      fetchData();
    }
    
  }, [OnlinePartner, currentUser])



  useEffect(() => {
    async function fetchDataMap () {

      let result = await axios({
        method: "GET",
        headers: {
          'Authorization': 'Bearer ' + currentUser.accessToken
        },
        url: process.env.REACT_APP_BACKEND+"/vorteilspartner/app/"+mapCenter.lat+"-"+mapCenter.lng+"-"+radiusFilter
      })

      setMapMarkers(result.data);
      setOfflinePartnerData(result.data);

    };

    if(currentUser?.accessToken) {
      fetchDataMap();
    }

  }, [radiusFilter, mapCenter, currentUser])



  const handleRadius = (radius) => {
    setRadiusFilter(radius);
  }

  const handleCenterChange = (newCenter) => {
    setMapCenter({
      lat: newCenter.lat,
      lng: newCenter.lng
    });
  }


  //const handleFilter = () => {

    /*
      let keys = ["name", 'plz', 'stadt'];
      let searchVal = search.toUpperCase();
      
      // e = foreach partner
      var result = sheetDataFiltered.filter(function(entry) {

        return keys.every(function(a) {
          console.log(a);
          //return values.includes(e[a])
        })
      })
      */
    /*
      console.log(sheetDataFiltered);

      let result = sheetDataFiltered.filter(f => 
        f.name.toUpperCase().includes(search.toUpperCase()) ||
        search === ""
      )
      let result = sheetDataFiltered.filter((f) => 
              f.name.toUpperCase().includes(search.toUpperCase()) ||
              f.stadt.toUpperCase().includes(search.toUpperCase()) ||
              search === ""
      );
    
      return result; 
  }
  */
  


  return (
    <>
      <Header />
      <Container style={{ maxWidth: "800px", marginBottom: "160px" }}>
        <Row style={{ justifyContent: "center" }}>
          <Col className="text-center">
            <img
              src={require("../../img/Vorteilspartner.png")}
              alt=""
              style={{ maxWidth: "120px", marginTop: "50px" }}
            />
            <h1 style={{ color: "grey" }}>VIN1 Vorteilswelt</h1>
            <br />
            <h4>Wie nutzt Du Deine Vorteile?</h4>
            <span><b>Online: </b>Nutze den Code als Gutscheincode in der Online-Plattform des Partners.</span><br />
            <span><b>Vor Ort: </b>Scanne den QR-Code des Partners vor Ort und zeige das Ergebnis dem Personal vor.</span>
            <br />
            <br />
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", marginBottom: "20px"}}>
          <Col style={{textAlign: "center",  display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            {showQrReader && (
              <>
                <p className="fw-bold text-center">
                  Scanne jetzt den Vorteilspartnercode, um deinen Vorteil zu
                  erhalten.
                </p>
                <div className={style.qrreader}>

                  <QR vorteilspartner={offlinePartnerData} vorname={user.vorname} nachname={user.nachname} setShowQrReader={setShowQrReader} />                  
                  {/*
                  <QrReader
                    delay={delayScan}
                    style={{ width: "100%", height: "60vh", margin: "0", padding: "0" }}
                    //facingMode={"front"}
                    constraints={ {facingMode: 'user'} }
                    //^3.0.0-beta1
                    //onError={handleErrorFile}
                    //onScan={handleQRScan}
                    onResult={(result, error) => {
                      if (!!result) {
                        if (
                          result.text ===
                          "https://my.vin1.eu/register?partner=108000"
                        ) {
                          handleQRScan(result.text);
                          setDelayScan(false);
                          setShowQrReader(!showQrReader);
                        }
                      }
                      if (!!error) {
                        //console.log(error);
                      }
                    }}
                  />
                  */}

                </div>
               
                <br />
                <br />
              </>
            )}

            {!showQrReader && (
              <div style={{width: "auto", maxWidth: "90%"}}>
                <Button style={{background: "#520DA7", padding: "8px 32px", width: "100%"}} onClick={() => setShowQrReader(!showQrReader)}>
                    <FontAwesomeIcon style={{width:"auto", cursor: "pointer", marginRight: "5px" }} icon={faQrcode} size="lg" />
                    QR Scanner Vorteilspartnercodes
                </Button>
              </div>
            )}

            </Col>
        </Row>

        {/*
        <Row style={{ justifyContent: "center"}}>
          <Col style={{textAlign: "center", maxWidth: "90%"}}>
            {!showVorteilsseite && (
              <Button variant="success" style={{background: "#520DA7", padding: "8px 32px", width: "auto"}} onClick={() => setShowVorteilsseite(!showVorteilsseite)}>
                <FontAwesomeIcon style={{width:"auto", cursor: "pointer", marginRight: "5px" }} icon={faMapLocation} size="lg" />
                Vorteilspartner Seite anzeigen
              </Button>
            )}
            {showVorteilsseite && (
              <WebViewModal
                url={"https://vin1.eu/vorteilsprogramm/"}
                hideModal={setShowVorteilsseite}
              />
            )}
             
          </Col>
        </Row>
          <br />
        */}

        <Row>
          <Col className="mt-3 d-flex justify-content-center" sm={12}>   
            <p>Wähle aus, welche Vorteilspartner angezeigt werden sollen</p>
          </Col>

          <Col className="mt-2 d-flex justify-content-center">

            {OnlinePartner && (
              <>
                <Button
                  onClick={() => setOnlinePartner(true)}
                  style={{
                    background: "green",
                    border: "none",
                    marginRight: "15px",
                  }}
                >
                  Online Vorteile
                </Button>
                <Button
                  onClick={() => setOnlinePartner(false)}
                  style={{
                    background: "none",
                    color: "#520ca7aa",
                    border: "2px solid #520ca7aa",
                  }}
                >
                  Vor Ort Vorteile
                </Button>
              </>
            )}

            {!OnlinePartner && (
              <>
                <Button
                  onClick={() => setOnlinePartner(true)}
                  style={{
                    background: "none",
                    color: "#520ca7aa",
                    border: "2px solid #520ca7aa",
                    marginRight: "15px",
                  }}
                >
                  Online Vorteile
                </Button>
                <Button
                  onClick={() => setOnlinePartner(false)}
                  style={{ background: "green", border: "none" }}
                >
                  Vor Ort Vorteile
                </Button>
              </>
            )}

            </Col>
          </Row>

          {OnlinePartner &&
            <div className="mt-4">
              <VorteilspartnerSuchliste
                data={onlinePartnerData}
                keineDatenMeldung={<p className="text-center">Keine Vorteilspartner im Kartenausschnitt gefunden.<br />Vergrößere den Suchbereich oder ändere die Suchposition.</p>}
                type={"online"}
              />
            </div>
          }

          
          {!OnlinePartner && 
            <>
              <GoogleMapsKarte zoom={zoom} center={mapCenter} markers={mapMarkers} handleRadius={handleRadius} handleCenterChange={handleCenterChange} />
              <Row className="mt-3">
                <Col xs={12} className="d-flex justify-content-center">
                  <p>Tippe/Klicke auf einen der Karten-Marker, um weitere Details zu erhalten oder suche in der Liste.</p><br />
                </Col>
                <Col xs={12} className="d-flex justify-content-center">
                  <p>Hier werden nur die Vorteilspartner aus deinem Suchradius angezeigt.</p>
                </Col>
              </Row>

              <VorteilspartnerSuchliste
                data={offlinePartnerData}
                keineDatenMeldung={<p className="text-center">Keine Vorteilspartner in diesem Radius gefunden.</p>}
                type={"offline"}
              />
            </>
          }
      </Container>
    </>
  );
}

export default Vorteilspartner