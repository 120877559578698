import React from 'react'

import { RingSpinnerOverlay } from 'react-spinner-overlay'

import style from "./Spinner.module.css"

function Spinner(props) {


  return (
    <RingSpinnerOverlay
        size={50}  
        message={<p className={style.spinner}>{props.message ? props.message : ""}</p>}
        overlayColor="#00000080"
        color= "#bc92ef"
       
    />
  )
}

export default Spinner