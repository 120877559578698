import React from "react";

import { Modal, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";



function DownloadModalMaklervollmacht(props) {

  async function downloadPDF(filepath, filename) {
    fetch(filepath).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const newBlob = new Blob([blob], { type: "application/pdf" });
        const fileURL = window.URL.createObjectURL(newBlob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = filename;
        document.body.appendChild(alink); // Required for Firefox
        alink.click();
        alink.remove(); // After triggering click, removing the link
      });
    });
  }

  return (
    <Modal show={props.showModal} onHide={() => props.setShowModal(!props.showModal)}>
      <Modal.Header closeButton>
        <Modal.Title>Download Verträge</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ marginTop: "0px" }} className="fw-bold">
          Maklervertrag/-vollmacht
        </p>
        <div style={{}}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              downloadPDF(
                "/pdf/Maklervertrag+Vollmacht_VIN1.pdf",
                "Maklervertrag+Vollmacht_VIN1.pdf"
              )
            }
          >
            <span style={{ fontSize: "16px", textDecoration: "underline" }}>
              Download Maklervertrag/-vollmacht (PDF)
            </span>
            <FontAwesomeIcon
              icon={faDownload}
              size="lg"
              style={{ marginLeft: "10px" }}
            />
          </div>
        </div>

        <p style={{ marginTop: "40px" }} className="fw-bold">
          Erstinformationen
        </p>
        <div style={{}}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              downloadPDF(
                "/pdf/Erstinformationen_VIN1.pdf",
                "Erstinformationen_VIN1.pdf"
              )
            }
          >
            <span style={{ fontSize: "16px", textDecoration: "underline" }}>
              Download Erstinformationen (PDF)
            </span>
            <FontAwesomeIcon
              icon={faDownload}
              size="lg"
              style={{ marginLeft: "10px" }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <p
          style={{
            fontSize: "100%",
            display: "flex",
            justifyContent: "flex-end",
            width: "auto !important",
            background: "#520da7",
            padding: "15px",
            color: "white",
            fontWeight: "bold",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={() => props.setShowModal(!props.showModal)}
        >
          Schließen
        </p>
      </Modal.Footer>
    </Modal>
  );
}

export default DownloadModalMaklervollmacht;
