import React, { useEffect, useContext, useState } from "react";
import style from "./VorteilspartnerKachel.module.css";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faQrcode,
  faMapLocation,
  faGlobe,
  faCircleCheck,
  faTicket,
  faBookOpen,
  faChevronDown,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";

import { Row, Col, Button, Badge } from "react-bootstrap";

import Swal from "sweetalert2";

import WebViewModal from "../WebViewModal/WebViewModal";
import axios from "axios";
import { AuthContext } from "../../../store/auth-context";
import VorteilsAktionsButtons from "./VorteilsAktionsButtons";


function VorteilspartnerKachel(props) {

  const { currentUser } = useContext(AuthContext);

  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState(false);

  const [displayAktionen, setDisplayAktionen] = useState([]);

  const handleExternalURL = (url) => {
    /*
    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
    */
    window.open(url, '_blank',  'noopener, noreferrer');
  };




  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
      function handleResize() {
          setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getWindowDimensions() {
    const width = window.innerWidth
    const height = window.innerHeight

    return {
        width,
        height
    };
  }




  /*
  const handleMehrInfos = (url) => {
    setShowModal(false);
     window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
  }
  */

  const handleNavigation = (data) => {
    
    /*let data = {
      plz: 5761,
      stadt: "Maria Alm",
      land: "Österreich",
      anschrift: "Almerwirtsfeld 20",
      name: "Bergleben Maria Alm Haus Panorama",
    };
    */
    window.open(
      "https://www.google.de/maps/search/"+data.name+', '+data.anschrift+', '+data.plz+', '+data.stadt+', '+data.land,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleVorteilscode = (id, name, url, vorteilOnline) => {

    // Nur den Vorteilscode nicht direkt sichtbar machen - die Beschreibung ist ok
    axios({
      method: "post",
      headers: {
        'Authorization': 'Bearer ' + currentUser.accessToken
      },
      url: process.env.REACT_APP_BACKEND+"/vorteilspartner/code",
      data: {
        id: id
      }
    }).then(res => {

      if(res.data.vorteilscode) {

        Swal.fire({
          html: "<h4>Vorteilscode von Partner</h4>"
                +"<h3>"+name+"</h3>"
                +"<br />Dein Vorteilscode lautet:<br />"
                +"<b>" + res.data.vorteilscode + "</b>"
                +'<br />'
                +'<button id="copyCodeButton" class="swal2-confirm swal2-styled">Code kopieren</button>'
                +"<br /><br />"
                +"Der Vorteil beinhaltet: <br />"
                +"<b>" + res.data.vorteilOnline + "</b><br />",
                icon: "info",
          confirmButtonText: 'Zur Webseite',
          denyButtonText: 'Schließen',
          showDenyButton: true,
          showConfirmButton: true,
          showCloseButton: true,
          reverseButtons: true,
          didOpen: () => {
            // Attach event listener
            document.getElementById('copyCodeButton').addEventListener('click', () => {
              navigator.clipboard.writeText(res.data.vorteilscode)
              .then(() => {
                  Swal.showValidationMessage('Code kopiert.');  // This will show a brief message in the modal
              })
              .catch(err => {
                  console.error('Async: Could not copy text: ', err);
              });
          });
          },
          preConfirm: () => {
            //Prevent Closing Alert with Code
            handleExternalURL(url)
            return false; // Prevent confirmed
          }

        })
      }
      else 
      {
        Swal.fire({
          title: "Abruf von Vorteilscode",
          html: "Beim Abrufen des Codes ist es zu einem Fehler gekommen.<br />Bitte stelle sicher, dass du mit dem Internet verbunden bist.",
          icon: "info",
        })
      }

    })
  }


  return (
  
    <>
      {windowDimensions.width >= 766 && 
        <div className={style.cardContainer}>
          <Row style={{minHeight: "100px"}} className="p-2">
            <Col xs={3}>
              {props.logo ?
                <div
                  className={style.cardImgContainer}
                  style={{
                    //backgroundImage: `url("https://goldmountains-multimedia.eu/wp-content/uploads/2019/03/Goldmountains_Multimedia@4x-1024x348.png")`,
                    //backgroundImage: `url('/logo192.png')`,
                    backgroundImage: `url("${props.logo}")`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                  >
                  </div>
                  :
                  <div
                    className={style.cardImgContainer}
                    style={{
                      background: "lightgrey",
                      padding: "0px 12px",
                      marginRight: "6px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px"
                    }}
                  >
                    <span style={{"color": "darkgrey"}}>No IMG</span>
                  </div>
              } 
            </Col>

            <Col xs={9}>
                <div className="d-flex justify-content-between">
                  {props.kategorie && <span style={{fontSize: "90%"}}>{props.kategorie}</span>}
                  {(props.online && props.offline) && <Badge bg="primary" style={{marginTop: "8px", marginRight: "8px", display: "flex", alignItems: "center" }}>auch vor Ort Vorteil</Badge>}
                </div>
                <h4
                  style={{fontSize: "115%"}}
                >
                  {props.name}
                </h4>
                
                {props.type=="offline" && 
                  <div style={{marginBottom: "10px"}}>
                    <span>{props.anschrift ?? props.anschrift} - {props.plz ?? props.plz} {props.stadt ?? props.stadt}</span>
                  </div>
                }
              
                
                <div>
                
                  <p className="card-text">{props.vorteilOnline ?? <i>keine Beschreibung</i>}</p>
              
                  {!displayAktionen.includes(props.name) ? 
                    <div
                      className="px-2 pb-2 text-center"
                      onClick={() => setDisplayAktionen([...displayAktionen, props.name])}
                    >
                      <span style={{paddingRight: "10px"}}>Aktionen anzeigen</span>
                      <FontAwesomeIcon className={style.icon} icon={faChevronDown} size="lg" />
                    </div>
                    :
                    <VorteilsAktionsButtons
                      id={props.id}
                      vorteilId={props.vorteilId}
                      name={props.name}
                      plz={props.plz}
                      stadt={props.stadt}
                      anschrift={props.anschrift}
                      land={props.land}
                      webseite={props.webseite}
                      online={props.online}
                      handleNavigation={handleNavigation}
                      handleExternalURL={handleExternalURL}
                      handleVorteilscode={handleVorteilscode}
                    />
                  }
                </div>

            </Col>
          </Row>
        </div>
      }




      {/* Mobile View */}
      {windowDimensions.width < 766 &&
        <div className={style.cardContainer}>
          <Row style={{minHeight: "100px"}} className="p-2">
            <Col xs={4}>
              {props.logo ?
                <div
                  className={style.cardImgContainer}
                  style={{
                    //backgroundImage: `url("https://goldmountains-multimedia.eu/wp-content/uploads/2019/03/Goldmountains_Multimedia@4x-1024x348.png")`,
                    backgroundImage: `url("${props.logo}")`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                  >
                  </div>
                  :
                  <div
                    className={style.cardImgContainer}
                    style={{
                      background: "lightgrey",
                      padding: "0px 12px",
                      marginRight: "6px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px"
                    }}
                  >
                    <span style={{"color": "darkgrey"}}>No IMG</span>
                  </div>
              } 
            </Col>

            <Col xs={8}>
                <div className="d-flex justify-content-between">
                  {props.kategorie && <span style={{fontSize: "90%"}}>{props.kategorie}</span>}
                  {(props.online && props.offline) && <Badge bg="primary" style={{marginTop: "8px", marginRight: "8px", display: "flex", alignItems: "center" }}>auch vor Ort Vorteil</Badge>}
                </div>
                <h4
                  style={{fontSize: "115%"}}
                >
                  {props.name}
                </h4>

                {props.type=="offline" && 
                  <div style={{marginBottom: "10px"}}>
                    <span>{props.anschrift ?? props.anschrift} - {props.plz ?? props.plz} {props.stadt ?? props.stadt}</span>
                  </div>
                }

                {props.type=="online" &&
                  <div style={{marginBottom: "10px"}}>
                    {props.vorteilOnline ?? <i>keine Beschreibung</i>}
                  </div>
                }
            </Col>
          </Row>

          
          <div>
           
            {!displayAktionen.includes(props.name) ? 
              <div
                className="px-2 pb-2 text-center"
                onClick={() => setDisplayAktionen([...displayAktionen, props.name])}
              >
                <span style={{paddingRight: "10px"}}>Aktionen anzeigen</span>
                <FontAwesomeIcon className={style.icon} icon={faChevronDown} size="lg" />
              </div>
              :
              <VorteilsAktionsButtons
                id={props.id}
                vorteilId={props.vorteilId}
                name={props.name}
                plz={props.plz}
                stadt={props.stadt}
                anschrift={props.anschrift}
                land={props.land}
                webseite={props.webseite}
                online={props.online}
                handleNavigation={handleNavigation}
                handleExternalURL={handleExternalURL}
                handleVorteilscode={handleVorteilscode}
              />
            }
          </div>

        </div>
      }{/* end view mobile */}

    </>
    
  );
}

export default VorteilspartnerKachel;
