import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function VorteilspartnerLandingpage() {
  
  const { id } = useParams();

  useEffect(() => {
    // Redirecting to the external domain
    window.location.href = `https://vorteilspartner.vin1.eu/${id}`;
  }, [id]);

  return null; // This component does not render anything
}

export default VorteilspartnerLandingpage;