import React from "react";
  
  import Header from '../Header/Header'
  
  import Card from "../Card/Card";
  
  import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";
  
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faCirclePlus, faDownload } from "@fortawesome/free-solid-svg-icons";
  
  import style from "./Informationen.module.css";
  
  import { Container, Row, Col } from 'react-bootstrap';
  

  
  function Informationen() {
  
    return (
      <>

        <Header url={"/"} />
        
        <Container style={{maxWidth: "800px"}}>

          <Row style={{justifyContent: "center"}}>
            <Col style={{textAlign: "center"}}>
              <img src={require("../../img/Informationen.png")} alt="" style={{maxWidth: "160px", marginTop: "30px"}} />
  
              <h1>Informationen</h1>
              <p className="text-center">
                  Finde hier Informationen zu den Themen
                  <br />
                  Versicherungen, Kredite, Finanzen & Invest, Energie, Immobilien und mehr.
              </p>
              
            </Col>
          </Row>

          <Row style={{ marginTop: "16px", justifyContent: "center" }}>
            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
              <Card url={"versicherungen"} img={"Versicherungen.png"} title={"Versicherungen"} />
            </Col>
            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
              <Card url={"finanzen"} img={"Finanzen.png"} title={"Kredite"} />
            </Col>
            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
              <Card url={"invest"} img={"Invest.png"} title={"Finanzen & Invest"} />
            </Col>
            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
              <Card url={"energie"} img={"Energie.png"} title={"Energie"} />
            </Col>
            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
              <Card url={"immobilien"} img={"Immo.png"} title={"Immobilien"} />
            </Col>
            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
              <Card url={"partnerprogramm"} img={"PartnerProgramm.png"} title={"VIN1 Partnerprogramm"} />
            </Col>
          </Row>

          <div style={{marginBottom: "80px"}} />

        </Container>
      </>
    )
  }
  
  export default Informationen