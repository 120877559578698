import React, { useEffect, useState } from 'react'
import style from "./Vorteilspartner.module.css";

//import VorteilspartnerKachelOffline from "../Bausteine/VorteilspartnerKachel/VorteilspartnerKachelOffline"
//import VorteilspartnerKachelOnline from "../Bausteine/VorteilspartnerKachel/VorteilspartnerKachel"
import VorteilspartnerKachel from "../Bausteine/VorteilspartnerKachel/VorteilspartnerKachel"

import { Row, Col }  from "react-bootstrap";


function VorteilspartnerSuchliste(props) {

  const [search, setSearch] = useState("");
  const [kategorieFilter, setKategorieFilter] = useState("");
  const [vorteileKategorien, setVorteileKategorien] = useState([]);

  
  useEffect(() => {

    //Filter Array - unique Kategorien
    setVorteileKategorien(
      Array.from(
        new Set(
          props.data.map(
            (item) => item.kategorie
          )
        )
      ).filter(item => item)
    );

  },[props.data])
  

  return (
    <>
      <Row className="d-flex justify-content-center mb-4">
        <Col sm={12} md={12}>
        
          <p>Filtere die Vorteilspartner Liste nach Kategorie</p>
          <select
            className="form-select"
            value={kategorieFilter}
            onChange={(e) => setKategorieFilter(e.target.value)}
          >
            <option value={""} key={0}>Alle Kategorien</option>
            {vorteileKategorien?.map((option, i) => {
              return (
                <option value={option} key={i}>
                  {option}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>


      <Row>
        <Col sm={12} md={12}>
          <div
            className={style.formInputCustom}
            style={{ marginBottom: "20px" }}
          >
            <label htmlFor="partner" className="form-label">
              Suche Vorteilspartner (Name, PLZ oder Stadt)
            </label>
            <br />
            <input
              type="text"
              id="partner"
              name="partner"
              placeholder="Suche nach Vorteilspartner"
              autoComplete="on"
              autoCapitalize="none"
              onChange={(e) => {
                if(e.target.value.trim().length > 2) 
                {
                  setSearch(e.target.value.trim());
                  //searchList(e);
                }
                else if (e.target.value.trim().length  == 0)
                {
                  setSearch("");
                }
              }}
            />
          </div>
        </Col>
      </Row>
          

      <Row>
        <Col className="d-flex justify-content-center w-100">

          {props.data.length == 0 &&
            <i>{props.keineDatenMeldung}</i>
          }

          {props.data.length > 0 && props.data.filter((f) => 
              (f.name.toUpperCase().includes(search.toUpperCase()) ||
              f.plz?.toString().includes(search) ||
              f.stadt?.toUpperCase().includes(search.toUpperCase()) ||
              search === "") &&
              (kategorieFilter === "" || f.kategorie == kategorieFilter)
            ).length == 0 &&
              <i>Keine Daten auf Basis deiner Suche gefunden.</i>
          }

        </Col>
        <Col xs={12}>

          {/*(props.data && props.type === "offline") &&
            props.data.filter(
              (f) => 
              (f.name.toUpperCase().includes(search.toUpperCase()) ||
              f.plz?.toString().includes(search) ||
              f.stadt?.toUpperCase().includes(search.toUpperCase()) ||
              search === "") &&
              (kategorieFilter === "" || f.kategorie == kategorieFilter)
            )
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((data) => (
              <div key={data._id} className={style.CardContainer}>
                <VorteilspartnerKachel
                  type={props.type}
                  id={data._id}
                  vorteilId={data.vorteilId}
                  name={data.name}
                  logo={data.partnerLogo150}
                  kategorie={data.kategorie}
                  online={data.online}
                  offline={data.offline}
                  vorteilOnline={data.vorteilOnline}
                  vorteilOffline={data.vorteilOffline}
                  webseite={data.webseite}
                  anschrift={data.anschrift}
                  plz={data.plz}
                  stadt={data.stadt}
                  land={data.land}
                />
              </div>
            ))*/}

          
          {props.data && props.type &&
            props.data.filter(
              (f) => 
              (f.name.toUpperCase().includes(search.toUpperCase()) ||
              f.plz?.toString().includes(search) ||
              f.stadt?.toUpperCase().includes(search.toUpperCase()) ||
              search === "") &&
              (kategorieFilter === "" || f.kategorie == kategorieFilter)
            )
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((data) => (
              <div key={data._id} className={style.CardContainer}>
                <VorteilspartnerKachel
                  type={props.type}
                  id={data._id}
                  vorteilId={data.vorteilId}
                  name={data.name}
                  logo={data.partnerLogo150}
                  kategorie={data.kategorie}
                  online={data.online}
                  offline={data.offline}
                  vorteilOnline={data.vorteilOnline}
                  vorteilOffline={data.vorteilOffline}
                  webseite={data.webseite}
                  anschrift={data.anschrift}
                  plz={data.plz}
                  stadt={data.stadt}
                  land={data.land}
                />
              </div>
          ))}

        </Col>
      </Row>

    </>
  )
}

export default VorteilspartnerSuchliste