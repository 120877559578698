import React from 'react'

import style from "./VINButton.module.css"

function VINButton(props) {

  return (
    <div className={[style.container, style.containerOne]}>
        <button
          className={style.vinbutton}
          onClick={props.onClick}
          type={props.type === "submit" ? "submit" : "button"}
        >
          <span className={style.buttonText}>{props.text}</span>
        <div className={style.fillOne}></div>
        </button>
    </div>
  )
}

export default VINButton