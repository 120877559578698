import React, { Fragment, useContext, useState } from 'react'
import Header from '../Header/Header'
import style from "./MeineDaten.module.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";

import { Container, Row, Col, Button } from 'react-bootstrap';

import { getAuth, updatePassword, updateEmail } from "firebase/auth";

import Swal from 'sweetalert2'
import VINButton from '../../VINButton';
import { AuthContext } from '../../store/auth-context';
import { UserContext } from '../../store/user-context';

import moment from 'moment';

import QrcodeVorteilspartner from "../Bausteine/QrcodeVorteilspartner";


function Kundendaten() {

  const { currentUser } = useContext(AuthContext);
  const { user } = useContext(UserContext);


  // ------------------------- Set New Login E-Mail ------------------------------
  const [statusEmailChange, setStatusEmailChange] = useState(false);
  const [newEmail, setNewEmail] = useState("");

  const [linkCopied, setLinkCopied] = useState(false);

  function handleChangeEmail() {

      const auth = getAuth();

      const userAuth = auth.currentUser;
     // const newPassword = getASecureRandomPassword();
  
      updateEmail(userAuth, newEmail).then(() => {
  
        setStatusEmailChange(!statusEmailChange);
  
        Swal.fire({
          title: 'Login E-Mail Änderung',
          html: 'Dein Login E-Mail Adresse wurde erfolgreich übernommen.<br />Ab dem nächsten Login ist deine neu hinterlegte E-Mail Adresse gültig.',
          icon: 'success',
          confirmButtonText: 'Ok'
        });

      }).catch((error) => {

        if(error.code === "auth/invalid-email")
        {
          Swal.fire({
            title: 'Login Änderung',
            html: 'Deine eingegebene E-Mail ist nicht gültig.<br />Bitte gib eine gültige Login E-Mail Adresse ein.',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        }
        else if(error.code === "auth/email-already-in-use")
        {
          Swal.fire({
            title: 'Login Änderung',
            text: 'Deine eingegebene E-Mail wird bereits in einem anderen Benutzeraccount verwendet.',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        }
        else
        {
            Swal.fire({
                title: 'Fehler',
                text: 'Es ist ein unbekannter Fehler aufgetreten. Dein alter Login ist weiterhin gültig.',
                icon: 'error',
                confirmButtonText: 'Ok'
              });
        }

      });
  }


  // ------------------------- Set New Login Password ------------------------------
  const [statusPWDChange, setStatusPWDChange] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  function handleChangePassword() {

    if(newPassword !== newPassword2)
    {
      Swal.fire({
          icon: 'info',
          title: 'Passwort',
          text: 'Die eingegebenen Passwörter stimmen nicht überein.',
          confirmButtonText: 'Ok'
      });
      return;
    }

    if(newPassword.length < 8)
    {
      Swal.fire({
          icon: 'info',
          title: 'Passwort',
          text: 'Das eingegebene Passwort muss min. 8 Zeichen lang sein.',
          confirmButtonText: 'Ok'
      });
      return;
    }


    const auth = getAuth();

    const userAuth = auth.currentUser;
   // const newPassword = getASecureRandomPassword();

    updatePassword(userAuth, newPassword).then(() => {

      setStatusPWDChange(!statusPWDChange);

      Swal.fire({
        title: 'Passwort-Änderung',
        text: 'Dein Passwort wurde erfolgreich übernommen.',
        icon: 'success',
        confirmButtonText: 'Ok'
    });
    }).catch((error) => {
      Swal.fire({
        title: 'Fehler beim Passwort ändern',
        text: 'Das Passwort konnte nicht übernommen werden. Bitte versuche es später erneut.',
        icon: 'error',
        confirmButtonText: 'Ok'
    });
    });
        
  }



  return (
    <>
      <Header />
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center", marginBottom:"80px"}}>
          <Col>
            <h1>Meine Daten</h1>

            {user.error ?
              <h5 className="mt-5 text-center mx-auto" style={{color: "#520da7"}}> - Daten konnten nicht geladen werden - </h5>
              :
              <>
            
                <p>Kundennummer</p>
                <h4 style={{color: "#520da7"}}>{user.kdnr ?? "in Bearbeitung"}</h4>
                <hr />

                {user.firma  &&
                  <>
                    <p>Firma</p>
                    <h4 style={{color: "#520da7"}}>{user.firma}</h4>
                    <hr />
                  </>
                } 


                <p>Vorname + Nachname</p>
                <h4 style={{color: "#520da7"}}>{`${user.vorname ?? "-"} ${user.nachname ?? "-"}`}</h4>
                <hr />

                <p>Straße + Hausnummer</p>
                <h4 style={{color: "#520da7"}}>{user.anschrift ?? "nicht hinterlegt"}</h4>
                <hr />

                <p>PLZ + Stadt</p>
                <h4 style={{color: "#520da7"}}>{`${user.plz ?? "-"} ${user.stadt ?? "-"}`}</h4>
                <hr />

                <p>E-Mail</p>
                <h4 style={{color: "#520da7"}}>{user.email ?? "nicht hinterlegt"}</h4>
                <hr />

                <p>Telefon/Mobil</p>
                <h4 style={{color: "#520da7"}}>{user.telefon ?? "nicht hinterlegt"}</h4>
                <hr />

                <p>Geburtsdatum</p>
                <h4 style={{color: "#520da7"}}>
                  {user.geburtsdatum ?
                    moment(user.geburtsdatum).format("DD.MM.YYYY")
                    :
                    <i>nicht hinterlegt</i>
                  }
                </h4>
              
                <hr />
              
            

                {user.sponsor &&
                  <>
                    <p>Sponsor</p>
                    <h4 style={{color: "#520da7"}}>{user.sponsor}</h4>
                    <hr />
                  </>
                }

                {(!statusPWDChange || !statusEmailChange) && 
                  <Container>
                    <Row className="justify-content-center">
                    
                      <Col className="d-flex justify-content-center mt-3">
                        <div className="pointer" onClick={() => setStatusEmailChange(!statusEmailChange)}>
                          <VINButton text={"Login E-Mail ändern"} />
                        </div>
        
                        {/*<Button className={style.BtnCustom} onClick={() => setStatusEmailChange(!statusEmailChange)}>Login E-Mail ändern</Button>*/}
                      </Col>
                      <Col className="d-flex justify-content-center mt-3">
                      <div className="pointer" onClick={() => setStatusPWDChange(!statusPWDChange)}>
                          <VINButton text={"Passwort ändern"} />
                        </div>
                        {/*<Button className={style.BtnCustom} onClick={() => setStatusPWDChange(!statusPWDChange)}>Passwort ändern</Button>*/}
                      </Col>
                    </Row>
                  </Container>
                }


                {statusEmailChange &&
                  <Container style={{maxWidth: "500px", marginBottom: "60px", marginTop: "60px"}}>
                    <Row style={{justifyContent: "flex-end"}}>
                        <FontAwesomeIcon style={{width:"auto", cursor: "pointer", marginBottom: "10px"}} icon={faXmark} size="2x" onClick={() => { setStatusEmailChange(!statusEmailChange) }} />
                    </Row>
                    <Row style={{justifyContent: "center"}}>
                        <h2 style={{color: '#520da7', fontSize: "140%"}} className="fw-bold text-center">
                            Login E-Mail-Adresse ändern
                        </h2>
                        <p className="text-center">Hier kannst du deine neues Login E-Mail-Adresse festlegen.<br /><br />
                        <span className="fw-bold">Beachte, dass du Zugriff auf diese E-Mail Adresse benötigst, um dein Passwort zurückzusetzen!</span>
                        </p>
                        <div className={style.formInputCustom}>
                            <label htmlFor="email" className="form-label">Neue Login E-Mail-Adresse</label><br />
                            <input
                                type="text"
                                id="email"
                                name="email"
                                placeholder="Login E-Mail-Adresse"
                                autoComplete="off"
                                onChange={(e) => setNewEmail(e.target.value.trim())}
                            />
                        </div>
                        <Button className={style.BtnCustom} onClick={handleChangeEmail}>Login E-Mail übernehmen</Button>
                    </Row>
                  </Container>
                }

        
                {statusPWDChange &&
                  <Container style={{maxWidth: "500px", marginBottom: "60px", marginTop: "60px"}}>
                    <Row style={{justifyContent: "flex-end"}}>
                        <FontAwesomeIcon style={{width:"auto", cursor: "pointer", marginBottom: "10px"}} icon={faXmark} size="2x" onClick={() => { setStatusPWDChange(!statusPWDChange) }} />
                    </Row>
                    <Row style={{justifyContent: "center"}}>
                        <h2 style={{color: '#520da7', fontSize: "140%"}} className="fw-bold text-center">
                            Passwort ändern
                        </h2>
                        <p className="text-center">Hier kannst du dein neues Passwort für deinen Login festlegen.<br />
                        </p>
                        <div className={style.formInputCustom}>
                            <label htmlFor="password" className="form-label">Neues Passwort min. 8 Zeichen {newPassword.length < 8 && <span style={{color: "red"}}> - Passwort zu kurz!</span>}</label><br />
                            <input
                                type="password"
                                id="password"
                                name="password"
                                placeholder="********"
                                autoComplete="off"
                                onChange={(e) => setNewPassword(e.target.value.trim())}
                            />
                        </div>

                        <div style={{marginTop: "15px"}} />

                        <div className={style.formInputCustom}>
                            <label htmlFor="password2" className="form-label">Passwort wiederholen  min. 8 Zeichen {newPassword2.length < 8 && <span style={{color: "red"}}> - Passwort zu kurz!"</span>} </label><br />
                            <input
                                type="password"
                                id="password2"
                                name="password2"
                                placeholder="********"
                                autoComplete="off"
                                onChange={(e) => setNewPassword2(e.target.value.trim())}
                            />
                        </div>

                        <Button className={style.BtnCustom} onClick={handleChangePassword}>Passwort übernehmen</Button>
                    </Row>
                  </Container>
                }
           

            
                <p className="mt-5">
                  Solltest du dein Konto löschen wollen oder sollten
                  sich Daten geändert haben wie z.B. bei einem Umzug,
                  dann kontaktiere bitte den Kundenservice.
                </p>
                <p>
                  Telefon: +4925966148181<br />
                  E-Mail: <a href="mailto:myvin@vin1.eu" noreferer="true" nofollow="true">myvin@vin1.eu</a>
                </p>
              </>
            }
          </Col>
        </Row>


        {/* ==================== Anzeige Vorteilspartner-Daten nur wenn Fehlerfrei geladen und Kunde ein Vorteilspartner ist! ==================== */}

        {!user.error && user.vp &&

          <Row style={{justifyContent: "center", marginBottom:"80px"}}>
            <Col>
              <h1>Vorteilspartner Daten</h1>
                
                <p className="mt-5">IBAN - Prämien Auszahlung</p>
                <>
                  {user.vp_iban ?
                    <>
                      <p>
                        {"DE47 12XX XXXX XXXX X123 00"}
                      </p>
                    </>
                    :
                    <p className="italic">- keine Bankverbindung hinterlegt, Kontakt zu VIN1 aufnehmen. -</p>
                  }
                  <hr />
                </>

                {user.vp_id && 
                  <>
                    <p>QR-Code Download - Link zu Landingpage</p>
                    <QrcodeVorteilspartner id={user.vp_id} />
                    <hr />
                  </>
                }


                {user.vp_landingpage && 
                  <>
                    <p>Landingpage</p>
                    <Row className="justify-content-center align-items-center">
                      <Col xs={12} md={6}>
                        <a href={"https://vorteilspartner.vin1.eu/"+user.vp_id} target="_blank">
                          {"https://vorteilspartner.vin1.eu/"+user.vp_id}
                        </a>
                      </Col>

                      <Col xs={12} md={6}>
                        <Button style={{
                            margin: "8px",
                            background: "var(--primary-color)",
                            border: "none"
                          }}
                          onClick={() => {
                          navigator.clipboard
                          .writeText("https://vorteilspartner.vin1.eu/"+user.vp_id)
                          .then(() => {
                            setLinkCopied(true);
                          });
                          }}
                        >
                          {linkCopied ?
                            <>
                              <FontAwesomeIcon icon={faCheck} size="lg"
                                style={{ display:"inline", paddingRight:"8px" }}
                              />
                              <Fragment className="ml-2">Link kopiert</Fragment>
                            </>
                            :
                            <>Link kopieren</>
                          }
                        </Button> 

                      </Col>
                    </Row>
                    <hr />
                  </>
                }

                {user.vp_logo &&
                  <>
                    <Row className="mt-5 mb-3 justify-content-start align-items-center">
                      <Col xs={12} md={6}>
                        <p>Dein hinterlegtes Vorteilspartner Logo</p>
                        {user.vp_logo ?
                          <img
                            src={user.vp_logo}
                            width="250px"
                            height="auto"
                          />
                          :
                          <i>kein Logo hinterlegt</i>
                        }
                      </Col>
                      {/*
                      <Col xs={12} md={6}>
                        <Button
                          style={{margin: "8px", background: "var(--primary-color)", border: "none"}}
                          onClick={() => alert("Coming Soon - Bald kannst Du Dein Logo direkt hier in der App ändern!")}
                        >
                          Logo ändern
                        </Button> 
                      </Col>
                      */}
                      
                    </Row>
                    <hr />
                  </>
                }
      
                {user.vp_landingpage && 
                  <>
                    {user.vp_landingpage_desc ?
                      <>
                        <p>Beschreibung Landingpage</p>
                        <p>
                          {user.vp_landingpage_desc}
                        </p>
                      </>
                      :
                      <p className="italic">- keine Beschreibung hinterlegt -</p>
                    }
                    <hr />
                  </>
                }

                {user.vp_online && 
                  <>
                    <p>Vorteil Online</p>
                    URL zur Vorteilsseite:{" "}
                    <b>
                      <a href={user.vp_webseite} target="_blank">{user.vp_webseite}</a>
                    </b>
                    <br />
                    Vorteilscode: <b>{user.vp_vorteilscode}</b>
                    <br />
                    Vorteil: <b>{user.vp_vorteilOnline}</b>
                    <hr />
                  </>
                }

                {user.vp_vorteilOffline && 
                  <>
                    <p>Vorteil vor Ort</p>
                    Anschrift: <b>{user.vp_anschrift}</b>
                    <br />
                    PLZ + Stadt: <b>{user.vp_plz+" "+user.vp_stadt}</b>
                    <br />
                    Land: <b>{user.vp_land}</b>
                    <br />
                    Vorteil:  <b>{user.vp_vorteilOffline}</b>
                    <hr />
                  </>
                }
                
            </Col>
          </Row>
        }


      </Container>
    </>
  )
}

export default Kundendaten