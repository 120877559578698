import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faQrcode,
  faMapLocation,
  faGlobe,
  faCircleCheck,
  faTicket,
  faBookOpen,
  faChevronDown,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";

import { Row, Col, Button, Badge } from "react-bootstrap";


function VorteilsAktionsButtons(props) {

  
  return (
    <div>
      {props.plz && props.stadt && props.anschrift && props.land && (
        <Button
          style={{
            background: "#520DA7",
            margin: "8px 10px",
            className: "align-items-center",
            width: "40%"
          }}
          onClick={() =>
            props.handleNavigation({
              plz: props.plz,
              stadt: props.stadt,
              land: props.land,
              anschrift: props.anschrift,
              name: props.name ?? props.name,
            })
          }
        >
          <FontAwesomeIcon
            style={{ width: "auto", marginRight: "5px" }}
            icon={faMapLocation}
            size="lg"
          />
          Navigation
        </Button>
      )}

      {props.webseite && (
        <Button
          style={{
            background: "#520DA7",
            margin: "4px 10px",
            className: "align-items-center",
            width: "40%"
          }}
          onClick={() => props.handleExternalURL(props.webseite)}
        >
          <FontAwesomeIcon
            style={{ width: "auto", cursor: "pointer", marginRight: "5px" }}
            icon={faGlobe}
            size="lg"
          />
          Webseite
        </Button>
      )}

      {props.online && (
        <Button
          style={{
            background: "#520DA7",
            margin: "4px 10px",
            className: "align-items-center",
            width: "40%"
          }}
          onClick={() =>
            props.handleVorteilscode(
              props.id,
              props.name,
              props.webseite,
              props.vorteilOnline
            )
          }
        >
          <FontAwesomeIcon
            style={{ width: "auto", cursor: "pointer", marginRight: "5px" }}
            icon={faTicket}
            size="lg"
          />
          Online-Vorteilscode
        </Button>
      )}
    </div>
  );
}

export default VorteilsAktionsButtons;
