import React, {
    useState,
    useEffect,
    useContext,
    useRef,
  } from "react";
  
  import { useNavigate, useLocation, useParams } from 'react-router-dom';
  

  //import UserContext from "../store/user-context";
  
  import Header from '../Header/Header'
  

  import CustomModal from "../Bausteine/CustomModal";
  
  import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";
  
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import {
    faChevronLeft,
    faChevronRight
  } from "@fortawesome/free-solid-svg-icons";
  

  import style from "./Partnerseite.module.css";
  
  import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
  
  import SelectInput from "../Bausteine/SelectInput/SelectInput";  
  import axios from 'axios';
  import Swal from "sweetalert2";
  
  // Optionen
  import PartnerAnfrageSenden from "./PartnerAnfrageSenden";
  //import PartnerNews from "./VIN_Bausteine/PartnerNews";

  import { Rating } from "react-simple-star-rating";
  import { AuthContext } from "../../store/auth-context";
  import PartnerDokumente from "./PartnerDokumente";



  function Partnerseite() {

    const ColorTheme = 'var(--primary-color)';
  
    const navigate = useNavigate();
    const location = useLocation();

    // Kann mongo._id oder slug sein! Wird beides vom Backend erkannt!
    let { slug } = useParams();

    //const { partnerName } = useParams();
    //const decodedPartnerName = decodeURIComponent(partnerName); // Leerzeichen => %20, ...
  
    const { currentUser } = useContext(AuthContext);

    const [navigateBack, setNavigateBack] = useState("");

    // überprüfen, ob der Partner alles anbietet, wenn nicht array anpassen! (regelt z.B. Footer)
    const partnerActions = ["Anfrage/Dateien senden", "Dokumente", "Partner bewerten", "Problem melden"]; //, "VIN1-News"]; 
    const [selectedCat, setSelectedCat] = useState("");

    const [showModal, setShowModal] = useState(false);


    // =========================== PARTNER ermitteln ================================
    const [partner, setPartner] = useState({});
    /*
      partner_name: "Goldmountains Group",
      partnerIMG: "https://goldmountains.eu/wp-content/uploads/2019/03/Goldmountains_Group_Logo@4x-1024x366.png",
      bewertung: 5,
      kategorien: ["Angebote", "Aufträge", "Rechnungen", "Nutzungsrechte", "Sonstiges"],
      dateienSenden: false,
      dateienSendenKategorien: ["Test123", "2. Test"],
    });
    */

    // Lädt alle Abonnierten Partner...
    
    useEffect(() => {

      if(currentUser && currentUser.uid) {

        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + currentUser.accessToken
            },
            // von Params
            url: process.env.REACT_APP_BACKEND+"/partner/daten/"+slug,
        })
        .then(res => {
          setPartner(res.data);
        })
        .catch(error => {
          Swal.fire({
            "icon": "error",
            "title": "Fehler",
            "html": "Fehler beim Abrufen des Partners."
        });
        })
      }
    },[currentUser])
    



    // =========================== Problemfall ================================
    const [showProblemModal, setShowProblemModal] = useState(false);

    const [problem, setProblem] = useState("");

    const handleSaveProblem = () => {
        axios({
            method: "post",
            headers: {
                'Authorization': 'Bearer ' + currentUser.accessToken
              },
            url: process.env.REACT_APP_BACKEND+"/partner/vorfall",
            data: {
                partner_slug: partner.slug,
                nachricht: problem,
            }
        })
        .then(res => {
            Swal.fire({
                icon: "success",
                title: "Erfolg",
                html: "Dein Problemfall wurde erfolgreich übermittelt."
            })
            setShowProblemModal(false);
        })
        .catch(err => {
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Dein Problemfall konnte nicht gespeichert werden. Bitte versuche es später erneut. ", err
            })
        })
    }

    // =========================== Mitteilung ================================
    const [showMitteilungModal, setShowMitteilungModal] = useState(false);

    const [anlass, setAnlass] = useState("-");
    const [mitteilung, setMitteilung] = useState("");

    const handleSaveMitteilung = () => {
        axios({
            method: "post",
            headers: {
                'Authorization': 'Bearer ' + currentUser.accessToken
              },
            url: process.env.REACT_APP_BACKEND+"/partner/mitteilung",
            data: {
                partner_slug: partner.slug,
                anlass: anlass,
                nachricht: mitteilung,
                uid: currentUser.uid
            }
        })
        .then(res => {
            Swal.fire({
                icon: "success",
                title: "Erfolg",
                html: "Deine Mitteilung wurde erfolgreich übermittelt."
            })
            setShowMitteilungModal(false);
        })
        .catch(err => {
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Deine Mitteilung konnte nicht gespeichert werden. Bitte versuche es später erneut. ", err
            })
        })
    }

    // =========================== BEWERTUNG ================================
    const [showBewertungModal, setShowBewertungModal] = useState(false);
    const [rating, setRating] = useState(0);
    const [ratingOld, setRatingOld] = useState(0);
    
    const handleRating = (rate) => {
      setRating(rate)
      // other logic
    }
    
    const handleSaveBewertung = () => {

      if(rating == 0) {
          Swal.fire({
              icon: "info",
              title: "Bewertung",
              html: "Du musst vor dem Speichern eine Bewertung vornehmen."
          });
          return;
      }

      axios({
          method: "post",
          headers: {
              'Authorization': 'Bearer ' + currentUser.accessToken
            },
          url: process.env.REACT_APP_BACKEND+"/partner/bewertung", 
          data: {
              partner_slug: partner.slug,
              bewertung: rating
          } 
      })
      .then(res => {
          Swal.fire({
              icon: "success",
              title: "Erfolg",
              html: "Deine Bewertung wurde erfolgreich übermittelt."
          })
          setRatingOld(res.data.bewertung);
          setRating(0);
          setShowBewertungModal(false);
      })
      .catch(err => {
              
          if(err.response?.status == 409)
          {
              Swal.fire({
                  icon: "info",
                  title: "Bewertung",
                  html: "Du hast diesen Partner bereits bewertet."
              });
          } else {
              Swal.fire({
                  icon: "error",
                  title: "Fehler",
                  html: "Deine Bewertung konnte nicht gespeichert werden. Bitte versuche es später erneut."
              });
          }
          
      })
  }





    const handleQuickLink = (changedCategory) => {

      if(changedCategory == "Partner bewerten")
      {
        setShowBewertungModal(true);
        return;
      }
      if(changedCategory == "Mitteilung senden")
      {
        setShowMitteilungModal(true);
        return;
      }

      if(changedCategory == "Problem melden")
      {
        setShowProblemModal(true);
        return;
      }

      setSelectedCat(changedCategory);
      window.scrollTo({
        top: 0,
        behavior: "instant"
      });
    }


    useEffect(() => {

      if(currentUser && currentUser.uid && partner?.partner_name) {
        axios({
            method: "post",
            headers: {
                'Authorization': 'Bearer ' + currentUser.accessToken
              },
            url: process.env.REACT_APP_BACKEND+"/partner/meine-bewertung", 
            data: {
                partner_slug: partner.slug
            } 
        })
        .then(res => {
          if(res.data.bewertung && res.data.bewertung !== undefined) {
            setRatingOld(res.data.bewertung);
          }
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Fehler beim Abrufen deiner Bewertung."
          })
        })
      }

    },[currentUser, partner]);



    useEffect(() => {

      if(location.state) {
        if(location.state.category){
          setSelectedCat(location.state.category);
        }
      }
    
      // Rücknavigation je nach Herkunft
      if(location.state?.category == "Dateien senden")
      { 
        setNavigateBack("/connect");
      }
      else if(location.state?.category == "Dokumente")
      { 
        setNavigateBack("/dokumente");
      }
      else
      {
        setNavigateBack("/dokumente/");
      }
      

    }, [location.state])
  
  
  
    return (
      <>
        <Header url={navigateBack} />

          <Container style={{maxWidth: "800px"}}>
            <Row style={{justifyContent: "center"}}>
                <Col style={{textAlign: "center"}}>
                    {/*<img src={require("../../img/VIN1_Logo_Kreis.png")} alt="" style={{maxWidth: "160px", margin: "30px 0px"}} />*/}
                    {/*<p className="text-center">In diesem Bereich findest du alle Aktionen die VIN1 bereitstellt.</p>*/}
                </Col>
            </Row>
          </Container>

          <Container style={{maxWidth: "800px"}}>

            <Row style={{justifyContent: "center"}}>

              <Col xs={12} className="d-flex justify-content-center">
                  {partner?.partnerIMG && 
                      <img src={partner.partnerIMG} className="mt-3" style={{width: "100%", maxWidth: "300px", objectFit: "contain"}} />
                  }
              </Col>

              <Col>
                  <h1 style={{color: "#520da7", margin: "0px"}}>{partner?.partner_name}</h1><br />
                  <p className="text-center">
                      In diesem Bereich findest du alles von Partner {partner ? partner.partner_name : <i>undefined</i>}.
                  </p>      
              </Col>
            </Row>


            <Row style={{justifyContent: "center"}}>

              {!selectedCat &&
                <Col>
                  <h5 className="text-center">
                    Was möchtest du tun?
                  </h5>      
                </Col>
              }

              {!selectedCat &&
                <Row className="justify-content-center" style={{marginBottom: "50px", marginTop: "30px"}}>
                  {partnerActions.map((kategorie) =>
                      <Col
                        xs={12}
                        sm={6}
                        md={4}
                        key={kategorie}
                        className="d-flex justify-content-center align-items-center"
                        style={{width: "100%", margin: "8px"}}
                      
                      >
                        <div
                          onClick={() => handleQuickLink(kategorie)}
                          className="d-flex justify-content-between align-items-center"
                          style={{width: "100%", maxWidth: "500px", border: "2px solid #520ca799", borderRadius: "8px", padding: "8px 10px", cursor: "pointer"}}
                        >
                          {selectedCat === kategorie &&
                            <p style={{maxWidth: "fit-content", color: ColorTheme, margin: "0px"}} className="active text-left">{kategorie}</p>
                          }
                          {selectedCat !== kategorie &&
                            <p style={{maxWidth: "fit-content", color: ColorTheme, margin: "0px"}}>{kategorie}</p>
                          }
                          <FontAwesomeIcon style={{ color: ColorTheme, paddingRight: "10px"}} icon={faChevronRight} size="1x" />
                        </div>
                        
                      </Col>
                    )}
                </Row>
              }
              {selectedCat &&
                <Row className="d-flex justify-content-center justify-content-md-between align-items-center mb-2 mb-md-5 pt-md-5">
                  
                  <Col sm={12} md={4} className="text-center py-4 py-md-0">
                    <button
                      className="px-2.5 py-1.5 rounded-md bg-vinColor text-white border-none hover:text-red-500"
                      onClick={() => setSelectedCat(null)}
                    >
                      <FontAwesomeIcon className={style.icon} icon={faChevronLeft} />
                      &nbsp;andere Aktion wählen
                    </button>
                  </Col>

                  <Col sm={12} md={4}>
                    <h5 className="text-center">
                      {selectedCat}
                    </h5>
                  </Col>

                  <Col sm={0} md={4}>
                    {" "}
                  </Col>

                </Row>
              }
              

            </Row>



  
            <Row className="mb-5">
    

              {/* Anfrage senden */}
              {(selectedCat == "Anfrage/Dateien senden" && partner?.dateienSenden) && <PartnerAnfrageSenden partner={partner} />}
              {(selectedCat == "Anfrage/Dateien senden" && !partner?.dateienSenden) &&
                <Col className="text-center">
                  <h5 className="mt-3 text-center">Der Bereich "Anfrage/Dateien senden" wird von {partner?.partner_name} aktuell nicht angeboten.</h5>
                </Col>
              }

              {/* Dokumente */}
              {(selectedCat == "Dokumente" && partner?.kategorienFreischalten) && <PartnerDokumente partner={partner} />}
              {(selectedCat == "Dokumente" && !partner?.kategorienFreischalten) && 
                <Col className="text-center">
                  <h5 className="mt-3 text-center">Der Bereich "Dokumente" wird von {partner.partner_name} aktuell nicht angeboten.</h5>
                </Col>
              }


            </Row>


            {/*
            <Container style={{maxWidth: "fit-content"}}>

              <Row className="justify-content-center" style={{marginBottom: "50px", marginTop: "150px"}}>
              
                <p className="text-center">Du willst eine andere Aktion von <b>{partner?.partner_name}</b> nutzen?
                  <br />Klicke auf einen der nachfolgenden Links.
                </p>

                {partnerActions.map((kategorie) =>
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      key={kategorie}
                      className="text-center d-flex justify-content-center"
                      style={{padding: "10px"}}
                      onClick={() => handleQuickLink(kategorie)}
                    >
                        {selectedCat === kategorie &&
                          <p style={{maxWidth: "fit-content", color: "var(--primary-color)"}} className="active">{kategorie}</p>
                        }
                        {selectedCat !== kategorie &&
                          <p style={{maxWidth: "fit-content", color: "var(--primary-color)"}}>{kategorie}</p>
                        }
                      
                    </Col>
                  )}
                </Row>
              </Container>
            */}



            {(showBewertungModal && partner) &&  
              <CustomModal 
                  title={"Bewertung - "+partner.partner_name}
                  visible={showBewertungModal}
                  setVisible={setShowBewertungModal}
                  handleSave={handleSaveBewertung}
                  saveText={"Absenden"}
              > 
                <p>Jetzt Partner bewerten. Du kannst halbe und ganze Sterne vergeben.</p>
                <Rating
                  onClick={handleRating}
                  allowFraction={true} //allow half Star Rating
                  initialValue={ratingOld !== 0 ? ratingOld : null}
                  //onPointerEnter={onPointerEnter}
                  //onPointerLeave={onPointerLeave}
                  //onPointerMove={onPointerMove}
                />
                {ratingOld !== 0 && <p className="mt-1">Deine aktuelle Bewertung: {ratingOld}/5</p>}
                {rating !== 0 && <p className="mt-1">Deine neue Bewertung: {rating}/5</p>}
                {ratingOld !== 0 && <p className="mt-3">Du kannst deine Bewertung durch klicken auf die Sterne anpassen.</p>}
               
              </CustomModal>
            }

            {/*(showMitteilungModal && partner) && 
              <CustomModal 
                  title={"Mitteilung - "+partner.partner_name}
                  visible={showMitteilungModal}
                  setVisible={setShowMitteilungModal}
                  handleSave={handleSaveMitteilung}
                  saveText={"Absenden"}
              > 
                  <p>
                    Du hast Fragen an den Partner? Schreibe jetzt eine Mitteilung. Der Partner wird sich wenn nötig mit Dir in Verbindung setzen.
                  </p>

                  <Form.Label>Anlass</Form.Label>
                  <Form.Group className="mb-3">
                    <select
                      className="form-select"
                      value={anlass}
                      onChange={(e) => setAnlass(e.target.value)}
                    >
                      <option value={"-"} disabled>-</option>
                      <option value={"Auftragsanfrage"} key={0}>Auftragsanfrage</option>
                      <option value={"Rückruf"} key={1}>Rückruf</option>
                    </select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                      <Form.Label>Mitteilung</Form.Label>
                      <Form.Control as="textarea" rows={5}
                          placeholder="Mitteilung"
                          name="nachricht"
                          value={mitteilung}
                          onChange={(e) => setMitteilung(e.target.value)}
                      />
                  </Form.Group>
              </CustomModal>
          */}


            {(setShowProblemModal && partner) && 
              <CustomModal 
                  title={"Problemfall schildern - "+partner.partner_name}
                  visible={showProblemModal}
                  setVisible={setShowProblemModal}
                  handleSave={handleSaveProblem}
              > 
                  <p>Du hast ein Problem mit diesem Partner? Schreibe uns eine Mitteilung. Unsere Clearing-Stelle wird sich um dein Problem kümmern.
                      Der Partner erfährt nicht deinen Namen.
                  </p>
                  <Form.Group className="mb-3">
                      <Form.Label>Problemfall Beschreibung</Form.Label>
                      <Form.Control as="textarea" rows={4}
                          placeholder="Problemfall beschreiben"
                          name="nachricht"
                          value={problem}
                          onChange={(e) => setProblem(e.target.value)}
                      />
                  </Form.Group>
              </CustomModal>
            }

  

  
  
          {/*
            <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
              <Modal.Header closeButton>
                <Modal.Title>Connecte dich mit einem Partner</Modal.Title>
              </Modal.Header>
              <Modal.Body>

              </Modal.Body>
              <Modal.Footer>
                <p
                  style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                  onClick={() => setShowModal(!showModal)}
                  >
                  Schließen
                </p>
                <p
                  style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                  onClick={() => setShowModal(!showModal)}
                  >
                  Connecten
                </p>
              </Modal.Footer>
            </Modal>
          */}
  
  
        </Container>
      </>
    )
  }
  
  export default Partnerseite;