import React, { useState, useEffect, useContext } from 'react'
import Header from '../Header/Header'

import { useNavigate, useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import axios from "axios";
import Swal from 'sweetalert2';

import GewinnspielCard from './GewinnspielCard';
import GewinnspielCardPreview from './GewinnspielCardPreview';
import GewinnspielCardOld from './GewinnspielCardOld';


//import GewinnspielCardOld from './GewinnspielCardOld';

import { UserContext } from '../../store/user-context';
import { AuthContext } from '../../store/auth-context';
import GewinnspielGewinne from './GewinnspielGewinne';
import { Spinner } from 'react-bootstrap';


function Gewinnspiel() {

  const { user } = useContext(UserContext);
  const { currentUser } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const [gewinnspiele, setGewinnspiele] = useState([]);
  const [teilgenommen, setTeilgenommen] = useState([]);
  const [countLaufendeSpiele, setCountLaufendeSpiele] = useState(0);
  const [showCurrentGewinnspiele, setShowCurrentGewinnspiele] = useState(true);


  useEffect(() => {

    // Gewinnspiele abrufen VIN
    async function loadGewinnspieleVIN() {

      const response = await axios.get("https://api.vin1.eu/gewinnspiele/gewinnspiele.php?AUTH=bv8FnNYM6JU6Uk7QE8ujvM6S6WMVpg");
      
        const gewinnspieleAPI = [];
        for (const key in response.data) {
          const gewinnspielObj = {
              id: response.data[key].ID,
              title: response.data[key].TITEL,
              image: response.data[key].IMG_B64 ? response.data[key].IMG_B64 : null,
              text:  response.data[key].NACHRICHT,
              publishDate: response.data[key].DATUM,
              startdatum: response.data[key].STARTDATUM,
              enddatum: response.data[key].ENDDATUM,
              url: response.data[key].URL,
              pdf: response.data[key].PDF_B64,
              teilgenommen: 0
          };

          
          if(new Date(response.data[key].ENDDATUM) >= new Date().getTime())
            setCountLaufendeSpiele(prevState => (prevState+1));

          gewinnspieleAPI.push(gewinnspielObj);
        }
        return gewinnspieleAPI;
      
    }

    async function loadTeilgenommen() {
      
      var bodyFormData = new FormData();
      bodyFormData.append('kundennummer', user.kdnr);
      bodyFormData.append("AUTH", "bv8FnNYM6JU6Uk7QE8ujvM6S6WMVpg");

      const response = await axios.post("https://api.vin1.eu/gewinnspiele/gewinnspielTeilgenommen.php", bodyFormData);
      return response.data.IDs;
    }



    async function loadGewinnspiele() {

      // Gewinnspiele inkl. Teilnahmen an Gewinnspielen!
      // return axios, sonst wird nicht gewartet..
      return axios({
        method: "get",
        headers: {
          Authorization: "Bearer " + currentUser.accessToken,
        },
        url: process.env.REACT_APP_BACKEND + "/my-vin1/gewinnspiele",
      })
        .then(res => {
      
          const gewinnspieleAPI = [];
          //for (const key in res.data) {
          res.data.forEach(g => {
            gewinnspieleAPI.push({
                id: g._id,
                title: g.title,
                image: g.imageUrl ? g.imageUrl : null,
                text:  g.message,
                publishDate: g.publishDate,
                startdatum: new Date(g.startDate),
                enddatum: new Date(g.endDate),
                url: null, // gibt es nicht mehr
                pdf: g.pdfFileUrl,
                teilgenommen: g.teilgenommen
            });

            if(new Date(g.endDate).getTime() >= new Date().getTime())
              setCountLaufendeSpiele(prevState => (prevState+1));
          });

       


          return gewinnspieleAPI;
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Fehler beim Abrufen der Gewinne.",
          });
        });
      
    }
      
    

  
    async function getAll() {

      setIsLoading(true);

      let gewinnspieleMongo=[];
      if(currentUser && currentUser.accessToken) {
        // Partner Gewinnspiele abrufen
        gewinnspieleMongo = await loadGewinnspiele();
      }


      // VIN1 Gewinnspiele ("Alt")
      const gewinnspieleVIN = await loadGewinnspieleVIN();
      const teilgenommenIDs = await loadTeilgenommen();
      
      teilgenommenIDs.forEach((item, index) => {

        gewinnspieleVIN.filter( g => {
          if(g.id === item){
            g.teilgenommen = 1;
          }
        }); 
      });
      
      setGewinnspiele([...gewinnspieleVIN, ...gewinnspieleMongo]);
      setIsLoading(false);
    }

    getAll();
    
  }, [currentUser])

 



    async function handleGewinnspiel(id) {

      // Gewinnspiele VIN1
      if(id.length !== 24)
      {

        var bodyFormData = new FormData();
        bodyFormData.append("gewinnspielid", id);
        bodyFormData.append('kundennummer', user.kdnr);
        bodyFormData.append('vorname', user.vorname);
        bodyFormData.append('nachname', user.nachname);
        bodyFormData.append("AUTH", "bv8FnNYM6JU6Uk7QE8ujvM6S6WMVpg");

        let result = "";
        try {
          
          result = await axios({
            method: "post",
            url: "https://api.vin1.eu/gewinnspiele/teilnehmen.php",
            data: bodyFormData,
          })
        }
        catch (err) {
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Es ist ein Fehler bei der Übertragung aufgetreten. Versuche es später erneut."
          });
        }

        if(result.data.status === "time-error")
        {
          Swal.fire({
            icon: "info",
            title: "Teilnahme nicht mehr möglich",
            html: "Die Zeit zur Teilnahme am Gewinnspiel ist leider abgelaufen."
          });
        }
        
        if(result.data.status === "error-update" || result.data.status === "error-insert")
        {
          Swal.fire({
            icon: "error",
            title: "Gewinnspielteilnahme",
            html: "Es ist ein Fehler bei der Teilnahmeregistrierung aufgetreten.<br />Versuche es später erneut."
          });
        }

        if(result.data.status === "false" || result.data.status === "error-insert")
        {
          Swal.fire({
            icon: "error",
            title: "Gewinnspielteilnahme",
            html: "Es ist ein Fehler bei der Teilnahmeregistrierung aufgetreten.<br />Versuche es später erneut."
          });
        }

        if(result.data.status === "exists" || result.data.status === "true")
        {
          Swal.fire({
            icon: "success",
            title: "Gewinnspielteilnahme",
            html: "Du nimmst ab jetzt am Gewinnspiel teil.<br />Viel Glück!"
          });
        }
        
        setGewinnspiele([...gewinnspiele], gewinnspiele.filter( g => {
          if(g.id === id){
            g.teilgenommen = 1;
          }
        }));


      } 
      else
      {
        // Neue Gewinnspiele
        if(!currentUser.accessToken) {
          Swal.fire({
            icon: 'error',
            title: 'Fehler',
            html: 'Du bist nicht Authentifiziert.'
          });
          return;
        }

        axios({
          method: "post",
          headers: {
            Authorization: "Bearer " + currentUser.accessToken,
          },
          url: process.env.REACT_APP_BACKEND + "/my-vin1/gewinnspiel-teilnehmen",
          data: {
            gewinnspielId: id
          }
        })
        .then(res => {
        
            if(res.data.status === "time-error-end")
            {
              Swal.fire({
                icon: "info",
                title: "Teilnahme nicht möglich",
                html: "Die Zeit zur Teilnahme am Gewinnspiel ist leider abgelaufen."
              });
              return;
            }
            else if(res.data.status === "time-error-start")
            {
              Swal.fire({
                icon: "info",
                title: "Teilnahme nicht möglich",
                html: "Die Zeit zur Teilnahme am Gewinnspiel ist leider abgelaufen."
              });
              return;
            }
          
          Swal.fire({
            icon: "success",
            title: "Gewinnspielteilnahme",
            html: "Du nimmst ab jetzt am Gewinnspiel teil.<br />Viel Glück!"
          });


          setGewinnspiele([...gewinnspiele], gewinnspiele.filter( g => {
            if(g.id === id){
              g.teilgenommen = 1;
            }
          }));

        
  
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Fehler",
            html: "Fehler beim Teilnehmen am Gewinnspiel.",
          });
        });

      }
        
    }
  

  return (
    <>
      <Header />
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col className="text-center">
          <img
              src={require("../../img/Gewinnspiel.png")}
              alt=""
              style={{ maxWidth: "120px", marginTop: "30px"}}
            />
            <h1>Dein ganz persönlicher Gewinnspielbereich</h1>
            <br />
            <p>
              Nimm kostenlos an unseren Gewinnspielen teil, indem Du beim jeweiligen Gewinnspiel auf den Button "Jetzt teilnehmen" klickst.
            </p>
          </Col>
        </Row>

        <Row>
          <GewinnspielGewinne />
        </Row>
        
        <br />

        <p className="text-center">Welche Gewinnspiele sollen angezeigt werden?</p>

        <Row style={{ justifyContent: "center" }}>
          <Col
            style={{
              marginBottom: "30px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {showCurrentGewinnspiele && (
              <>
                <Button
                  onClick={() => setShowCurrentGewinnspiele(true)}
                  style={{
                    background: "green",
                    border: "none",
                    marginRight: "15px",
                  }}
                >
                  Aktuelle Gewinnspiele
                </Button>
                <Button
                  onClick={() => setShowCurrentGewinnspiele(false)}
                  style={{
                    background: "none",
                    color: "#520ca7aa",
                    border: "2px solid #520ca7aa",
                  }}
                >
                  Abgelaufene Gewinnspiele
                </Button>
              </>
            )}

            {!showCurrentGewinnspiele && (
              <>
                <Button
                  onClick={() => setShowCurrentGewinnspiele(true)}
                  style={{
                    background: "none",
                    color: "#520ca7aa",
                    border: "2px solid #520ca7aa",
                    marginRight: "15px",
                  }}
                >
                  Aktuelle Gewinnspiele
                </Button>
                <Button
                  onClick={() => setShowCurrentGewinnspiele(false)}
                  style={{ background: "green", border: "none" }}
                >
                  Abgelaufene Gewinnspiele
                </Button>
              </>
            )}
          </Col>
        </Row>


        {isLoading &&
          <Row className="d-flex justify-content-center">
            <Col xs={12} className="d-flex justify-content-center">
              <Spinner />
            </Col>
            <Col xs={12} className="mt-3 d-flex justify-content-center">
              <p>Lade Gewinnspiele</p>
            </Col>
          </Row>
        }

        

        { /* ========== Wenn Auswahl "Aktuelle Gewinnspiele" ========== */}
        {!isLoading && showCurrentGewinnspiele &&
          <>
          
            {gewinnspiele.length > 0 && gewinnspiele.map(gewinnspiel => 
                
                (new Date(gewinnspiel.enddatum) >= new Date().getTime()) ?
                  (new Date(gewinnspiel.startdatum) >= new Date().getTime()) ?
                    <GewinnspielCardPreview key={gewinnspiel.id} data={gewinnspiel} /> 
                    :
                    <GewinnspielCard key={gewinnspiel.id} data={gewinnspiel} handleGewinnspiel={handleGewinnspiel} /> 
                :  null 
            )}


            {!isLoading && countLaufendeSpiele === 0 &&
              <p className='text-center'><i>Momentan findet kein Gewinnspiel statt. Schaue später wieder vorbei.</i></p>
            }
          </>
        }

        { /* ========== Wenn Auswahl "Abgelaufene Gewinnspiele" ========== */}
        {!isLoading && !showCurrentGewinnspiele && 
          <>
            {gewinnspiele.length > 0 && gewinnspiele.map((gewinnspiel) => 
                (new Date(gewinnspiel.enddatum) <= new Date().getTime()) ?
                  <GewinnspielCardOld key={gewinnspiel.id} data={gewinnspiel} /> 
                  : null 
            )}
          </>
        }






    {/*
          <br /><br />
      

        <h5 className="text-center">Abgelaufene Gewinnspiele</h5>

        {gewinnspiele.map((gewinnspiel) =>  
          (new Date(gewinnspiel.enddatum).getTime() < new Date().getTime()) ?
            (
              <GewinnspielCardOld key={gewinnspiel.id} data={gewinnspiel} text={gewinnspiel.text} />
            )
            : null
        )}
      
      */}

      <Row style={{marginBottom:"80px"}}></Row>
      
      </Container>
    </>
  )
}

export default Gewinnspiel