import React, {
  useState,
  useEffect,
  useContext,
} from "react";

import { useNavigate } from "react-router-dom";

//import Card from "../Card/Card";
import CardDoppelt from "../Card/CardDoppelt";

import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";
import PDFViewerModal from "../Bausteine/PDFViewerModal/PDFViewerModal";

import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti'
import GewinnModal from '../Bausteine/GewinnModal'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserAlt,
  faSignOutAlt,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";

import style from "./Home.module.css";

import { db } from "../../firebase";
import { getAuth, signOut } from "firebase/auth";
import { updateDoc, doc, collection, getDocs } from "firebase/firestore";
//import { serverTimestamp } from "firebase/firestore";

import { Container, Row, Col, Button, Card } from "react-bootstrap";

import Swal from "sweetalert2";
import { AuthContext } from "../../store/auth-context";
import { UserContext } from "../../store/user-context";
import WebpImage from "../Bausteine/WebpImage";

import { formatDateTime } from '../../util/date/dates'
import HeaderMobileHome from "../Header/HeaderMobileHome";
import CardMobileHome from "../Card/CardMobileHome";

//wie Style CardDoppelt, nur hier einzeln für Verbesserungsvorschläge importiert.
import styleCardDoppelt from "../Card/Card.module.css"



function HomeMobil() {

  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);
  const { user, updateUser, unreadDocs } = useContext(UserContext);

  // ====== Gewinnspiel ======
  const { width, height } = useWindowSize()
  const [gewinne, setGewinne] = useState(false);
  const [gewinnModal, setGewinnModal] = useState(true);

  /*
  useEffect(() => {
    
    if(showConfetti) {
      const timeout = setTimeout(() => {
        setShowConfetti(false);
      }, 10000);

      return () => clearTimeout(timeout); 
    }
  }, [showConfetti]);
  */



  useEffect(() => {

    async function getGewinne() {
      const colRef = collection(db, "Users", currentUser.uid, "Gewinne");
      const Gewinne = await getDocs(colRef);

      let DocumentsArr = [];
      Gewinne.forEach(doc => {
        if(!doc.data().nichtMehrAnzeigen || !doc.data().gelesen) {
          DocumentsArr.push({
            id: doc.id,
            data: doc.data()}
          );
        }
      });
      setGewinne(DocumentsArr);
    }

    if(currentUser?.uid) {
      getGewinne();
    }

  }, [currentUser]);

  // ==========================

  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState(false);

  const handleExternalURL = (url) => {
    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
  };

  function handleSignOut() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        updateUser({});
        navigate("/login");
      })
      .catch((error) => {
        Swal.fire({
          title: "Fehler beim Logout",
          text: "Klicke erneut auf Logout um dich abzumelden.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  }


  const handleGewinnspielModalNichtErneutZeigen = (gewinnId) => {
  
    // update the document
    let indexGewinn = gewinne.findIndex((item) => item.id == gewinnId)

    if (indexGewinn !== -1) {

      let fieldsToUpdate = {
        gelesen: true,
        gelesenAm: new Date(),
        nichtMehrAnzeigen: true
      }

      const docRef = doc(db, "Users", currentUser.uid, "Gewinne", gewinnId);
    
      // write back the entire 'user' object
      updateDoc(docRef, fieldsToUpdate, { merge: true })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Gewinn',
          html: 'Dein Gewinn wird dir weiterhin im Bereich "Gewinnspiele" angezeigt.'
        });

        //update gewinne array!
        // Create a copy of the array to avoid modifying the original state directly
        const newData = [...gewinne];
        // Use splice to remove the element at the found index
        newData.splice(indexGewinn, 1);
        // Update the state with the modified array
        setGewinne(newData);
        setGewinnModal(false);    

      })
      .catch((err) => {
        console.log("Error updating document: ", err);
      });
    } else {
      console.log("Gewinn Eintrag mit ID nicht gefunden.");
    }

  }


  return (
    <>
      <HeaderMobileHome />
      {gewinne && gewinne.length >= 1 && 
        <>
          <Confetti
            width={width}
            height={height}
          />

          {gewinne.map(gewinn => 

            <GewinnModal
              key={gewinn.id}
              title={(gewinn.data.partner_name ? gewinn.data.partner_name : "VIN1")+"-Gewinnspiel: Du hast gewonnen!"}
              visible={gewinnModal}
              setVisible={() => {
                setGewinnModal(false);
              }}
            > 
              <p>
                Herzlichen Glückwunsch!<br />
                Du hast am Gewinnspiel: 
                <b> {gewinn.data.title}</b> vom&nbsp;
                  {gewinn.data.enddate?.seconds ?
                    formatDateTime(gewinn.data.enddate.seconds*1000)
                    : "Datum fehlerhaft"
                  }
                &nbsp;gewonnen!
              </p>

              <hr />
              <h6 style={{fontWeight: "bold"}}>Gewinn</h6>
              <p>{gewinn.data.message}</p>

              <hr />
              <h6 style={{fontWeight: "bold"}}>Was passiert als nächstes?</h6>
              <p>
                Der Gewinnspielveranstalter meldet sich spätestens in den nächsten Tagen bei dir, um dir den Gewinn zu übergeben.
                Du kannst dieses Fenster schließen. Deine Gewinne werden dir weiterhin im Bereich Gewinnspiele angezeigt.
              </p>

        
              <div className="d-flex justify-content-end">
                {/* Bleibt die ID hier gleich?  WIrd eingetragen in FB UID doc in Array */}
                <Button
                  onClick={() => handleGewinnspielModalNichtErneutZeigen(gewinn.id)} 
                >
                  Nicht erneut anzeigen
                </Button>
                <Button
                    onClick={() => {
                      setGewinnModal(false);
                    }}
                    variant={"danger"}
                    style={{marginLeft: "12px"}}
                  >
                    Schließen
                </Button>

              </div>
            </GewinnModal>

          )}

        </>
      }


      <Container className={style.containerKacheln} style={{ padding: 0 }}>
      
        <Row className={style.rowContainer}>
          <Col>
            <p
              style={{
                width: "100%",
                textAlign: "center",
                fontFamily: "'Poppins', sans-serif",
                fontSize: "20px",
                color: "grey",
                margin: "50px 0px 0px 0px",
              }}
            >
              smart. digital. nachhaltig.
            </p>

            <p
              className="text-vinColor"
              style={{
               width: "100%",
               textAlign: "center",
               fontFamily: "'Mouly', sans-serif",
               fontSize: "40px",
               margin: "10px 0px 0px 0px"
            }}>
              Mach dein VIN Ding!
            </p>
          </Col>
        </Row>
        

        <Row className="mt-5">
          <Col style={{ textAlign: "center" }}>
            <p className={style.boldText}>
              Hallo{user.vorname ? " " + user.vorname + "," : ", "} vintastisch
              Dich hier zu sehen!
            </p>
          </Col>
        </Row>



        {/*
        <Row className="mt-5">
          <Col style={{ textAlign: "center"}}>
            <h5>&#45; Connecte dich mit der Welt &#45;</h5>
          </Col>
        </Row>
        */}

        <Container style={{maxWidth: "800px"}}>
          <Row style={{ marginTop: "16px", justifyContent: "center" }}>
            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
              <CardMobileHome url={"connect"} img={"Connect.png"} title={"Connect"} />
            </Col>
            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
              <CardMobileHome url={"vorteile"} img={"Vorteilspartner.png"} title={"Vorteilswelt"} />
            </Col>

            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
              <CardMobileHome img={"Mobil.png"} title={"KFZ-Rechner"}
                 externalURL={"https://hello.friday.de/quote/selectPrecondition?partnerId=VINCAR01"}
              />
            </Col>


            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
              <CardMobileHome img={"Finanzen.png"} title={"Kreditrechner"}
                externalURL={"https://forms.vin1.eu/kreditrechner.html"}
              />
            </Col>
            

            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
              <CardMobileHome img={"Baufinanzierung.png"} title={"Baufinanzierung"}
                externalURL={"https://forms.vin1.eu/baufinanzierung.html"}
              />
            </Col>
            
            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
              <CardMobileHome url={"gewinnspiel"} img={"Gewinnspiel.png"} title={"Gewinnspiele"} />
            </Col>
            
          </Row>
        </Container>

      


        <Row style={{ marginTop: "20px", justifyContent: "center" }}>
          <Col xs={6} md={4} style={{ marginTop: "30px" }}>
              <CardMobileHome url={"verbesserungen"} img={"Verbesserungen.png"} title={"VIN1 Verbesserungen"} />
          </Col>
        </Row>

      

      </Container>

      <br />

      {showModal == "aefdsf" && <WebViewModal url={modalUrl} hideModal={setShowModal} />}
      {!showModal == "wdfsdg" && <PDFViewerModal filename={"sample.pdf"} hideModal={setShowModal} />}

    </>
  );
}

export default HomeMobil;
