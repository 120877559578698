import React, { useState, useContext } from 'react'

import style from "./GewinnCard.module.css"
import classLister from 'css-module-class-lister';

import { Row, Col, Button }  from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons"

import CustomModal from "../Bausteine/CustomModal";

import{ formatDateTime } from '../../util/date/dates'



function GewinnCard(props) {

  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const classes = classLister(style);



  return (
    <>
   
      <div className={style.cardContainerUpload} style={{marginBottom: "20px"}}>
        <Row style={{width:"100%"}}>
           <Col sm={12} md={6}>
                <p className="mt-2 fw-bold" style={{marginBottom: "5px", textAlign: "left"}}>{props.data.title}</p>
                <p style={{fontSize: "100%"}}>Gewinnspiel Ende: {formatDateTime(props.data.endDate)} </p>
            </Col>
            <Col style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                <Button
                    className="m-1"
                    style={{cursor: "pointer", background: "#520ca7", maxHeight:"40px"}}
                    onClick={() => setShowDetailsModal(true)}
                >
                    Mehr Infos
                </Button>
            </Col>
        </Row>
      </div>

      {showDetailsModal && 
        <CustomModal 
            title={"Gewinnspiel Details - "+props.data.title}
            visible={showDetailsModal}
            setVisible={setShowDetailsModal}
        >
        <div class="mb-3 d-flex justify-content-center">
            <img src={props.data.imageUrl} style={{width: "200px", background: "grey"}} />
        </div>
        <h5>Gewinnspiel: {props.data.title}</h5>
        <p>Beschreibung: <br />{props.data.message}</p>

        <hr />

        <p>Gewinn-Status: <br />
            {!props.data.gewinnAusgeliefert && "Dein Gewinn wird vom Partner vorbereitet."}
            {props.data.gewinnAusgeliefert && "Dein Gewinn ist unterwegs zu dir."}
        </p>
        <p>Hinweis: <br />
            Wir prüfen laufend, ob Gewinne in angemessener Zeit bereitgestellt werden. Andernfalls nehmen wir Kontakt zum Partner oder zu dir auf. Danke für deine Mithilfe, indem du
            uns den Gewinn erhalt an dieser Stelle quittierst, wenn es soweit ist. Vielen Dank für deine Mithilfe.
        </p>

        <Row className="d-flex justify-content-end m-2">
            {!props.data.gewinnErhalten &&
                <Button
                    className="mt-1 w-auto"
                    variant={"success"}
                    style={{cursor: "pointer", maxHeight:"40px"}}
                    onClick={() => props.handleGewinnErhalten(props.data._id)}
                >
                    Gewinn als erhalten markieren
                </Button>
            }
            {props.data.gewinnErhalten &&
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon style={{color: "green", paddingRight: "8px"}} icon={faCircleCheck} size="lg" />
                    <span>Gewinn erhalten, bestätigt {props.data.gewinnErhaltenAm && " - "+formatDateTime(new Date(props.data.gewinnErhaltenAm))}</span>
                </div>
            }
        </Row>
      
        </CustomModal>
      }


    </>
  )
}

export default GewinnCard
