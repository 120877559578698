import React, { useState, useEffect, useContext } from 'react'
import Header from '../../Header/Header'

import { useLocation } from 'react-router-dom'

import CardSmall from "../../Card/CardSmall"
import CardSub from "../../Card/CardSub"
import CardDokument from "../../Card/CardDokument";
import CardUpload from "../../Card/CardUpload"

import axios from "axios";
import IMGViewModal from '../../Bausteine/IMGViewModal/IMGViewModal'

import { Container, Row, Col } from 'react-bootstrap';

import { ref, uploadBytesResumable, getDownloadURL, uploadTask, deleteObject } from "@firebase/storage";
import { db, storage } from "../../../firebase";
import { collection, doc, addDoc, getDoc, setDoc, getDocs, deleteDoc, query, where, onSnapshot, orderBy } from "firebase/firestore"; 
import Swal from 'sweetalert2'
import { makeID } from '../../../util/makeID';
import { AuthContext } from '../../../store/auth-context'


function DokuKredite() {

  const ColorTheme = "#0B3499";

  const cards = [
    {
      img: "MobilKredit.png",
      title: "Mobil-Kredit",
    },
    {
      img: "Baufinanzierung.png",
      title: "Baufinanzierung",
    },
    {
      img: "Verbraucherkredit.png",
      title: "Verbraucherkredit",
    }
  ]

  const { currentUser } = useContext(AuthContext);

   // Preview Modal JPG + PNG
   const [showModal, setShowModal] = useState(false);
   const [modalTitle, setModalTitle] = useState("");
   const [modalImgSrc, setModalImgSrc] = useState("");
  
   const location = useLocation();
   const navigateBack = location?.state?.navigateBack ? location.state.navigateBack : "/partner/vin1";
   const url_state = location?.state?.navigateBack ? {category: "Dokumente"} : {};
  
    const [categoryTwo, setCategoryTwo] = useState("");
    //const [categoryThree, setCategoryThree] = useState("");
  
    function handleCategory(categoryTwo) {
      setCategoryTwo(categoryTwo);
    }
  
    // Momentan keine Subcategory...
    /*
    function handleSubCategory(categoryThree) {
      setCategoryThree(categoryThree);
    }
    */
  
    const [pickedFiles, setPickedFiles] = useState([]);
    const [privateDocs, setPrivateDocs] = useState([]);
  
    function removeFileUploadHandler (id) {
      //console.log("remove: " + name);
      setPickedFiles(pickedFiles.filter(obj => obj.id !== id));
    }
  
    function handleFile(e) {
  
        setPickedFiles([...pickedFiles, {
          id: makeID(8),
          file: e.target.files[0],
          name: e.target.files[0].name,
          size: e.target.files[0].size,
          type: e.target.files[0].type,
          path: e.target.value
        }
      ]);
    }
  
  
    function handleFileUpload() {
  
      if(pickedFiles.length >=1) {
  
        for (let i = 0; i < pickedFiles.length; i++) {
  
          // Upload Resumable....
          let UUID = crypto.randomUUID();
          //const storageRef = ref(storage, "/"+auth.currentUser.uid+"/"+UUID+pickedFiles[i].name);
          const storageRef = ref(storage, "/"+currentUser.uid+"/"+UUID+pickedFiles[i].name);
          const uploadTask = uploadBytesResumable(storageRef, pickedFiles[i].file);

          uploadTask.on("state_changed", (snapshot) => {
              //const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              //setProgress(progress); 
          },
          (error) => {
            console.log("Error: " +error);
            //https://firebase.google.com/docs/storage/web/upload-files
          },
          () => {
              // Hier kann tein Callback definiert werden, nach erfolgreichem Upload
              // Erstellen des "Meta-Dokuments zum Upload"
              getDownloadURL(uploadTask.snapshot.ref)
              .then((url) => {

                //addDoc(collection(db, "Users", auth.currentUser.uid, "Immobilien"), {     
                addDoc(collection(db, "Users", currentUser.uid, "Dokumente"), {     
                  title: UUID+pickedFiles[i].name,
                  description: "",
                  uploader: "self",
                  category: "Kredite",
                  categoryTwo: categoryTwo,
                  created: new Date(),
                  mimeType: pickedFiles[i].type,
                  size: pickedFiles[i].size,
                  url: url
                })
                .then((docRef) => {
                  // Mit Snapshot Listener dürfen die Docs nicht manuell angepasst werden, weil dann doppelt.
                  /*setPrivateDocs([
                    {
                      id: docRef.id,
                      data: {
                        title: UUID+pickedFiles[i].name,
                        description: "",
                        uploader: "self",
                        category: "Kredite",
                        categoryTwo: categoryTwo,
                        created: new Date(),
                        mimeType: pickedFiles[i].type,
                        size: pickedFiles[i].size,
                        url: url
                      }
                    },
                    ...privateDocs
                  ])
                  */
                })
                .catch((error) => {
                  //console.log(error);
                  Swal.fire({
                    title: "Dokument Upload",
                    text: "Es gab einen Fehler beim Upload deiner Dokumente. Bitte versuche es später erneut.",
                    icon: "error"
                  });

                });

              });
          });


        } // for-loop multiple files
               
          Swal.fire({
            title: 'Erfolg',
            html: 'Alle Dateien wurden erfolgreich hochgeladen.',
            icon: 'success'
          });
          setPickedFiles([]);
      }
  
    }
  
  
  
    // -------  In Context packen -------
    const [kategorien, setKategorien] = useState([]);
  
    useEffect(() => {
      async function getSubcategories() {
        let kategorien = await axios.get(
          "https://api.mariusschulte.com/kategorien.php"
        );
        //console.log(kategorien);
  
        setKategorien(kategorien.data);
        //console.log(kategorien.data);
      }
      getSubcategories();
  
       //Dynamically set Category from "Unread" Docs Feed
       if(location.state) {
        if(location.state.category){
          setCategoryTwo(location.state.category);
        }

        //if(location.state.category && location.state.subcategory) {
        //  setCategoryThree(location.state.subcategory);
        //}
      }
  
  
      // Get Dokumente
      async function getDokumente() {

          const colRef = collection(db, "Users", currentUser.uid, "Dokumente");
          const q = query(
            colRef,
            where("category", "==", "Kredite"),
            where("categoryTwo", "==", categoryTwo),
            orderBy("created", "desc")
          );
      
          const unsubscribe = onSnapshot(q, (snapshot) => {
              let DocumentsArr = [];
              snapshot.forEach(doc => {
                  if (doc.data()) {
                      DocumentsArr.push({
                          id: doc.id,
                          data: doc.data()
                      });
                  }
              });
              setPrivateDocs(DocumentsArr);
          });
      
          // Make sure to unsubscribe from your listener when you no longer need it
          return unsubscribe;  
      }
      if(currentUser?.uid && categoryTwo) {
        const unsubscribe = getDokumente();
      }
  
    }, [currentUser, categoryTwo]);



    async function deleteFileAndDoc(id, filename) {

      //const storageRef = ref(storage, "/"+auth.currentUser.uid+"/"+filename);
      const storageRef = ref(storage, "/"+currentUser.uid+"/"+filename);
      // Delete the file
      deleteObject(storageRef).then(() => {
        // if file deleted, delete metadata
        deleteDoc(doc(db, "Users", currentUser.uid, "Dokumente", id))
        .then (() => {
          //setPrivateDocs(privateDocs.filter(docs => docs.id !== id));
        })
        .catch(error =>  {
          console.log(error);
        })
      })
      .catch((error) => {
        console.log(error);
      });
  
    }
  


  return (
    <>
      <Header
        url={navigateBack}
        url_state={url_state}
      />
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col className="text-center">
            <img
              src={require("../../../img/Dokumente.png")}
              alt=""
              style={{ maxWidth: "120px", marginTop: "30px"}}
            />
            <h1 style={{color: ColorTheme}}>Dokumente Kredite</h1><br />
            <p className="text-center">
              Hier findest du Deine Unterlagen rund um das Thema Kredite.
            </p>
            <p className="text-center">
              Wähle eine Kategorie
            </p>
          </Col>
        </Row>


        <Row className="mb-5 justify-content-center">

          <Col key={"offen"} xs={12} md={5} style={{marginTop: "20px"}} onClick={() => handleCategory("Offene Bestätigungen")}>
              <CardSub title={"Offene Bestätigungen"} selected={categoryTwo == "Offene Bestätigungen" ? 1 : null} color={ColorTheme} />
          </Col>
          

          <Col key={"abgelehnt"} xs={12} md={5} style={{marginTop: "20px"}} onClick={() => handleCategory("Abgelehnt")}>
              <CardSub title={"Abgelehnte Vorgänge"} selected={categoryTwo == "Abgelehnt" ? 1 : null} color={ColorTheme} />
          </Col>
          
        </Row>


        <Row style={{justifyContent: "center"}}>

          {cards.map(card => 
            <Col
              key={card.title}
              xs={6} md={3} style={{marginTop: "20px"}} onClick={() => handleCategory(card.title)}
            >
              <CardSmall img={card.img} title={card.title} selected={categoryTwo == card.title ? 1 : null} color={ColorTheme} />
            </Col>  
          )}

        </Row>




        <Row style={{marginTop: "16px", justifyContent: "center", marginBottom: "50px"}} >
        {/* Dokument Upload */}
        {categoryTwo && categoryTwo !== "Dokumente" && categoryTwo !== "Offene Bestätigungen" && categoryTwo !== "Abgelehnt" &&
         <Col
            xs={12} md={12}
            className="uploadContainer"
          >
            <p className="text-center" style={{marginTop: "30px"}}>Deine gewählte Kategorie: <span className="fw-bold">{categoryTwo}</span></p>

            <h4 className="text-center">Dateiupload</h4>
            <p>1. Wähle deine Dateien aus (Fotos oder PDF)</p>

            <label htmlFor="docUpload" className="uploadBtn" style={{cursor: "pointer"}}>
              Datei auswählen
              <input
                id="docUpload"
                type="file"
                accept='application/pdf, image/png, image/jpeg'
                style={{display: "none"}}
                onChange={handleFile}
              />
            </label>
            

      
            {pickedFiles.length > 0 &&
              <div className="text-center" style={{marginTop: "20px", width: "100%"}}>
                     {/*<p>2. Gib der Datei eine kurze und knackige Beschreibung (z.B. "Perso" oder "Fahrzeugbrief")</p>*/}
                  <p>2. Lade das/die Dokument/-e hoch</p>
                  
                  {pickedFiles.map(file => 
                      <CardUpload key={file.id} data={file} removeFileUploadHandler={removeFileUploadHandler} />
                  )}
                  {/*}p className="text-center">{progress}%</p>*/}
                  <button className="uploadBtn" style={{cursor: "pointer"}} onClick={() => handleFileUpload()}>Dokumente hochladen</button>
              </div>
            }
            </Col>
          }
        </Row>


        <Row style={{marginTop: "16px", justifyContent: "center", marginBottom: "80px", marginTop: pickedFiles.length > 0 ? "50px" : "0px"}} >
          {/* Dokumenten Liste */}
          {categoryTwo && categoryTwo !== "Dokumente" &&
          <Col
              xs={12} md={12}
              className="text-center"
            >
              <h4 className="text-center" style={{marginBottom: "20px"}}>Meine Dokumente</h4>

              {(privateDocs.filter((key) => key.data.categoryTwo == categoryTwo)).length == 0 && <p style={{marginTop: "10px"}}>Noch keine Dokumente vorhanden.</p>}
              
              {(privateDocs.filter((key) => key.data.categoryTwo == categoryTwo)).map(doc =>
                  <CardDokument key={doc.id} data={doc} deleteFileAndDoc={deleteFileAndDoc} setModalTitle={setModalTitle} setModalImgSrc={setModalImgSrc} setShowModal={setShowModal} />
              )}

            </Col>
            }
        </Row>
        
        {showModal && <IMGViewModal title={modalTitle} imgSrc={modalImgSrc} visible={setShowModal} />}     

      </Container>
    </>
  );
}

export default DokuKredite
