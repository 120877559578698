import React, { useState } from 'react'
import Header from '../../Header/Header'
import { useNavigate } from 'react-router-dom';

import WebViewModal from "../../Bausteine/WebViewModal/WebViewModal";

import { Container, Row, Col } from 'react-bootstrap';

import style from './Versicherungen.module.css'

function Versicherungen() {

  const ColorTheme = "#87BAF6";

  let navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState(false);

  const handleExternalURL = (url) => {

    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
  }

  return (
    <>
      <Header />
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col style={{textAlign: "center"}}>
            <h1 style={{color: ColorTheme}}>Übersicht Versicherungen</h1>
          </Col>
        </Row>

        <br />
        <br />

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Sachversicherung.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Handy & Reise</h5>
            <p className="text-center">
              Handy kaputt? Die lange geplante Reise muss doch storniert werden? Im Auslandsurlaub krank geworden? Unverhofft kommt oft! Lieber vorher günstig absichern, statt später teuer blechen. 
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategory: "Handy & Reise"}})}>Jetzt beraten lassen &gt;</button>  
          </Col>
      
        </Row>



        <br />
        <br />


        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/HaftRecht.png")} alt="" className={style.ColImg} />
          </Col>
            <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Haft & Recht</h5>
            <p className="text-center">
              Privathaftpflicht, Diensthaftpflicht, Rechtsschutzversicherung, sofortige Rechtshilfe und mehr. Wir sorgen dafür, dass Du Dein gutes Recht auch durchsetzen kannst. 
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategory: "Haft & Recht"}})}>Jetzt beraten lassen &gt;</button>  
          </Col>
        </Row>


        <br />
        <br />


        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/RisikoVorsorge.png")} alt="" className={style.ColImg} />
          </Col>
            <Col xs={{span: 12, order: 2}} md={{span: 5, order: 2}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Risiko & Vorsorge</h5>
            <p className="text-center">
              Wir beraten Dich wie Du Dich und Deine Familie vor finanziellen Folgen aus Unfällen in der Freizeit oder bei der Arbeit schützen kannst. 
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategory: "Risiko & Vorsorge"}})}>Jetzt beraten lassen &gt;</button>  
          </Col>
        </Row>

        <br />
        <br />

        <Row className={style.Row}>
        <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Mobil.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Auto & Fahrzeuge</h5>
            <p className="text-center">
              Ob eine Versicherung mit günstigen Tarifen oder mal schnell eine eVB Nummer beantragen, VIN1 hilft Dir mit optimaler und schneller Beratung. 
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategory: "Auto & Fahrzeuge"}})}>Jetzt beraten lassen &gt;</button>  
          </Col>
       
        </Row>

        <br />
        <br />

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Gesundheit.png")} alt="" className={style.ColImg} />
          </Col>
            <Col xs={{span: 12, order: 2}} md={{span: 5, order: 2}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Kranken</h5>
            <p className="text-center">
              Besser gesetzlich oder privat versichert? Oder reicht auch eine Krankenzusatzversicherung? Tausche Dich jetzt mit uns aus und finde Deinen passenden Tarif!
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategory: "Kranken"}})}>Jetzt beraten lassen &gt;</button>  
          </Col>
        </Row>

        <br />
        <br />

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/AlterPflege.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Altersvorsorge & Pflege</h5>
            <p className="text-center">
              Mit einer modernen, flexiblen Vorsorge denkst Du heute schon an Morgen – auch für Deine Familie. Wir beraten dich, was Du unbedingt für deine Zukunft benötigst. 
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategory: "Altersvorsorge & Pflege"}})}>Jetzt beraten lassen &gt;</button>  
          </Col>
        </Row>

        <br />
        <br />


        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Tier.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Tier</h5>
            <p className="text-center">
              Deine treuen Begleiter verdienen den besten Schutz. Ob Haftpflicht oder Krankenversicherung, VIN1 holt das Beste für Deinen Liebling raus. 
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategory: "Tier"}})}>Jetzt beraten lassen &gt;</button>  
          </Col>
        </Row>

        <br />
        <br />

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Immo.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Haus & Wohnung</h5>
            <p className="text-center">
            Ob Sturm- oder Wasserschaden, Wohnungsbrand oder Einbruch-Diebstahl. Wir sichern Dich ab und das zu günstigen Beiträgen.
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategory: "Haus & Wohnung"}})}>Jetzt beraten lassen &gt;</button>      
          </Col>
        </Row>

      
        <br />
        <br />

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Gewerbe.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Gewerbe</h5>
            <p className="text-center">
              Modernste Beratung für das Gewerbegeschäft. Speziell geschulte Firmenkundenbetreuer. Wir schaffen die beste Absicherung für Dein Unternehmen.
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategory: "Gewerbe"}})}>Jetzt beraten lassen &gt;</button>      
          </Col>
        </Row>

      
        <br />
        <br />


        <Row className={style.Row}>
          <div className={style.BottomCard}>
            <h4 className="text-center" style={{width: "85%"}}>Vergleichen und optimieren</h4>
            {/*<p className="text-center" style={{width: "85%"}}>Unkomplizierter Vergleich für das beste Ergebnis!</p>*/}
            {/*<button className={style.BtnNormal} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Hier mehr erfahren &gt;</button>*/}
            <button className={style.BtnNormal} style={{marginTop: "16px"}} onClick={() => navigate("/beratung", { state: { category: "Versicherung"}})}>Jetzt Kontakt aufnehmen &gt;</button>
          </div>
        </Row>

        <br />

        <Row style={{marginBottom:"80px"}}>
          <Col style={{textAlign:"center"}}>
            <button className={style.BtnNormal} onClick={() => navigate("/dokumente/versicherungen")}>Zu Deiner Dokumentenablage &gt;</button>
          </Col>
        </Row>

        {showModal && <WebViewModal url={modalUrl} hideModal={setShowModal} />}

      </Container>
    </>
  )
}

export default Versicherungen