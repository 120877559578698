import React, { useState, useEffect, useRef, useContext } from "react";
import { HeaderWithoutAuthOhneNavigation } from "../Header/Header";
import Spinner from "../Bausteine/Spinner/Spinner";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

import { Container, Row, Col, Form, Button } from "react-bootstrap";

import axios from "axios";
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import style from "./Formular.module.css";

import CustomModal from "../Bausteine/CustomModal"
import VINButton from "../../VINButton";



function Mietkaution() {

  let navigate = useNavigate();


  async function downloadPDF(filepath, filename) {

    fetch(filepath).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = filename;
            alink.click();
        })
    })
  }
    


  const [searchParams] = useSearchParams();

  //"Auswertung aus welcher Kampagne der Besucher kam"
  const [leadkampagne, setLeadkampagne] = useState("");

  useEffect(() => {

    if (searchParams.get("id") !== null) {
      setLeadkampagne(searchParams.get("id"));
    }

  },[searchParams])

  const [showModalErstinformationen, setShowModalErstinformationen] = useState(false);
  const [spinner, setSpinner] = useState(false);
  // Kunde
  const [vorname, setVorname] = useState("");
  const [nachname, setNachname] = useState("");
  const [plz,setPlz] = useState("");

  // Flagge = Ländervorwahl für IntlTelefon 
  const [laenderVorwahl, setLaenderVorwahl] = useState("+49");
  const [telefon, setTelefon] = useState("");
  const [email, setEmail] = useState("");

  const [cbErstinformationen, setCbErstinformationen] = useState(false);
  const [cbDatenschutz, setCbDatenschutz] = useState("");









    
 
  // ---------------------------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------

  const handleSubmit = async (e) => {

    e.preventDefault();


    // Pflichtfelder ausgefüllt
    if (
      !vorname ||
      !nachname ||
      !plz ||
      !telefon ||
      !email ||
      !cbErstinformationen ||
      !cbDatenschutz 
    ) {
        Swal.fire({
            icon: 'info',
            title: 'Pflichfelder',
            html: 'Bitte fülle alle Felder aus und sende das Formular erneut ab.'

        })
        return;
    }

        setSpinner(true);
      
        //Create Task CRM
        axios({
            method: 'post',
            headers: {
                //'Authorization': 'Bearer ' + accessToken //keine Auth notwendig!
            },
            url: process.env.REACT_APP_BACKEND+"/my-vin1/leads",
            data: {
                art: "Lead-Mietkautionsversicherung",
                gruppe: 'Versicherung',
                leadkampagne: leadkampagne,
                beschreibung: {
                    Vorname: vorname,
                    Nachname: nachname,
                    PLZ: plz,
                    'E-Mail': email,
                    Telefon: telefon
                }     
            }
        })
        .then(res => {
            setSpinner(false);
            Swal.fire({
                icon: 'success',
                title: 'Erfolg',
                html: 'Das Formular wurde erfolgreich abgesendet.<br />'
                      +'Unser Team kümmert sich um alles weiter und wird dich zeitnah kontaktieren.'
            })
        })
        .catch(err => {
            setSpinner(false);
            Swal.fire({
                icon: 'error',
                title: 'Fehler',
                html: 'Fehler beim Übermitteln des Formulars. Bitte erneut versuchen.'
            })
        })


       
    
  }


  // ---------------------------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <HeaderWithoutAuthOhneNavigation />

      <Container style={{ maxWidth: "800px" }}>
        <Row style={{ justifyContent: "center" }}>
          <Col>
            <h1 style={{ marginBottom: "30px" }}>Mietkautionsversicherung</h1>
          </Col>
        </Row>
      </Container>
      <Container style={{ maxWidth: "650px", marginBottom: "180px" }}>
        <Row style={{ justifyContent: "center" }}>
          <h2
            style={{ color: "#520da7", fontSize: "140%" }}
            className="fw-bold text-center"
          >
            Jetzt Informieren.
          </h2>
          <p className="text-center">
            Vintastisch, dass du dich für unsere Mietkautionsversicherung interessierst.
            <br />
            Damit wir dich kontaktieren können, benötigen wir ein paar Informationen.
          </p>
        </Row>


        {/*<p>Standort: {standort}</p>*/}

          {/* Chrome Browser austricksen damit kein autoComplete... */}
          <input type="hidden" value="something" />
  
          <Row style={{ justifyContent: "center", marginBottom: "80px", marginTop: "50px" }}>


            <Form autoComplete="off">

              <span>* Pflichtfeld</span>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="vorname" className="form-label">
                  Vorname*
                </label>
                <br />
                <input
                  type="text"
                  id="vorname"
                  name="vorname"
                  placeholder="Vorname"
                  autoComplete="on"
                  value={vorname}
                  onChange={(e) => setVorname(e.target.value)}
                />
              </div>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="nachname" className="form-label">
                  Nachname*
                </label>
                <br />
                <input
                  type="text"
                  id="nachname"
                  name="nachname"
                  placeholder="Nachname"
                  autoComplete="on"
                  value={nachname}
                  onChange={(e) => setNachname(e.target.value)}
                />
              </div>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="plz" className="form-label">
                  PLZ*
                </label>
                <br />
                <input
                  type="text"
                  id="plz"
                  name="plz"
                  placeholder="PLZ"
                  autoComplete="on"
                  inputMode="numeric"
                  maxLength={5}
                  value={plz}
                  onChange={(e) => setPlz(e.target.value.replace(/[^\d]/g, ""))}
                />
              </div>

            

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="telefon" className="form-label">
                  Telefon/Mobil*
                </label>
                <br />
                <Row>
                  <Col>
                    {/*<Form.Select
                      style={{width: "auto", display: "inline-block"}}
                      value={laenderVorwahl} // Make sure to set the value prop to the state value
                      onChange={(e) => setLaenderVorwahl(e.target.value)} 
                    >
                      <option value="+49">DE +49</option>
                    </Form.Select>
                    */}

                    <input
                      style={{width: "auto"}}
                      type="text"
                      id="telefon"
                      name="telefon"
                      placeholder="151123456"
                      inputMode="tel"
                      autoComplete="on"
                      value={telefon}
                      onChange={(e) => {
                        let input = e.target.value.replace(/[^+\d]/g, '').trim();
                        if(input.length > 13) {
                          input = input.slice(0, 13);
                        }
                        setTelefon(input);
                      }}
                    />
                  </Col>
                </Row>
              </div>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="EmailLogin" className="form-label">
                  E-Mail*
                </label>
                <br />
                <input
                  type="text"
                  id="EmailLogin"
                  name="EmailLogin"
                  inputMode="email"
                  placeholder="max@example.com"
                  autoComplete="new-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
                />
              </div>



              <label className="form-check-label" style={{ margin: "40px 0px 0px 20px" }}>
                <input
                  id="cbErstinformationen"
                  className="form-check-input"
                  type="checkbox"
                  checked={cbErstinformationen}
                  onChange={() => setCbErstinformationen(!cbErstinformationen)}
                />

                <span style={{ paddingLeft: "10px" }}>
                  Ich habe die <a href="#" onClick={() => setShowModalErstinformationen(true)}>Erstinformationen</a> gelesen.*
                </span>
              </label>



              <label className="form-check-label" style={{ margin: "20px 0px 30px 20px" }}>
                <input
                    id="cbTippgeber"
                    className="form-check-input"
                    type="checkbox"
                    checked={cbDatenschutz}
                    onChange={(e) => setCbDatenschutz(e.currentTarget.checked)}
                />   
                <span style={{paddingLeft: "10px"}}>Ich stimme den <a href="/datenschutz" target="_blank">Datenschutzbedingungen</a> zum Zweck der Kontaktaufnahme zu.*</span>
              </label>

            
            
              <div className="pointer text-center mt-3" onClick={handleSubmit}>
                <VINButton text={"Absenden"} />
              </div>

              <Row
                style={{marginTop: "80px"}}
                className={style.footerContainer}
              >
                <p style={{width: "auto", cursor: "pointer", margin: "10px"}} onClick={() => navigate("/impressum")}>Impressum</p>
                <p style={{width: "auto", cursor: "pointer", margin: "10px"}} onClick={() => navigate("/datenschutz")}>Datenschutz</p>
                <p style={{width: "auto", cursor: "pointer", margin: "10px"}} onClick={() => navigate("/nutzungsbedingungen")}>Nutzungsbedingungen</p>
              </Row>

            </Form>

            {spinner &&
                <Spinner
                    message={"Bitte warten. Daten werden verarbeitet."}
                />
            }

            {showModalErstinformationen &&
                <CustomModal 
                    title={"Erstinformationen"}
                    visible={showModalErstinformationen}
                    setVisible={setShowModalErstinformationen}
                > 
                     <Row className="mb-3" style={{justifyContent: "center"}}>
                        <Col>

                            <p>Hier findest alle wichtigen Informationsdokumente zum Download</p>

                            <p style={{marginTop:"30px"}} className="fw-bold">
                                Erstinformationen
                            </p>
                            <div style={{}}>
                                <div style={{cursor: "pointer"}} onClick={() => downloadPDF("pdf/Erstinformationen_VIN1.pdf", "Erstinformationen_VIN1.pdf")}>
                                <span style={{fontSize: "16px", textDecoration: "underline" }}>Download Erstinformationen (PDF)</span>
                                <FontAwesomeIcon icon={faDownload} size="lg" style={{marginLeft: "10px"}} />
                                </div>
                            </div>

                        </Col>
                    </Row>
                </CustomModal>
            }

          </Row>
        
      </Container>


    </>
  );
}

export default Mietkaution;
