import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap'

import CardSub from './Card/CardSub';
import SelectInput from './Bausteine/SelectInput/SelectInput';
import PartnerConnectCard from './Bausteine/PartnerConnect';
import { AuthContext } from '../store/auth-context';

import axios from 'axios';
import Swal from 'sweetalert2';
import { UserContext } from '../store/user-context';


function WeiterleitungPartnerSelection(props) {

    const navigate = useNavigate();

    const { currentUser } = useContext(AuthContext);
    const { partnerListe, setPartnerListe } = useContext(UserContext);


    const [showPartnerSelect, setShowPartnerSelect] = useState(false);
  
    const [selectedPartnerName, setSelectedPartnerName] = useState("");
  
    const [showModal, setShowModal] = useState(false);
  
    const [bereich, setBereich] = useState("");
  
    const [selectedBereich, setSelectedBereich] = useState("");
   
    const [partnerInput, setPartnerInput] = useState("");
    const [kategorieInput, setKategorieInput] = useState("Alle Partner");
  
    const [kategorieListe, setKategorieListe] = useState([
      {
        label: "Alle Partner",
        value: "Alle Partner"
      },{
        label: "Architekten",
        value: "Architekten"
      },{
        label: "Auto",
        value: "Auto"
      }, {
        label: "Multimedia",
        value: "Multimedia"
      }
    ]);
  
    const [partnerListeModal, setPartnerListeModal] = useState([]);


    
    
    const handleAbo = () => {
        setShowModal(true);
    }

    const handleKategorieChange = (data) => {
        //console.log(data.value);
        setKategorieInput(data.value);

        // Axios set Partner in DB

        // Partner in aktuelle Liste direkt aufnehmen!
    }

    const handlePartnerChange = (e) => {
        setSelectedPartnerName(e.value);
    }



    const handlePartnerChangeModal = (data) => {
        //console.log(data.value);
        setPartnerInput(data.value);

        // VIN1 CRM Backend => PartnerCollection => Partner Object in subscribers hinterlegen

        // In Firebase User Doc Daten subscriptions Array Feld den "unique" partner_name pushen

        // Partner in aktuelle Liste direkt aufnehmen!
    }


    const handlePartnerNavigate = (partnerName) => {
        //alert("navigiere: "+)
        //console.log(partnerName);
        navigate(partnerName);
    }





    useEffect(() => {

      if(currentUser?.uid) {
  
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + currentUser.accessToken
            },
            url: process.env.REACT_APP_BACKEND+"/partner/connected-partner",
        })
        .then(resData => {
  
          let liste = [];
          resData.data.forEach(elem => {
            liste.push({value: elem.slug, label: elem.partner_name})
          })
          setPartnerListe(liste);
        })
        .catch(error => {
          Swal.fire({
            "icon": "error",
            "title": "Fehler",
            "html": "Fehler beim Abrufen deiner Partner."
        });
        })
      }
    },[currentUser])





    return (
    
      <>

        {/* VIN1 oder Partner */}
        <Row className="mt-3 justify-content-center">
          <Col
              key={"VIN1"}
              xs={12} md={3}
              style={{marginBottom: "20px"}}
              onClick={() => navigate("/partner/vin1", { state: {category: props.navigateTo} } )}
          >
              <CardSub key={"VIN1"} title={"VIN1"} />
          </Col>

          <Col
              key={"Partner"}
              xs={12} md={3}
              style={{marginBottom: "20px"}}
              onClick={() => setShowPartnerSelect(!showPartnerSelect)}
          >
              <CardSub key={"Partner"} title={"Partner"} />
          </Col>
        
        </Row>

      
        {/* Weiterleitung zu einer Partnerseite nach Auswahl und Button Click */}
        {showPartnerSelect && 
          <Container style={{marginBottom: "160px"}}>
            <Row className="justify-content-center mt-5">

              <Col xs={12} md={12} className="text-center">
                {/*
                  <p>
                    <b>In Kürze kannst du hier Partnern Dateien senden!</b><br />
                  </p>
                */}
                
                  
                  <p className="text-center">Wähle im folgenden Dropdown einen Partner aus mit dem du bereits verbunden bist.<br />
                    Du kannst durch Eingaben in dem Feld nach Partnern suchen.
                  </p>

                  <div>
                    <SelectInput
                        value={{'label': selectedPartnerName, 'value': selectedPartnerName}}
                        options={partnerListe}
                        placeholder={"Suche nach einem Partner..."}
                        noOptionsMessage={"Du bist noch mit keinem Partner verbunden."}
                        handleSelectChange={handlePartnerChange}
                    
                    />
                  </div>

                  {selectedPartnerName &&
                    <div
                        onClick={() => navigate('/partner/'+selectedPartnerName, { state: { category: props.navigateTo } } )}
                        className="btn btn-success w-auto mt-3"
                    >
                        Zum Partnerbereich wechseln
                    </div>
                  }
              
                  <h5 className="text-center mt-5">Du kannst deinen Partner nicht finden?</h5>
                  <p className="text-center mt-3">
                    Empfange Dokumente oder Neuigkeiten von deinen Partnern.
                    <br />
                    Verbinde dich jetzt!
                  </p>
   
                    
              </Col>

            </Row>


            <PartnerConnectCard />
          </Container>
               
        }



        {/* Partner Kachel Liste
            <Row style={{marginTop: "16px", justifyContent: "center", marginBottom: "160px"}} >
            {partnerListe.map(p => 
                <Col key={p.partner_name} xs={6} md={4} style={{marginTop: "20px"}} onClick={() => handlePartnerNavigate(p.partner_name)} >
                <CardPartnerExternalImage url={""} img={p.partnerIMG} title={p.partner_name} />
                </Col>
            )}
            </Row>
            */}

          <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
            <Modal.Header closeButton>
                <Modal.Title>Connecte dich mit einem Partner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Wähle eine Kategorie, um die Auswahl zu verkleinern oder suche im unteren Feld direkt nach einem Partner</p>
                <SelectInput placeholder={"Wähle eine Kategorie"} noOptionsMessage={"Keine Kategorie gefunden."} options={kategorieListe} handleSelectChange={handleKategorieChange} />

                <p>Wähle im folgenden Dropdown einen Partner aus. Du kannst durch Eingaben in dem Feld nach Partnern suchen.</p>
                <SelectInput placeholder={"Suche nach einem Partner..."} noOptionsMessage={"Kein Partner gefunden."} options={partnerListeModal} handleSelectChange={handlePartnerChangeModal} />


                <Form.Check
                  type={'checkbox'}
                  label={"Mit dem Abonnieren des Partners stimmst du zu, dass deine persönlichen Kundeninformationen (Anschrift, Geburtsdatum, Kontaktmöglichkeiten) für den abonnierten Partner sichtbar werden und dieser Dokumente in deine Dateiablage einspielen kann. Der Partner hat keine Einsicht auf deine anderen Dokumente."}
                  id="cbZustimmung"
                />
            </Modal.Body>
            <Modal.Footer>
                <p
                  style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#656565", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                  onClick={() => setShowModal(!showModal)}
                >
                  Schließen
                </p>
                <p
                  style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                  onClick={() => setShowModal(!showModal)}
                >
                  Mit Partner connecten
                </p>
            </Modal.Footer>
          </Modal>
      </>
    )
}

export default WeiterleitungPartnerSelection

