import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import style from "./WebViewModal.module.css"

const Backdrop = (props) => {
    return <div className={style.backdrop} onClick={props.onConfirm} />
}

//Wer Inhalte von Dritten über ein IFRAME auf seiner Website einbindet, kann dafür haftbar gemacht werden.
//Sowohl das Urheberrecht als auch das Datenschutzrecht sind zu betrachten.


const ModalOverlay = (props) => {

  console.log("props modal");
  console.log(props);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
      function handleResize() {
          setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getWindowDimensions() {
    const width = window.innerWidth
    const height = window.innerHeight

    return {
        width,
        height
    };
  }

  return (
    <>
        <div className={style.modalHeaderContainer}>
            <FontAwesomeIcon className={style.closeModal} icon={faXmark} size="xl"
                onClick={() => props.hideModal(false)}
            />
              {windowDimensions.width <= 766 ? 
                <p className={style.url}>{props.url.substring(0, 40)+ "..."}</p>
                : 
                <p className={style.url}>{props.url.substring(0, 80)+ "..."}</p>
              }
            <div></div>
        </div>
        <iframe src={props.url} title="iframe Overlay"></iframe>
    </> 
  )
}

const WebViewModal = (props) => {

    return (
      <>
        {ReactDOM.createPortal(<Backdrop hideModal={props.hideModal} />, document.getElementById('backdrop-root'))}
        {ReactDOM.createPortal(<ModalOverlay url={props.url} hideModal={props.hideModal} />, document.getElementById('overlay-root'))}
      </>
      
    )
}

export default WebViewModal