import React, { useState, useEffect, useRef, useContext } from "react";
import { HeaderWithoutAuthOhneNavigation } from "../Header/Header";
import Spinner from "../Bausteine/Spinner/Spinner";
import { useNavigate, useParams, useSearchParams, Link } from "react-router-dom";

import { Container, Row, Col, Form, Button } from "react-bootstrap";

import axios from "axios";
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import style from "./PasswortReset.module.css";

import CustomModal from "../Bausteine/CustomModal"
import VINButton from "../../VINButton";
import VINButtonSecondary from "../../VINButtonSecondary";



function PasswortSetPreregister() {

  let navigate = useNavigate();
  let params = useParams();

  let googleuid = params.googleuid;
  let token = params.token;
  
  
  useEffect(() => {

    if(!params.googleuid || !params.token || !(params.token.length == 60) ) {
      navigate("/");
    }

  },[params])
  
  

  const [spinner, setSpinner] = useState(false);

  const [loginPassword, setLoginPassword] = useState("");
  const [loginPassword2, setLoginPassword2] = useState("");

 
  // ---------------------------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------

  const handleSubmit = async (e) => {

    e.preventDefault();


    if (
      !loginPassword ||
      !loginPassword2 
    ) {
        Swal.fire({
            icon: 'info',
            title: 'Pflichfelder',
            html: 'Bitte fülle beide Passwortfelder aus und sende das Formular erneut ab.'

        })
        return;
    }

    if (loginPassword != loginPassword2) {
        Swal.fire({
            icon: 'info',
            title: 'Passwörter',
            html: 'Die beiden Passwörter stimmen nicht überein. Korriere die Passwörter und sende das Formular erneut ab.'

        })
        return;
    }

        setSpinner(true);
      
        
        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND+"/my-vin1/new-initial-passwort",
            data: {
                googleuid: googleuid,
                token: token,
                newPassword: loginPassword,
            }
        })
        .then(res => {
            setSpinner(false);
            Swal.fire({
                icon: 'success',
                title: 'Erfolg',
                html: 'Dein Passwort ist ab sofort gültig.',
                confirmButtonText: "Zurück zum Login"
            })
            .then((result) => {
              navigate("/");
            })
        })
        .catch(err => {
            console.log(err);
            setSpinner(false);
            Swal.fire({
                icon: 'error',
                title: 'Fehler',
                html: err.data?.message
            })
        })
        
        setSpinner(false);
    
  }


  // ---------------------------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <HeaderWithoutAuthOhneNavigation />

      <Container style={{ maxWidth: "800px" }}>
        <Row style={{ justifyContent: "center" }}>
          <Col>
            <h1 style={{ marginBottom: "30px" }}>Willkommen bei VIN1</h1>
          </Col>
        </Row>
      </Container>
      <Container style={{ maxWidth: "650px", marginBottom: "180px" }}>
        <Row style={{ justifyContent: "center" }}>
          <p className="text-center">
            Dein VIN1-Zugang wurde bereits für Dich vorbereitet.
            Es fehlt nur noch ein kleiner Schritt, damit Du alle Vorteile der VIN1-Welt nutzen kannst.
          </p>
          <p className="text-center">
            Vergib Dein persönliches Passwort, um Dich in der VIN1 App anzumelden.
          </p>
        </Row>


          {/* Chrome Browser austricksen damit kein autoComplete... */}
          <input type="hidden" value="something" />
  
          <Row style={{ justifyContent: "center", marginBottom: "80px", marginTop: "50px" }}>


            <Form autoComplete="off">

              <span>* Pflichtfeld</span>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="password1" className="form-label">
                  Passwort (min. 8 Zeichen)*
                </label>
                <br />
                <input
                  type="password"
                  id="password1"
                  name="password1"
                  placeholder="********"
                  autoComplete="new-password"
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value.trim())}
                />
              </div>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="password2" className="form-label">
                  Passwort wiederholen*
                </label>
                <br />
                <input
                  type="password"
                  id="password2"
                  name="password2"
                  placeholder="********"
                  autoComplete="new-password2"
                  value={loginPassword2}
                  onChange={(e) => setLoginPassword2(e.target.value.trim())}
                />
              </div>

              <Row className="mt-3 mb-5">
                <Col
                  xs={12}
                  className="mt-3"
                >
                  <div className="pointer text-center" onClick={handleSubmit}>
                    <VINButton text={"Passwort speichern"} />
                  </div> 
                </Col>

              </Row>

            </Form>

            {spinner &&
                <Spinner
                    message={"Bitte warten. Daten werden verarbeitet."}
                />
            }

          </Row>
        
      </Container>


    </>
  );
}

export default PasswortSetPreregister;
