import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import style from "./Card.module.css"

import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";

function Card(props) {

  let navigate = useNavigate();

 
  const [showModal, setShowModal] = useState(false);

  const handleExternalURL = (url) => {
    //window.open(url, '_blank', 'noopener,noreferrer');
    setShowModal(!showModal);
  }

  return (
    <div onClick={ () => {
      if(props.url && !props.externalURL) {
          navigate(props.url, { state: { navigateBack: "/partner/vin1", category: "Dokumente" }});
      }
      else if(!props.url && props.externalURL) {
          handleExternalURL(props.externalURL);
      }
      else {

      }
  }} className={style.cardContainer}>
          {showModal && (
              <WebViewModal
                url={props.externalURL}
                hideModal={setShowModal}
              />
            )}
        <img className={style.cardImg} src={require("../../img/"+props.img)} alt="" />
        <p className={style.cardTitle}>{props.title}</p>
    </div>
  )
}

export default Card