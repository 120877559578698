import React, {  useState, useEffect } from 'react'
import Header from '../Header/Header'

import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, Button } from 'react-bootstrap';
import axios from "axios"

import NewsletterCardVP from './NewsletterCardVP';

function NeuigkeitenVP() {

  let navigate = useNavigate();
  
  const [newsletter, setNewsletter] = useState([]);

  useEffect(() => {
    loadNewsletter();
  }, [])

  async function loadNewsletter() {
    const response = await axios.get("https://api.vin1.eu/newsletter_vp.php?AUTH=bv8FnNYM6JU6Uk7QE8ujvM6S6WMVpg");
    
    if(response.data !== "no data") {
      const newsletterAPI = [];
      for (const key in response.data) {

        const newsObj = {
            id: response.data[key].ID,
            title: response.data[key].TITEL,
            datum: response.data[key].DATUM,
            image: response.data[key].IMG_B64 ? response.data[key].IMG_B64 : null,
            newstext: response.data[key].NACHRICHT,
            url: response.data[key].URL,
            pdf: response.data[key].PDF_B64,

            /*
            "ID": 1,
            "TITEL": "Beispielnewsletter",
            "NACHRICHT": "Dies ist ein Beispielnewsletter",
            "DATUM": "01.08.2022",
            "URL": "https://example-test.com",
            "PDF_FILENAME": "",
            "PDF_B64": "",
            "IMG_B64":
            */
        };
        newsletterAPI.push(newsObj);
      }
      setNewsletter(newsletterAPI);
    }
  }
  


  return (
    <>
      <Header />
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col className="text-center">
            <img
              src={require("../../img/News.png")}
              alt=""
              style={{ maxWidth: "120px", marginTop: "40px"}}
            />
            <h1>Dein ganz persönlicher Newsbereich im Vertrieb</h1>
            <br />
            <p>
              Hier erwarten dich Informationen zu neuen Möglichkeiten mit VIN1.<br />
              Bleibe auf dem laufenden, was den Vertrieb rund um VIN1 angeht.
            </p>
          </Col>
        </Row>
        
        <br />

        {newsletter.length > 0 && newsletter.map((news) =>  
          <NewsletterCardVP key={news.id} data={news} />
        )}
        {newsletter.length === 0 &&
          <p className='text-center'><i>Momentan liegen keine neuen News vor.</i></p>
        }

      <Row style={{marginBottom:"80px"}}></Row>
      
      </Container>
    </>
  )
}

export default NeuigkeitenVP