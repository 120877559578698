import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';

import CardDoppelt from '../Card/CardDoppelt';

import PartnerConnectModal from './PartnerConnectModal';
import { useNavigate, useLocation } from 'react-router-dom';

import SelectInput from './SelectInput/SelectInput';

import axios from 'axios'
import Swal from 'sweetalert2';


import { db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";

import { AuthContext } from "../../store/auth-context"
import { UserContext } from '../../store/user-context';
import VINButton from '../../VINButton';




function PartnerConnectCard () {

  const navigate = useNavigate();
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (val) => {
    setShowModal(val);
  }


  const { user, partnerListe, setPartnerListe } = useContext(UserContext);
  const { currentUser } = useContext(AuthContext);

  const [selectedPartner, setSelectedPartner] = useState("");
  const [partnerListeModal, setPartnerListeModal] = useState([]);

  const handlePartnerChangeModal = (e) => {
      setSelectedPartner(e);
  }


  useEffect(() => {
      
      async function getPartner() {
          try {
              // Status in CRM Tracken
              let partner = await axios({
                  method: 'get',
                  headers: {
                      'Authorization': 'Bearer ' + currentUser.accessToken
                  },
                  url: process.env.REACT_APP_BACKEND+"/partner/list"
              });
              
              let partnerArr = [];
              partner.data.forEach(elem => {
                  partnerArr.push({value: elem._id, label: elem.partner_name+", "+elem.stadt});
              });
              setPartnerListeModal(partnerArr);
          }
          catch(error) {
              Swal.fire({
                  icon: "error",
                  title: "Partner Liste",
                  html: "Fehler beim Abrufen der Partner Liste.",
              });
          }
      }

      if(currentUser) { 
        getPartner();
      }
  
  }, [currentUser])



  const handleNewConnect = () => {

      console.log(selectedPartner);

          // Status in CRM Tracken
          axios({
              method: 'post',
              headers: {
                  'Authorization': 'Bearer ' + currentUser.accessToken
              },
              url: process.env.REACT_APP_BACKEND+"/partner/connect",
              data: {
                  partner_id: selectedPartner.value
              }
          })
          .then(res => {
 
              /*
              const docRef = doc(db, "Users", currentUser.uid);
          
              //https://firebase.google.com/docs/reference/js/v8/firebase.firestore.FieldValue#arrayunion
              updateDoc(docRef, {
                  partner: firebase.firestore.FieldValue.arrayUnion({id: partner_id, partner_name: selectedPartner.label.split(", ")[0] }),
              })
              .catch (error => {
                  Swal.fire({
                      icon: "error",
                      title: 'Fehler',
                      html: 'Fehler beim Verbinden mit Partner: '+error
                  });
              });
              */
              
              // Create Partner Object in Firebase Doc
              //selectedPartner.label.split(", ")[0]
              //partner: [];
              /*
              partner: [
                  {
                      partner_name: selectedPartner.label.split(", ")[0],
                      partner_id: selectedPartner.value
                  },
                  {...}
              ];
              */

              //liste.push({value: elem.partner_name, label: elem.partner_name})

              //aktuelle PartnerListe
              setPartnerListe([ ...partnerListe, {
                      partner_name: res.data.partner_name,
                      slug: res.data.slug,
                      stadt: res.data.stadt,
                      aboNeedsAccept: res.data.aboNeedsAccept,
                      aboStatus: res.data.aboStatus, // is ignored if not aboNeedsAccept
                      //label: selectedPartner.label,
                      //value: selectedPartner.value
                  }
              ]);

              //Reset Select
              setSelectedPartner(null);
            
              if(res.data.aboNeedsAccept)
              {
                Swal.fire({
                  icon: "success",
                  title: "Ausstehende Bestätigung",
                  html: "Erfolgreich mit Partner <b>"+selectedPartner.label+"</b> verbunden! Du musst aber noch die Bestätigung abwarten.",
                });
              }
              else
              {
                Swal.fire({
                    icon: "success",
                    title: "Partner verbinden",
                    html: "Erfolgreich mit Partner <b>"+selectedPartner.label+"</b> verbunden!",
                });
              }

          })
          .catch(error => {

              if(error.response?.data?.error == "Bereits mit Partner verbunden") {
                  Swal.fire({
                      icon: "info",
                      title: "Partner verbinden",
                      html: "Du bist bereits mit Partner:<br />"
                          +"<b>"+selectedPartner.label+"</b> verbunden.",
                  });
              } else {
                  Swal.fire({
                      icon: "error",
                      title: "Partner verbinden",
                      html: "Fehler beim Verbinden mit Partner:<br />"
                          +"<b>"+selectedPartner.label+"</b>",
                  });
              }
          })

  }


  return (
    <>
      {location.pathname !== '/connect/unternehmens-connect' &&
        <Row className="justify-content-center">
            <Col xs={12} md={12} style={{ marginTop: "20px" }}>
            <CardDoppelt
                url={""}
                img={"Gewerbe.png"}
                title={"Unternehmens-Connect"}
                text={
                  <>
                      <p>
                        Hier kannst du dich mit neuen Partnern verbinden und anschließend den Partner Bereich nutzen, wie Dokumente oder Dateien senden.
                      </p>
                      {/*<Button onClick={() => setShowModal(true)} style={{background: "var(--primary-color)", margin: "0px", border: "var(--primary-color)"}}>Partner Connect</Button>*/}
                      <Button
                        style={{background: "var(--primary-color)", margin: "0px", border: "var(--primary-color)"}}
                        onClick={() => navigate("/connect/unternehmens-connect")}
                      >
                        Jetzt verbinden 
                      </Button>
                  </>
                }
            />
            </Col>
        </Row>
      }

        {/*showModal &&
          <PartnerConnectModal showModal={showModal} setShowModal={handleShowModal} />
        */}
      
      {location.pathname === '/connect/unternehmens-connect' &&

        <Row className="d-flex justify-content-center text-center">
          <Col>
            <h5>Verbinde Dich mit einem Partner</h5>
              
            <p>Wähle im folgenden Dropdown einen Partner aus. Du kannst durch Eingaben in dem Feld nach Partnern suchen.</p>

            <div
              style={{
                position: "relative",
                zIndex: 2
              }}
            >
              <SelectInput
                value={selectedPartner ? selectedPartner : null}
                placeholder={"Suche nach einem Partner..."}
                noOptionsMessage={"Kein Partner gefunden."}
                options={partnerListeModal}
                handleSelectChange={handlePartnerChangeModal}
                classNamePrefix="react-select-custom"
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}     //important
              
              />
            </div>

            <div className="pointer mt-4" onClick={handleNewConnect}>
              <VINButton text={"Jetzt verbinden"} />
            </div>

          </Col>
          
        </Row>   
      }

    </>
  )
}

export default PartnerConnectCard