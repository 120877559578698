import React, { useContext, useState } from "react";
import Header from "../Header/Header";
import Spinner from "../Bausteine/Spinner/Spinner";

import CardUpload from "../Card/CardUpload";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import { Container, Row, Col, Button, Modal } from "react-bootstrap";

import axios from "axios";
import Swal from "sweetalert2";

import QrcodeSponsor from "../Bausteine/QrcodeSponsor";

import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "@firebase/storage";
import { db, storage } from "../../firebase";
import {
  collection,
  doc,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { makeID } from "../../util/makeID";
import { AuthContext } from "../../store/auth-context";
import { UserContext } from "../../store/user-context";
import VINButton from "../../VINButton";
import CustomModal from "../Bausteine/CustomModal";




function Cashback() {

  const { user } = useContext(UserContext);
  const { currentUser } = useContext(AuthContext);

  const navigate = useNavigate();

  const [spinner, setSpinner] = useState(false);

  const [persoID, setPersoID] = useState("");
  const [pickedVorne, setPickedVorne] = useState({});
  const [pickedHinten, setPickedHinten] = useState({});
  const [b64Vorne, setB64Vorne] = useState("");
  const [b64Hinten, setB64Hinten] = useState("");

  const [descVorne, setDescVorne] = useState("");
  const [descHinten, setDescHinten] = useState("");

  const [submitted, setSubmitted] = useState(false);

  const [acceptedTippgeber, setAcceptedTippgeber] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const [showAusweisnummerHinweis, setShowAusweisnummerHinweis] = useState(false);

  /*
  const downloadSVG = () => {
    ReactDOM.findDOMNode(this.wrapper).startDownload({
      width: 400,
      height: 400,
      filename: 'somefile.png'
    });
  }
  */


  /*
  const svgRef = useRef();

  function downloadBlob(blob, filename) {
    const objectUrl = URL.createObjectURL(blob);
 
    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
 
    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
 }

  const downloadSVG = useCallback(() => {
    const content = svgRef.current.children[0].innerHTML;
    const contentWithSvg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" height="128" width="128" viewBox="0 0 29 29">
  ${content}
 </svg>`;
    const blob = new Blob([contentWithSvg], { type: "image/svg+xml" });
    downloadBlob(blob, `qrcode-sponsor.svg`);
 }, []);
 */




  function showDownloadModal () {
    setShowModal(!showModal);
  }

  async function downloadPDF(filepath, filename) {

    fetch(filepath).then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = filename;
          alink.click();
      })
    })
  }


  function handleFileVorne(e) {
    setPickedVorne({
      id: makeID(8),
      file: e.target.files[0],
      name: e.target.files[0].name,
      size: e.target.files[0].size,
      type: e.target.files[0].type,
      path: e.target.value
    });

    getBase64(e.target.files[0], (res) => {
      //console.log(res);
      setB64Vorne(res);
    });
  }

  function handleFileHinten(e) {
    setPickedHinten({
      id: makeID(8),
      file: e.target.files[0],
      name: e.target.files[0].name,
      size: e.target.files[0].size,
      type: e.target.files[0].type,
      path: e.target.value
    });
    getBase64(e.target.files[0], (res) => {
      //console.log(res);
      setB64Hinten(res);
    });
  }

  function removeFileUploadHandler(id) {
    //console.log("remove name: " + name);
    if (pickedVorne.id === id) {
      setPickedVorne({});
      setB64Vorne("");
    }
    if (pickedHinten.id === id) {
      setPickedHinten({});
      setB64Hinten("");
    }
  }


  function handleFileUpload(file, filename, which) {

    let UUID = crypto.randomUUID();

    if (file) {
      const storageRef = ref(
        storage,
        "/" + currentUser.uid + "/" + UUID + filename
      );

      //console.log("REF: " + storageRef);

      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          //console.log(snapshot);
        },
        (err) => console.log(err),
        () => {
          // Hier kann tein Callback definiert werden, nach erfolgreichem Upload
          // Erstellen des "Meta-Dokuments zum Upload"
          //console.log("Referenz zur Datei: ");
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            //console.log(file);
            //console.log("AUTH: " + auth.currentUser.uid);

            if (which === "Vorne") {
              addDoc(collection(db, "Users", currentUser.uid, "Privat"), {
                title: UUID + filename,
                description: "Ausweis Vorderseite",
                uploader: "self",
                category: "Ich",
                sub_category: "Ausweis",
                created: new Date(),
                mimeType: file.type,
                size: file.size,
                url: url,
              });
            } else if (which === "Hinten") {
              addDoc(collection(db, "Users", currentUser.uid, "Privat"), {
                title: UUID + filename,
                description: "Ausweis Rückseite",
                uploader: "self",
                category: "Ich",
                sub_category: "Ausweis",
                created: new Date(),
                mimeType: file.type,
                size: file.size,
                url: url,
              });
            }

            //console.log(url);
            /*
                if(which == "Vorne") {
                  const docRef = addDoc((db, "Users", "Privat"), {
                    catagory: "Ich",
                    sub_category: "Ausweis",
                    description: descVorne,
                    mimeType: pickedVorne.type,
                    created: new Date(),
                    title: crypto.randomUUID()+filename,
                    uploader: "self",
                    url: url
                  });
                  console.log("Document written with ID: ", docRef.id);
                } else if (which == "Hinten") {
                  const docRef = await addDoc((db, "Users", "Privat"), {
                    catagory: "Ich",
                    sub_category: "Ausweis",
                    description: descVorne,
                    mimeType: pickedVorne.type,
                    created: new Date(),
                    title: crypto.randomUUID()+filename,
                    uploader: "self",
                    url: url
                  });
                  console.log("Document written with ID: ", docRef.id);
                }
                */
          });
        }
      );
    }
  }

  function getBase64(file, cb) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }



  const handleSubmit = async () => {

    if (
      persoID !== "" &&
      pickedVorne !== "" &&
      pickedHinten !== "" &&
      b64Vorne !== "" &&
      b64Hinten !== "" &&
      acceptedTippgeber
    ) {

      //Absenden
      let data = {
        PERSOID: persoID,
        FRONT: b64Vorne,
        BACK: b64Hinten,
        FILETYPE_FRONT: pickedVorne.type,
        FILETYPE_BACK: pickedHinten.type
      };


      setSpinner(true);

        // Perso Bilder hinterlegen + "Tippgeber Verifizierung in CRM hinterlegen"
        let res = await axios({
          method: 'post',
          headers: {
            'Authorization': 'Bearer ' + currentUser.accessToken
          },
          url: process.env.REACT_APP_BACKEND+"/my-vin1/perso-verifizierung",
          data: data
        });


        // CRM Checkbox setzen Tippgeber verifiziert
        await axios({
          method: 'post',
          headers: {
            'Authorization': 'Bearer ' + currentUser.accessToken
          },
          url: process.env.REACT_APP_BACKEND+"/my-vin1/update-status",
          data: {
            "update": "tippgeber",
            "googleuid": currentUser.uid
          }
        });
          
          
        if(res.data.status == "success") {

            /*
            //Bestätigungsemail versenden
            var bodyFormData2 = new FormData();
            bodyFormData2.append('kundennummer', user.kdnr);
            bodyFormData2.append('email', user.email);
            bodyFormData2.append('templateId', 4667);
            axios({
                method: 'post',
                url: "https://api.mariusschulte.com/crm/sendMail.php",
                data: bodyFormData2
            });
            */

          // ALT, durch neues CRM userCtx abgelöst
          // State anpassen damit direkt geändert
          //let statusUpdate = await setDoc(doc(db, "Users", currentUser.uid), {
          //  verifiziert: "1"
          //}, { merge: true });

          /*
          .then(DocRef => {
            //console.log("Document has been added successfully");
          })
          .catch(error => {
              //console.log(error.message);
          });
          */

          handleFileUploadPDF("pdf/Tippgebervertrag_VIN1.pdf", "Tippgebervertrag_VIN1.pdf");

      
          setSpinner(false);


          //Speicherdialog Perso
          Swal.fire({
            title: "Erfolg",
            html: "Super, dass du deine Daten übermittelt hast. "+
                  "Ab sofort ist es dir möglich neue Kunden zu gewinnen und Cashback zu empfangen. "+
                  "Soll dein Personalausweis in deiner persönlichen Dokumentenablage gespeichert werden (empfohlen)?",
            icon: "success",
            showDenyButton: true,
            confirmButtonText: "Speichern",
            denyButtonText: "Nicht speichern",
          })
          .then(async (result) => {
            if (result.isConfirmed) {

              Swal.fire({
                title: "Dokumente",
                html: "Dein Personalausweis wurde erfolgreich abgespeichert.",
                icon: "success"
              });

              handleFileUpload(pickedVorne.file, pickedVorne.name, "Vorne");
              handleFileUpload(pickedHinten.file, pickedHinten.name, "Hinten");
            } else if (result.isDenied) {
              Swal.fire({
                html: "Deine Daten wurden nicht in deiner privaten Dokumentenablage gespeichert.",
                icon: "info",
              });
            }
          });

          setSubmitted(!submitted);

        } 
        else
        {
          setSpinner(false);
          Swal.fire({
            title: "Formular",
            html: "Deine Daten konnten aufgrund eines Verbindungsfehlers nicht übermittelt werden. Bitte versuche es später erneut.",
            icon: "error",
          });
        }
      
          
      setSpinner(false);
        
    } // if Pflichtfelder
    else
    {
      setSpinner(false);
      Swal.fire({
        title: "Pflichtfelder",
        html: "Bitte fülle alle Felder aus und sende das Formular erneut ab.",
        icon: "info",
      });
      return;
    }

    setSpinner(false);
  };




  function handleFileUploadPDF(filepath, filename) {


    let UUID = crypto.randomUUID();
    if (filepath) {
      const storageRef = ref(
        storage,
        "/" + currentUser.uid + "/" + UUID + filename
      );

      //console.log(storageRef);
      //console.log("/" + currentUser.uid + "/" + UUID + filename);

      var url = filepath;

      //You may not need this part if you have the PDF data locally already
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
              //console.log(this.response, typeof this.response);
              //contentType expplizit setzten, sonst Rule Fehler wegen falschem Content type!
              var blob = new Blob([this.response], { type: 'application/pdf' });
              
              var fileOfBlob = new File([blob], UUID + filename, { type: blob.type });//var fileOfBlob = new File([this.response], 'your_file.pdf');
             
        const uploadTask = uploadBytesResumable(storageRef, fileOfBlob);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            //console.log(snapshot);
          },
          (err) => console.log(err),
          () => {

            getDownloadURL(uploadTask.snapshot.ref).then((url) => {


                addDoc(collection(db, "Users", currentUser.uid, "Privat"), {
                  title: UUID + filename,
                  description: "",
                  uploader: "app",
                  category: "Cashback",
                  sub_category: "Verträge",
                  created: new Date(),
                  mimeType: "application/pdf",
                  size: fileOfBlob.size,
                  url: url,
                });
              
            });
          }
        );
        // Blob
    //     })
    //  }) 
    // /Blob

      }
    }
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();  


  } //filepath
}


  // ----------------------------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Header url={"/"} />
      <Container style={{ maxWidth: "800px", marginBottom: "160px" }}>

        {(!user.tippgeber && !submitted) && (
          <>
            <Row style={{ justifyContent: "center" }}>
              <Col className="text-center">
                <img
                  src={require("../../img/Cashback.png")}
                  alt=""
                  style={{ maxWidth: "120px", marginTop: "50px" }}
                />
                <h1 style={{ color: "grey" }}>Cashback</h1>
                <br />
                <p>
                  Bevor Du berechtigt bist Cashback zu empfangen,
                  <br />
                  musst Du Dich als Tippgeber registrieren und unseren Verifizierungsprozess abschließen.
                  <br />
                  Dafür benötigen wir ein paar Informationen von Dir.
                </p>

                <br />

                <p className="text-center"><span className="fw-bold">1.</span><br />
                  Trage Deine Ausweisnummer ein.
                  <br />
                  <span
                    onClick={() => setShowAusweisnummerHinweis(true)}
                    style={{fontSize: "85%", textDecoration: "underline", color: "var(--primary-color)"}}
                  >
                    Wo finde ich meine Ausweisnummer?
                  </span>
                </p>    

                <label htmlFor="persoID">Deine Ausweisnummer</label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    style={{ width: "80%", textAlign: "left" }}
                    id="persoID"
                    className="form-control"
                    placeholder="Deine Ausweisnummer"
                    type="text"
                    onChange={(e) => setPersoID(e.target.value.trim())}
                  />
                </div>

                <br />
                <br />
                



                <p className="text-center"><span className="fw-bold">2.</span><br />
                  Nimm zunächst ein Foto von der Vorder- & Rückseite Deines
                  Personalausweises auf.<br />
                  Stelle sicher, dass die Bilder scharf und hell genug sind,
                  sodass die Daten mit Deinem Kundenkonto abgeglichen werden
                  können.
                </p>  

                <br />

                  <p>
                  Bitte lade ein Bild von der <b>Vorderseite</b> Deines
                  Personalausweises über diesen Button hoch.
                  <br />
                  {/*<span className="fw-normal">Du kannst eine Beschreibung vergeben, um das Dokument beim Upload direkt in deinen Notfallkoffer zu importieren.</span>*/}
                </p>

                {pickedVorne.name && (
                  <CardUpload
                    key={pickedVorne.id}
                    data={pickedVorne}
                    setDesc={setDescVorne}
                    removeFileUploadHandler={removeFileUploadHandler}
                  />
                )}
                {!pickedVorne.name && (
                  <p>Es wurde noch kein Dokument ausgewählt.</p>
                )}
                <label
                  htmlFor="docUploadVorne"
                  className="filePicker"
                >
                  Dokument auswählen
                  <input
                    id="docUploadVorne"
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{ display: "none" }}
                    onChange={handleFileVorne}
                  />
                </label>

                <br />
                <br />
                <br />

                <p>
                  Bitte lade ein Bild von der <b>Rückseite</b> Deines Personalausweises
                  über diesen Button hoch.
                  <br />
                  {/*<span className="fw-normal">Du kannst eine Beschreibung vergeben, um das Dokument beim Upload direkt in deinen Notfallkoffer zu importieren.</span>*/}
                </p>
                

                {pickedHinten.name && (
                  <CardUpload
                    key={pickedHinten.id}
                    data={pickedHinten}
                    setDesc={setDescHinten}
                    removeFileUploadHandler={removeFileUploadHandler}
                  />
                )}
                {!pickedHinten.name && (
                  <p>Es wurde noch kein Dokument ausgewählt.</p>
                )}

                <label
                  htmlFor="docUploadHinten"
                  className="filePicker"
                >
                  Dokument auswählen
                  <input
                    id="docUploadHinten"
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{ display: "none" }}
                    onChange={handleFileHinten}
                  />
                </label>

              </Col>
            </Row>

            <br />
            <br />


            <Row>
              <Col className="text-center">

                <p className="text-center"><span className="fw-bold">3.</span><br />
                Bestätigung Tippgeber<br />
                Jetzt bestätigen, absenden und Du hast es geschafft.</p>

                <label className="form-check-label" style={{marginTop: "20px"}}>
                    <input
                        id="cbTippgeber"
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => setAcceptedTippgeber(!acceptedTippgeber)}
                        />   
                    <span style={{paddingLeft: "10px"}}>Ich habe den&nbsp;
                    <span style={{color: "#0d6efd", textDecoration: "underline"}} onClick={showDownloadModal}>Tippgebervertrag</span>&nbsp;
                      und die <span style={{color: "#0d6efd", textDecoration: "underline"}} onClick={showDownloadModal}>AGB</span> gelesen und akzeptiere die Verträge und Bestimmungen.</span>
                </label>

              </Col>
            </Row>

            <Row className="justify-content-center mt-5">
              <Col style={{ textAlign: "center" }}>
              
                {/*<Button
                  style={{ background: "#520da7", padding: "15px", width: "80%", fontWeight: "bold"}}
                  onClick={() => {
                    //setSpinner(true);
                    handleSubmit();
                  }}
                >
                  Absenden
                </Button>
                */}

                <div className="pointer" onClick={!spinner ? handleSubmit : null}>
                  <VINButton text={"Absenden"} />
                </div>
              </Col>
            </Row>
          </>
        )}




        {(user.tippgeber || submitted) && (
          <>
            <Row style={{ justifyContent: "center" }}>
              <Col className="text-center">
                <img
                  src={require("../../img/Cashback.png")}
                  alt=""
                  style={{ maxWidth: "120px", marginTop: "50px" }}
                />
                <h1 style={{ color: "grey" }}>Cashback</h1>
                <br />
                <p>
                  Herzlichen Glückwunsch!
                </p>
                <p>
                  Hier findest du Informationen zu deinem Cashback.
                  </p>
                  <p className="text-center"><span className="fw-bold">Wichtiger Hinweis:</span> Deine Kundennummer {user.kdnr} ist deine Sponsor-ID!</p>
                
                  <br />
                  <Button
                    style={{ background: "", marginRight: "8px", padding: "15px", width: "auto", fontWeight: "bold"}}
                    onClick={() => navigate("/dokumente/privat", { state: { "category": "Cashback" }} )}
                  >
                    Meine Abrechnungen
                  </Button>
                  <Button
                    style={{ background: "", marginLeft: "8px", padding: "15px", width: "auto", fontWeight: "bold"}}
                    onClick={() => navigate("/vertriebsnews", { state: { "category": "Cashback" }} )}
                  >
                    Partner News
                  </Button>
                  
             
              </Col>
            </Row>

            <Row style={{ justifyContent: "center", marginTop: "50px"}}>
                <Col>
                  <h3 className="text-center">Dein Sponsor-Code</h3>
                  <br />
                  <QrcodeSponsor partner={user.kdnr} />
                </Col>
              </Row>
          </>
        )}


        {spinner &&
          <Spinner
            message={"Bitte warten. Daten werden verarbeitet."}
          />
        }     

      </Container>


      <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
        <Modal.Header closeButton>
          <Modal.Title>Download Verträge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p style={{marginTop:"0px"}} className="fw-bold">
                  Tippgebervertrag
              </p>
              <div style={{}}>
                <div style={{cursor: "pointer"}} onClick={() => downloadPDF("pdf/Tippgebervertrag_VIN1.pdf", "Tippgebervertrag_VIN1.pdf")}>
                  <span style={{fontSize: "16px", textDecoration: "underline" }}>Download Tippgebervertrag (PDF)</span>
                  <FontAwesomeIcon icon={faDownload} size="lg" style={{marginLeft: "10px"}} />
                </div>
              </div>


              <p style={{marginTop:"40px"}} className="fw-bold">
                AGB
              </p>
              <div style={{}}>
                <div style={{cursor: "pointer"}} onClick={() => downloadPDF("pdf/AGB_VIN1.pdf", "AGB_VIN1.pdf")}>
                  <span style={{fontSize: "16px", textDecoration: "underline" }}>Download AGB (PDF)</span>
                  <FontAwesomeIcon icon={faDownload} size="lg" style={{marginLeft: "10px"}} />
                </div>
              </div>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
            onClick={() => setShowModal(!showModal)}
            >
            Schließen
          </p>
        </Modal.Footer>
      </Modal>


      {showAusweisnummerHinweis && 
        <CustomModal 
            title={"Wo finde ich meine Ausweisnummer?"}
            visible={showAusweisnummerHinweis}
            setVisible={setShowAusweisnummerHinweis}
        > 
            <p>Du findest deine Ausweisnummer an der markierten Stelle im Bild:</p>
            <img src={require("./Hinweis_Ausweisnummer.jpeg")} style={{maxWidth: "100%"}} />
           
        </CustomModal>
      }

    </>
  );
}

export default Cashback;
