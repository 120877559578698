import React, { useMemo, useState, useEffect, useRef } from 'react'
import { GoogleMap, MarkerF, InfoWindowF, CircleF, useJsApiLoader, Circle } from '@react-google-maps/api';
import { RouletteSpinner } from 'react-spinner-overlay';

import { Row, Col, Button }  from "react-bootstrap";
import { faSquarePollHorizontal } from '@fortawesome/free-solid-svg-icons';
/*
import Parked from "../assets/images/parked.png";
import Incident from "../assets/images/IncidentsDrones.png";
import InAir from "../assets/images/IntheAirDrones.png";
import Mission from "../assets/images/missionmarker.png";
import Offline from "../assets/images/offlinedrones.png";
*/


function GoogleMapsKarte(props) {

  const [isVisible, setIsVisible] = useState(true);


  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBq_sSUm7eHIavy2IRO3VJlDHyLOG6agIE" // ,
    // ...otherOptions
  })

  const containerStyle = {
    width: "100%",
    height: "500px",
  };


  //ref center change
  const mapRef = useRef(null);


  //const centerMemo = useMemo((props.center) => (props.center), []);
  
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState("");

  const [radius, setRadius] = useState(1000);
  const [zoom, setZoom] = useState(14);  
  const [center, setCenter] = useState();
  // Wenn Karte verschoben wird und neues Center festgelegt wird
  const [newCenter, setNewCenter] = useState();
  
  const [startPositionChanged, setStartPositionChanged] = useState(false);

  
  const [rerenderMap, setRerenderMap] = useState(0);

  const [radiusCircle, setRadiusCircle] = useState(true);

  useEffect(() => {
    setZoom(props.zoom);
    setCenter(props.center);

    const newMarkerList = props.markers.map(marker => ({
        id: marker._id,
        name: marker.name,
        anschrift: marker.anschrift,
        plz: marker.plz,
        stadt: marker.stadt,
        vorteilOffline: marker.vorteilOffline,
        coordinates: marker.koordinaten.coordinates
      }
    ));

    setMarkers(newMarkerList);

  }, [props])




  const handleCenterChanged = (center) => {
      if (map && map.getCenter()) {
        const newCenter = map.getCenter().toJSON();
        setNewCenter(newCenter);
      }
  } 

  const handleNewCenter = () => {
    props.handleCenterChange(newCenter);
    //Reload Markers für neues Center
  }

  /*
  useEffect(() => {

    setRadiusCircle(prevVal => false);
    setRadiusCircle(prevVal => true);
    //setRerenderMap(prevVal => prevVal+1);

  }, [radius, center])
  */


/*
  const handleNewMarker = () => {
    setMarkers([...markers, {
      id: 5,
      name: "Holländer Hof",
      status: "Gastro",
      position: {
        lat: 51.3022613,
        lng: 8.1310291
      }
    }])
  }
  */


  /*
  const handleRemoveMarker = () => {
    setMarkers([markers.filter(marker => marker.id != 5)])
  }
  */

  const handleNavigation = (selMarker) => {
    window.open(
      "https://www.google.de/maps/search/"+selMarker.name+', '+selMarker.anschrift+', '+selMarker.plz+', '+selMarker.stadt,
      "_blank",
      "noopener,noreferrer"
    );
  };


  //Referenz zur Map+Circle und dann Änderungen durchführen.
  //https://developers.google.com/maps/documentation/javascript/reference/polygon?hl=de#Circle.getBounds
  const [ map, setMap ] = useState(null);
  const [ circle, setCircle ] = useState(null);
  
  const handleRadiusChange = (newRadius) => {

    //Ruft die LatLngBounds dieses Kreises ab.
    //console.log(circle.getBounds());

    let circleOptions = {strokeColor: "var(--primary-color)",strokeOpacity: 0.5,strokeWeight: 2,fillColor: "var(--primary-color)",fillOpacity: 0.2};

    // Zoom-Ansicht anpassen, Style neu setzten, sonst von groß auf kleine Auflösung Kreis unscharf.
    if(newRadius == 1000) {
      map.setZoom(14.4);
      circle.setOptions(circleOptions);
    }
    else if(newRadius == 2000) {
      map.setZoom(13.4);
      circle.setOptions(circleOptions);
    }
    else if(newRadius == 5000) {
      map.setZoom(12);
      circle.setOptions(circleOptions);
    }
    else if(newRadius == 10000) {
      map.setZoom(11.1);
      circle.setOptions(circleOptions);
    }
    else if(newRadius == 25000) {
      map.setZoom(9.7);
      circle.setOptions(circleOptions);
    }
    else if(newRadius == 50000) {
      map.setZoom(8.75);
      circle.setOptions(circleOptions);
    }
    else if(newRadius == 100000) {
      map.setZoom(7.7);
      circle.setOptions(circleOptions);
    }
    else if(newRadius == 250000) {
      map.setZoom(6.4);
      circle.setOptions(circleOptions);
    }
    else if(newRadius == 500000) {
      map.setZoom(5.3);
      circle.setOptions(circleOptions);
    }
    else if(newRadius == 750000) {
      map.setZoom(4.8);
      circle.setOptions(circleOptions);
    }
    else if(newRadius == 1000000) {
      map.setZoom(4.4);
      circle.setOptions(circleOptions);
    }
    else if(newRadius == 3000000) {
      map.setZoom(2.5);
      circle.setOptions(circleOptions);
    }

     // Zuerst Zoom anpassen, da sonst der Kreis unscharf wird, die Werte werden wohl in Anlehung an die Auflösung der Karte gesetzt (absolute Werte);
     //Ändert den Radius auf der Map
     circle.setRadius(parseInt(newRadius));

     //Ändert den Selection Radius
     setRadius(newRadius);
  }
  
  
/*
  const [markers, setMarkers] = useState([
    {
      id: 1,
      name: "Olympia Grill Nordkirchen",
      status: "parked",
      position: {
        lat: 51.7384763,
        lng: 7.5248376,
      },
    },
    {
      id: 2,
      name: "VIN Center",
      status: "inair",
      position: {
        lat: 51.709610,
        lng: 7.549220,
      },
    },
    {
      id: 3,
      name: "Denver, Colorado",
      status: "incident",
      position: {
        lat: 30.2553,
        lng: 78.0972,
      },
    }
  ]);
*/
 

  /*
  const [changeMyTypeID, setToggleChangeMyTypeID] = useState(1);
  const mapRef = useRef(null);

  const onMapLoad = (mapInstance) => {
    mapRef.current = mapInstance;
  };
  console.log("mapRefCurrent", mapRef.current);

  const MapType = {
    roadmap: "roadmap",
    satellite: "satellite",
    hybrid: "hybrid",
    terrain: "terrain",
  };
  const handleMapToggle = () => {
    if (changeMyTypeID === 0) {
      setToggleChangeMyTypeID(1);
    } else if (changeMyTypeID === 1) {
      setToggleChangeMyTypeID(2);
    } else if (changeMyTypeID === 2) {
      setToggleChangeMyTypeID(3);
    } else if (changeMyTypeID === 3) {
      setToggleChangeMyTypeID(4);
    } else if (changeMyTypeID === 4) {
      setToggleChangeMyTypeID(1);
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      if (changeMyTypeID === 1) {
        mapRef.current.setMapTypeId(MapType.roadmap);
      } else if (changeMyTypeID === 2) {
        mapRef.current.setMapTypeId(MapType.terrain);
      } else if (changeMyTypeID === 3) {
        mapRef.current.setMapTypeId(MapType.satellite);
      } else if (changeMyTypeID === 4) {
        mapRef.current.setMapTypeId(MapType.hybrid);
      }
    }
  }, [changeMyTypeID]);
  console.log(changeMyTypeID);
  */




  return (
    <>
    <Row style={{ justifyContent: "center", margin: "30px 0px 30px 0px"}}>
      <Col sm={12} md={12}>
        <p>Suchradius wählen</p>
        <select
          className="form-select"
          value={radius}
          onChange={(e) => {
            handleRadiusChange(e.target.value);
            props.handleRadius(e.target.value); // Neue Partner abrufen
          }}
        >
          <option value={1000} key={0}>1km</option>
          <option value={2000} key={1}>2km</option>
          <option value={5000} key={2}>5km</option>
          <option value={10000} key={3}>10km</option>
          <option value={25000} key={4}>25km</option>
          <option value={50000} key={5}>50km</option>
          <option value={100000} key={6}>100km</option>
          <option value={250000} key={7}>250km</option>
          <option value={500000} key={8}>500km</option>
          <option value={750000} key={9}>750km</option>
          <option value={1000000} key={10}>1000km</option>
          <option value={3000000} key={11}>3000km</option>
        </select>
      </Col>
    </Row>

    {!isLoaded && (
      <div>
        <p>Lade Karte ...</p>
      </div>
    )}

  {/*
    <button onClick={handleNewMarker}>Add Marker</button>
    <button onClick={handleRemoveMarker}>Remove Marker</button>
    <button onClick={handleRadiusChange}>Change Radius</button>
  */}

    {isLoaded && (
      <>
        <GoogleMap
          key={rerenderMap}
          zoom={zoom}
          center={center}
          mapContainerStyle={containerStyle}
          onLoad={(map) => setMap(map)}
          onCenterChanged={() => handleCenterChanged(map)}
          //onChange={handleMapChange}
          //onGoogleApiLoaded={({ map }) => (mapRef.current = map)}
        >

        
          {radiusCircle &&
            <CircleF  
                center={center}
                radius={radius}
                onLoad={(circle) => setCircle(circle)}
                options={{
                  strokeColor: "var(--primary-color)",
                  strokeOpacity: 0.5,
                  strokeWeight: 2,
                  fillColor: "var(--primary-color)",
                  fillOpacity: 0.2,
                }}
                visible={true}
            />
          }


          {markers.map((marker) => {
            return (
              <div key={marker._id}>
                <MarkerF
                  position={{lat: marker.coordinates[1], lng: marker.coordinates[0]}}
                  onClick={() => {
                    setSelectedMarker(marker);
                  }}
                />
              </div>
            );
          })}

          <MarkerF
            position={center}
            icon={{
              url: "http://maps.google.com/mapfiles/ms/micons/purple-pushpin.png"
            }}
            style={{zIndex: "10000"}}
          />


          {selectedMarker && (
            <InfoWindowF
              position={{lat: selectedMarker.coordinates[1], lng: selectedMarker.coordinates[0]}}
              options={{
                pixelOffset: new window.google.maps.Size(0, -40),
              }}
              onCloseClick={() => setSelectedMarker(false)}
            >
              <div className="text-center" style={{ background: "white", zIndex: 5000, fontSize: "8px", minWidth: "250px"}}>
               
                <h6 style={{textAlign: "left"}}>Partner</h6>
                <h5 style={{textAlign: "left"}} className="mb-2">{selectedMarker.name}</h5>

                <h6 style={{textAlign: "left"}}>Anschrift</h6>
                <h6 className="mb-3" style={{textAlign: "left"}}>
                  {selectedMarker.anschrift}<br />
                  {selectedMarker.plz} {selectedMarker.stadt}
                </h6>

                <h6 style={{textAlign: "left"}}>Vorteil</h6>
                <h6 style={{textAlign: "left"}} className="mb-4">
                  {selectedMarker.vorteilOffline}<br />
                </h6>

                <Button style={{margin: "8px"}} onClick={() => setSelectedMarker(false)}>Schließen</Button>
                <Button style={{margin: "8px", background: "var(--primary-color)"}} onClick={() => handleNavigation(selectedMarker)}>Navigation</Button>
              </div>
            </InfoWindowF>
          )} 

        </GoogleMap>

        {newCenter &&
          <Row>
            <Col className="mt-4 d-flex justify-content-center">
              <Button style={{background: "#520DA7", padding: "8px 32px"}} onClick={handleNewCenter}>In diesem Kartenbereich suchen</Button>
            </Col>
          </Row>
        }
      </>
    )}
 </>
  );
}

export default GoogleMapsKarte