import React from 'react'

import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faSignOut  } from "@fortawesome/free-solid-svg-icons"

import style from "./Header.module.css"
import { useNavigate } from "react-router-dom";

//import  secureLocalStorage  from  "react-secure-storage";

function Header(props) {

    let navigate = useNavigate();

    const handleNavigateBack = () => {
        navigate(-1);
    }

   const handleBackWithSelection = (url, url_state) => {
        navigate(url, { state: url_state })
   }

    const handleNavigateBackDokumente = (url) => {
        navigate(url);
    }




    const handleNavigateHome = () => {
        navigate("/");
    }

    const handleLogout = () => {

        //unsubscribe && unsubscribe();
    
        signOut(auth)
        .then(() => {
            sessionStorage.clear();
            navigate("/login");
        })
        .catch((error) => alert(error.message));  
    

    }


  return (
    <header className={style.headerContainer}>
        <div className={style.headerInner}>
            
            <div
                className="d-flex justify-content-center align-items-center"
                style={{cursor: "pointer", width: "80px"}}
                onClick={() => {
                    if(props.url && !props.url_state) {
                        handleNavigateBackDokumente(props.url);
                    }
                    else if(props.url && props.url_state) {
                        handleBackWithSelection(props.url, props.url_state);
                    }
                    else {
                        handleNavigateBack()
                    }
                }}
            >
                <FontAwesomeIcon className={style.icon} icon={faChevronLeft} />
                <span className={style.onlyDesktop}>&nbsp;Zurück</span>
            </div>

            <div>
                <img src={require("../../img/VIN1_Logo_Lila.png")} alt="" width="100px" className={style.icon} onClick={handleNavigateHome} />
            </div>

            <div className="d-flex justify-content-right">
                <FontAwesomeIcon style={{width: "80px"}} className={style.icon} icon={faSignOut} onClick={handleLogout}  />
            </div>

        </div>
    </header>
  )
}

export default Header



export function HeaderWithoutAuth (props) {

    let navigate = useNavigate();

    const handleNavigateBack = () => {
        navigate(-1);
    }

    const handleNavigateLogin = () => {
        // Key wird hier nicht gefunden...
        //secureLocalStorage.removeKey(auth);
        navigate("/login");
    }

  return (
    <header className={style.headerContainer}>
        

        <div className={style.headerInner}>
            <div className="d-flex justify-content-center align-items-center" style={{cursor: "pointer", width: "80px"}} onClick={() => {handleNavigateBack()}}>
                <FontAwesomeIcon className={style.icon} icon={faChevronLeft} />
                <span className={style.onlyDesktop}>&nbsp;Zurück</span>
            </div>
            <img src={require("../../img/VIN1_Logo_Lila.png")} alt="" width="100px" className={style.icon} onClick={handleNavigateLogin} />
            <div style={{minWidth: "80px"}}></div>
        </div>
    </header>
  )
}




export function HeaderWithoutAuthOhneNavigation (props) {

  return (
    <header className={style.headerContainer}>
        
        <div className={style.headerInner}>
            <div style={{minWidth: "80px"}}></div>
            <img src={require("../../img/VIN1_Logo_Lila.png")} alt="" width="100px" className={style.icon} />
            <div style={{minWidth: "80px"}}></div>
        </div>
    </header>
  )
}

