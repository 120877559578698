import React from 'react'

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faXmark, faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";

import style from "./CardGesellschaften.module.css"
import classLister from 'css-module-class-lister';

import { Row, Col }  from 'react-bootstrap';


//import DownloadLink from 'react-download-link'
//import { getStorage, ref, getDownloadURL } from "firebase/storage";
//import { auth, db, storage  } from "../../firebase";
//import { deleteDoc, doc } from "firebase/firestore"; 

//import axios from 'axios';
//import Swal from 'sweetalert2'
//import Dokumente from '../DokumenteWeiterleitung';

function CardBeratung(props) {

  const classes = classLister(style);

  return (
   
      <div className={style.cardContainerUpload} style={{marginBottom: "20px"}}>
        <Row style={{width:"100%"}}>
            <Col style={{display: "flex", flex: "1", flexDirection: "column", alignItems: "flex-start", padding: "10px 0px 0px 15px"}}>
                <p className="fw-bold" style={{marginBottom: "5px", textAlign: "left"}}>Bereich: {props.kategorie}</p>
                <p style={{marginBottom: "5px", textAlign: "left"}}>
                  {props.beschreibung && <span>Nachricht: {props.beschreibung}</span>}
                  {!props.beschreibung && <span> - Keine Nachricht übermittelt -</span>}
                </p>
                <p>Übermittelt am: {new Intl.DateTimeFormat('de-DE', {
                      month: '2-digit',
                      day: '2-digit',
                      year: '2-digit',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric'
                    }).format(new Date(props.createdAt))}
                  </p>
            </Col>
        </Row>
      </div>
  )
}

export default CardBeratung
