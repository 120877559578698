import axios from 'axios';
import Swal from 'sweetalert2';


export default async function signMaklervertrag (currentUser, signatureVertragB64, signatureVollmachtB64) {

    if(!signatureVertragB64)
    {
        Swal.fire({
            icon: "info",
            title: "Maklervertrag",
            html: "Du musst den Maklervertrag unterschreiben, bevor du das Formular absenden kannst.",
        });
        return 1;
    }

    if(!signatureVollmachtB64)
    {
        Swal.fire({
            icon: "info",
            title: "Unterschrift Maklervollmacht",
            html: "Du musst die Maklervollmacht unterschreiben, bevor du das Formular absenden kannst.",
        });
        return 2;
    }

    try
    {
        await axios({
            method: 'post',
            headers: {
            'Authorization': 'Bearer ' + currentUser.accessToken
            },
            url: process.env.REACT_APP_BACKEND+"/my-vin1/sign-maklervollmacht",
            data: {
                'signature_maklervertrag': signatureVertragB64,
                'signature_maklervollmacht':  signatureVollmachtB64
            }
        });

        return true;
    }
    catch(err)
    {
        console.log(err);
        Swal.fire({
            icon: "error",
            title: "Maklermandat",
            html: "Fehler bei der Übertragung. Später erneut versuchen."
        });
        return err;
    } 

}