import React, { useState } from "react";

import Header from "../Header/Header";
import { Container, Row, Col, Button } from "react-bootstrap";

import Spinner from 'react-bootstrap/Spinner';


function ZinschartFrame() {

  const [loading, setIsLoading] = useState(true);

  return (
    <>
      <Header url={"/baufinanzierung"} />

      <Container style={{ maxWidth: "800px" }}>
        <Row style={{ justifyContent: "center" }}>
          <Col style={{ textAlign: "center" }}>
            <h1>Zinschart Baufinanzierung</h1>
          </Col>
        </Row>


        <Row style={{justifyContent: "center"}}>

            {loading && 
              <div className="text-center mt-3">
                <Spinner animation="border" role="status" />
                <p className="text-center">Wird geladen...</p>
              </div>
            }

            <iframe
              id="calculatorFrame"
              style={{
                  marginTop:"80px",
                  marginBottom: "80px",
                  width: "100%",
                  height: loading ? "0%" : "100%",
                  frameBorder: "0",
                  background: "white"
              }}
              src={"https://www.interhyp.de/iframe/marketing-partner/zinsverlauf/?LAF_PARTNER=TG_VIVAREVO_GERMANY&adCam"}
              onLoad={() => setIsLoading(false)}
            />

        </Row>

        <div style={{ marginBottom: "80px" }} />
      </Container>
    </>
  );
}

export default ZinschartFrame;
