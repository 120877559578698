import React, { useContext } from 'react'
import Header from '../Header/Header'
import { HeaderWithoutAuth } from '../Header/Header';

import { Container, Row, Col } from 'react-bootstrap';
import { UserContext } from '../../store/user-context';



function Impressum(props) {

  const { user } = useContext(UserContext);

  return (
    <>
      {user && <Header />}
      {!user && <HeaderWithoutAuth />}

      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col>
            <h1 style={{marginBottom: "30px"}}>Impressum</h1>
			
            <h5>Dieser Internetauftritt erhält Informationen der</h5>
            <p>VIN1 Germany GmbH<br />Aspastr.24 | D-59394 Nordkirchen<br />Telefon: +49(0) 2596/ 614 8181<br />E-Mail: hello@vin1.eu</p>
            <p><i>eingetragen im Handelsregister des Amtsgerichtes Coesfeld<br />Handelsregisternummer HRB 20746<br /></i><em>Vertreten durch die Geschäftsführerin Frau Adaleta Dinovic (M.Sc.)<br /></em></p>
            <p><strong>Vermittlerregister<br /></strong>(<a href="http://www.vermittlerregister.info">http://www.vermittlerregister.info</a>):<br />Register-Nr.: Bitte Anfragen (Technische Probleme bei der Vergabe durch die IHK) §34d Abs.1 GewO<br />Register-Nr.: Bitte Anfragen (Technische Probleme bei der Vergabe durch die IHK) §34i Abs.1 S.1 GewO</p>
            <p><strong>Die Eintragung im Vermittlerregister kann überprüft werden:</strong><br />Deutscher Industrie- und Handelskammertag (DIHK) e.V.<br />Breite Straße 29<br />10178 Berlin<br />Telefon: 0180 600 585 0<br />(20 Cent/Anruf aus dem dt. Festnetz, höchstens 60 Cent/Anruf aus Mobilfunknetzen)<br /><a href="http://www.vermittlerregister.info">www.vermittlerregister.info</a></p>
            <p><strong>Berufsbezeichnung und berufsrechtliche Regelungen<br /></strong>Versicherungsmakler mit Erlaubnis nach § 34d Abs. 1 Gewerbeordnung; Bundesrepublik Deutschland<br />Immobiliardarlehensvermittler nach § 34i Abs. 1 S. 1 Gewerbeordnung; Bundesrepublik Deutschland<br />Zuständige Kammer: IHK Nord Westfalen, Münster</p>
            <p><strong>Berufsrechtliche Regelungen:</strong></p>
            <ul>
            <li>34d Gewerbeordnung (GewO)</li>
            <li>§ 59-68 Gesetz über den Versicherungsvertrag (VVG)</li>
            </ul>
            <p>Verordnung über die Versicherungsvermittlung und -beratung (VersVermV)</p>
            <p>Die berufsrechtlichen Regelungen können über die vom Bundesministerium der Justiz und von der juris GmbH betriebenen Homepage <a href="http://www.gesetze-im-internet.de">http://www.gesetze-im-internet.de</a> eingesehen und abgerufen werden.</p>
            <p><strong>Angaben zur Berufshaftpflichtversicherung</strong></p>
            <p><strong>Name und Sitz des Versicherers: <br /></strong>andsafe Aktiengesellschaft<br />Provinzial-Allee 1<br />48159 Münster</p>
            <p><strong>Geltungsraum der Versicherung: <br /></strong>In den geografischen Grenzen Europas sowie den außereuropäischen Gebieten, die zum Geltungsbereich der Europäischen Union gehören</p>
            <p><strong>Schlichtungsstellen, die bei einem Streit angerufen werden können:<br /></strong></p>
            <p>Versicherungsombudsmann e.V.<br />Prof. Dr. Günter Hirsch<br />Postfach 08 06 32<br />10006 Berlin<br />Telefon: 0800 3696000 (kostenfrei)</p>
            <p>(weitere Informationen unter: <a href="http://www.%1fversicherungsombudsmann.de">www.&shy;versicherungsombudsmann.de)</a></p>
            <p>Ombudsmann für die private Kranken-und Pflege&shy;versicherung<br />Herr Heinz Lanfermann<br />Postfach 060222<br />10052 Berlin<br />Telefon: 0800 2550444 (kostenfrei aus deutschen Telefonnetzen)<br />(weitere Informationen unter: <a href="http://www.pkv-ombudsmann.de">www.pkv-ombudsmann.de)</a></p>
            <p>Sonstige Auskunfststellen:</p>
            <p>Bundesanstalt für Finanzdienstleistungsaufsicht (BAFin)<br />Graurheindorfer Straße 108<br />53117 Bonn<br />Telefon: 0228 4108-0</p>
            <p><strong>Rechtliche Hinweise</strong></p>
            <p><strong>Urheberrecht / Logos / Links</strong></p>
            <p>Alle Texte, Bilder und weitere hier veröffentlichten Informationen unterliegen dem Urheberrecht des Eigentümers. Des Weiteren alle auf den Seiten genannten Produktnamen und zitierten Handelsmarken/Logos und Copyrights unterliegen dem eigentumsrechtlichen Schutz ihrer jeweiligen Eigentümer.</p>
            <p>Eine Vervielfältigung, Verbr /eitung oder öffentliche Wiedergabe ist ausschließlich im Falle einer widerruflichen und nicht übertragbaren Zustimmung der Eigentümer gestattet.</p>
            <article>
            <p>Alle auf unseren Internetseiten veröffentlichten Angaben und Informationen wurden gewissenhaft recherchiert und geprüft. Trotz aller Sorgfalt können wir für die Richtigkeit, Vollständigkeit und Aktualität der zur Verfügung gestellten Informationen keine Garantie oder Haftung übernehmen, da sich die Daten mittlerweile geändert haben können. Dies gilt auch für externe Internetseiten, auf die mithilfe eines Hyperlinks hingewiesen wird. Für die Inhalte dieser aufgrund einer Verlinkung erreichten Seiten übernehmen wir keine Verantwortung. Alle Inhalte und Gestaltungen der Internet-seiten von VIN1 sind urheberrechtlich geschützt. Eine Vervielfältigung dieser Seiten oder Inhalte bedarf einer vorherigen schriftlichen Zustimmung von VIN1.</p>
            <p><span className="fw-bold">Webdesign und Gestaltung:<br /></span><span>Divison : Goldmountains Multimedia<br /></span><span>Goldmountains Group UG (haftungsbeschränkt) &amp; Co.KG</span><br /><span>Aspastr.24 / D-59394 Nordkirchen</span><br /><span>Telefon: +49(0)2596/ 614888-0</span><br /><span>E-Mail: </span><a href="mailto:info@goldmountains.eu"><span>info@goldmountains.eu</span></a></p>
            </article>


          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Impressum