import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import style from "./PDFViewerModal.module.css"

//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
//import { PDFViewer } from '@react-pdf/renderer';

//import SinglePagePDFViewer from "./single-page";
import AllPagesPDFViewer from "./AllPages";


const Backdrop = (props) => {
    return <div className={style.backdrop} onClick={props.onConfirm} />
}




const ModalOverlay = (props) => {

  /*
  const [numPage, setNumPage] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = (numPage) => {
    setNumPage(numPage);
    setPageNumber(1);
  }
  */

  const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  }

const goToNextPage = () => {
  setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
}

const samplePDF = "/pdf/Tippgebervertrag_VIN1.pdf";


  return (
    <Fragment>
      <div className={style.modalHeaderContainer}>
        <FontAwesomeIcon
          className={style.closeModal}
          icon={faXmark}
          size="xl"
          onClick={() => props.hideModal(false)}
        />
        <p className={style.url}>{props.filename}</p>
        <div></div>
      </div>

      <div className={style.allPagesContainer}>
        <object
          data="/pdf/Tippgebervertrag_VIN1.pdf"
          type="application/pdf"
          style={{ width: "100%", height: "100%" }}
        >
          <p>Alternative Text...</p>
        </object>
      </div>

      {/*
       

      <div className={style.allPagesContainer}>
        <AllPagesPDFViewer pdf={samplePDF} />
      </div>
      */}
    </Fragment>
  );
}



const PDFViewerModal = (props) => {

    return (
      <Fragment>
        {ReactDOM.createPortal(<Backdrop hideModal={props.hideModal} />, document.getElementById('backdrop-root'))}
        {ReactDOM.createPortal(<ModalOverlay filename={props.filename} hideModal={props.hideModal}/>, document.getElementById('overlay-root'))}
      </Fragment>
      
    )
}

export default PDFViewerModal