import React, { useState, useEffect, useContext } from "react";
import Header from "../Header/Header";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileText,
  faChevronRight,
  faNewspaper,
  faChevronDown,
  faChevronUp,
  faGift
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import { db } from "../../firebase";
import { collection, getDocs, doc, updateDoc, query, where, orderBy } from "firebase/firestore";

import { Container, Row, Col, Button } from "react-bootstrap";

import style from "./UnreadDocs.module.css";

import Swal from "sweetalert2";
import axios from "axios";
import { AuthContext } from "../../store/auth-context";

import CardFeed from "./CardFeed";

//import CardSub from "../Card/CardSub"
//import CardDoppelt from "../Card/CardDoppelt";

function UnreadDocs() {

  const filterValues = [
    "Angebot",
    "Rechnung",
    "Vertrag",
    "News",
    //"Partner-Angebote"
  ];

  let navigate = useNavigate();

  const [newsfeed, setNewsfeed] = useState("");

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
      function handleResize() {
          setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getWindowDimensions() {
    const width = window.innerWidth
    const height = window.innerHeight

    return {
        width,
        height
    };
  }


  const [showFilter, setShowFilter] = useState(true);

  const [unreadDocs, setUnreadDocs] = useState([]);
  const [unreadDocsFiltered, setUnreadDocsFiltered] = useState([]);

  /*
  response = await axios({
    method: 'get',
    headers: {
      'Authorization': 'Bearer ' + currentUser.accessToken
    },
    url: process.env.REACT_APP_BACKEND+"/my-vin1/newsletter/"+id
  });

  {
    id: "asdklfjdslgkaasfsdf234sg",
    kategorie: "Partner-Angebote",
    name: "Gratis Scheibenwischer zu jeder Autoreparatur",
    partner_name: "Goldmountains Group",
    date: 1677924200,
    
  }
  */

  const [readDocs, setReadDocs] = useState([]);
  const [readDocsFiltered, setReadDocsFiltered] = useState([]);

  const [filter, setFilter] = useState(filterValues);
  const [allChecked, setAllChecked] = useState(true);

  const [showNewDocs, setShowNewDocs] = useState(true);

  const { currentUser } = useContext(AuthContext);



  async function getFirebaseDocs(status) {

    //const auth = getAuth();
    //const colRef = collection(db, "Users", currentUser.uid, "Dokumente");
    const colRef = collection(db, "Users", currentUser.uid, "Dokumente");

    const q = query(
      colRef,
      where("showInNewsfeed", "==", status),
      orderBy("created", "desc")
    );

    const documents = await getDocs(q);

    let documentsArr = [];

    documents.forEach((doc) => {
      documentsArr.push({id: doc.id, ...doc.data()});
    });

    //setUnreadDocs(unreadDocumentsArr);
    return documentsArr;
  }



  async function loadNewsletter(status) {
      
    const response = await axios.get(
      "https://api.vin1.eu/newsletterFeed.php?AUTH=bv8FnNYM6JU6Uk7QE8ujvM6S6WMVpg"
    );
    const newsletterAPI = [];
    for (const key in response.data) {
      const newsObj = {
        id: response.data[key].ID,
        name: response.data[key].TITEL,
        type: "news",
        created: response.data[key].DATUM,
        enddatum: response.data[key].ENDDATUM
      };
      newsletterAPI.push(newsObj);
    }

    // Newsletter CRM
    let newsletterAPICRM = [];
    if(status == "ungelesen")
    {
      newsletterAPICRM = await axios({
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + currentUser.accessToken
        },
        url: process.env.REACT_APP_BACKEND+"/my-vin1/newsletter"
      });
    }
    else if (status == "gelesen")
    {
      newsletterAPICRM = await axios({
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + currentUser.accessToken
        },
        url: process.env.REACT_APP_BACKEND+"/my-vin1/newsletter-archiv"
      });
    }
    


    //console.log(newsletterAPI2.data);
    //console.log(newsletterAPI);

    //console.log([...newsletterAPI, ...newsletterAPI2.data])
    // make an sorted array!

    //setNewsletter(newsletterAPI);
    return [...newsletterAPI, ...newsletterAPICRM.data]

    //setUnreadDocs([...unreadDocs], [...newsletterAPI]);
  }




  // ------ Feed switch, Newsletter + Docs erneut laden! ------
  useEffect(() => {

    async function getAllDocsNewsletter() {
      if(currentUser?.uid) { 

        const unreadDocs = await getFirebaseDocs(true);
        const readDocs = await getFirebaseDocs(false);


        if(showNewDocs)
        {
          //Ungelesen abrufen
          const newsUngelesen = await loadNewsletter("ungelesen");

          setUnreadDocs([...unreadDocs, ...newsUngelesen].sort((a, b) => {

            function convertToDate(dateField) {
              if (!dateField) {
                console.error("Undefined or null dateField:", dateField);
                return new Date(0); // Default date
              }
          
              if (dateField instanceof Date) {
                return dateField;
              } else if (dateField && typeof dateField.toDate === 'function') {
                try {
                  return dateField.toDate();
                } catch (error) {
                  console.error("Error converting Firebase Timestamp to Date:", error);
                }
              } else if (typeof dateField === 'string') {
                const parsedDate = Date.parse(dateField);
                if (!isNaN(parsedDate)) {
                  return new Date(parsedDate);
                } else {
                  console.error("Invalid date string:", dateField);
                }
              } else if (typeof dateField === 'number') {
                // Assuming the number is a Unix timestamp in seconds
                return new Date(dateField * 1000);
              } else {
                console.error("Unrecognized or invalid dateField type:", typeof dateField, dateField);
              }
          
              return new Date(0); // Default date for any other case
            }
          
          
            const dateA = convertToDate(a.created);
            const dateB = convertToDate(b.created);
          
            // Compare dates in descending order
            return dateB.getTime() - dateA.getTime();
          }));
          


          setUnreadDocsFiltered([...unreadDocs, ...newsUngelesen]);
        }
        else if (!showNewDocs)
        {
          //Gelesen abrufen
          const newsGelesen = await loadNewsletter("gelesen");
          setReadDocs([...readDocs, ...newsGelesen]);
          setReadDocsFiltered([...readDocs, ...newsGelesen]);
        }

      }
    }

    getAllDocsNewsletter();
   

  },[showNewDocs, currentUser])



  // ------ Filter zurücksetzen ------
  useEffect(() => {
    if (filter.length < filterValues.length) {
      setAllChecked(false);
    }
    else
    {
      setAllChecked(true);
    }
  }, [filter]);
  

 
  // ---------- Dynamisches Filtern ----------
  useEffect(() => {


    setUnreadDocsFiltered(unreadDocs.filter(elem => {

        // wenn nicht alle checkboxen ausgefüllt (= kein Filter) dann filtern...
        if (filter.length <= filterValues.length) {

          //check if undefined + if in array
          if(elem.type && filter.some(f => f.toLowerCase() == elem.type.toLowerCase())) {
            return true;
          }
          else if (elem.type == "") {
            // Alte docs
            return true;
          }
          else {
            return false;
          }   
        } else {
          return true;
        }
    }));
    

  }, [filter])

  
  // Set Filter onChange Checkbox
  const setCBs = (e) => {

    if (e.target.checked && !filter.includes(e.target.name)) {
      setFilter([...filter, e.target.name]);
    } else {
      setFilter(oldVal => oldVal.filter(obj => obj !== e.target.name));
    }
    /*
    setFilter({
      ...filter,
      [e.target.name]: e.target.checked
    });
    */
  }


  const handleFilterBtn = () => {
    setShowFilter(!showFilter);
  }


  const resetFilter = () => {
    setFilter(filterValues);
  };


  const handleUpdateReadStatusDoc = async(docId) => {

    const docRef = doc(db, "Users", currentUser.uid, "Dokumente", docId);

    try {
      await updateDoc(docRef, {
        showInNewsfeed: false,
      });
      
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: 'Fehler',
        html: 'Fehler beim Updaten des "gelesen" Status. '+error
      });
    }

  }



  return (
    <>
      <Header url={"/news"}/>
      <Container style={{ maxWidth: "800px" }}>

        <Row className="justify-content-center">
          <Col style={{ textAlign: "center" }}>
            <img
              src={require("../../img/Newsfeed.png")}
              alt=""
              style={{ maxWidth: "160px", margin: "40px 0px" }}
            />
            <h5>Mit einem Klick alles im Blick</h5>
            <div>
              Hier findest Du neue Dokumente (Anträge, Angebote, Rechnungen) und aktuelle News und Sonderaktionen. 
              Nutze die Filterfunktion, um Aktuelles schneller aus einem bestimmten Bereich einzusehen.
            </div>
          </Col>
        </Row>


        {/*
        <hr />

        <Row style={{ justifyContent: "center", paddingLeft: "15px" }} className={style.gap}>
          <h5 className="text-center fw-bold">Wähle aus (vorauswahl?)</h5>
          <Col xs={4}>
            <CardSub title={"VIN1"} onClick={() => setNewsfeed("VIN1")} />
          </Col>
          <Col xs={4}>
            <CardSub title={"Partner"} onClick={() => setNewsfeed("Partner")}/>
          </Col>
        </Row>

        <hr />
        */}


        <Row className="mt-5 justify-content-center">

          {windowDimensions.width <= 766  &&
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", marginBottom: "30px"}} onClick={handleFilterBtn}>
              <span style={{paddingRight: "10px", fontSize: "120%"}}>Filter anzeigen</span>
              {showFilter && <FontAwesomeIcon className={style.icon} icon={faChevronUp} size="lg" />}
              {!showFilter && <FontAwesomeIcon className={style.icon} icon={faChevronDown} size="lg" />}
            </div>    
          }

          {(windowDimensions.width >= 766 || (windowDimensions.width <= 766 && showFilter)) &&
            <>
              <p className="text-center">Welche Dokumente und News sollen angezeigt werden?</p>
              <div className={style.cbContainer}>

                {filterValues.map(f => 
                  <label key={f} className="form-check-label mt-2">
                    <input
                      id={f.toLowerCase()}
                      className="form-check-input"
                      type="checkbox"
                      name={f}
                      checked={filter.includes(f) ? true : false}
                      onChange={(e) => setCBs(e)}
                    />
                    <span style={{ paddingLeft: "10px", }}>{f}</span>
                  </label>
                )}
                
              </div>

              <Row className="justify-content-center">
                {!allChecked &&
                  <Button onClick={resetFilter} className={[style.resetBtn, "w-auto"]} >
                    Filter zurücksetzen
                  </Button>
                }
              </Row>

            </>
          }

        </Row>



        <Row style={{ justifyContent: "center" }}>
          <Col
            style={{
              marginBottom: "30px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {showNewDocs && (
              <>
                <Button
                  onClick={() => setShowNewDocs(true)}
                  style={{
                    background: "green",
                    border: "none",
                    marginRight: "15px",
                  }}
                >
                  Ungelesene Dokumente
                </Button>
                <Button
                  onClick={() => setShowNewDocs(false)}
                  style={{
                    background: "none",
                    color: "#520ca7aa",
                    border: "2px solid #520ca7aa",
                  }}
                >
                  Gelesene Dokumente
                </Button>
              </>
            )}

            {!showNewDocs && (
              <>
                <Button
                  onClick={() => setShowNewDocs(true)}
                  style={{
                    background: "none",
                    color: "#520ca7aa",
                    border: "2px solid #520ca7aa",
                    marginRight: "15px",
                  }}
                >
                  Ungelesene Dokumente
                </Button>
                <Button
                  onClick={() => setShowNewDocs(false)}
                  style={{ background: "green", border: "none" }}
                >
                  Gelesene Dokumente
                </Button>
              </>
            )}
          </Col>
        </Row>


        {/* Feed Ungelesene Dokumente + News */}
        {showNewDocs &&
          <CardFeed data={unreadDocsFiltered} status={showNewDocs} handleUpdateGelesen={handleUpdateReadStatusDoc} />
        }


        {/* Feed Ungelesene Dokumente + News */}
        {!showNewDocs &&
          <CardFeed data={readDocsFiltered} status={showNewDocs} />
        }

      </Container>

      <div style={{ marginBottom: "50px" }}></div>
    </>
  );
}

export default UnreadDocs;
