
//Download File from local prject path, e.g.:
/*
* downloadPDF(
*    "/pdf/Maklervertrag+Vollmacht_VIN1.pdf",
*    "Maklervertrag+Vollmacht_VIN1.pdf"
* )
*/

export async function downloadPDF(filepath, filename) {

    fetch(filepath).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;

            /*alink.setAttribute('download', 'Test.pdf');
            document.body.appendChild(alink);
            alink.click();
            alink.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            */

            alink.download = filename;
            alink.click();
        })
    })
}

 
  



// Bilder Download implementieren