import React, { useState, useEffect, useContext, useRef } from 'react';
import Header from '../../Header/Header';
import Spinner from '../../Bausteine/Spinner/Spinner';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

import CardGesellschaften from '../../Card/CardGesellschaften';
import { Container, Row, Col, Button } from 'react-bootstrap';
import style from './BestaendeUebertragen.module.css'

import Select, { createFilter } from 'react-select';
import Swal from 'sweetalert2';

import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "@firebase/storage";
import { db, storage } from "../../../firebase";
import {
  collection,
  doc,
  addDoc,
  setDoc
} from "firebase/firestore";
 
import GesellschaftenData from './Data/GesellschaftenData'
import axios from 'axios';

import VINButton from '../../../VINButton';
import { UserContext } from '../../../store/user-context';
import { AuthContext } from '../../../store/auth-context';


import { formatDateTime, formatDateTimeLong } from '../../../util/date/dates'

import MaklermandatUnterschrift from '../../Bausteine/MaklermandatUnterschrift';
import signMaklervertrag from '../../hooks/signMaklervertrag';


function BestaendeUebertragen() {

  const unterschriftenRef = useRef();

  const { user, updateUser } = useContext(UserContext);
  const { currentUser } = useContext(AuthContext);

  const [spinner, setSpinner] = useState(false);

  //const [acceptedMaklervollmacht, setAcceptedMaklervollmacht] = useState(false);

  const [submittedOK, setSubmittedOK] = useState(false);

  //const [maklervollmacht, setMaklervollmacht] = useState("0");
  const [options, setOptions] = useState([]);

  const [meineGesellschaften, setMeineGesellschaften] = useState([]);

  const [inputFields, setInputFields] = useState([
    {
      label: '',
      value: ''
    }
  ]);




  const handleSubmit = async () => {


    setSpinner(true);

    //Convert List of Objects to Array
    let dataArr = [];
    var values = Object.values(inputFields);
    values.forEach(function(values){
      if(values.value !== "") {
        dataArr.push(values.value);
      }  
    });


    if(dataArr.length === 0) {
      Swal.fire({
        title: "Versicherung",
        html: "Du musst eine Versicherungsgesellschaft auswählen, bevor du das Formular absenden kannst.",
        icon: "info",
      });
      //
      setSpinner(false);
      return;
    }


    // PDF Maklermandat in CRM + App mittels Backend hinterlegen.
    if(!user.maklervollmachtSignedCb) {

      let unterschriftVertragB64 = unterschriftenRef.current.getSigVertragData();
      let unterschriftVollmachtB64 = unterschriftenRef.current.getSigVollmachtData()
      
      //Error Handling in signMaklervertrag mit Swal
      let status = await signMaklervertrag(currentUser, unterschriftVertragB64, unterschriftVollmachtB64);

      if(status) {
        updateUser({
          ...user,
          maklervollmachtSignedCb: true,
        });
      } else {
        setSpinner(false);
        return;
      }
    }

    // PDF Maklermandat in CRM + App mittels Backend hinterlegen.
    if(!user.maklervollmachtSignedCb) {

      let unterschriftVertragB64 = unterschriftenRef.current.getSigVertragData();
      let unterschriftVollmachtB64 = unterschriftenRef.current.getSigVollmachtData()
      
      //Error Handling in signMaklervertrag mit Swal
      let status = await signMaklervertrag(currentUser, unterschriftVertragB64, unterschriftVollmachtB64);

      if(status) {
        updateUser({
          ...user,
          maklervollmachtSignedCb: true,
        });
      } else {
        setSpinner(false);
        return;
      }
    }

    /*
    if(!acceptedMaklervollmacht || !datenschutz) {
      Swal.fire({
        title: "Pflichtfelder",
        html: "Bitte akzeptiere zunächst den Maklervertrag/-vollmacht und die Datenschutzerklärung und sende das Formular erneut ab.",
        icon: "info",
      });
      return;
    }
    */


    try {

      // Bestands-Gesellschaften im CRM hinterlegen
      await axios({
        method: 'post',
        headers: {
          'Authorization': 'Bearer ' + currentUser.accessToken
        },
        url: process.env.REACT_APP_BACKEND+"/my-vin1/connect",
        data: {
          gesellschaften: dataArr
        }
      })

      setSpinner(false);

      updateUser({
        ...user,
        maklervollmachtSignedCb: true
      });

      if(!user.maklervollmachtSignedCb) {
        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Deine Daten wurden erfolgreich an uns übermittelt."
                +"Eine Kopie deines Maklermandats wurde unter private Dokumente > Ich > Vollmacht hinterlegt. "
                +"Deine Verträge findest du in Kürze in deiner digitalen Dokumentenablage."
         
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Erfolg",
          html: "Deine Daten wurden erfolgreich an uns übermittelt. Deine Verträge findest du in Kürze in deiner digitalen Dokumentenablage."
        });
      }
      
    

      // Update Cards mit übermittelten Daten
      let VersicherungArr = [];
      let VersicherungValues = Object.values(inputFields);
      VersicherungValues.forEach(function(values){
        if(values.value !== "") {
          VersicherungArr.push(values.value);
        }  
      });

      let Gesellschaften  = [];
      Array.from(VersicherungArr).forEach(versicherung => {
        Gesellschaften = [...Gesellschaften, {
              _id: makeid(15),
              versicherung: versicherung,
              createdAt: new Date().toString() //Umwandlung ins richtige Format in der Card!
        }
          ]
      });
      setMeineGesellschaften([...Gesellschaften, ...meineGesellschaften]); 


    }
    catch(error) {
      setSpinner(false);
      Swal.fire({
        title: "Versicherung",
        html: "Fehler bei der Übertragung. Später erneut versuchen.",
        icon: "info",
      });
    } 

  }




  const makeid = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }



  const addFields = () => {
    //alert("Add new field");
    let newfield = {
      label: '',
      value: ''
    }
    setInputFields([...inputFields, newfield])
  }

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1)
    setInputFields(data)
  }

  const handleFormChange = (index, event) => {

    let data = [...inputFields];
    data[index] = event;
    setInputFields(data);
  }


/*
  function handleFileUploadPDF(url, filename) {

    // Maklervollmacht als Kopie (von dem public Pfad der App) in den Privaten Ordner unter Vollmachten hinterlegen

    let UUID = crypto.randomUUID();
    if (url) {
      const storageRef = ref(
        storage,
        "/" + currentUser.uid + "/" + UUID + filename
      );

      //You may not need this part if you have the PDF data locally already
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200) {
            //console.log(this.response, typeof this.response);
            //contentType expplizit setzten, sonst Rule Fehler wegen falschem Content type!
            var blob = new Blob([this.response], { type: 'application/pdf' });
            var fileOfBlob = new File([blob], UUID + filename, { type: blob.type });
            //var fileOfBlob = new File([this.response], 'your_file.pdf');
          

        const uploadTask = uploadBytesResumable(storageRef, fileOfBlob);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle upload progress or other states if needed
          },
          (err) => console.log(err),
          () => {
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                addDoc(collection(db, "Users", currentUser.uid, "Privat"), {
                  title: UUID + filename,
                  description: "",
                  uploader: "app",
                  category: "Ich",
                  sub_category: "Vollmacht",
                  created: new Date(),
                  mimeType: "application/pdf",
                  size: fileOfBlob.size,
                  url: url,
                });   
              });
            }
          );
        }
      }
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();  

    } //url

  }

  */




  useEffect(() => {

    //Alle Gesellschaften hinterlegen
    setOptions(GesellschaftenData);
  
    if(currentUser) {
      //Übermittelte Gesellschaften abrufen
      axios({
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + currentUser.accessToken
        },
        url: process.env.REACT_APP_BACKEND+'/my-vin1/connect',
      }). then(res => { 
        setMeineGesellschaften(res.data);
      })
      .catch(err => {
        console.log(err);
      });
    }

  }, [currentUser, user])

  
  

  
  return (
    <>
     
      <Container style={{maxWidth: "800px"}}>
        <Row className="mt-5" style={{justifyContent: "center"}}>
          <Col style={{textAlign: "center"}}>
            
            {/*<img src={require("../../../img/hands.png")} alt="" style={{maxWidth: "160px", margin: "40px 0px"}} />*/}
      
              <img src={require("../../../img/Bestandsuebertragung.png")} alt="" style={{maxWidth: "160px", margin: "0px 0px 20px 0px"}} />
                <h5>Alle wichtigen Dokumente an einem Ort</h5>
                <p className="text-center">Jederzeit verfügbar und datenschutzkonform gespeichert</p>
                <br />

                <p className="text-center"><span className="fw-bold">1.</span><br />
                Erzähle uns kurz, wo du versichert bist.</p>
                
                <br />
                <p className="text-center">Als Angabe reicht uns der Name deiner Versicherungen.</p>
                <p className="text-center">Klicke in die Felder und suche nach deinen Versicherungen, indem du einen Suchbegriff eingibst.</p>
                  <br />



              <div className={style.SelectInputContainer}>
                {inputFields.map((input, index) => 

                    <div key={index} className={style.InputRow} style={{marginBottom: "50px", width: index >= 1 ? "80%" : "100%", marginLeft: index >= 1 ? "0px" : "20%", display: "flex", alignItems: "center"}}>
                      <div className={style.selectContainer} style={{flex: "8", textAlign: "left"}} >
                        <Select    
                            value={input?.value !== "" ? input : ""}
                            onChange={event => handleFormChange(index, event)}
                            placeholder={"Suche eine Versicherungsgesellschaft"}
                            noOptionsMessage={() => "Kein Eintrag passt zu deiner Suche"}
                            options={options}
                            filterOption={createFilter({ignoreAccents: false})}
                          />
                        </div>
                        <div className={style.removeBtn} style={{flex: "2"}}>
                          {index >= 1 && <button onClick={() => removeFields(index)} style={{marginLeft:"15px"}}className={style.Btn}>Entfernen</button> }
                        </div>
                    </div>
                  
                )}

            </div>

            <div style={{display: "flex", justifyContent: "center", alignItems:"center", flexDirection: "column"}}>
              <FontAwesomeIcon className={style.icon} icon={faCirclePlus} size="2x" onClick={addFields} style={{cursor: "pointer", marginBottom: "10px"}} />
              <p style={{display: "inline-flex", margin: "0px 0px 0px 10px"}}>Weitere Versicherungsgesellschaft hinzufügen</p>
            </div>


            <br />
            <br />

            <MaklermandatUnterschrift ref={unterschriftenRef} />

            <br />
            <br />
            <br />


              <div className="pointer" onClick={handleSubmit}>
                <VINButton text={"Absenden"} />
              </div>

      
                {/*<div style={{display: "flex", justifyContent: "center"}}>
                  <Button
                    style={{ background: "#520da7", padding: "15px", width: "auto", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                    onClick={handleAcceptance}
                  >Absenden</Button>
                </div>*/}

                {submittedOK && <p className="text-center fw-bold" style={{marginTop: "10px", color: "#1f8139"}}>Du hast das Formular erfolgreich an uns übermittelt.</p>}          


          </Col>
        </Row>
        


        <Row style={{justifyContent: "center", marginBottom: "80px", marginTop: "80px"}} >
          {/* Dokumenten Liste */}
          <Col
              xs={12} md={12}
              className="text-center"
            >
              <h4 className="text-center" style={{marginBottom: "20px"}}>Meine übermittelten Gesellschaften</h4>
              {(meineGesellschaften.length === 0) &&
                <p style={{marginTop: "10px"}}>
                  Du hast noch keine Gesellschaften übermittelt.
                </p>
              }
              {(meineGesellschaften.length >= 1) && meineGesellschaften.map((gesellschaft) =>
                <CardGesellschaften
                  key={gesellschaft._id}
                  versicherung={gesellschaft.versicherung}
                  createdAt={gesellschaft.createdAt}
                />  
              )} 

            </Col>
            
        </Row>


        <br />
        <br />
        <br />

      </Container>

      {spinner &&
        <Spinner
          message={"Bitte warten. Daten werden verarbeitet."}
        />
      }

    </>
  )
}

export default BestaendeUebertragen
