import React, { useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faEnvelopeOpenText, faFolderOpen, faCreditCard, faUser, faUserGroup } from "@fortawesome/free-solid-svg-icons";

import { UserContext } from "../../../store/user-context";
import { AuthContext } from '../../../store/auth-context';


function MobileAppMenu() {

  const navigate = useNavigate();
  const location = useLocation();

  const { unreadDocs } = useContext(UserContext);
  const { currentUser } = useContext(AuthContext);

  const navItems = [
    {
        link: "/",
        icon: faHouse,
        title: "Home",
    },
    {
        link: "/news/dokumente-feed",
        icon: faEnvelopeOpenText,
        title: "Postfach",
    },
    {
        link: "/dokumente",
        icon: faFolderOpen,
        title: "Dokumente",
    },
    {
        link: "/cashback",
        icon: faCreditCard,
        title: "Cashback",
    },
    {
        link: "/beratung",
        icon: faUserGroup,
        title: "Beratung",
    },
  ];


  // Only render after Login, when firebase uid is loadeyd
  const mobileAppMenu = !currentUser?.uid ? null : (
    <div className="mobileAppMenu">

        {/* h-20 = 5rem = 80px */}
        <div
            style={{borderTop: "2px solid #e0d2f1"}}
            className="fixed bottom-0 left-0 z-50 w-full h-20"
        >

            <div className="grid h-full max-w-100 grid-cols-5 mx-auto">
 
            {navItems.map(item =>
                
                <button
                    key={item.title}
                    onClick={() => navigate(item.link)}
                    type="button"
                    className="flex-col items-center justify-center pt-2 px-1 group border-0 bg-white relative"
                >
                    {/* Color behindg Icon...
                        <div
                            className={`py-2 px-3 ${location.pathname === item.link ? "bg-vinColorActive rounded-xl" : ""}`}
                        >
                    */}
                    <div>
                     
                        <div className="relative">
                            {/* Notification Circle */}
                            {item.title == "Postfach" &&
                                <div className={`absolute inline-flex items-center justify-center w-5 h-5 text-[10px] text-white border-2 border-white rounded-full -top-1 -right-1 ${unreadDocs > 0 ? "bg-red-500" : "bg-gray-500"}`}>
                                    {unreadDocs}
                                </div>
                            }
                            <FontAwesomeIcon
                                className={`w-100 h-4 ${location.pathname === item.link ? "text-vinColorIconNav":"text-gray-500"}`}
                                icon={item.icon}
                                size="lg"
                            />
                        </div>

                        <span className={`text-[10px] ${location.pathname === item.link ? "text-vinColorIconNav":"text-gray-800"}`}>
                            {item.title}
                        </span>

                    </div>
                </button>
            )}


            </div>
        </div>

    </div>
  )

  return(
    <>
        {mobileAppMenu}
    </>
  )
}

export default MobileAppMenu