import React, { useState, useEffect, useRef, useContext } from "react";
import { HeaderWithoutAuth } from "../Header/Header";
import Spinner from "../Bausteine/Spinner/Spinner";
import WelcomeSpinner from "../Bausteine/Spinner/WelcomeSpinner";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

import axios from "axios";

import { Container, Row, Col, Form, Button } from "react-bootstrap";

import style from "./Login.module.css";

// Funktioniert noch nicht so gut, mal später checken.
//import IntlTelInput from 'react-intl-tel-input';
//import 'react-intl-tel-input/dist/main.css';

import Swal from "sweetalert2";

import {
  getAuth,
  createUserWithEmailAndPassword,
  deleteUser
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import VINButton from "../../VINButton";
import { UserContext } from "../../store/user-context";



function Register(props) {

  const { user, updateUser } = useContext(UserContext);

  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  //Vorteilspartner Landingpage leitet zum Login, aber Partner wird über LocalStorage getrackt!
  const partnerValue = localStorage.getItem("partner");

  useEffect(() => {
    setSponsor(partnerValue);
  },[partnerValue])



  useEffect(() => {

    // Kundennummer Sponsor
    if (searchParams.get("partner") !== null) {
      setSponsor(searchParams.get("partner"));
    }

    //Vorteilspartner (Direkte verknpüfung zu... überlegen! Kunde sichtbar in Partner CRM?)
    if (searchParams.get("vorteilspartner") !== null) {
      setVorteilspartner(searchParams.get("vorteilspartner"));
    }

    // Standortpartner
    if (searchParams.get("standort") !== null) {
      setStandort(searchParams.get("standort"));
    }

    // Mitarbeiter
    if (searchParams.get("mitarbeiter") !== null) {
      setMitarbeiter(searchParams.get("mitarbeiter"));
    }

    // Quelle z.B. 1 = Flyer, ...
    if (searchParams.get("id") !== null) {
      setQuelle(searchParams.get("id"));
    }

  },[searchParams])



  //const [spinner, setSpinner] = useState(false);

  //  =============== Der Willkommenstext läuft mindestens genau 1x durch = 5s ===============

  const timerRef = useRef();

  const [endSpinner, setEndSpinner] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [timerCompleted, setTimerCompleted] = useState(true);

  const startTimer = () => {
    timerRef.current = setTimeout(() => {
      navigate("/");
      setTimerCompleted(true);
      setTimerStarted(false);
    }, 5000);
  };

  useEffect(() => {
    setTimerStarted(false);
    setTimerCompleted(true);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    if (endSpinner) {
      setTimerStarted(false);
      setTimerCompleted(true);
      clearTimeout(timerRef.current);
    }
  }, [endSpinner]);


  // Regex
  const regexTelefon =
    "/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im";
  
  //https://www.twilio.com/docs/glossary/what-e164
  const regexTelefon2 = "^\+[1-9]\d{1,14}$"; // Format E.134 => +49151...

  // Kunde
  const [anrede, setAnrede] = useState("Herr");
  const [firma, setFirma] = useState("");
  const [vorname, setVorname] = useState("");
  const [nachname, setNachname] = useState("");
  const [strasse, setStrasse] = useState("");
  const [hausnr, setHausnr] = useState("");
  const [plz, setPLZ] = useState("");
  const [ort, setOrt] = useState("");
  const [land, setLand] = useState("Deutschland");

  // Flagge = Ländervorwahl für IntlTelefon 
  const [laenderVorwahl, setLaenderVorwahl] = useState("+49");
  const [telefon, setTelefon] = useState("");


  //Hilfs-States Problem iOS Kalender DatePicker
  const [tagGeburtstag, setTagGeburtstag] = useState("");
  const [monatGeburtstag, setMonatGeburtstag] = useState("");
  const [jahrGeburtstag, setJahrGeburtstag] = useState("");
  //const [geburtsdatum, setGeburtsdatum] = useState("");

  const beratungsAnlässe = ["Energie", "Versicherung", "Finanzierung", "Immobilien", "Invest", "Vorteilspartner", "Sonstiges"];
  // Initialize state by creating an object where keys are the labels and values are all false
  const [beratungBereich, setBeratungBereich] = useState(
    beratungsAnlässe.reduce((acc, label) => {
      acc[label] = false;
      return acc;
    }, {})
  );
  const handleCheckboxChange = (label) => {
    setBeratungBereich((prev) => ({
      ...prev,
      [label]: !prev[label], // Toggle the checked state of the corresponding checkbox
    }));
  };

  const [beratungsAnlassSonstiges, setBeratungsanlassSonstiges] = useState("");

  const [beratung, setBeratung] = useState(false);
  const [erreichbar1, setErreichbar1] = useState(false);
  const [erreichbar2, setErreichbar2] = useState(false);
  const [erreichbar3, setErreichbar3] = useState(false);

  const [vorteilspartnerInteresse, setVorteilspartnerInteresse] = useState(false);

  const [sponsor, setSponsor] = useState("");
  const [sponsorDisabled, setSponsorDisabled] = useState(false);
  const [partnerprogramm, setPartnerprogramm] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginPassword2, setLoginPassword2] = useState("");

  //Standortpartner
  const [standort, setStandort] = useState("");

  //Quellen
  const [quelle, setQuelle] = useState("");
  const [mitarbeiter, setMitarbeiter] = useState("");

  //Vorteilspartner
  const [vorteilspartner, setVorteilspartner] = useState("");
  

 
  // ---------------------------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------

  const handleNeukundeRegister = async (e) => {

    e.preventDefault();


    if( isNaN(tagGeburtstag) || isNaN(monatGeburtstag) || isNaN(jahrGeburtstag)) {
      Swal.fire({
        title: "Geburtsdatum",
        text: "Das eingegebene Geburtsdatum ist nicht gültig. Du darfst nur Zahlen verwenden.",
        icon: "info",
        confirmButtonText: "Ok",
      });
      return;
    }

    if(jahrGeburtstag.length !== 4) {
      Swal.fire({
        title: "Geburtsdatum",
        text: "Bitte gib das Jahr deines Geburtstages vollständig an und sende das Formular erneut ab.",
        icon: "info",
        confirmButtonText: "Ok",
      });
      return;
    }

    if(tagGeburtstag < 1 || tagGeburtstag > 31 || monatGeburtstag < 1 || monatGeburtstag > 12 || jahrGeburtstag < 1920 || jahrGeburtstag > new Date().getFullYear() - 17) {
      Swal.fire({
        title: "Geburtsdatum",
        text: "Das eingegebene Geburtsdatum ist nicht gültig. Korrigiere dieses und sende das Formular erneut ab. ",
        icon: "info",
        confirmButtonText: "Ok",
      });
      return;
    }


    let geburtsdatum = jahrGeburtstag+"-"+monatGeburtstag+"-"+tagGeburtstag;

    // Pflichtfelder ausgefüllt
    if (
      vorname !== "" &&
      nachname !== "" &&
      strasse !== "" &&
      hausnr !== "" &&
      plz !== "" &&
      ort !== "" &&
      telefon !== "" &&
      geburtsdatum !== "" &&
      loginEmail !== "" &&
      loginPassword !== "" &&
      loginPassword2 !== ""
    ) {
      // App Login erstellen
      if (loginEmail !== "" && loginPassword !== "") {
        if (loginPassword.length < 8) {
          Swal.fire({
            title: "Passwort",
            text: "Das eingegebene Passwort muss min. 8 Zeichen lang sein.",
            icon: "info",
            confirmButtonText: "Ok",
          });
          return;
        }
        if (loginPassword2 !== loginPassword) {
          Swal.fire({
            title: "Passwörter",
            text: "Die eingegbenen Passwörter stimmen nicht überein",
            icon: "error",
            confirmButtonText: "Ok",
          });
          return;
        }

        //Spinner
        startTimer();
        setTimerStarted(true);
        setEndSpinner(false);

        // Alle Bedingungen erfüllt
        async function createAcc() {
          try {
            const auth = getAuth();
            const account = await createUserWithEmailAndPassword(
              auth,
              loginEmail,
              loginPassword
            );

            let erreichbar = [];

            if (erreichbar1 !== "" && erreichbar1 === true) {
              erreichbar.push("morgens");
            }
            if (erreichbar2 !== "" && erreichbar2 === true) {
              erreichbar.push("mittags");
            }
            if (erreichbar3 !== "" && erreichbar3 === true) {
              erreichbar.push("abends");
            }

            //create user db
            // nur db, crm aktuell weg

            // firebaseAuth Middleware
            let accessToken = await account.user.getIdToken();

            let res = await axios({
              method: "post",
              headers: {
                'Authorization': 'Bearer ' + accessToken
              },
              url: process.env.REACT_APP_BACKEND + "/my-vin1/create",
              data: {
                googleuid: account.user.uid, //googleuid
                anrede: anrede, //anrede
                firma: firma, //firma
                vorname: vorname, //vorname
                nachname: nachname, //nachname
                anschrift: strasse + " " + hausnr,
                plz: plz, //plz
                stadt: ort, //stadt
                land: land, //land
                email: loginEmail, //email
                telefon: laenderVorwahl+telefon, //telefon
                geburtsdatum: geburtsdatum, //geburtsdatum
                sponsor: sponsor, //sponsor (number! sonst Fehler)
                standort: standort, //standort (="betreuuender Makler")
                vorteilspartner: vorteilspartner, //vorteilspartner (="Kunde gelauncht über Vorteilspartner")
                vorteilspartnerInteresse: vorteilspartnerInteresse, //Ich will Vorteilspartner werden Checkbox
                mitarbeiter: mitarbeiter, //standort (="betreuuender Makler")
                tippgeber: partnerprogramm, //kunde hat interesse tippgeber zu werden
                beratung: beratung, // => Aufgabe erzeugen Erstberatung!
                erreichbar: erreichbar,
                beratungBereich: beratungBereich, //Multiple Checkboxes (Object mit Bereichen, default: false je Eintrag)
                beratungsAnlassSonstiges: beratungsAnlassSonstiges //Text-Feld Sonstiges
              }
            });

            //if CRM fails, delete user to reuse email again!
            
            if(res.status != 200) {
              const delUser = await deleteUser(
                auth.currentUser
              );
              Swal.fire({
                icon: "error",
                title: "Fehler",
                text: "Dienst nicht erreichbar. Bitte später erneut versuchen.",
                confirmButtonText: "Ok",
              });
              return;
            }
            

            //Kundendaten anlegen
            const userMeta = {
              kundennummer: res.data.kdnr,
              anrede: anrede,
              firma: firma,
              vorname: vorname,
              nachname: nachname,
              geburtsdatum: geburtsdatum,
              email: loginEmail,
              telefon: laenderVorwahl+telefon,
              strasse: strasse + " " + hausnr,
              plz: plz,
              ort: ort,
              land: land,
              sponsor: sponsor,
              vorteilspartner: vorteilspartner,
              standort: standort,
              mitarbeiter: mitarbeiter,
              verifiziert: "0",
              created: new Date(), //new Date().toISOString() = "YYYY-MM-DDTHH:MM:SS.MMMZ"
            };

            // Set App User Data

            setDoc(doc(db, "Users", account.user.uid), userMeta)
              .then((docRef) => {
                //console.log("Document has been added successfully");
              })
              .catch((error) => {
                //console.log(error.message);
              });

            setBeratung(false);
            setErreichbar1(false);
            setErreichbar2(false);
            setErreichbar3(false);
            setPartnerprogramm(false);

            const user = account.user;

            //Update Context
            updateUser(userMeta);

            // Alt mit Spinner!
            //setSpinner(false);
            /*
            Swal.fire({
              title: "Registrierung",
              text: "Deine Registrierung war erfolgreich. Du bist automatisch in deinen Account eingeloggt worden.",
              icon: "success",
              confirmButtonText: "Ok",
            });
            */

            //navigate("/");

          } catch (error) {
            if (error.code == "auth/email-already-in-use") {
              setEndSpinner(true);
              //setSpinner(false);
              Swal.fire({
                title: "Account",
                text: "Die eingegbenen E-Mail Adresse ist bereits mit einem App-Account verknüpft. Verwende die Passwort vergessen Option.",
                icon: "info",
                confirmButtonText: "Ok",
              });
            } else if (error.code == "auth/invalid-email") {
              setEndSpinner(true);
              //setSpinner(false);
              Swal.fire({
                title: "Account",
                text: "Die eingegbenen E-Mail Adresse nicht gültig.",
                icon: "info",
                confirmButtonText: "Ok",
              });
            } else {
              console.log(error);
              setEndSpinner(true);
              //setSpinner(false);
              Swal.fire({
                title: "Account",
                text: "Es ist ein unbekannter Fehler aufgetreten. Bitte versuche es später erneut.",
                icon: "info",
                confirmButtonText: "Ok",
              });
            }
          }
        }

        const account = await createAcc();
        //setSpinner(false);
      }
    } else {
      Swal.fire({
        title: "Pflichtfelder",
        text: "Bitte fülle alle mit * markierten Pflichtfelder aus.",
        icon: "info",
        confirmButtonText: "Ok",
      });
    }
  };

  // ---------------------------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <HeaderWithoutAuth />

      <Container style={{ maxWidth: "800px" }}>
        <Row style={{ justifyContent: "center" }}>
          <Col>
            <h1 style={{ marginBottom: "30px" }}>Kundenkonto</h1>
          </Col>
        </Row>
      </Container>
      <Container style={{ maxWidth: "650px", marginBottom: "180px" }}>
        <Row style={{ justifyContent: "center" }}>
          <h2
            style={{ color: "#520da7", fontSize: "140%" }}
            className="fw-bold text-center"
          >
            Jetzt kostenlos anmelden.
          </h2>
          <p className="text-center">
            Vintastisch, dass du dabei sein möchtest.
            <br />
            Für die Erstellung Deines Kundenkontos benötigen wir ein paar Daten.
          </p>
        </Row>


        {/*<p>Standort: {standort}</p>*/}

          {/* Chrome Browser austricksen damit kein autoComplete... */}
          <input type="hidden" value="something" />
  
          <Row style={{ justifyContent: "center", marginBottom: "80px", marginTop: "50px" }}>


            <Form autoComplete="off" method="POST">

              <span>* Pflichtfeld</span>
            
              <div className={style.formInputCustom}>
                <label className="form-label">Anrede</label>
                <br />
                <select
                  className={style.DropdownSelection}
                  onChange={(e) => setAnrede(e.target.value)}
                  value={anrede}
                  style={{marginLeft: "10px"}}
                >

                  {firma.length == 0 &&
                    <>
                      <option value="Herr">Herr</option>
                      <option value="Frau">Frau</option>
                    </>
                  }
                  
                  <option value="Firma">Firma</option>
                </select>
              </div>



              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="firma" className="form-label">
                  Firma (optional)
                </label>
                <br />
                <input
                  type="text"
                  id="firma"
                  name="firma"
                  placeholder="Firma"
                  autoComplete="off"
                  value={firma}
                  onChange={(e) => {
                    setFirma(e.target.value);
                    setAnrede("Firma");
                  }}
                />
              </div>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="vorname" className="form-label">
                  Vorname*
                </label>
                <br />
                <input
                  type="text"
                  id="vorname"
                  name="vorname"
                  placeholder="Vorname"
                  autoComplete="on"
                  value={vorname}
                  onChange={(e) => setVorname(e.target.value.trim())}
                />
              </div>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="nachname" className="form-label">
                  Nachname*
                </label>
                <br />
                <input
                  type="text"
                  id="nachname"
                  name="nachname"
                  placeholder="Nachname"
                  autoComplete="on"
                  value={nachname}
                  onChange={(e) => setNachname(e.target.value.trim())}
                />
              </div>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="strasse" className="form-label">
                  Strasse*
                </label>
                <br />
                <input
                  type="text"
                  id="strasse"
                  name="strasse"
                  placeholder="Straße"
                  autoComplete="on"
                  value={strasse}
                  onChange={(e) => setStrasse(e.target.value)}
                />
              </div>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="hausnr" className="form-label">
                  Hausnummer*
                </label>
                <br />
                <input
                  type="text"
                  id="hausnr"
                  name="hausnr"
                  placeholder="Hausnr"
                  inputMode="text"
                  autoComplete="on"
                  value={hausnr}
                  onChange={(e) => setHausnr(e.target.value.trim())}
                />
              </div>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="plz" className="form-label">
                  PLZ*
                </label>
                <br />
                <input
                  type="text"
                  id="plz"
                  name="plz"
                  placeholder="PLZ"
                  autoComplete="on"
                  inputMode="numeric"
                  value={plz}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setPLZ(e.target.value.trim());
                    }
                  }}
                />
              </div>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="ort" className="form-label">
                  Ort*
                </label>
                <br />
                <input
                  type="text"
                  id="ort"
                  name="ort"
                  placeholder="Ort"
                  autoComplete="on"
                  value={ort}
                  onChange={(e) => setOrt(e.target.value)}
                />
              </div>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="land" className="form-label">
                  Land*
                </label>
                <br />
                <select
                  className={style.DropdownSelection}
                  onChange={(e) => setLand(e.target.value)}
                  value={land}
                  style={{marginLeft: "10px"}}
                >
                  <option value="Deutschland">Deutschland</option>
                  <option value="Österreich">Österreich</option>
                  <option value="Schweiz">Schweiz</option>
                </select>
              </div>

              {/*
                <IntlTelInput
                  defaultValue="DE"
                  onlyCountries={["de", "at", "ch"]}
                  preferredCountries={["DE", "AT", "CH"]}
                  onPhoneNumberChange={(e) => console.log(e)}
                />
            
                <IntlTelInput 
                      //value={laenderVorwahl ? laenderVorwahl : null}
                      
                      defaultValue={"DE"}
                      onlyCountries={["DE", "AT", "CH"]}
                      //preferredCountries={["de", "at", "ch"]}
                      onPhoneNumberChange={(e) => {console.log(e)}}
                      onPhoneNumberBlur={() => {}}
                />*/}

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="telefon" className="form-label">
                  Telefon/Mobil*
                </label>
                <br />
                <Row>
                  <Col>
                    <Form.Select
                      style={{width: "auto", display: "inline-block"}}
                      value={laenderVorwahl} // Make sure to set the value prop to the state value
                      onChange={(e) => setLaenderVorwahl(e.target.value)} 
                    >
                      <option value="+49">DE +49</option>
                      <option value="+43">AT +43</option>
                      <option value="+41">CH +41</option>
                    </Form.Select>

                    <input
                      style={{width: "auto"}}
                      type="text"
                      id="telefon"
                      name="telefon"
                      placeholder="151123456"
                      inputMode="tel"
                      autoComplete="on"
                      value={telefon}
                      onChange={(e) => {
                        let input = e.target.value.replace(/[^+\d]/g, '').trim();
                        if(input.length > 13) {
                          input = input.slice(0, 13);
                        }
                        setTelefon(input);
                      }}
                    />
                  </Col>
                </Row>
              </div>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >

                <label htmlFor="geburtsdatum" className="form-label">
                  Geburtsdatum*
                </label>
                <br />
                {/*
                <input
                  type="date"
                  id="geburtsdatum"
                  name="geburtsdatum"
                  placeholder="Geburtsdatum"
                  autoComplete="on"
                  value={geburtsdatum}
                  onChange={(e) => setGeburtsdatum(e.target.value.trim())}
                />*/}

                <div style={{ display: "inline-flex justify-content-center"}}>
                  <input
                    type="text"
                    id="tag"
                    name="tag"
                    placeholder="TT"
                    style={{display: "inline-block", width: "3em"}}
                    inputMode="numeric"
                    maxLength={2}
                    required
                    value={tagGeburtstag}
                    onChange={(e) => setTagGeburtstag(e.target.value.replace(/[^\d]/g, ""))}
                  />
                  . 
                  <input
                    type="text" 
                    id="monat"
                    name="monat"
                    placeholder="MM"
                    style={{display: "inline-block", width: "3em"}}
                    inputMode="numeric"
                    maxLength={2}
                    required
                    value={monatGeburtstag}
                    onChange={(e) => setMonatGeburtstag(e.target.value.replace(/[^\d]/g, ""))}
                  />
                  .
                  <input
                    type="text"
                    id="jahr"
                    name="jahr"
                    placeholder="JJJJ"
                    style={{display: "inline-block", width: "4em"}}
                    inputMode="numeric"
                    maxLength={4}
                    required
                    value={jahrGeburtstag}
                    onChange={(e) => setJahrGeburtstag(e.target.value.replace(/[^\d]/g, ""))}
                  />
                
                </div>
              </div>




              <label className="form-check-label" style={{ margin: "40px 0px 10px 20px" }}>
                <input
                  id="cbBeratung"
                  className="form-check-input"
                  type="checkbox"
                  checked={beratung}
                  onChange={() => setBeratung(!beratung)}
                />

                <span style={{ paddingLeft: "10px" }}>
                  Ich möchte direkt beraten werden.
                </span>
              </label>



              {beratung && 
                <div
                  style={{
                    padding: "8px",
                    borderRadius: "16px",
                    background: "#e5deed"
                  }}
                >

                  <p className="mt-3 text-center fw-bold">
                    Wozu möchtest du beraten werden?*
                  </p>
                
                <br />
                <br />

                  {beratungsAnlässe.map((thema) => 
                    <>
                      <label
                        key={thema}
                        className="form-check-label"
                        style={{ margin: "20px 0px 0px 20px" }}
                      >
                        <input
                          id={`cbErreichbar-${thema}`}
                          className="form-check-input"
                          type="checkbox"
                          checked={beratungBereich[thema]} // Use the corresponding value from the state
                          onChange={() => handleCheckboxChange(thema)} // Pass the label to the change handler
                        />
                        <span style={{ paddingLeft: "10px" }}>{thema}</span>
                      </label>



                      {thema === "Sonstiges" && beratungBereich[thema] && (
                        <Form.Group>
                          <Form.Control
                            type="text"
                            id="beratungsanlass"
                            name="beratungsanlass"
                            style= {{
                              margin: "10px 8px 0px 8px",
                              borderRadius: "4px",
                              width: "calc(100% - 16px)",
                              boxSizing: "border-box"
                            }}
                            placeholder="Bitte beschreiben"
                            value={beratungsAnlassSonstiges}
                            onChange={(e) => setBeratungsanlassSonstiges(e.target.value)}
                          />
                        </Form.Group>
                      )}

                      <br />

                    </>
                  )}

                  <p className="mt-4 text-center fw-bold">
                    Wann bist du zu erreichen?*
                  </p>
                  <label
                    className="form-check-label"
                    style={{ margin: "20px 0px 0px 20px" }}
                  >
                    <input
                      id="cbErreichbar1"
                      className="form-check-input"
                      type="checkbox"
                      checked={erreichbar1}
                      onChange={() => setErreichbar1(!erreichbar1)}
                    />

                    <span style={{ paddingLeft: "10px" }}>Morgens</span>
                  </label>

                  <label
                    className="form-check-label"
                    style={{ margin: "20px 0px 0px 20px" }}
                  >
                    <input
                      id="cbErreichbar2"
                      className="form-check-input"
                      type="checkbox"
                      checked={erreichbar2}
                      onChange={() => setErreichbar2(!erreichbar2)}
                    />

                    <span style={{ paddingLeft: "10px" }}>Mittags</span>
                  </label>

                  <label
                    className="mb-3 form-check-label"
                    style={{ margin: "20px 0px 0px 20px" }}
                  >
                    <input
                      id="cbErreichbar3"
                      className="form-check-input"
                      type="checkbox"
                      checked={erreichbar3}
                      onChange={() => setErreichbar3(!erreichbar3)}
                    />

                    <span style={{ paddingLeft: "10px" }}>Abends</span>
                  </label>

                </div>
              }


              {/*
              <label className="form-check-label" style={{ margin: "30px 0px 0px 20px" }}>
                <input
                  id="cbPartnerprogramm"
                  className="form-check-input"
                  type="checkbox"
                  checked={partnerprogramm}
                  onChange={() => setPartnerprogramm(!partnerprogramm)}
                />

                <span style={{ paddingLeft: "10px" }}>
                  Ich habe Interesse &nbsp;
                  <a href="https://vin1.eu/vorteilspartner" target="_blank">Vorteilspartner</a>
                  &nbsp; zu werden.
                </span>
              </label>
              */}


              <label className="form-check-label" style={{ margin: "40px 0px 10px 20px" }}>
                <input
                  id="cbBeratung"
                  className="form-check-input"
                  type="checkbox"
                  checked={vorteilspartnerInteresse}
                  onChange={() => setVorteilspartnerInteresse(!vorteilspartnerInteresse)}
                />

                <span style={{ paddingLeft: "10px" }}>
                  Ich möchte Vorteilspartner werden.
                </span>
              </label>





              <div
                className={style.formInputCustom}
                style={{ marginTop: "40px" }}
              >
                <label htmlFor="tippgebernummer" className="form-label">
                  Tippgebernummer / Sponsor-ID (optional)
                </label>
                <br />
                <input
                  type="number"
                  id="tippgebernummer"
                  name="tippgebernummer"
                  placeholder="Tippgebernummer / Sponsor-ID"
                  autoComplete="off"
                  inputMode="numeric"
                  disabled={sponsorDisabled}
                  value={sponsor}
                  onChange={(e) => setSponsor(e.target.value.trim())}
                />
              </div>




              <p
                className="text-center fw-bold"
                style={{ fontSize: "120%", marginTop: "40px" }}
              >
                Login-Daten für dein VIN1-Konto festlegen
              </p>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="EmailLogin" className="form-label">
                  E-Mail (für Login & Kontakt)*
                </label>
                <br />
                <input
                  type="text"
                  id="EmailLogin"
                  name="EmailLogin"
                  inputMode="email"
                  placeholder="user@example.com"
                  autoComplete="new-email"
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value.trim().toLowerCase())}
                />
              </div>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="password1" className="form-label">
                  Passwort (min. 8 Zeichen)*
                </label>
                <br />
                <input
                  type="password"
                  id="password1"
                  name="password1"
                  placeholder="********"
                  autoComplete="new-password"
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value.trim())}
                />
              </div>

              <div
                className={style.formInputCustom}
                style={{ marginTop: "20px" }}
              >
                <label htmlFor="password2" className="form-label">
                  Passwort wiederholen*
                </label>
                <br />
                <input
                  type="password"
                  id="password2"
                  name="password2"
                  placeholder="********"
                  autoComplete="new-password2"
                  value={loginPassword2}
                  onChange={(e) => setLoginPassword2(e.target.value.trim())}
                />
              </div>

            
              <div className="pointer text-center mt-5" onClick={handleNeukundeRegister}>
                <VINButton text={"Absenden"} />
              </div>

            </Form>

          </Row>

        
      </Container>

      {timerStarted && timerCompleted && !endSpinner &&
        <WelcomeSpinner />
      }

    </>
  );
}

export default Register;
