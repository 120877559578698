import React, { useContext } from 'react'
import Header from '../Header/Header'
import { HeaderWithoutAuth } from '../Header/Header'

import { Container, Row, Col } from 'react-bootstrap';
import { UserContext } from '../../store/user-context';


function Nutzungsbedingungen() {

  const { user } = useContext(UserContext);

  return (
    <>
      {user && <Header />}
      {!user && <HeaderWithoutAuth />}
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col>
            <h1 style={{marginBottom: "30px"}}>Nutzungsbedingungen</h1>
            <h5>AGB & Nutzungsbedingungen MyVIN Web-App</h5>
            <p>
              Wir, die VIN1 Germany GmbH, sind Betreiber der Internet-Plattform <a href="https://vin1.eu/" target="_blank" rel="noreferrer noopener">www.vin1.eu</a>
            </p>
            <p>
              Wir sind als Makler für Versicherungen im Sinne des § 34d Abs. 1 Gewerbeordnung und Darlehen sowie Finanzdienstleistungen im Sinne von § 34i GewO tätig. Wir sind im Handelsregister des Amtsgerichts Coesfeld unter der Nummer HRB 20746 eingetragen.
            </p>
            <p>
              Für die Verwaltung deiner Dokumente bieten wir dir eine Web-Applikation (MyVIN1 Web-App) an.
            </p>

            <br />
            <h5>Im Rahmen der MyVIN1 Web-App kannst du</h5>
            <p>
              - Deine bestehenden Verträge aller Art digital verwalten,<br />
              - Eine Beratung zu unseren Produkten in Anspruch nehmen,<br />
              - Informationen zu deinen bestehenden Versicherungsverträgen einholen,<br />
              - Schadensmeldungen online erledigen<br />
              - Bei Bedarf weitere Versicherungsverträge direkt über die Internetplattform<br />
                (<a href="https://vin1.eu/" target="_blank" rel="noreferrer noopener">www.vin1.eu</a>) online oder über einen unserer Berater abschließen<br />
            </p>

            <br />
            <h5>Was tun wir für dich?</h5>
            <p>
              Wir vertreten unsere Kunden aktiv und passiv gegenüber den Versicherern. Wir
            </p>
            <p>  
              - geben alle notwendigen Willenserklärungen ab und<br />
              - nehmen diese auch entgegen,<br />
              - schließen neue Verträge ab,<br />
              - ändern bestehende Verträge und<br />
              - kündigen bestehende Verträge.<br />
            </p>
            <p>
              Wir handeln immer nur mit deinem ausdrücklichen Einverständnis.
            </p>

            <br />
            <h5>1. Geltungsbereich: Was regeln diese Bedingungen?</h5>
            <p>
              1.1 Diese Bedingungen (im Folgenden „AGB“) regeln das gesamte vertragliche Verhältnis zwischen dir (im Folgenden „Kunde“) und uns (im Folgenden „Makler“). Die AGB gelten für sämtliche Geschäfte, die du über unsere Internet-Plattform, per Web-App oder im direkten Kontakt mit uns tätigst, dies natürlich nur unter der zwingenden Voraussetzung, dass die AGB vor Abschluss des Geschäftes auch wirksam Bestandteil der Vertrags wurden. Die AGB werden nur dann Bestandteil des Vertrags zwischen dir und uns, wenn wir dich vor Vertragsschluss ausdrücklich auf die AGB hinweisen und du die Möglichkeit hast, von deren Inhalt Kenntnis zu nehmen.
            </p>
            <p>
              1.2 Die AGB gelten in der zum Zeitpunkt der Bestellung gültigen Fassung.
            </p>
            <p>
              1.3 Abweichende AGB gelten nur, wenn wir diesen schriftlich zugestimmt haben.
            </p>
            <p>  
              1.4 Wir müssen dir nach § 15 Versicherungs-Vermittlungs-Verordnung Informationen beim ersten Kontakt geben. Diese Erstinformation kannst du unter <a href="https://vin1.eu/" target="_blank" rel="noreferrer noopener">www.vin1.eu</a> abrufen und herunterladen.
            </p>
            <p>
              1.5 Wir sind bei der Vermittlung eines Darlehens außerdem verpflichtet dich nach Maßgabe des Artikels 247 § 13 Abs. 2 und § 13 b Abs. 1 EGBGB zu informieren. Diese Unterlagen erhältst du von uns, bevor du uns mit der Vermittlung beauftragst und bevor wir mit der Vermittlung deines Darlehens beginnen.
            </p>
            <p>
              1.6 Mit der Registrierung in der MyVIN1 Web-App kommt ein Nutzungsvertrag zwischen Dir und der VIN1 Germany GmbH hinsichtlich der Nutzung der Web-App zustande.
            </p>
            <p>
              1.7 Die Nutzung der MyVIN1 Web-App ist unabhängig von deinem bestehenden Versicherungsschutz und Vertragsverhältnis.
            </p>

            <br /> 
            <h5>2. Leistungsgegenstand: Wie kannst du unser Portal nutzen?</h5>
            <p>
              2.1 Du kannst auf unserer Internet-Plattform, bzw. Web-App bestehende Versicherungen einfach und digital verwalten. Du kannst deine Verträge auch optimieren oder neue Versicherungsverträge abschließen. Du kannst unsere Internet-Plattform bzw. Web-App kostenlos nutzen und die Nutzung jederzeit beenden.
              <br /><br />2.2 Wenn du auf unserer Internet-Plattform bzw. Web-App unsere Leistungen nutzt, ändert sich an deinen Verträgen nichts. Wir übernehmen nicht automatisch deine Versicherungsverträge oder verändern diese.
              <br /><br />2.3 Wir werden nur als Makler für dich tätig, wenn du uns ausdrücklich beauftragst. Soweit du uns entsprechend beauftragt hast, können wir wie folgt tätig werden:
              <br />&emsp;• Bestehende Verträge in unsere Internet-Plattform bzw. Web-App aufnehmen und dir anzeigen,
              <br />&emsp;• neue Verträge abschließen,
              <br />&emsp;• bestehende Verträge ändern,
              <br />&emsp;• Verträge kündigen.
              <br /><br />2.4 Wir sind darum bemüht, dir unsere Dienste jederzeit bestmöglich über unsere Internet-Plattform zugänglich zu machen. Wir schulden oder garantieren dir aber nicht, dass du unsere Internet-Plattform bzw. Web-App erreichen kannst. Wir haften nicht – außer im Fall eines vorsätzlichen oder grob fahrlässigen Handelns – für Ausfälle aus:
              <br />technischen Gründen, z.B.:
              <br />&emsp;• gestörte Hardware,
              <br />&emsp;• fehlerhafte Software, • Wartungs-Arbeiten.
              <br />&emsp;• Gründen, die wir nicht beeinflussen können, z.B.:
              <br />&emsp;• höhere Gewalt,
              <br />&emsp;• Verschulden durch Dritte.
              <br />Die vorstehende Haftungsbeschränkung gilt nicht für Schäden an Gesundheit und Leben, oder für typischerweise vorhersehbare Schäden, die aufgrund einer Verletzung einer wesentlichen Vertragspflicht entstehen (sog. Kardinalpflichten).
            </p>

            <br /> 
            <h5>3. Nutzungsbedingungen: Welche Regeln musst du beachten, wenn du unser Portal nutzt?</h5>
            <p>
                3.1 Um die MyVIN1 Web-App bzw. die Online Plattform nutzen zu können, musst du dich zuerst über die MyVIN1 App oder über das Kontaktformular (<a href="https://vin1.eu/kontakt/" target="_blank" rel="noreferrer noopener">https://vin1.eu/kontakt/</a>) registrieren und so ein Nutzerkonto einrichten.
                <br /><br />3.2 Zur Nutzung und Registrierung sind nur folgende unbeschränkt geschäftsfähige Personen berechtigt:
                <br />&emsp;- volljährige natürliche Personen, - Personen-Gesellschaften,
                <br />&emsp;- juristische Personen.
                <br /><br />Personen-Gesellschaften und juristische Personen müssen sich durch eine vertretungsberechtigte Person unter Angabe des Vor- und Zunamens registrieren.
                <br /><br />3.3 Wenn du unsere Dienste nutzt, musst du alle Fragen vollständig und wahr beantworten. Daten musst du ebenfalls vollständig und richtig angeben. Wenn wir Zweifel daran haben, dass die Angaben nicht richtig und vollständig sind, können wir die Zusammenarbeit jederzeit beenden und deine Registrierung rückgängig machen. Im Hinblick auf die Verarbeitung von Daten gelten unsere Datenschutzerklärungen. Diese kannst du unter <a href="https://vin1.eu/datenschutz/" target="_blank" rel="noreferrer noopener">www.vin1.eu/datenschutz/</a> einsehen und herunterladen. Du hast keinen Anspruch auf ein Nutzerkonto. Wir können deinen Zugang übergangsweise sperren, sofern ein berechtigtes Interesse daran besteht. Dabei beachten wir deine und unsere Interessen in gleichen Maßen. Wenn wir dein Nutzerkonto sperren, informieren wir dich in Textform darüber. Textform bedeutet: E- Mail, SMS oder Fax, nicht unbedingt Brief.
                <br /><br />3.4 Durch die Nutzung unserer Dienste kommt zwischen uns ein Nutzungsvertrag zustande. Die Einzelheiten zur Nutzung sind in diesen AGB geregelt.
                <br /><br />3.5 Wenn unsere Internet-Plattform bzw. Web-App ein entsprechendes Angebot vorsieht, ermöglicht sie dir Folgendes: Du kannst kostenlos und unverbindlich nach einer passenden Versicherung, Energieangebot, Finanzierungsangebot und Immobilie suchen.
                <br />Außerdem kannst du bestehende Verträge übersichtlich verwalten und optimieren. Dabei werden alle objektiven und anerkannten Bewertungsmaßstäbe für die in unserem Rahmen abgedeckten Dienstleistungen und Produkte berücksichtigt.
                <br /><br />3.6 Du kannst außerdem deine bestehenden Versicherungen auf Nutzen und Vollständigkeit prüfen. Dieses Prüfungsverfahren erfolgt anhand objektiver und allgemein anerkannter Maßstäbe. Bitte beachte Folgendes: Im Lauf der Zeit kann sich dein Bedarf verändern. Eine bestehender Vertrag kann zu einem späteren Zeitpunkt deines Lebens nicht mehr deinen Bedürfnissen entsprechen. Dieser kann auch von den von der Software zugrunde gelegten Maßstäben abweichen. Wir übernehmen keine Gewähr dafür, dass bestehende Verträge, die wir übernehmen, bei Abschluss:
                <br />die geeignetste,
                <br />die beste,
                <br />die sinnvollste Wahl für dich dargestellt haben.
                <br /><br />3.7 Die Nutzung unserer Internet-Plattform bzw. Web-App kann eine persönliche Beratung nicht unbedingt ersetzen. Daher bieten wir dir auf Wunsch an, dich individuell zu beraten. Der notwendige Kontakt hierfür kann über verschiedene Kanäle erfolgen. Wir beraten dich entweder persönlich oder beauftragen damit einen kompetenten Dritten.
                <br /><br />3.8 Wenn wir deine Verträge verwalten sollen, musst du uns eine Vollmacht erteilen. Eine Vollmacht berechtigt uns unter anderem:
                <br />Deine bestehenden Verträge bei den Versicherern einzusehen und
                <br />die Daten Ihrer bestehenden Verträge in unser System zu übertragen.
                <br />Wir übernehmen keine Gewähr dafür, dass die Daten, die wir von den Versicherern erhalten, vollständig oder richtig sind.
                <br />Soweit du uns mit der Vermittlung eines Darlehens beauftragt hast, berechtigt uns die Vollmacht Erklärungen gegenüber den Banken und Finanzdienstleistern abzugeben und entgegenzunehmen.
                <br /><br />3.9 Du verpflichtest dich, die angebotenen Dienste nur entsprechend deren Zweck zu verwenden.
                <br />Du darfst die Software nicht in deine eigenen Computer-Programme einbringen oder mit diesen
                <br />&emsp;- zusammensetzen,
                <br />&emsp;- verkaufen,
                <br />&emsp;- vermieten,
                <br />&emsp;- verleihen,
                <br />&emsp;- vertreiben,
                <br />&emsp;- unterlizenzieren,
                <br />&emsp;- in weitere Programme integrieren,
                <br />&emsp;- mit weiteren Programmen verknüpfen,
                <br />&emsp;- für illegale Zwecke verwenden.
                <br /><br />3.10 Wir sind jederzeit berechtigt:
                <br />- die Bereitstellung der Internet-Plattform bzw. App einzustellen oder - dein Recht zur Nutzung dieser zu beenden.
                <br /><br />Dies gilt insbesondere dann, wenn du gegen die Nutzungsbedingungen verstößt.
                <br /><br />3.11 Du kannst jederzeit verlangen, dass wir dein Nutzerkonto löschen. Im Hinblick auf die Speicherung von Daten über die Geschäftsbeziehung hinaus gelten unsere Datenschutzerklärungen. Diese kannst du unter <a href="https://vin1.eu/datenschutz/" target="_blank" rel="noreferrer noopener">wwwvin1.eu/datenschutz</a> einsehen und herunterladen.
                <br /><br />3.12 Du bist für das sichere Aufbewahren deiner Zugangsdaten zuständig. Du musst die Zugangsdaten vertraulich behandeln. Das bedeutet, dass du die Daten geheim halten musst. Dritte dürfen diese nicht einsehen. Besteht ein Verdacht auf Missbrauch der Daten, musst du uns dies sofort mitteilen. Bitte schicke uns hierfür eine formlose Email an hello@vin1.eu
                <br /><br />3.13 Du musst entsprechende Vorkehrungen treffen, deine auf unserer Internet- Plattform bzw. App verwendeten Daten zu sichern. Gemeint sind alle Daten, die von dir auf der Internet-Plattform:
                <br />
                <br />&emsp;- eingegeben,
                <br />&emsp;- hochgeladen,
                <br />&emsp;- gespeichert,
                <br />&emsp;- anderweitig an uns übersandt oder
                <br />&emsp;- von uns an dich versandt wurden.
                <br /><br />Diese Daten und Inhalte musst du regelmäßig und gefahrentsprechend sichern und eigene Sicherungskopien erstellen. Dies dient dazu, dass du bei Verlust von Daten und Informationen diese selbst wiederherstellen kannst.
                <br /><br />3.14 Wir als VIN1 Germany können jederzeit und ohne vorgängige Ankündigung Aktualisierungen oder Updates der MyVIN1 Web-App bzw. der VIN1 Internetplattform durchführen und sind jederzeit berechtigt, die angebotenen Dienstleistungen zu ändern oder einzustellen.
                <br /><br />3.15 Sämtliche in der MyVIN1 Web-App und der VIN1 Internetplattform dargestellten Inhalte wie Texte, Grafiken, Logos, Bilder, etc. sind immaterialgüterrechtlich geschützt und Eigentum der VIN1 Germany GmbH, lizensierter Nutzer oder sonstiger Dritter. Durch das Nutzungs- und Geschäftsverhältnis sowie die Nutzung der MyVIN1 Web-App oder der VIN1 Internetplattform gehen keinerlei Rechte auf dich über.
              </p>

            <br /> 
            <h5>4. Vollmacht: Was gilt für die Vollmacht?</h5>
            <p>
              4.1 Du erteilest uns deine Vollmacht auf zwei Wegen:
              <br /><br /> &emsp;1. Digitale Unterschrift:
                <br />&emsp;&emsp;1. Wir erfassen deine Unterschrift digital und tragen sie in die Vollmacht ein. Wir können auch andere Verfahren verwenden, um deine Unterschrift zu digitalisieren.
                <br /><br />&emsp;2. Eigenhändige Unterschrift:
                <br />&emsp;&emsp;1. Du unterschreibst die Vollmacht eigenhändig und sendest uns die unterschriebene Vollmacht per Post.
                <br /><br />4.2 Wir speichern deine Unterschrift in unserem System. Wir nutzen deine Unterschrift auch für andere Dokumente, nachdem du der Nutzung zuvor ausdrücklich zugestimmt hast. Wir leiten die unterschriebene Vollmacht – falls erforderlich – an Versicherer oder andere berechtigte Dritte weiter.
                  <br /><br />4.3 Wir haben das Recht, eine Vollmacht jederzeit und ohne Angabe von Gründen abzulehnen.
              <br /><br /> 4.4 Der Umfang der Vollmacht richtet sich nach deinem jeweiligen Auftrag. Sie entspricht in Ausmaß und Reichweite einer für diese Branche üblichen Vollmacht. Die Vollmacht erstreckt sich zum Beispiel auf:
              <br />• Beschaffung deiner Daten,
              <br />• Betreuung und Verwaltung deiner Verträge,
              <br />• Vermittlung von neuen Verträgen,
              <br />• Unterstützung beim Regulieren von Versicherungsschäden.
              <br /><br />4.5 Du kannst die Vollmacht jederzeit widerrufen. Auch wir haben dieses Recht. Allerdings dürfen wir die Vollmacht in für dich ungünstigen Zeiten nur widerrufen, wenn ein wichtiger Grund dafür vorliegt.
            </p>

            <br /> 
            <h5>5. Vermittlung von Versicherungsverträgen: Wie vermitteln wir dir eine passende Versicherung?</h5>
            <p>
              5.1 Du kannst uns beauftragen, Versicherungsverträge für dich abzuschließen. Dann sind wir verpflichtet, unserem Vorschlag eine hinreichende Anzahl von auf dem Markt angebotenen Verträgen und Versicherern zugrunde zu legen. Darauf aufbauend geben wir dir einen fachlichen Rat, welcher Vertrag geeignet erscheint, deine Bedürfnisse zu erfüllen. Dies gilt nicht, wenn wir im Einzelfall vor Abgabe der Vertragserklärung auf eine begrenzte Auswahl von Unternehmen und Produkten hinweisen.
              <br /><br />5.2 Wenn wir dich auf eine begrenzte Auswahl hinweisen, teilen wir dir mit, auf welcher Markt- und Informationsgrundlage unser Vorschlag erfolgt. Außerdem teilen wir dir die Namen der zugrunde gelegten Unternehmen mit. Auf diese Pflicht kannst du durch eine gesonderte schriftliche Erklärung verzichten.
              <br /><br />5.3 Wir vermitteln dir nur Verträge, die deinen individuellen Bedürfnissen entsprechen. Unsere Vorschläge basieren unter anderem auf diesen Kriterien:
              <br />• dem Verhältnis von Preis und Leistung,
              <br />• der Zuverlässigkeit beim Bearbeiten von Schäden und
              <br />• unserer Expertise.
              <br /><br />5.4 Wir können Lebensversicherungen und Dienstleistungen mit Anlagezweck nur dann vermitteln, wenn du dich identifizierst. Dazu musst du deine Identität durch einen gültigen amtlichen Ausweis nachweisen. Der Ausweis muss ein Lichtbild enthalten und die Pass- und Ausweispflicht erfüllen.
              <br /><br />5.5 Wir vermitteln dir nur Verträge von Unternehmen:
              <br />• die unter Aufsicht der Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin) stehen und
              <br />• deren Regelwerk in deutscher Sprache verfasst ist.
              <br />Auch ausländische Versicherer sind in Deutschland tätig. Die Produkte dieser Unternehmen vermitteln wir nur, wenn deren Angebot und Zuverlässigkeit mit einem deutschen Unternehmen vergleichbar sind. Eine Pflicht hierzu besteht nicht.
              <br />Einige Unternehmen, sogenannte Direktversicherer, vertreiben ihre Produkte nur selbst. Diese lassen den Abschluss von Verträgen durch Dritte nicht zu. Wir weisen darauf hin, dass wir diese Verträge nicht vermitteln können.
              <br /><br />5.6 Du musst alle Angaben, die wir für eine vertragsgemäße Beratung und Verwaltung benötigen, wahrheitsgemäß und innerhalb der geforderten Frist übermitteln.
              <br /><br />5.7 Wenn du mitwirken musst, zum Beispiel einen Antrag unterschreiben, musst du:
              <br />• alle geforderten Erklärungen,
              <br />• auf unsere Aufforderung oder auf Aufforderung des Versicherers
              <br />• ohne Verzug abgeben.
            </p>

            <br /> 
            <h5>6. Darlehensvermittlung. Wie vermitteln wir dir Darlehen?</h5>
            <p>
              6.1 Wir vermitteln ausschließlich an Verbraucher Darlehen zur Finanzierung einer eigenen Immobilie nach §34 i GewO. Der Darlehensvertrag kommt nicht mit uns, sondern einer Bank zustande.
              <br /><br />6.2 Du kannst uns uneingeschränkt damit beauftragen, einen Darlehensvertrag oder eine entgeltliche Finanzierungshilfe zu vermitteln;
              <br />b. die Gelegenheit zum Abschluss eines Darlehensvertrags oder einer entgeltlichen Finanzierungshilfe derart nachzuweisen, dass du in die Lage versetzt wirst, den Vertrag abzuschließen;
              <br />c. dir auf anderer Weise beim Abschluss eines Vertrags Darlehensvertrags behilflich zu sein.
              <br /><br />6.3 Wir sind hierbei unabhängig und nicht an einen bestimmten Darlehensgeber gebunden und berücksichtigen bei der Produktberatung und Produktauswahl eine ausreichende Anzahl von inländischen Darlehensgebern, mindestens jedoch zwei.
              <br /><br />6.4 Wir schulden allerdings nicht den erfolgreichen Abschluss eines Darlehensvertrages für den uns genannten Zweck. Soweit du es wünschst, betreuen wir dich aber auch bis zum rechtskräftigen Abschluss des Darlehensvertrages und Auszahlung des Darlehens auf dein Konto. In diesem Fall vertreten wir dich auch gegenüber dem darlehensgebenden Kreditinstitut.
              <br /><br />6.5 Wenn du uns beauftragst gilt dieser Auftrag aber uneingeschränkt zur alleinigen Beschaffung des benötigten Darlehens. Du beauftragst während der Dauer eines Darlehensvermittlungsauftrages keine weiteren Vermittler mit dem Nachweis und/oder der Vermittlung eines Darlehensvertrages für den in diesem Vertrag genannten Zweck.
              <br /><br />6.6 Um für dich ein passendes Darlehen zu finden, sind wir auf deine Mithilfe angewiesen. Du bist daher insbesondere zur zügigen und vollständigen Erteilung wahrheitsgemäßer Angaben und Zurverfügungstellung der zur Durchführung der Darlehensvermittlung notwendigen Informationen verpflichtet. Diesen uns so geschilderten Sachverhalt legen wir als Beratungsgrundlage zugrunde. Dieser Sachverhalt wird als vollständig, wahrheitsgemäß und abschließend bewertet.
              <br /><br />6.7 Du bist dabei insbesondere verpflichtet, uns nach Abschluss eines Darlehensvermittlungsvertrages alle Nachweise zur Verfügung zu stellen, die wir und der Darlehensgeber benötigen, um die in jedem Fall durchzuführende Kreditwürdigkeitsprüfung durchführen zu können. Solche Nachweise sind insbesondere alle bonitätsrelevanten Daten wie erzieltes Einkommen, einschließlich deren Entwicklung, Zahlungsverpflichtungen gegenüber Dritten, Personenstandsurkunden, Grundbuchauszüge sowie weitere geeignete Nachweise sein.
              <br /><br />6.8 Bitte informiere uns während der Laufzeit des Vertrages über alle Änderungen, die für die Vermittlung des Darlehens wesentlich sein können, oder alle Änderungen innerhalb der nach Ziff. 6.6. dieser AGB zur Kreditwürdigkeitsprüfung verlangten Nachweise.
            </p>

            <br /> 
            <h5>7. Kommunikation: Wie verständigen wir uns mit dir?</h5>
            <p>
              Wir verständigen uns mit dir elektronisch über die MyVIN1 Web-App bzw. der VIN1 Internetplattform (<a href="https://vin1.eu/" target="_blank" rel="noreferrer noopener">www.vin1.eu</a>) sowie über andere übliche Kommunikationsmittel (Telefon, E-Mail, Fax, etc.). Ausgenommen davon sind lediglich Erklärungen, die der gesetzlichen Schriftform unterliegen. Davon dürfen wir nicht abweichen.
            </p>

            <br /> 
            <h5>8. Beraterhaftung: In welchen Fällen und in welcher Höhe haften wir bei der Versicherungsvermittlung?</h5>
            <p>
              8.1 Unsere Haftung ist mit Ausnahme der in den §§ 60, 61 VVG geregelten Beratungs- und Dokumentationspflichten in der gesetzlich festgelegten Höhe der bestehenden Vermögensschaden-Haftpflichtversicherung begrenzt. Unsere Haftung ist aber wie folgt begrenzt: Wir haften höchstens bis zur Höhe des im Zeitpunkt des Vertragsschlusses typischen, vorhersehbaren Schadens. Wir haften nicht für ausbleibenden wirtschaftlichen Erfolg, entgangenen Gewinn und daraus entstandene mittelbare Schäden.
              <br /><br />8.2 In unbegrenzter Höhe haften wir nur für Schäden:
              <br />• die sich aus den in den §§ 60, 61 VVG geregelten Beratungs- und Dokumentationspflichten ergeben,
              <br />• die wir vorsätzlich und grob fahrlässig herbeigeführt haben oder
              <br />• aus der Verletzung des Lebens, des Körpers und der Gesundheit.
              <br /><br />8.3 Für Mängel oder Fehler an der Internet-Plattform bzw. App haften wir nur, wenn wir diesen Mangel oder Fehler arglistig, vorsätzlich oder grob fahrlässig verschwiegen haben.
              <br /><br />8.4 Wir haften nicht für Störungen, die wir nicht beeinflussen können. Dazu gehören zum Beispiel:
              <br />• überlastete Leitungen oder
              <br />• Ausfall von Telekommunikations-Verbindungen.
              <br /><br />8.5 Die hier dargestellten Begrenzungen unserer Haftung gelten ebenso für:
              <br />• unsere Mitarbeiter und Erfüllungsgehilfen und
              <br />• sonstige Dritte, die uns helfen, unsere Pflichten zu erfüllen.
            </p>

            <br /> 
            <h5>9. Beraterhaftung: In welchen Fällen und in welcher Höhe haften wir bei der Darlehensvermittlung?</h5>
            <p>
              9.1 Bei der Vermittlung von Darlehensverträgen richtet sich die Haftung nach den gesetzlichen Vorschriften. Unsere und die Haftung unserer Erfüllungsgehilfen auf Schadensersatz für die Verletzung von Beratungspflichten ist auf den vertragstypischen, vorhersehbaren Schaden begrenzt.
              <br /><br />9.2 Unsere und die Haftung unserer Erfüllungsgehilfen für leichte Fahrlässigkeit für eine Verletzung unserer Pflichten ist summenmäßig begrenzt auf der in der Vermögensschaden-Haftpflichtversicherung gesetzlich festgelegten Höhe für jeden Schadenfall.
              <br /><br />9.3 Die zuvor genannten Haftungsbeschränkungen gelten nicht für die Haftung wegen vorsätzlichen Verhaltens oder grober Fahrlässigkeit, für garantierte Beschaffenheitsmerkmale, wegen Verletzung des Lebens, des Körpers oder der Gesundheit oder nach dem Produkthaftungsgesetz. Die zuvor genannten Haftungsbeschränkungen gelten auch nicht für die Verletzung wesentlicher Vertragspflichten (Kardinalpflichten). Wesentliche Vertragspflichten sind solche sich aus der Natur des Vermittlungsgeschäftes ergebenden Pflichten, deren Einschränkung die Erreichung des Vertragszwecks gefährden würde oder deren Erfüllung die ordnungsgemäße Durchführung des Vertragszwecks unmöglich machen.
              <br /><br />9.4 Soweit du uns die Informationen und Unterlagen nicht rechtzeitig mitteilst, die für eine sachgerechte Wahrnehmung deiner Interessen erforderlich sind, bist du nicht berechtigt, insoweit Schäden geltend zu machen, die dadurch entstehen, dass ein Darlehensvertrag nicht oder nicht mit den erforderlichen Konditionen abgeschlossen wird. Gleiches gilt, wenn du relevante Veränderungen oder notwendige Angaben nicht oder nicht rechtzeitig mitteilst.
              <br /><br />9.5 Wir sind um Aktualität und Genauigkeit aller übermittelter Informationen bemüht. Soweit Informationen von Dritten bereitgestellt werden, kann keine Zusicherung oder Gewährleistung hinsichtlich deren Aktualität, Richtigkeit, Zuverlässigkeit oder Vollständigkeit gegeben werden. VIN1 Germany übernimmt keinerlei Gewähr oder Haftung für Inhalte, die von Dritten angeboten werden, selbst wenn diese über die MyVIN1 Web-App oder der VIN1 Internetplattform erreichbar bzw. abrufbar sind (z.B. durch Links auf andere Webseiten).
            </p>

            <br /> 
            <h5>10. Widerrufsrecht</h5>
            <p>
              Du kannst den Nutzungsvertrag jederzeit widerrufen und verlangen, dass die VIN1 Germany dein Nutzerkonto kostenfrei und mit sofortiger Wirkung sperrt. Ein Widerruf hat keine Auswirkungen auf bestehenden Versicherungsschutz.
              <br /><br />Um dein Widerrufsrecht auszuüben, kannst du dich jederzeit per Post oder mit einer kurzen E-Mail an folgende Adresse an uns wenden: hello@vin1.eu .
              <br /><br />Im Widerrufsfall werden deine Daten bis zum Ende der gesetzlichen Aufbewahrungsfristen aufbewahrt und anschließend unwiederbringlich gelöscht – soweit sie nicht zur Erfüllung bestehender Versicherungsverträge Verwendung finden.
              <br /><br />Genaue Informationen zur Datenverarbeitung bei Nutzung der MyVIN1 Web-App bzw. der VIN1 Onlineplattform findest du unter: <a href="https://vin1.eu/datenschutz" target="_blank" rel="noreferrer noopener">www.vin1.eu/datenschutz</a>
            </p>
            <p className="fw-bold">Muster Widerrufsformulierung:</p>
            <p> 
              An die<br />
              <br />
              VIN1 Germany GmbH
              <br />Aspastraße 24
              <br />D-59394 Nordkirchen
              <br /><br />Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Nutzungsvertrag vom [Datum]. Name des/der Verbraucher(s) Anschrift des/der Verbraucher(s) [Datum] Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier) (*) Unzutreffendes bitte streichen.
              <br /><br />Durch Widerruf Deines Nutzungsvertrages für MyVIN1 Web-App bzw. der VIN1 Onlineplattform ändert sich am Bestehen deiner Versicherungsverträge nichts. Der Widerruf ist unter der oben angeführten Adresse an VIN1 Germany zu richten.
            </p>

            <br /> 
            <h5>11. Vertragsdauer</h5>
            <p>
              Du kannst deinen VIN1 Nutzungsvertrag jederzeit kostenfrei ohne Angabe von Gründen mit sofortiger Wirkung kündigen.
              <br /><br />VIN1 Germany kann deinen Nutzungsvertrag bei Verstößen gegen gesetzliche oder regulatorische Vorschriften sowie gegen diese Nutzungsbedingungen mit sofortiger Wirkung kündigen.
              <br /><br />Im Falle einer Kündigung wird dein Nutzerkonto gesperrt. Die von dir angegebenen Daten stehen danach ausschließlich zur weiteren Bearbeitung deiner allenfalls bestehenden Versicherungsverträge zur Verfügung. Erfolgt eine Kündigung, ohne dass Versicherungsverträge vorliegen, werden die von dir eingegebenen Daten für die weitere Bearbeitung gesperrt und nach dem Ende der gesetzlichen Aufbewahrungsfristen unwiederbringlich gelöscht.
            </p>

            <br /> 
            <h5>12. Schlussbestimmungen</h5>
            <p>
              12.1 Wenn eine Regelung dieser AGB unwirksam sein sollte, bleiben alle weiteren Abschnitte wirksam. Auch bereits bestehende Verträge bleiben unberührt. Die unwirksame Regelung entfällt ersatzlos, an ihre Stelle tritt die gesetzliche Regelung hierzu. Stellt sich heraus, dass in den AGB Regelungslücken bestehen, bleiben alle weiteren Abschnitte wirksam. Auch die bestehenden Verträge bleiben unberührt.
              <br /><br />12.2 Wenn wir diese AGB ändern, geschieht dies – sofern nicht lediglich rechtlich vorteilhaft für dich – wie folgt: Bevor die angepassten AGB gültig werden, senden wir dir die geänderten Abschnitte oder AGB in Textform zu. Wir weisen dich gesondert auf die neuen Passagen und das Datum des geplanten Inkrafttretens hin. Außerdem räumen wir dir eine sechswöchige Frist zum Widerspruch ein. Diese Frist beginnt, wenn du unsere Nachricht in Textform erhalten hast. Du hast dann sechs Wochen Zeit Widerspruch einzulegen. Wenn wir innerhalb dieser Frist keinen Widerspruch von dir erhalten, gelten die geänderten AGB als vereinbart. Wir weisen dich bei Fristbeginn gesondert auf die Folgen deines Schweigens hin.
              <br /><br />12.3 Es gilt das Recht der Bundesrepublik Deutschland. Das UN-Kaufrecht sowie alle internationalen Kollisionsnormen sind ausgeschlossen.
            </p>

            <br />
            <p>
              Letzte Aktualisierung 12.10.2022
            </p>
            


          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Nutzungsbedingungen