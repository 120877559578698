import React, { useState } from 'react'
import Header from '../../Header/Header'
import { useNavigate } from 'react-router-dom';

import WebViewModal from '../../Bausteine/WebViewModal/WebViewModal';
import { Container, Row, Col } from 'react-bootstrap';
import style from './Immobilien.module.css'


function Immobilien() {

 const ColorTheme = "#549E56";

  let navigate = useNavigate();

 
  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState(false);

  const handleExternalURL = (url) => {

    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
  }

  return (
    <>
      <Header />
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col style={{textAlign: "center"}}>
            <h1 style={{color: ColorTheme}}>Übersicht Immobilien</h1>
          </Col>
        </Row>

        <br />
        <br />

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 2}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{ color: ColorTheme }}>Immobilienbewertung</h5>
            <p className="text-center">
              Starte jetzt Deine professionelle Immobilienbewertung. Wir ermitteln mit modernen Tools und zunächst auf Basis einer der größten deutschen Immobilienpreisdatenbanken den Wert Deiner Immobilie.
            </p>
            {/*<button className={style.VivarevoBtn} onClick={() => handleExternalURL("https://vivarevo.de/immobewertung/")}>Mehr Informationen &gt;</button>*/}
             <button className={style.VivarevoBtn} onClick={() => navigate("/beratung", { state: { category: "Immo", subcategory: "Immobilien-Bewertung" }})}>Jetzt beraten lassen &gt;</button>
          </Col>

          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Immo.png")} alt="" className={style.ColImg} />
          </Col>
        </Row>

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Verkaufen.png")} alt="" className={style.ColImg} />
          </Col>
            <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{ color: ColorTheme }}>Verkaufen</h5>
            <p className="text-center">
              Du möchtest Dein Haus verkaufen? Wir unterstützen Dich bei der erfolgreichen Vermarktung, bis hin zum Besorgen aller benötigten Unterlagen und unterstützen dich bei allen wichtigen Terminen.
            </p>
            {/*<button className={style.VivarevoBtn} onClick={() => handleExternalURL("https://vivarevo.de/kontakt/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.VivarevoBtn} onClick={() => navigate("/beratung", { state: { category: "Immo", subcategory: "Verkaufen" }})}>Jetzt beraten lassen &gt;</button>
          </Col>
        </Row>



        <Row className={style.Row}>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 2}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{ color: ColorTheme }}>Kaufen</h5>
            <p className="text-center">
              Du suchst eine Immobilie? Wir finden garantiert das passende Objekt für Dich. Auf Wunsch begleiten wir den Kaufprozess bis hin zum Notartermin.
            </p>
            {/*<button className={style.VivarevoBtn} onClick={() => handleExternalURL("https://vivarevo.de/kontakt/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.VivarevoBtn} onClick={() => navigate("/beratung", { state: { category: "Immo", subcategory: "Kaufen" }})}>Jetzt beraten lassen &gt;</button>
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Kaufen.png")} alt="" className={style.ColImg} />
          </Col>
        </Row>

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Vermietung.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{ color: ColorTheme }}>Vermietung</h5>
            <p className="text-center">
              Du sucht eine Wohnung oder möchtest vermieten? In jedem Fall finden wir das passende Match für Dich.
            </p>
            {/*<button className={style.VivarevoBtn} onClick={() => handleExternalURL("https://vivarevo.de/kontakt/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.VivarevoBtn} onClick={() => navigate("/beratung", { state: { category: "Immo", subcategory: "Vermietung" }})}>Jetzt beraten lassen &gt;</button>
          </Col>
        </Row>



        <Row className={style.Row}>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 2}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{ color: ColorTheme }}>Marketing</h5>
            <p className="text-center">
              Wir rücken Deine Immobilie ins beste Licht. Wir bieten Dir professionelle Unterstützung in verschiedenen Medienformaten.
            </p>
            {/*<button className={style.VivarevoBtn} onClick={() => handleExternalURL("https://vivarevo.de/kontakt/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.VivarevoBtn} onClick={() => navigate("/beratung", { state: { category: "Immo", subcategory: "Marketing" }})}>Jetzt beraten lassen &gt;</button>
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Marketing.png")} alt="" className={style.ColImg} />
          </Col>
        </Row>

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Architekt.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{ color: ColorTheme }}>Architekt</h5>
            <p className="text-center">
              Wir bieten Dir architektonische Leistungen und sind von der Konzeption über die Entwicklung, bis hin zur Realisierung dein kompetenter Partner.
            </p>
            {/*<button className={style.VivarevoBtn} onClick={() => handleExternalURL("https://vivarevo.de/kontakt/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.VivarevoBtn} onClick={() => navigate("/beratung", { state: { category: "Immo", subcategory: "Architekt" }})}>Jetzt beraten lassen &gt;</button> 
          </Col>
        </Row>

        <br />
        <br />


        <div className="row">
          <div className={style.BottomCard}>
            <h4 className="text-center">Dein digitaler Makler der Zukunft</h4>
            <br />
            {/*<button className={style.VivarevoBtnNormal} onClick={() => handleExternalURL("https://vivarevo.de/")}>Hier mehr erfahren &gt;</button>*/}
            <button className={style.VivarevoBtnNormal} onClick={() => navigate("/beratung")}>Jetzt beraten lassen &gt;</button>
          </div>
        </div>

        <br />
        <br />
        <br />

        <Row style={{marginBottom:"80px"}}>
          <Col style={{textAlign: "center"}}>
            <button className={style.VivarevoBtnNormal} onClick={() => navigate("/dokumente/immobilien")}>Zu Deiner Dokumentenablage &gt;</button>
          </Col>
        </Row>


        {showModal && <WebViewModal url={modalUrl} hideModal={setShowModal} />}

      </Container>
    </>

  )
}

export default Immobilien