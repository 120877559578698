import React, {
  useState,
  useEffect,
  useContext,
} from "react";


import { useNavigate } from "react-router-dom";

//import Card from "../Card/Card";
import CardDoppelt from "../Card/CardDoppelt";

import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";
import PDFViewerModal from "../Bausteine/PDFViewerModal/PDFViewerModal";

import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti'
import GewinnModal from '../Bausteine/GewinnModal'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserAlt,
  faSignOutAlt,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
     
import style from "./Home.module.css";

import { db } from "../../firebase";
import { getAuth, signOut } from "firebase/auth";
import { updateDoc, doc, collection, getDocs } from "firebase/firestore";
//import { serverTimestamp } from "firebase/firestore";

import { Container, Row, Col, Button } from "react-bootstrap";

import Swal from "sweetalert2";
import { AuthContext } from "../../store/auth-context";
import { UserContext } from "../../store/user-context";
import WebpImage from "../Bausteine/WebpImage";

import { formatDateTime } from '../../util/date/dates'

import HomeMobile from './HomeMobile';

function Home() {

  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);
  const { user, updateUser, unreadDocs } = useContext(UserContext);

  // ====== Gewinnspiel ======
  const { width, height } = useWindowSize()
  const [gewinne, setGewinne] = useState(false);
  const [gewinnModal, setGewinnModal] = useState(true);

  /*
  useEffect(() => {
    
    if(showConfetti) {
      const timeout = setTimeout(() => {
        setShowConfetti(false);
      }, 10000);

      return () => clearTimeout(timeout); 
    }
  }, [showConfetti]);
  */



  useEffect(() => {

    async function getGewinne() {
      const colRef = collection(db, "Users", currentUser.uid, "Gewinne");
      const Gewinne = await getDocs(colRef);

      let DocumentsArr = [];
      Gewinne.forEach(doc => {
        if(!doc.data().nichtMehrAnzeigen || !doc.data().gelesen) {
          DocumentsArr.push({
            id: doc.id,
            data: doc.data()}
          );
        }
      });
      setGewinne(DocumentsArr);
    }

    if(currentUser?.uid) {
      getGewinne();
    }

  }, [currentUser]);

  // ==========================

  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState(false);

  const handleExternalURL = (url) => {
    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
  };

  function handleSignOut() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        updateUser({});
        navigate("/login");
      })
      .catch((error) => {
        Swal.fire({
          title: "Fehler beim Logout",
          text: "Klicke erneut auf Logout um dich abzumelden.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  }


  const handleGewinnspielModalNichtErneutZeigen = (gewinnId) => {
  
    // update the document
    let indexGewinn = gewinne.findIndex((item) => item.id == gewinnId)

    if (indexGewinn !== -1) {

      let fieldsToUpdate = {
        gelesen: true,
        gelesenAm: new Date(),
        nichtMehrAnzeigen: true
      }

      const docRef = doc(db, "Users", currentUser.uid, "Gewinne", gewinnId);
    
      // write back the entire 'user' object
      updateDoc(docRef, fieldsToUpdate, { merge: true })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Gewinn',
          html: 'Dein Gewinn wird dir weiterhin im Bereich "Gewinnspiele" angezeigt.'
        });

        //update gewinne array!
        // Create a copy of the array to avoid modifying the original state directly
        const newData = [...gewinne];
        // Use splice to remove the element at the found index
        newData.splice(indexGewinn, 1);
        // Update the state with the modified array
        setGewinne(newData);
        setGewinnModal(false);    

      })
      .catch((err) => {
        console.log("Error updating document: ", err);
      });
    } else {
      console.log("Gewinn Eintrag mit ID nicht gefunden.");
    }

  }


  return (
    <>
    <div className="mobileApp">
      <HomeMobile />
    </div>

    <div className="desktopApp">
      {gewinne && gewinne.length >= 1 && 
        <>
          <Confetti
            width={width}
            height={height}
          />

          {gewinne.map(gewinn => 

            <GewinnModal
              key={gewinn.id}
              title={(gewinn.data.partner_name ? gewinn.data.partner_name : "VIN1")+"-Gewinnspiel: Du hast gewonnen!"}
              visible={gewinnModal}
              setVisible={() => {
                setGewinnModal(false);
              }}
            > 
              <p>
                Herzlichen Glückwunsch!<br />
                Du hast am Gewinnspiel: 
                <b> {gewinn.data.title}</b> vom&nbsp;
                  {gewinn.data.enddate?.seconds ?
                    formatDateTime(gewinn.data.enddate.seconds*1000)
                    : "Datum fehlerhaft"
                  }
                &nbsp;gewonnen!
              </p>

              <hr />
              <h6 style={{fontWeight: "bold"}}>Gewinn</h6>
              <p>{gewinn.data.message}</p>

              <hr />
              <h6 style={{fontWeight: "bold"}}>Was passiert als nächstes?</h6>
              <p>
                Der Gewinnspielveranstalter meldet sich spätestens in den nächsten Tagen bei dir, um dir den Gewinn zu übergeben.
                Du kannst dieses Fenster schließen. Deine Gewinne werden dir weiterhin im Bereich Gewinnspiele angezeigt.
              </p>

        
              <div className="d-flex justify-content-end">
                {/* Bleibt die ID hier gleich?  WIrd eingetragen in FB UID doc in Array */}
                <Button
                  onClick={() => handleGewinnspielModalNichtErneutZeigen(gewinn.id)} 
                >
                  Nicht erneut anzeigen
                </Button>
                <Button
                    onClick={() => {
                      setGewinnModal(false);
                    }}
                    variant={"danger"}
                    style={{marginLeft: "12px"}}
                  >
                    Schließen
                </Button>

              </div>
            </GewinnModal>

          )}

        </>
      }




      <Container className={style.ContainerHeader}>
        <Row className={style.iconheaderRow}>
          <div
            className={style.iconHeaderContainer}
            onClick={handleSignOut}
          >
            <span style={{ marginRight: "8px" }}>Logout</span>
            <FontAwesomeIcon
              className={style.icon}
              icon={faSignOutAlt}
              size="xl"
            />
          </div>
        </Row>
        <Row className={style.iconheaderRow}>      
          <div
            className={style.iconHeaderContainer}
            onClick={() => navigate("/meine-daten", { state: { props: user } })}
          >
            <span style={{ marginRight: "8px" }}>Meine Daten</span>
            <FontAwesomeIcon
              className={style.icon}
              icon={faUserAlt}
              size="xl"
            />
          </div>
        </Row>
        <Row className={style.iconheaderRow}>
          <div
            className={style.iconHeaderContainer}
            onClick={() => navigate("beratung")}
          >
            <span style={{ marginRight: "8px" }}>Jetzt beraten lassen</span>
            <FontAwesomeIcon
              className={style.icon}
              icon={faHeadset}
              size="xl"
            />
          </div>
        </Row>
      </Container>
      <Container className={style.containerKacheln} style={{ padding: 0 }}>
        <Row className={style.rowContainer}>
          <Col>
            <p className={style.slogan}>smart. digital. nachhaltig.</p>
            {/*<img
              className={style.vinlogo}
              src={require("../../img/VIN1_Logo_Lila.png")}
              alt=""
            />*/}
            <WebpImage
                webpSrc={require("../../img_webp/VIN1_Logo_Lila.webp")}
                fallbackSrc={require("../../img/VIN1_Logo_Lila.png")}
                alt="VIN1 Logo"
                className={style.vinlogo}
            />
            <br />
            <p
              className="text-vinColor"
              style={{
               width: "100%",
               textAlign: "center",
               fontFamily: "'Mouly', sans-serif",
               fontSize: "40px",
               margin: "10px 0px 40px 0px"
            }}>
              Mach dein VIN Ding!
            </p>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }}>
            <p className={style.boldText}>
              Hallo{user.vorname ? " " + user.vorname + "," : ", "} vintastisch
              Dich hier zu sehen!
            </p>
          </Col>
        </Row>



        {/*
        <Row className="mt-5">
          <Col style={{ textAlign: "center"}}>
            <h5>&#45; Connecte dich mit der Welt &#45;</h5>
          </Col>
        </Row>
        */}


        <Row className="justify-content-center">
          <Col xs={12} md={12} style={{ marginTop: "20px", cursor: "pointer"}}>
            <CardDoppelt
              style={{cursor: "pointer"}}
              url={"/news"}
              img={"NeueDokumente.png"}
              title={"Mit einem Klick alles im Blick"}
              shake={unreadDocs >= 1 ? true : false}
              text={
                <p>
                  {unreadDocs === 0 && (
                    <>
                      Für Dich liegen aktuell <b>keine neuen Informationen</b> vor!
                    </>
                  )}
                  {unreadDocs === 1 && (
                    <>
                      Für Dich liegt <b>eine neue Information</b> vor!
                    </>
                  )}
                  {unreadDocs > 1 && (
                    <>
                      Für Dich liegen <b>{unreadDocs} neue Informationen</b> vor!
                    </>
                  )}
                  <br />
                  Hier findest Du Deine Dokumente und die neuesten Informationen auf einen Blick.
                  <br />
                  <b>Schaue Dir jetzt deine persönlichen Informationen an!</b>
                </p>
              }
            />
          </Col>
        </Row>





        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/connect", { state: user })}
          >
            <CardDoppelt
              url={""}
              externalURL={""}
              img={"Connect.png"}
              title={"Connect"}
              text={
                <p>
                  Hier kannst Du Deine externen Verträge zusammenführen, Dateien an Deine Partner senden oder Kontakt aufnehmen.
                  <br />
                  <b>Jetzt deinen digitalen Makler nutzen!</b>
                </p>
              }
            />
          </Col>
        </Row>


        {/*
        <Row className="mt-5">
          <Col style={{ textAlign: "center" }}>
            <h5>&#45; VIN1 Vorteile &#45;</h5>
          </Col>
        </Row>
        */}

        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/informationen", { state: user })}
          >
            <CardDoppelt
              url={"informationen"}
              img={"Informationen.png"}
              title={"Informationen"}
              text={
                <p>
                  Finde hier Informationen zu den Themen
                  <br />
                  Versicherungen, Finanzen, Energie, Immobilien und mehr.
                  <br />
                  <b>Jetzt informieren!</b>
                </p>
              }
            />
          </Col>
        </Row>



        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => window.open("https://hello.friday.de/quote/selectPrecondition?partnerId=VINCAR01", "_blank")}
          >
            <CardDoppelt
              url={""}
              img={"Mobil.png"}
              title={"Dein Auto, Deine Regeln"}
              text={
                <p>
                  Mit unserer Versicherungslösung bestimmst Du den Kurs, während wir für den optimalen Schutz sorgen.
                  <br />
                  <b>Jetzt vergleichen!</b>
                </p>
              }
            />
          </Col>
        </Row>


        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => window.open("https://forms.vin1.eu/kreditrechner.html", "_blank")}
          >
            <CardDoppelt
              img={"Finanzen.png"}
              title={"Kreditrechner"}
              text={
                <p>
                  Finde den idealen Kredit für Deine Bedürfnisse! Unser benutzerfreundlicher Kreditrechner hilft Dir, eine maßgeschneiderte Finanzierungslösung zu finden.
                  <br />
                  <b>Jetzt vergleichen!</b>
                </p>
              }
            />
          </Col>
        </Row>



        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => window.open("https://forms.vin1.eu/baufinanzierung.html", "_blank")}
          >
            <CardDoppelt
              url={"baufinanzierung"}
              img={"Baufinanzierung.png"}
              title={"Wohntraum finanzieren"}
              text={
                <p>
                  Wir begleiten Dich auf dem Weg in die eigenen vier Wände mit der passendenden Finanzierungslösung und besten Konditionen!
                  <br />
                  <b>Jetzt vergleichen!</b>
                </p>
              }
            />
          </Col>
        </Row>



        {/*
          <Row style={{ marginTop: "16px", justifyContent: "center" }}>
            <Col
              xs={12}
              md={12}
              style={{ marginTop: "20px" }}
              onClick={() => navigate("/energie/rechner")}
            >
              <CardDoppelt
                url={"energie/rechner"}
                img={"Solar.png"}
                title={"Energie-Vergleich"}
                text={
                  <p>
                    Hier kannst du deinen passenden Strom und Gastarif finden.
                    <br />
                    Wir unterstützen dich bei der richtigen Auswahl.
                    <br />
                    <b>Jetzt passenden Tarif finden und sparen!</b>
                  </p>
                }
              />
            </Col>
          </Row>
        */}

      

        {/*<Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/tarif-taxi", { state: user })}
          >
            <CardDoppelt
              url={""}
              img={"TarifTaxi.jpg"}
              title={"Tarif Taxi"}
              text={
                <p>
                  Dein Tuning-Taxi durch den Tarif-Dschungel.
                  <br />
                  <b>Jetzt Angebote sichern!</b>
                </p>
              }
            />
          </Col>
        </Row>*/}

        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/cashback", { state: user })}
          >
            <CardDoppelt
              url={""}
              img={"Cashback.png"}
              title={"Cashback"}
              text={
                <p>
                  Wir belohnen Dich. Erhalte langfristige Prämien für Deine Empfehlungen.{" "}
                  <br />
                  <b>Jetzt dein Cashback sichern!</b>
                </p>
              }
            />
          </Col>
        </Row>


        {/*<Row className="justify-content-center" style={{ marginTop: "16px"}}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/schaden", { state: user })}
          >
            <CardDoppelt
              url={""}
              img={"Schaden.png"}
              title={"Schaden melden"}
              text={
                <p>
                  Wir kämpfen für dein Recht. Wir kümmern uns nicht nur um neue
                  Verträge, sondern lösen auch deine Probleme im Schadenfall.
                  Bleib entspannt, du hast jetzt uns!
                  <br />
                  <b>Jetzt Schaden melden!</b>
                </p>
              }
            />
          </Col>
        </Row>
        */}


        {/*<Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("informationen/partnerprogramm", { state: user })}
          >
            <CardDoppelt
              url={""}
              img={"PartnerProgramm.png"}
              title={"VIN1 Partnerprogramm"}
              text={
                <p>
                  Wir haben garantiert das richtige Partnerprogramm für Dich!
                  <br />
                  <b>Jetzt dein VIN DING finden!</b>
                </p>
              }
            />
          </Col>
        </Row>
        */}

        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/gewinnspiel", { state: user })}
          >
            <CardDoppelt
              url={""}
              img={"Gewinnspiel.png"}
              title={"Gewinnspiele"}
              text={
                <p>Hier findest Du alle unsere Gewinnspiele an denen Du kostenlos teilnehmen kannst.
                  <br />
                  <b>Jetzt Gewinne abstauben!</b>
                </p>
              }
            />
          </Col>
        </Row>

       


        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/vorteile", { state: { props: user } }) }
          >
            <CardDoppelt
              url={""}
              img={"Vorteilspartner.png"}
              title={"VIN1 Vorteilswelt"}
              text={
                <p>
                  Hier findest Du vintastische Produkte und Dienstleistungen, Rabatte und exklusive Vorteile abgestimmt auf jeden Lifestyle.
                  <br />
                  <b>Jetzt Vorteil nutzen!</b>
                </p>
              }
            />
          </Col>
        </Row>


        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/verbesserungen")}
          >
            <CardDoppelt
              url={""}
              img={"Verbesserungen.png"}
              title={"VIN1 Verbesserungsvorschläge"}
              text={
                <p>
                  Dein Feedback ist uns wichtig! Hier kannst Du Anregungen, Kritik und Verbesserungen an uns übermitteln.
                  <br />
                  <b>Jetzt mitgestalten!</b>
                </p>
              }
            />
          </Col>
        </Row>

      

      </Container>

      <br />

      {showModal == "aefdsf" && <WebViewModal url={modalUrl} hideModal={setShowModal} />}
      {!showModal == "wdfsdg" && <PDFViewerModal filename={"sample.pdf"} hideModal={setShowModal} />}

    </div>
    </>
  );
}

export default Home;
